import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import type { ComputersPageViewModel } from './computers-page-view-model.js';
import './d-computers-page-content-inner.js';

/**
 *
 * Displays the computers page. The page may have a query parameter `tab` that sets the initial value for the main tab component.
 *
 */
@customElement('d-computers-page-content')
export class DComputersPageContent extends PageContent<ComputersPageViewModel> {
  renderContent() {
    return html`
      <d-computers-page-content-inner .contentStickyTop=${this.contentStickyTop} .pageView=${this.pageView}>
      </d-computers-page-content-inner>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computers-page-content': DComputersPageContent;
  }
}
