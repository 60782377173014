/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PageViewModel
 */
export interface PageViewModel {
  /**
   *
   * @type {number}
   * @memberof PageViewModel
   */
  pageId: number;
  /**
   *
   * @type {string}
   * @memberof PageViewModel
   */
  name: string;
  /**
   *
   * @type {Array<PageViewModel>}
   * @memberof PageViewModel
   */
  subPages: Array<PageViewModel>;
  /**
   *
   * @type {string}
   * @memberof PageViewModel
   */
  helpContent: string;
  /**
   *
   * @type {number}
   * @memberof PageViewModel
   */
  order: number;
}

/**
 * Check if a given object implements the PageViewModel interface.
 */
export function instanceOfPageViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pageId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'subPages' in value;
  isInstance = isInstance && 'helpContent' in value;
  isInstance = isInstance && 'order' in value;

  return isInstance;
}

export function PageViewModelFromJSON(json: any): PageViewModel {
  return PageViewModelFromJSONTyped(json, false);
}

export function PageViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pageId: json['pageId'],
    name: json['name'],
    subPages: (json['subPages'] as Array<any>).map(PageViewModelFromJSON),
    helpContent: json['helpContent'],
    order: json['order'],
  };
}

export function PageViewModelToJSON(value?: PageViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pageId: value.pageId,
    name: value.name,
    subPages: (value.subPages as Array<any>).map(PageViewModelToJSON),
    helpContent: value.helpContent,
    order: value.order,
  };
}
