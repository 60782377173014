import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { EmployeeForStaffingCalendar } from 'src/store';
import * as dabihStore from 'src/store';
import { getPeriodDayData, getScheduleDayData, leavePeriodTypes, timeInMinutes } from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';
import '../library/components/d-popup.js';
import '../library/editors/components/d-select-time.js';
import '../library/editors/elements/d-edit-number.js';
import '../library/editors/elements/d-edit-textarea.js';
import '../library/editors/elements/d-select-date.js';

import '../library/elements/d-label.js';
import '../library/elements/d-section.js';
import '../library/fields/d-expansion.js';
import '../library/fields/d-view-info.js';

import '../pages/d-graded-leave.js';
import '../pages/d-list-leave-periods.js';
import { BaseDialog, DialogCancelResult, DialogDeleteResult } from 'src/library/components/BaseDialog.js';
import type { LeavePeriodDay } from 'src/store/api';
import { LeavePeriodDayLeaveStatusEnum } from 'src/store/api';
import { minutesToTime } from 'src/utilities/text.js';

export interface EditPeriodResult {
  type: string;
  confirmed: boolean;

  /**
   * The end date or date and time
   */
  end: string;

  /**
   * The start date or date and time
   */
  start: string;
  notes: string;

  employeeUuid: string;
  newPeriod: boolean;

  periodId?: string;

  /**
   * Used for sickLeave. Otherwise, use 100.
   */
  grade: number;

  days: LeavePeriodDay[];
}

export interface EditPeriodsInput {
  type: string;
  application: 'timekeeping' | '';
  newPeriod: boolean;
  employee: EmployeeForStaffingCalendar;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  confirmed: boolean;
  notes: string;
  grade: number;
  days: LeavePeriodDay[];
  currentUserHasAccess: boolean;
  leavePeriodEditRestriction: boolean;
}

export type EditPeriodsResult =
  | DialogCancelResult
  | DialogDeleteResult
  | {
      action: 'done';
      type: string;
      confirmed: boolean;

      /**
       * The end date or date and time
       */
      end: string;

      /**
       * The start date or date and time
       */
      start: string;
      notes: string;

      grade: number;

      days: LeavePeriodDay[];
    };

/**
 *
 * FIX must sync with master. Calculations have changed
 * Hente ny funksjonalitet, vise info og alerts for fraværstyper
 *
 * USAGE:
 *    d-timekeeping-list-section
 *    d-staffing-calendar-table
 *    d-staffing-employee-day-popup
 *
 */
@customElement('edit-periods-dialog')
export class EditPeriodsDialog extends BaseDialog<EditPeriodsInput, EditPeriodsResult> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      .popup-card {
        flex-basis: 700px;
      }

      #periodTypeSelect {
        width: 160px;
      }

      d-pikaday {
        width: 100px;
      }

      .grade {
        width: 50px;
        margin-right: 6px;
        text-align: right;
        padding-right: 0;
      }

      .confirmed select {
        width: 120px;
      }

      .validHours {
        flex: none;
        margin-right: 6px;
        margin-bottom: 6px;
        font-weight: bold;
      }
      .invalidHours {
        flex: none;
        font-weight: 200;
      }
    `,
  ];
  @property({ type: String })
  application: 'timekeeping' | '' = '';
  @property({ type: Boolean })
  newPeriod = false;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: String })
  periodStart = '';
  @property({ type: String })
  defaultPeriodType = 'vacation';
  @property({ type: String })
  periodList = 'leavePeriods';
  @property({ type: String })
  type = 'vacation';
  @property({ type: Number })
  grade = 100;
  @property({ type: Array })
  days: LeavePeriodDay[] = [];
  @property({ type: Array })
  editDays: any[] = [];
  @property({ type: Boolean })
  confirmed = false;
  @property({ type: String })
  startDate = '';
  @property({ type: String })
  startTime = 'NONE';
  @property({ type: String })
  endDate = '';
  @property({ type: String })
  endTime = 'NONE';
  @property({ type: String })
  notes = '';
  width = 700;
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  private holidays = dabihStore.holidaysForStaffingCalendar();
  private statusOptions = [
    { value: 'false', text: 'Ubekreftet' },
    { value: 'true', text: 'Bekreftet' },
  ];

  get start() {
    return this.startDate;
  }

  get startHours() {
    return this.startTime.split(':')[0];
  }

  get startMinutes() {
    return this.startTime.split(':')[1] || '00';
  }

  get end() {
    return this.endDate;
  }

  get endHours() {
    return this.endTime.split(':')[0];
  }

  get endMinutes() {
    return this.endTime.split(':')[1] || '00';
  }

  protected get calculatedTitle() {
    let employeeName = '';
    if (this.employee) {
      employeeName = this.employee.name;
    }

    if (this.application === 'timekeeping') {
      let typeTerm = 'avspasering';
      if (this.type === 'plusTime') {
        typeTerm = 'plusstid';
      }
      if (this.newPeriod) {
        return 'Ny ' + typeTerm + ' for ' + employeeName;
      }
      return 'Rediger ' + typeTerm + ' for ' + employeeName;
    }
    if (this.newPeriod) {
      return 'Ny fraværsperiode for ' + employeeName;
    }
    return 'Rediger fraværsperiode for ' + employeeName;
  }

  protected get calculatedHeaderActions() {
    return this.newPeriod
      ? [
          { name: 'Avbryt', action: 'close' },
          { name: 'Ferdig', action: 'save', disabled: this.periodIsInvalid },
        ]
      : [
          { name: 'Slett', action: 'delete' },
          { name: 'Avbryt', action: 'close' },
          { name: 'Ferdig', action: 'save' },
        ];
  }

  private get isTimekeeping(): boolean {
    return this.application === 'timekeeping';
  }

  private get isSickLeave() {
    return this.type === 'sickLeave';
  }

  private get alerts() {
    const alerts: string[] = [];
    const type = this.employeePeriod?.type ?? '';
    if (this.employee.uuid && this.startDate) {
      const leavePeriod = this.employeePeriod;
      if (this.employee) {
        const sickSelfPrevDay =
          this.employee.leavePeriods.filter((item) => {
            return (
              item.type === 'sickSelf' &&
              item.confirmed &&
              this._periodInRange(
                item.start,
                item.end,
                LocalDate.fromString(this.startDate).minusDays(1).toString(),
                this.startDate,
              )
            );
          }).length > 0;
        if (!leavePeriod && type === 'sickSelf' && sickSelfPrevDay) {
          alerts.push('staffing_sickSelfPrevDay');
        }
      }
    }
    if (type === 'sickSelf' || type === 'sickLeave') {
      alerts.push('staffing_workRelatedHealthIssueAlert');
    }
    return alerts;
  }

  private get oneYearBeforeStart() {
    return LocalDate.fromString(this.startDate).plusYears(-1).plusDays(1).toString();
  }

  private get employeePeriod() {
    if (this.employee) {
      const employeePeriod = this.employee[this.periodList];
      return employeePeriod?.filter((item) => item.start === this.periodStart)[0];
    }
  }

  private get info() {
    const info: string[] = [];
    if (this.type === 'vacation') {
      info.push('staffing_vacationRules');
    }
    if (this.type === 'sickSelf') {
      info.push('staffing_sickSelfRules');
    }
    if (this.type === 'sickChildren') {
      info.push('staffing_sickChildrenRules');
    }
    return info;
  }

  /**
   * Virtual list of days so that it can change if dates or grade is changed before the user starts editing the days.
   *
   * @private
   */
  private get calculatedDays(): LeavePeriodDay[] {
    if (this.grade === 100) {
      return [];
    } else {
      const startDate = LocalDate.fromString(this.start);
      const endDate = LocalDate.fromString(this.end);
      return LocalDate.dates(startDate, endDate.plusDays(1)).map((day): LeavePeriodDay => {
        const leavePeriodDay = this.days.find((x) => x.date === day.toString());
        if (leavePeriodDay !== undefined) {
          return leavePeriodDay;
        } else {
          const s = getScheduleDayData(day, this.employee);
          const holiday = this.holidays[day.toString()] ?? '';
          if (s.holidays && holiday) {
            return {
              end: '00:00',
              leaveStatus: LeavePeriodDayLeaveStatusEnum.Leave,
              start: '00:00',
              date: day.toString(),
            };
          } else if (s.workHours) {
            const scheduledMinutes = dabihStore.timeInMinutes(s.end) - dabihStore.timeInMinutes(s.start);
            const endInMinutes = dabihStore.timeInMinutes(s.start) + (scheduledMinutes * (100 - this.grade)) / 100;

            const editEnd = minutesToTime(endInMinutes);
            return {
              end: editEnd,
              leaveStatus: LeavePeriodDayLeaveStatusEnum.Work,
              start: s.start,
              date: day.toString(),
            };
          } else {
            return {
              end: '00:00',
              leaveStatus: LeavePeriodDayLeaveStatusEnum.Leave,
              start: '00:00',
              date: day.toString(),
            };
          }
        }
      });
    }
  }

  _hideTypeSelect(application) {
    return application === 'timekeeping';
  }

  _isAllDayType(type) {
    const allDayTypes = ['vacation', 'sickLeave'];
    return allDayTypes.indexOf(type) > -1;
  }

  _defaultPeriodTypeChange() {
    this.type = this.defaultPeriodType;
  }

  _inPeriod(day, start, end) {
    const dayDate = LocalDate.fromString(day);
    const startDate = LocalDate.fromString(start);
    const endDate = LocalDate.fromString(end);
    return dayDate.isSameOrAfter(startDate) && dayDate.isSameOrBefore(endDate);
  }

  _isEmpty(arr) {
    return arr.length === 0;
  }

  _contains(alerts, alert) {
    return alerts.indexOf(alert) > -1;
  }

  _periodInRange(periodStart, periodEnd, rangeStart, rangeEnd) {
    if (periodStart && periodEnd && rangeStart && rangeEnd) {
      const periodStartDate = LocalDate.fromString(periodStart);
      const periodEndDate = LocalDate.fromString(periodEnd);
      const rangeStartDate = LocalDate.fromString(rangeStart);
      const rangeEndDate = LocalDate.fromString(rangeEnd);
      return (
        (periodStartDate.isSameOrBefore(rangeEndDate) && periodStartDate.isSameOrAfter(rangeStartDate)) ||
        (periodEndDate.isSameOrAfter(rangeStartDate) && periodStartDate.isSameOrBefore(rangeEndDate))
      );
    }
    return false;
  }

  _sickSelfPrevDay(employee, start) {
    return (
      employee.leavePeriods.filter(
        (item) =>
          item.type === 'sickSelf' &&
          item.confirmed &&
          this._periodInRange(item.start, item.end, LocalDate.fromString(start).minusDays(1).toString(), start),
      ).length > 0
    );
  }

  onDaysChanged(e: CustomEvent<{ days: LeavePeriodDay[] }>) {
    this.days = e.detail.days;
  }

  protected fetchResult(detail: string | undefined): EditPeriodsResult {
    if (detail === 'save') {
      return {
        action: 'done',
        end: this.endDate + (this.endTime === 'NONE' ? '' : ' ' + this.endTime),
        start: this.startDate + (this.startTime === 'NONE' ? '' : ' ' + this.startTime),
        type: this.type,
        confirmed: this.confirmed,
        notes: this.notes,
        grade: this.grade,
        days: this.calculatedDays,
      };
    }

    if (detail === 'delete') {
      return {
        action: 'delete',
      };
    }

    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: EditPeriodsInput) {
    this.employee = input.employee;
    this.application = input.application;
    this.newPeriod = input.newPeriod;
    this.startTime = input.startTime === '' ? 'NONE' : input.startTime;
    this.endTime = input.endTime === '' ? 'NONE' : input.endTime;
    this.startDate = input.startDate;
    this.endDate = input.endDate;
    this.type = input.type;
    this.notes = input.notes;
    this.confirmed = input.confirmed;
    this.grade = input.grade;
    this.days = input.days;
    this.currentUserHasAccess = input.currentUserHasAccess;
    this.leavePeriodEditRestriction = input.leavePeriodEditRestriction;
  }

  private get periodHoursText() {
    const startDate = LocalDate.fromString(this.startDate);
    const endDate = LocalDate.fromString(this.endDate);
    const dates = LocalDate.dates(startDate, endDate.plusDays(1));
    let minutesTotal = 0;
    let validMinutesTotal = 0;
    let validHoursTypeTerm = '';
    const period = {
      type: this.type,
      start: this.startDate,
      end: this.endDate,
      startTime: this.startTime === 'NONE' ? '00:00' : this.startTime,
      endTime: this.endTime === 'NONE' ? '24:00' : this.endTime,
      notes: this.notes,
      confirmed: this.confirmed,
    };
    dates.forEach((date) => {
      const periodDayData = getPeriodDayData(this.employee, period, this.type, date, undefined);
      minutesTotal += periodDayData.minutes;
      validMinutesTotal += periodDayData.validMinutes;
      if (this.type === 'timeOff') {
        validHoursTypeTerm = 'avspaseringstime';
      }
      if (this.type === 'plusTime') {
        validHoursTypeTerm = 'plusstime';
      }
    });
    const hours = Math.round((minutesTotal / 60 + Number.EPSILON) * 100) / 100;
    const validHours = Math.round((validMinutesTotal / 60 + Number.EPSILON) * 100) / 100;
    const invalidHours = hours - validHours;
    if (validHours !== 1) {
      validHoursTypeTerm += 'r';
    }
    let invalidHoursText = '';
    if (invalidHours) {
      if (this.type === 'timeOff') {
        invalidHoursText = 'Avspasering ut over vanlig arbeidstid telles ikke';
      }
      if (this.type === 'plusTime') {
        invalidHoursText = 'Plusstid innenfor vanlig arbeidstid telles ikke';
      }
    }
    return {
      validHoursText: validHours + ' ' + validHoursTypeTerm,
      invalidHoursText,
    };
  }
  protected renderBody() {
    return html`
      <d-section>
        <d-wrap>
          <d-wrap>
            ${this.application === 'timekeeping'
              ? nothing
              : html`<d-select-dropdown
                  .options=${leavePeriodTypes}
                  .value=${this.type}
                  @value-changed=${this.onTypeChanged}
                ></d-select-dropdown>`}
            <d-select-dropdown
              label="status"
              inline-label
              light-label
              .options=${this.statusOptions}
              .value=${this.confirmed ? 'true' : 'false'}
              ?disabled=${!this.currentUserHasAccess && this.leavePeriodEditRestriction}
              @value-changed=${this.onConfirmedChanged}
            ></d-select-dropdown>
          </d-wrap>
          <d-wrap>
            <d-wrap>
              <d-select-date
                in-dialog
                label="fra"
                inline-label
                light-label
                ?invalid="${this.periodIsInvalid}"
                .value=${this.startDate}
                @value-changed=${this.onStartDateChanged}
              ></d-select-date>

              <d-select-time
                whole-day-option
                ?invalid="${this.periodIsInvalid}"
                max="23:50"
                .value=${this.startTime}
                @value-changed=${this.onStartTimeChanged}
              ></d-select-time>
            </d-wrap>
            <d-wrap>
              <d-select-date
                in-dialog
                label="til"
                inline-label
                light-label
                ?invalid="${this.periodIsInvalid}"
                .value=${this.endDate}
                @value-changed=${this.onEndDateChanged}
              ></d-select-date>
              <d-select-time
                whole-day-option
                ?invalid="${this.periodIsInvalid}"
                min="00:05"
                .value=${this.endTime}
                @value-changed=${this.onEndTimeChanged}
              ></d-select-time>
            </d-wrap>
          </d-wrap>
          ${this.isSickLeave
            ? html`
                <d-wrap>
                  <d-edit-number
                    select-on-focus
                    min="5"
                    max="100"
                    label="Sykmeldingsprosent"
                    inline-label
                    light-label
                    .value=${this.grade}
                    @value-changed=${this.onGradeChanged}
                  ></d-edit-number>
                </d-wrap>
              `
            : nothing}
        </d-wrap>
      </d-section>
      ${this.periodIsInvalid
        ? html`
            <d-section>
              <d-view-info .content=${`<ul><li>Starttid må være før sluttid</li></ul>`} alert alert-list></d-view-info>
            </d-section>
          `
        : nothing}
      ${this.grade !== 100 && !this.periodIsInvalid
        ? html` <d-graded-leave
            .employee=${this.employee}
            .grade=${this.grade}
            .start=${this.startDate}
            .end=${this.endDate}
            .days=${this.calculatedDays}
            @days-changed=${this.onDaysChanged}
          ></d-graded-leave>`
        : nothing}
      ${this.renderTimekeepingPeriodsInfo()}

      <d-section>
        <d-edit-textarea label="Notater" .value=${this.notes} @value-changed=${this.onNotesChanged}></d-edit-textarea>
      </d-section>

      ${this.renderAlerts()}
      ${this.type === 'vacation'
        ? html`
            <d-section
              .label=${'Ferie registrert på ' +
              this.employee.name +
              ' i ' +
              LocalDate.fromString(this.startDate).year()}
            >
              <d-list-leave-periods
                cropped
                .employee=${this.employee}
                .start=${'' + LocalDate.fromString(this.startDate).year() + '-01-01'}
                .end=${'' + LocalDate.fromString(this.startDate).year() + '-12-31'}
                sumType="workdays"
                type="vacation"
              >
              </d-list-leave-periods>
            </d-section>
          `
        : nothing}
      ${this.type === 'sickLeave'
        ? html` <d-section
            .label=${'Sykmeldinger registrert på ' +
            this.employee.name +
            ' i ' +
            LocalDate.fromString(this.startDate).year()}
          >
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .start=${'' + LocalDate.fromString(this.startDate).year() + '-01-01'}
              .end=${'' + LocalDate.fromString(this.startDate).year() + '-12-31'}
              sumType="cropped"
              type="sickLeave"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${this.type === 'sickSelf'
        ? html` <d-section .label=${'Egenmeldinger registrert på ' + this.employee.name + ' siste tolv måneder'}>
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .end=${this.startDate}
              .start=${this.oneYearBeforeStart}
              sumType="cropped"
              secondarySumType="full"
              type="sickSelf"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${this.type === 'sickChildren'
        ? html` <d-section
            .label=${'Omsorgsdager registrert på ' +
            this.employee.name +
            ' i ' +
            LocalDate.fromString(this.startDate).year()}
          >
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .start=${'' + LocalDate.fromString(this.startDate).year() + '-01-01'}
              .end=${'' + LocalDate.fromString(this.startDate).year() + '-12-31'}
              sumType="full"
              type="sickChildren"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${html`<d-expansion .opened=${this.info.length > 0}>
        <d-expansion ?opened=${this._contains(this.info, 'staffing_vacationRules')}>
          <d-section>
            <d-view-info field="staffing_vacationRules"></d-view-info>
          </d-section>
        </d-expansion>
        <d-expansion .opened=${this._contains(this.info, 'staffing_sickSelfRules')}>
          <d-section>
            <d-view-info field="staffing_sickSelfRules"></d-view-info>
          </d-section>
        </d-expansion>
        <d-expansion .opened=${this._contains(this.info, 'staffing_sickChildrenRules')}>
          <d-section>
            <d-view-info field="staffing_sickChildrenRules"></d-view-info>
          </d-section>
        </d-expansion>
      </d-expansion>`}
    `;
  }

  private renderAlerts() {
    return html`<d-expansion ?opened=${!this._isEmpty(this.alerts)}>
      <d-expansion ?opened=${this._contains(this.alerts, 'staffing_sickSelfPrevDay')}>
        <d-section>
          <d-view-info field="staffing_sickSelfPrevDay" alert alert-list></d-view-info>
        </d-section>
      </d-expansion>
      <d-expansion ?opened=${this._contains(this.alerts, 'staffing_workRelatedHealthIssueAlert')}>
        <d-section>
          <d-view-info field="staffing_workRelatedHealthIssueAlert" alert alert-list></d-view-info>
        </d-section>
      </d-expansion>
    </d-expansion>`;
  }

  private renderTimekeepingPeriodsInfo() {
    if ((this.type === 'timeOff' || this.type === 'plusTime') && !this.periodIsInvalid) {
      return html`
        <d-section>
          <div class="validHours">${this.periodHoursText.validHoursText}</div>
          ${this.periodHoursText.invalidHoursText
            ? html` <div class="invalidHours">${this.periodHoursText.invalidHoursText}</div> `
            : nothing}
        </d-section>
      `;
    } else {
      return nothing;
    }
  }

  private onTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.type = e.detail.value;
  }

  private onConfirmedChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.confirmed = e.detail.value === 'true';
  }

  private get periodIsInvalid() {
    const startDate = LocalDate.fromString(this.startDate);
    const endDate = LocalDate.fromString(this.endDate);
    return (
      startDate.isAfter(endDate) ||
      (startDate.isSame(endDate) &&
        this.startTime !== 'NONE' &&
        this.endTime !== 'NONE' &&
        timeInMinutes(this.endTime) < timeInMinutes(this.startTime) + 5)
    );
  }

  private onStartDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.startDate = e.detail.value;
  }

  private onEndDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.endDate = e.detail.value;
  }

  private onStartTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (this.startTime === 'NONE') {
      let endTimeInMinutes = timeInMinutes(e.detail.value) + 60;
      if (endTimeInMinutes > 1435) {
        endTimeInMinutes = 1435;
      }
      this.endTime = minutesToTime(endTimeInMinutes);
    }
    this.startTime = e.detail.value;
    if (this.startTime === 'NONE' && this.endTime !== 'NONE') {
      this.endTime = 'NONE';
    } else if (this.startTime !== 'NONE' && this.endTime === 'NONE') {
      this.endTime = this.startTime;
    }
  }

  private onEndTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (this.endTime === 'NONE') {
      let startTimeInMinutes = timeInMinutes(e.detail.value) - 60;
      if (startTimeInMinutes < 0) {
        startTimeInMinutes = 0;
      }
      this.startTime = minutesToTime(startTimeInMinutes);
    }
    this.endTime = e.detail.value;
    if (this.endTime === 'NONE' && this.startTime !== 'NONE') {
      this.startTime = 'NONE';
    } else if (this.endTime !== 'NONE' && this.startTime === 'NONE') {
      this.startTime = this.endTime;
    }
  }

  private onGradeChanged(e: CustomEvent<{ value: number }>) {
    e.stopPropagation();
    this.grade = e.detail.value;
    if (this.grade < 5) {
      this.grade = 5;
    } else if (this.grade > 100) {
      this.grade = 100;
    }
  }

  private onNotesChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.notes = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-periods-dialog': EditPeriodsDialog;
  }
}
