import { css, html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { displayAlert } from 'src/utilities/display-alert.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-edit-textarea.js';

import '../../library/editors/elements/d-select-dropdown.js';
import type { SelectDropdownOption } from '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-radio.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import Bugsnag from '@bugsnag/js';

export interface Subscription {
  invoiceReceiver: string;
  invoiceAddress: string;
  invoicePostcode: string;
  invoiceLocality: string;
  invoiceReference: string;
  ownerEmail: string;
  specialTerms: string;
  invoiceSendMethod: 'MANUAL' | 'EHF';
  invoiceOrganizationNumber: string;
  singleUser: boolean;
  requiresSecureLogin: boolean;
}

/**
 *
 *
 */
@customElement('d-organization-subscription')
export class DOrganizationSubscription extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-wrap > *:last-child {
      margin-left: 6px;
    }
    d-wrap > * {
      margin-bottom: 4px;
      flex-grow: 1;
      flex-shrink: 1;
    }
    .action {
      padding: 6px 0;
      font-family: var(--small);
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
      cursor: pointer;
    }
    .flexbox-wrap > * {
      margin-right: 12px;
      margin-bottom: 12px;
    }
    .flexBasis600 {
      flex-basis: 600px;
    }
    .flexBasis300 {
      flex-basis: 300px;
    }

    a.textLink {
      color: black;
      display: inline-block;
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.3);
      text-decoration: none;
    }
  `;
  private static systemVersionOptions: SelectDropdownOption[] = [
    { value: 'standard', text: 'Standard' },
    { value: 'singleUser', text: 'Forenklet' },
  ];
  /**
   * @private
   */
  private static specialTermsOptions: SelectDropdownOption[] = [
    { value: 'NONE', text: 'Ingen' },
    { value: 'DNLF', text: 'Den norske legeforening' },
    { value: 'NTF', text: 'Den norske tannlegeforening' },
    { value: 'NFF', text: 'Norsk Fysioterapeutforbund' },
    { value: 'NKF', text: 'Norsk Kiropraktorforening' },
    { value: 'NMF', text: 'Norsk Manuellterapeutforening' },
    { value: 'NOF', text: 'Norsk Osteopatforbund' },
    { value: 'NPF', text: 'Norsk psykologforening' },
    { value: 'NNF', text: 'Norges Naprapatforbund' },
  ];
  private static subscriptionTypeOptions = [
    { value: 'EHF', text: 'EHF (elektronisk faktura)' },
    { value: 'MANUAL', text: 'Papirfaktura' },
  ];
  @query('#ownerEmail')
  ownerEmail!: HTMLSelectElement;
  @property({ type: Object })
  subscription!: Subscription;
  @property({ type: Object })
  editItem: Subscription = {
    invoiceReceiver: '',
    invoiceAddress: '',
    invoicePostcode: '',
    invoiceLocality: '',
    invoiceReference: '',
    ownerEmail: '',
    specialTerms: '',
    invoiceSendMethod: 'MANUAL',
    invoiceOrganizationNumber: '',
    singleUser: false,
    requiresSecureLogin: false,
  };
  @property({ type: Object })
  elmaParams = {};
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Array })
  availableOwnerEmployees: SelectDropdownOption[] = [];
  @property({ type: Boolean })
  singleUserVersionAvailable = false;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Array })
  administratorEmployeesList: {
    name: string;
    firstName: string;
    lastName: string;
    uuid: string;
    email: string | undefined;
  }[] = [];

  private get systemVersion() {
    return this.editItem.singleUser ? 'singleUser' : 'standard';
  }
  elmaLookup: (q: string) => Promise<{ text: string; value: string }[]> = () => Promise.resolve([]);

  async onUpdateSubscription() {
    const v = await this.elmaLookup(this.editItem.invoiceOrganizationNumber);
    if (!this._stringIsEmpty(this.editItem.invoiceOrganizationNumber) && !this.validInvoiceOrganizationNumber()) {
      displayAlert('Organisasjonsnummer må bestå av 9 siffer.');
    } else if (this.editItem.invoiceSendMethod === 'EHF' && !this.validInvoiceOrganizationNumber()) {
      displayAlert('Valg av EHF krever gyldig organisasjonsnummer.');
    } else if (this.editItem.invoiceSendMethod === 'EHF' && v.length !== 1) {
      displayAlert('Valg av EHF krever gyldig organisasjonsnummer. Finner ikke mottaker registrert i ELMA.');
    } else if (this._isValid()) {
      this.dispatchEvent(
        new CustomEvent<Subscription>('update-subscription', { composed: true, bubbles: true, detail: this.editItem }),
      );
    } else {
      Bugsnag.notify(new Error('Invalid subscription ' + JSON.stringify(this.editItem)));
      displayAlert('Fyll inn alle felt med gyldige verdier');
    }
  }

  _stringIsEmpty(value: string) {
    return value ? value.trim().length === 0 : true;
  }

  _isValid() {
    const t = this.editItem;

    return (
      !this._stringIsEmpty(t.ownerEmail) &&
      !this._stringIsEmpty(t.specialTerms) &&
      !this._stringIsEmpty(t.invoiceSendMethod) &&
      !this._stringIsEmpty(t.invoiceReceiver) &&
      !this._stringIsEmpty(t.invoiceAddress) &&
      !this._stringIsEmpty(t.invoicePostcode) &&
      !this._stringIsEmpty(t.invoiceLocality)
    );
  }

  render() {
    return html`
      <d-section>
        <d-wrap>
          <d-select-dropdown
            label="Administrator"
            .value=${this.editItem.ownerEmail}
            .options=${this.availableOwnerEmployees}
            toolbar
            system-content
            class="flexBasis300"
            @value-changed=${this.onOwnerEmailChanged}
          >
          </d-select-dropdown>
          <d-select-dropdown
            field="organization_systemVersion"
            ?disabled=${!this.singleUserVersionAvailable}
            .value=${this.systemVersion}
            .options=${DOrganizationSubscription.systemVersionOptions}
            toolbar
            system-content
            class="flexBasis300"
            @value-changed=${this.onSystemVersionChanged}
          >
          </d-select-dropdown>
        </d-wrap>
      </d-section>

      <d-section>
        <d-select-dropdown
          label="Medlem av"
          .value=${this.editItem.specialTerms}
          .options=${DOrganizationSubscription.specialTermsOptions}
          toolbar
          system-content
          class="flexBasis600"
          @value-changed=${this.onSpecialTermsChanged}
        >
        </d-select-dropdown>
      </d-section>

      <d-section>
        <d-edit-text
          class="flexBasis600"
          label="Fakturamottaker"
          value="${this.editItem.invoiceReceiver}"
          toolbar
          system-content
          @value-changed=${this.onInvoiceReceiverChanged}
        ></d-edit-text>

        <d-edit-text
          class="flexBasis600"
          label="Organisasjonsnummer"
          value="${this.editItem.invoiceOrganizationNumber}"
          placeholder="9 siffer"
          toolbar
          system-content
          @value-changed=${this.onInvoiceOrganizationNumberChanged}
        ></d-edit-text>

        <d-edit-textarea
          class="flexBasis600"
          label="Fakturaadresse"
          value="${this.editItem.invoiceAddress}"
          toolbar
          system-content
          @value-changed=${this.onInvoiceAddressChanged}
        ></d-edit-textarea>

        <d-wrap>
          <d-edit-text
            class="flexBasis300"
            label="Postnummer"
            value="${this.editItem.invoicePostcode}"
            toolbar
            system-content
            @value-changed=${this.onInvoicePostcodeChanged}
          ></d-edit-text>

          <d-edit-text
            class="flexBasis300"
            label="Poststed"
            value="${this.editItem.invoiceLocality}"
            toolbar
            system-content
            @value-changed=${this.onInvoiceLocalityChanged}
          ></d-edit-text>
        </d-wrap>
      </d-section>
      <d-section>
        <d-edit-text
          class="flexBasis600"
          label="Fakturareferanse/ressursnummer"
          value="${this.editItem.invoiceReference}"
          toolbar
          system-content
          @value-changed=${this.onInvoiceReferenceChanged}
        ></d-edit-text>
        <d-select-radio
          label="Fakturatype"
          option-direction="horizontal"
          .options="${DOrganizationSubscription.subscriptionTypeOptions}"
          .value="${this.editItem.invoiceSendMethod}"
          toolbar
          outskirts
          system-content
          @value-changed=${this.onInvoiceSendMethodChanged}
        >
        </d-select-radio>
      </d-section>

      <d-section>
        <div>
          <d-wrap split>
            <div></div>
            <div class="action" @click=${this.onUpdateSubscription}>Oppdater</div>
          </d-wrap>
        </div>
      </d-section>

      <d-section>
        <div>
          <h2>Brukeravtale</h2>
          <p>
            Vilkår for bruk av TrinnVis:
            <a class="textLink" href="https://trinnvis.no/om-trinnvis/brukeravtale/" target="_blank">Brukeravtalen</a>
          </p>
        </div>
      </d-section>

      <d-section>
        <div>
          <h2>Innloggingskrav</h2>
          <d-wrap split>
            ${this.editItem.requiresSecureLogin
              ? html`<div>
                    <p>Alle brukere må logge inn med BankID eller HelseID</p>
                  </div>
                  <div>
                    <div class="action" @click=${() => this._disconnectSecureLogin()}>Skru av krav</div>
                  </div>`
              : html`<div>
                    <p>Innlogging med BankID eller HelseID er valgfritt</p>
                  </div>
                  <div>
                    <div class="action" @click=${() => this._connectSecureLogin()}>Skru på krav</div>
                  </div>`}
          </d-wrap>
        </div>
      </d-section>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.editItem = this.subscription;
  }

  private validInvoiceOrganizationNumber() {
    return /\d{9}/.test(this.editItem.invoiceOrganizationNumber);
  }

  private onOwnerEmailChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, ownerEmail: e.detail.value };
  }

  private onSystemVersionChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, singleUser: e.detail.value === 'singleUser' };
  }

  private onSpecialTermsChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, specialTerms: e.detail.value };
  }

  private onInvoiceSendMethodChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoiceSendMethod: e.detail.value === 'EHF' ? 'EHF' : 'MANUAL' };
  }

  private onInvoiceOrganizationNumberChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoiceOrganizationNumber: e.detail.value.trim() };
  }

  private onInvoiceReceiverChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoiceReceiver: e.detail.value };
  }

  private onInvoiceAddressChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    console.log('this.editItem', this.editItem);
    this.editItem = { ...this.editItem, invoiceAddress: e.detail.value };
  }

  private onInvoicePostcodeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoicePostcode: e.detail.value };
  }

  private onInvoiceLocalityChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoiceLocality: e.detail.value };
  }

  private onInvoiceReferenceChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, invoiceReference: e.detail.value };
  }

  private _disconnectSecureLogin() {
    this.dispatchEvent(new CustomEvent('disconnect-secure-login', { composed: true, bubbles: true }));
  }

  private _connectSecureLogin() {
    this.dispatchEvent(new CustomEvent('connect-secure-login', { composed: true, bubbles: true }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-subscription': DOrganizationSubscription;
  }
}
