import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { property, query } from 'lit/decorators.js';
import '../elements/d-wrap.js';
import '../elements/d-action.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { DDialogContext } from 'src/outskirts/account/d-dialog-context.js';
import '../fields/d-closer.js';

/**
 *
 *
 * @fires close The dialog event when user closes the popup.
 *
 */
export interface DialogCancelResult {
  action: 'cancel';
}
export interface DialogDeleteResult {
  action: 'delete';
}

export abstract class BaseDialog<Input, Result> extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      dialog[open] {
        display: flex;
        flex-direction: column;
        outline: none;
        background: white;
        min-width: 356px;
        max-width: calc(100vw - 20px);
        max-height: calc((var(--vh, 1vh) * 100) - 140px);
        border: none;
        border-radius: 12px;
        padding: 0;
        box-shadow: 0 16px 24px 2px hsla(0, 0%, 0%, 0.3);
      }

      ::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1000;
        background-color: hsla(197, 82%, 20%, 0.6);
        backdrop-filter: blur(2px);
      }

      .closer {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
      }

      .header {
        flex: none;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        padding: 16px 20px 12px 20px;
      }

      .title {
        flex-grow: 1;
      }

      .main-title {
        font-size: 22px;
        font-weight: bold;
      }

      .sub-title {
        font-size: 20px;
        font-weight: 400;
      }

      :host([employee-day]) .sub-title div:last-child {
        color: var(--holidayColor);
      }

      .header-actions {
        margin-right: -8px;
        padding: 0 0 6px 12px;
      }

      .body {
        flex-grow: 1;
        padding: 0 20px;
        overflow-y: auto;
      }

      .footer {
        flex: none;
        min-height: 16px;
        padding: 0 20px;
      }

      .footer-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: 1px solid var(--borderColor);
        padding: 12px 0;
      }
    `,
  ];
  private static stack = 0;
  @property({ type: Boolean })
  show = false;
  @property({ type: Number, attribute: 'width' })
  width = 600;
  @property({ type: String })
  title = '';
  @property({ type: String })
  subtitle = '';
  @property({ type: String })
  subtitleSecond = '';
  @property({ type: Boolean })
  cancelOnOutsideClick = false;
  @property({ type: Array })
  headerActions: ActionInput[] = [];
  @property({ type: Array })
  footerActions: ActionInput[] = [];
  @query('dialog')
  private popupElement: HTMLDialogElement | undefined;

  protected get calculatedTitle(): string {
    return this.title;
  }

  protected get calculatedSubtitle(): string {
    return this.subtitle;
  }

  protected get calculatedHeaderActions(): ActionInput[] {
    return this.headerActions;
  }
  protected get calculatedFooterActions(): ActionInput[] {
    return this.footerActions;
  }

  private get hideCloser() {
    return this.calculatedHeaderActions.length > 0 || this.calculatedFooterActions.length > 0;
  }

  private get cardWidthStyle() {
    return 'width: ' + this.width + 'px';
  }

  static async open<Dialog extends BaseDialog<Input, Result>, Input, Result>(
    this: { new (): Dialog },
    input: Input,
  ): Promise<Result> {
    return await BaseDialog.openDialog(this, input);
  }

  static async openDialog<Dialog extends BaseDialog<Input, Result>, Input, Result>(
    DialogCreator: { new (): Dialog },
    input: Input,
  ): Promise<Result> {
    const dialog = new DialogCreator();

    dialog.initializeDialog(input);
    DDialogContext.addDialog(dialog);

    await dialog.updateComplete;

    const resolver: Promise<Result> = new Promise<Result>((res) => {
      dialog.addEventListener<any>(
        'close',
        (e: CustomEvent<string>) => {
          console.log('closing', e.detail);
          e.stopPropagation();
          const result = dialog.fetchResult(dialog.popupElement?.returnValue);
          console.log(result);
          dialog.remove();
          res(result);
        },
        { once: true },
      );
    });

    await dialog.showModal();
    return resolver;
  }

  lock() {
    BaseDialog.stack = BaseDialog.stack + 1;
    const body = document.body;
    if (body.style.position !== 'fixed') {
      const html = document.documentElement;
      const w = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
      const h = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const x = window.scrollX;
      const y = window.scrollY;
      body.style.width = `${w}px`;
      body.style.height = `${h}px`;
      body.style.left = `-${x}px`;
      body.style.top = `-${y}px`;
      body.style.position = 'fixed';
    }
  }

  unlock() {
    BaseDialog.stack = BaseDialog.stack - 1;
    if (!BaseDialog.stack) {
      const body = document.body;
      const x = body.style.left;
      const y = body.style.top;
      body.style.removeProperty('width');
      body.style.removeProperty('height');
      body.style.removeProperty('left');
      body.style.removeProperty('top');
      body.style.removeProperty('position');
      body.style.top = '';
      window.scrollTo(parseInt(x || '0') * -1, parseInt(y || '0') * -1);
    }
  }

  onClick(event) {
    if (
      this.shadowRoot &&
      this.popupElement &&
      event.target === this.popupElement &&
      (this.cancelOnOutsideClick || !this.hideCloser)
    ) {
      this.popupElement.close();
    }
  }

  onClose(e) {
    console.log('onClose', e);
    this.show = false;
    this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true, detail: e.detail }));
    this.unlock();
  }

  async showModal() {
    await this.updateComplete;
    if (this.popupElement) {
      this.popupElement.showModal();
      this.lock();
    }
  }

  render() {
    return html`
      <dialog id="popup" style="${this.cardWidthStyle}" @click=${this.onClick} @close=${this.onClose}>
        ${this.hideCloser ? nothing : html`<d-closer @click=${() => (this.show = false)}></d-closer> `}
        <div class="header">${this.renderHeader()}</div>
        ${this.renderPostHeader()}
        <div class="body">${this.renderBody()}</div>
        <div class="footer">${this.renderFooter()}</div>
      </dialog>
    `;
  }

  toggleShow() {
    if (this.shadowRoot && this.popupElement) {
      if (this.show) {
        this.popupElement.showModal();
      } else {
        this.popupElement.close();
      }
    }
  }

  close() {
    if (this.shadowRoot) {
      const popup = this.shadowRoot.getElementById('popup') as HTMLDialogElement;
      if (popup) {
        popup.close();
      }
    }
  }

  protected onDispatchAction(disabled: boolean, action: string) {
    if (!disabled) {
      this.popupElement?.close(action);
    }
  }

  protected abstract fetchResult(detail: string | undefined): Result;

  protected abstract initializeDialog(input: Input);

  protected renderHeader() {
    return html`
      ${this.calculatedHeaderActions.length > 0
        ? html`
            <div class="header-actions">
              ${this.calculatedHeaderActions.map((a) => {
                return html` <d-action
                  @click=${() => this.onDispatchAction(a.disabled ?? false, a.action ?? '')}
                  ?disabled=${a.disabled}
                  ?delete=${a.action === 'delete'}
                  >${a.name}</d-action
                >`;
              })}
            </div>
          `
        : nothing}
      <d-wrap class="title" baseline tight>
        <div class="main-title">${this.calculatedTitle}</div>
        <d-wrap baseline tight class="sub-title">
          <div>${this.calculatedSubtitle}</div>
          <div>${this.subtitleSecond}</div>
        </d-wrap>
      </d-wrap>
    `;
  }

  protected renderPostHeader() {
    return html``;
  }

  protected renderBody() {
    return html`<slot></slot>`;
  }

  protected renderFooter() {
    return html`
      ${this.calculatedFooterActions.length > 0
        ? html`
            <div class="footer-actions">
              ${this.calculatedFooterActions.map((a) => {
                return html` <d-action
                  @click=${() => this.onDispatchAction(a.disabled ?? false, a.action ?? '')}
                  ?disabled=${a.disabled}
                  ?delete=${a.action === 'delete'}
                  >${a.name}</d-action
                >`;
              })}
            </div>
          `
        : nothing}
    `;
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('show')) {
      this.toggleShow();
    }
  }
}
