import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { handleNavigation } from 'src/redux-behavior.js';
import { internalLinkStyles } from 'src/library/internal-link-styles';

/**
 *
 * used to show contextual information, e.g. in the issues checklist
 *
 * STATUS OK
 */
@customElement('d-view-info')
export class DViewInfo extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      :host([alert]),
      :host([alert]) * {
        color: var(--alertColor);
      }

      :host([mini]),
      :host([mini]) * {
        font-size: var(--fontSizeSmall);
      }

      * {
        font-family: var(--mainSans), sans-serif;
        font-weight: 400;
        line-height: var(--lineHeightLoose);
        font-size: var(--fontSizeDefault);
        color: var(--systemTextColor);
        text-transform: none;
        letter-spacing: normal;
      }

      strong {
        font-weight: 600;
      }

      p {
        margin: 8px 0;
      }

      ul {
        list-style-type: disc;
        padding: 0;
        margin: 8px 0 8px 30px;
      }

      ol {
        padding: 0;
        margin: 8px 0 8px 30px;
      }

      li {
        margin-bottom: 6px;
      }

      h1:first-child,
      h2:first-child,
      p:first-child,
      ul:first-child,
      ol:first-child {
        margin-top: 0;
      }

      h1:last-child,
      h2:last-child,
      p:last-child,
      ul:last-child,
      ol:last-child {
        margin-bottom: 0;
      }

      :host([info-list]) ul,
      :host([alert-list]) ul {
        list-style-type: none;
        margin-left: 9px;
      }

      :host([info-list]) ul li {
        padding-left: 30px;
        background: url(/images/info.svg) -5px -3px no-repeat;
        background-size: 32px 32px;
      }

      :host([alert-list]:not([help])) ul li {
        padding-left: 30px;
        background: url(/images/alert.svg) -5px -3px no-repeat;
        background-size: 32px 32px;
      }

      :host([alert-list]) ul li ul li {
        background: none;
      }

      :host([info-list]) ul li ul,
      :host([alert-list]) ul li ul {
        list-style-type: disc;
        padding-left: 20px;
      }

      :host([info-list]) ul li ul li,
      :host([alert-list]) ul li ul li {
        background: none;
        padding-left: 0;
      }

      a {
        margin-left: 11px;
        display: inline-block;
        color: var(--themeColor);
        text-decoration: none;
        outline: none;
      }

      li a {
        margin-left: 0;
      }

      a.reference {
        display: block;
        background: url(/images/references.svg) 0 0 no-repeat;
        background-size: 32px 32px;
        padding: 4px 0 4px 36px;
        color: var(--linkColorGray);
      }

      a[href^="https://trinnvis.no/hjelp"]
      {
        font-weight: 500;
        margin-left: 10px;
      }

      a[href^="https://trinnvis.no/hjelp"]:before
      {
        content: '? ';
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        top: 1px;
        border-radius: 50%;
        background: var(--themeColor);
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: white;
        text-align: center;
        margin-right: 8px;
        box-sizing: border-box;
      }

      a:hover {
        color: black;
      }

      a[href^="https://trinnvis.no/hjelp"]:hover:before
      {
        background: black;
      }

      /* System text e.g. in settings and help content */

      :host([help]) {
        font-family: var(--mainSans);
        font-weight: normal;
        font-size: 15px;
        text-transform: none;
        letter-spacing: normal;
        line-height: 160%;
        color: black;
      }

      :host([help]) strong {
        font-weight: 600;
      }

      :host([help]) p {
        font-size: 15px;
        font-weight: 400;
        line-height: 160%;
        margin: 8px 0 8px 0;
      }

      :host([help]) p:first-child {
        margin-top: 0;
      }

      :host([help]) p:last-child {
        margin-bottom: 0;
      }

      :host([help]) a.reference {
        display: block;
        line-height: 170%;
        margin: 0 0 -6px 0;
        padding: 6px 0 3px 44px;
        color: #929292;
      }

      body:not(.touch) :host([help]) a.reference:hover {
        color: #000000;
      }

      :host([help]) ul,
      :host([help]) ol {
        line-height: 160%;
        margin: 8px 0 8px 30px;
      }

      :host([help]) h2 {
        margin-top: 16px;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 20px;
      }

      :host(:not([help])) ul {
        list-style-type: none;
        padding: 0;
        margin-left: 10px;
        font-family: var(--mainSans);
        margin-bottom: 6px;
      }

      :host(:not([help])) ul:last-child {
        margin-bottom: 0;
      }

      :host(:not([help])) ul li {
        padding-left: 30px;
        background: url(/images/info.svg) -5px -4px no-repeat;
        background-size: 32px 32px;
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: 140%;
      }

      :host(:not([help])) ul ul,
      :host(:not([help])) ol ul {
        list-style-type: disc;
        margin-top: 6px;
      }

      :host(:not([help])) ol ol,
      :host(:not([help])) ul ol {
        list-style-type: decimal;
        margin-top: 6px;
      }

      :host(:not([help])) ul ul li,
      :host(:not([help])) ul ol li,
      :host(:not([help])) ol ul li,
      :host(:not([help])) ol ol li {
        background: none;
        padding-left: 0;
      }
    `,
    internalLinkStyles,
    css`
      a[data-internal-link-doctype] {
        background-position-y: 4px;
        color: var(--themeColorDarkerTwo);
      }
      a[data-internal-link-doctype]:not(.invalid):hover {
        background-position-y: -126px;
      }
    `,
  ];
  @property({ type: Boolean, attribute: 'alert', reflect: true })
  alert = false;
  @property({ type: Boolean, attribute: 'alert-list', reflect: true })
  alertList = false;
  @property({ type: Boolean, attribute: 'mini', reflect: true })
  mini = false;
  @property({ type: Boolean, attribute: 'help', reflect: true })
  help = false;
  @property({ type: String, attribute: 'content' })
  content = '';
  @property({ type: String })
  field = '';

  displayedContent() {
    if (this.content) {
      return this.content;
    } else if (commonFieldsByCode()[this.field]) {
      return commonFieldsByCode()[this.field].tooltip;
    }
  }

  _linkClick(event) {
    if (event.target.nodeName.toLowerCase() === 'a') {
      const s = event.target.href.substring(0, event.target.href.length - 1);
      if (s.startsWith('https://trinnvis.no/hjelp/')) {
        event.preventDefault();
        const helpPage = s.replace('https://trinnvis.no/hjelp/', '');
        this.dispatchEvent(
          new CustomEvent<{ page: string }>('update-help', {
            bubbles: true,
            composed: true,
            detail: { page: helpPage },
          }),
        );
      }
    }
  }

  async clickHtml(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const a = target.closest('a');
    if (a?.hasAttribute('data-internal-link-id')) {
      event.stopPropagation();
      event.preventDefault();
      window.history.pushState({}, '', a.getAttribute('href'));
      await handleNavigation(window.location);
    }
  }

  render() {
    return html`<div @click=${(e) => this.clickHtml(e)}>${unsafeHTML(this.displayedContent())}</div>`;
  }

  protected createRenderRoot() {
    const root = super.createRenderRoot();
    root.addEventListener('click', (e: Event) => this._linkClick(e));
    return root;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-info': DViewInfo;
  }
}
