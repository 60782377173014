import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { ActivityCode, ActivityCodeCommon } from 'src/store';
import * as dabihStore from 'src/store';
import '../../library/components/d-activity-codes-container.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-smooth-resize.js';
import { DPrepareBehavior } from './d-prepare-behavior.js';
import './d-prepare-employee-row.js';
import type { PrepareEmployeeRowItem } from './d-prepare-employee-row.js';
import './d-robot-header.js';
import { AccountStateEmployeeViewModelAccessLevelEnum } from 'src/store/api';

/**
 *
 */
@customElement('d-prepare-step-0')
export class DPrepareStep0 extends DPrepareBehavior {
  static readonly styles = [
    ...super.styles,
    css`
      :host {
        display: block;
      }

      d-activity-codes-container {
        margin-left: 4px;
        margin-bottom: 16px;
      }

      d-prepare-employee-row:first-child::content .delete-employee {
        display: none;
      }

      .action {
        padding: 6px 0;
        font-family: var(--small);
        font-weight: 600;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        cursor: pointer;
      }

      a {
        color: white;
        text-decoration: none;
      }

      a:hover {
        color: black;
      }

      .person-row {
        display: flex;
      }

      d-prepare-employee-row {
        flex: 1;
      }

      .delete-employee-wrapper {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        width: 30px;
      }

      .delete-employee {
        width: 30px;
        height: 30px;
        background: url('/images/x-thin-black.svg') 100% 50% no-repeat;
        background-size: 24px 24px;
        opacity: 0.4;
        cursor: pointer;
      }

      .delete-employee:hover {
        opacity: 0.8;
      }

      d-action {
        margin-left: -8px;
      }
    `,
  ];
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: String })
  ownerEmail = '';
  @property({ type: Array })
  employees: PrepareEmployeeRowItem[] = [];
  @property({ type: Array })
  codes: ActivityCode[] = [];
  @property({ type: Array })
  commonActivityCodes: ActivityCodeCommon[] = [];
  @property({ type: Array })
  activityCodes: string[] = [];
  @property({ type: Boolean })
  hideProfessions = false;
  @property({ type: Boolean })
  hideActivityCodes = false;
  @property({ type: Array })
  professions = [];
  @property({ type: Array })
  associationTypes = [];
  @property({ type: Boolean })
  openDetails = true;
  @property({ type: String })
  deletingEmployeeUuid = '';
  protected next = 'step1';

  get singleUserOption() {
    return this.employees.length <= 1;
  }

  deletingEmployeeRow(deletingEmployeeUuid, itemUuid) {
    console.log('deletingEmployeeUuid', deletingEmployeeUuid, 'itemUuid', itemUuid);
    return deletingEmployeeUuid === itemUuid;
  }

  private robotMessage =
    'Gratulerer med din nye TrinnVis-konto! Vi trenger å vite litt om virksomheten for å skreddersy innholdet for deg.';

  _isDisabled(index) {
    return index == 0;
  }

  _placeholder(value) {
    if (value) {
      return '';
    }
    return 'placeholder';
  }

  _professions(standardProfessions) {
    const professions: string[] = [];
    standardProfessions.forEach(function (item) {
      professions.push(item);
      if (item === 'Fysioterapeut') {
        professions.push('- Manuellterapeut');
      }
    });
    return professions;
  }

  _forwardOrFinish(e) {
    if (this.isValid()) {
      if (this.singleUser) {
        this._finish(e);
      } else {
        this._forward(e);
      }
    }
  }

  render() {
    return html`
      <d-robot-header .message=${this.robotMessage}></d-robot-header>
      <div class="outskirtsContent">
        <form id="accountConfig1Form" @submit=${(e) => this._forwardOrFinish(e)}>
          ${this.hideActivityCodes
            ? nothing
            : html` <div>
                <h2>Tjenestetype</h2>
                <p class="prompt">
                  For helsevirksomheter har TrinnVis mye innhold som er avhengig av hva slags helsetjenester som ytes.
                </p>
                <d-activity-codes-container
                  outskirts
                  .activityCodes=${this.activityCodes}
                  .codes=${this.codes}
                  .commonActivityCodes=${this.commonActivityCodes}
                ></d-activity-codes-container>
              </div>`}
          <d-expansion ?opened=${this.singleUserOption}>
            <div class="singleUserVersionSection">
              <h2>Forenklet versjon</h2>
              <p class="prompt">
                Hvis du er bare én person, kan du bruke forenklet versjon av TrinnVis. Denne versjonen har ingen
                ansvarsfordeling eller bemanningsfunksjonalitet, og utelater en rekke dokumenter som er overflødige for
                virksomheter med bare én person.
                <a href="https://trinnvis.no/hjelp/forenklet-versjon/" target="_blank">Les&nbsp;mer</a>
              </p>
              <d-checkbox
                outskirts
                ?checked=${this.singleUser}
                @checked-changed=${this.onSingleUserChanged}
                .optionLabel=${'Ja takk, jeg ønsker forenklet versjon'}
              >
              </d-checkbox>
            </div>
          </d-expansion>

          <h2>Personale</h2>
          <p class="prompt">
            Du kan utsette registreringen av personalet til senere, men vi anbefaler at du i alle fall registrerer dem
            som har ledende roller i virksomheten.
          </p>

          <d-smooth-resize>
            ${this.employees.map((item, index) => {
              return html` <d-expansion
                no-open-animation
                ?opened=${!this.deletingEmployeeRow(this.deletingEmployeeUuid, item.uuid)}
                ><div class="person-row">
                  <d-prepare-employee-row .index=${index} .item=${item} @employee-changed=${this.onEmployeeChanged}>
                  </d-prepare-employee-row>
                  <div class="delete-employee-wrapper">
                    ${index !== 0
                      ? html`<div class="delete-employee" @click=${() => this.onDeleteEmployee(item.uuid)}></div> `
                      : nothing}
                  </div>
                </div></d-expansion
              >`;
            })}
          </d-smooth-resize>
          ${this.singleUser
            ? nothing
            : html` <d-action outskirts @click=${() => this.onAddEmployee()}>Legg til flere</d-action>`}
          <div class="buttons">
            <button class="right" @click=${(e) => this._cancel(e)} type="button">Avbryt</button>
            <button class="right nextPage" type="submit">Neste</button>
          </div>
        </form>
      </div>
    `;
  }

  isValid() {
    const emails: string[] = [];
    let valid = true;
    this.employees.forEach(function (e) {
      const normalizedEmail = e.email.toLowerCase().trim();
      if (normalizedEmail) {
        console.log(e.email, emails.indexOf(normalizedEmail));
        if (emails.indexOf(normalizedEmail) === -1) {
          emails.push(normalizedEmail);
        } else {
          console.log('setting valid false for email ' + e.email);
          valid = false;
        }
      }
    });
    if (!valid) {
      alert('Du kan ikke registrere samme e-post på flere personer. Alle e-postene må være unike.');
    }
    return valid;
  }

  private onAddEmployee() {
    const data = {
      uuid: dabihStore.createUuid(),
      firstName: '',
      lastName: '',
      associationType: '',
      profession: '',
      email: '',
      accessLevel: AccountStateEmployeeViewModelAccessLevelEnum.User,
    };
    this.employees = [...this.employees, data];
    this.dispatchEvent(
      new CustomEvent<{ employees: PrepareEmployeeRowItem[] }>('employees-changed', {
        bubbles: true,
        composed: true,
        detail: { employees: this.employees },
      }),
    );
  }

  private onDeleteEmployee(uuid: string) {
    this.deletingEmployeeUuid = uuid;
    console.log('deletingEmployeeUuid', this.deletingEmployeeUuid);
    setTimeout(() => {
      this.employees = this.employees.filter((employee) => employee.uuid !== uuid);
      this.dispatchEvent(
        new CustomEvent<{ employees: PrepareEmployeeRowItem[] }>('employees-changed', {
          bubbles: true,
          composed: true,
          detail: { employees: this.employees },
        }),
      );
    }, 500);
  }

  private onEmployeeChanged(e: CustomEvent<{ item: PrepareEmployeeRowItem }>) {
    e.stopPropagation();
    this.employees = this.employees.map((employee) =>
      employee.uuid === e.detail.item.uuid ? e.detail.item : employee,
    );
    this.dispatchEvent(
      new CustomEvent<{ employees: PrepareEmployeeRowItem[] }>('employees-changed', {
        bubbles: true,
        composed: true,
        detail: { employees: this.employees },
      }),
    );
  }

  private onSingleUserChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    this.singleUser = e.detail.checked;
    this.dispatchEvent(
      new CustomEvent('single-user-changed', {
        bubbles: true,
        composed: true,
        detail: { checked: this.singleUser },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare-step-0': DPrepareStep0;
  }
}
