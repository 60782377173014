import { css, html, LitElement, PropertyValues } from 'lit';

import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import * as dabihStore from 'src/store';
import { displayAlert } from 'src/utilities/display-alert.js';

/**
 * Viser innloggingskjemaet, inkludert alternativer for å registrere ny konto eller få
 * tilsendt nytt passord
 *
 * @fires request-login submit login request
 * @fires request-password submit password request
 *
 */
@customElement('d-verify')
export class DVerify extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    #loginForm {
      max-width: 400px;
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #loginForm > * {
      margin-bottom: 10px;
    }

    h2,
    p {
      color: white;
      text-align: left;
    }

    #loginForm .logo {
      width: 146px;
      height: 37px;
      background: url(/images/trinnvis-logo.svg) 50% 50% no-repeat;
      background-size: contain;
      margin-bottom: 14px;
    }

    #loginForm input[type='email'],
    #loginForm input[type='password'] {
      font-family: var(--mainSans);
      font-size: 15px;
      text-align: center;
      border-radius: 4px;
    }

    #loginForm input[type='submit'] {
      margin-top: 10px;
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
    }

    #loginForm input[type='button'] {
      margin-top: 10px;
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
    }

    body:not(.touch) #loginForm input[type='submit']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    body:not(.touch) #loginForm input[type='button']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    body:not(.touch) #loginForm input[type='submit']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    #loginForm .alternatives {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-weight: 200;
      color: white;
      cursor: pointer;
    }

    #loginForm .alternatives > * {
      margin-bottom: 10px;
      opacity: 0.8;
    }

    body:not(.touch) #loginForm .alternatives > *:hover {
      opacity: 1;
    }

    input {
      border: none;
      box-shadow: none;
      padding: 5px 10px 6px 10px;
      resize: none;
      border-radius: 0;
      appearance: none;
      writing-mode: horizontal-tb;
      width: 100%;
      box-sizing: border-box;
      font-family: var(--mainSans), sans-serif;
      font-weight: 200;
      font-size: 15px;
      line-height: 160%;
      color: black;
    }
    input[type='submit'] {
      cursor: pointer;
      text-transform: uppercase;
    }

    .secureLogin {
      margin-top: 10px;
      border: 1px solid hsla(0, 0%, 100%, 0.4);
      border-radius: 12px;
      padding: 106px 30px 30px 30px;
      background: transparent url(/images/personal-data-items-gray.svg) 50% 20px no-repeat;
      background-size: 70px 70px;
      text-align: center;
    }

    #loginForm .secureLogin h2 {
      font-size: 20px;
      line-height: 120%;
      color: white;
    }

    #loginForm p {
      font-size: 15px;
      line-height: 150%;
      text-align: center;
    }

    #loginForm .secureLogin input {
      margin: 10px;
    }
    .otp {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
    .otp__input {
      text-align: center;
    }

    .otp__input {
      border: none;
      border-bottom: 2px solid rgb(203 213 225);
      font-size: 2rem;
      outline: none;
    }
  `;
  /**
   * Display login failed message.
   */
  @property({ type: Boolean })
  loginFailed = false;
  /**
   * The mode. Used when linking to a secure authentication method. If this value is `link` then use
   */
  @property({ type: String })
  mode = '';
  /**
   * The location search. Set this to value of `window.location.search` when mode is `link`.
   */
  @property({ type: String })
  locationSearch = '';
  @state()
  private username: string | null = '';
  @state()
  private code = '';

  @query('#otp')
  private otp!: HTMLDivElement;
  @queryAll('.otp__input')
  private otpInputs!: Array<HTMLInputElement>;

  async _verifySingleUseCode(e: SubmitEvent) {
    e.preventDefault();
    const elementsArray = Array.from(this.otpInputs);
    const code = elementsArray.map((i) => i.value).join('');

    this.dispatchEvent(
      new CustomEvent('request-verify', {
        bubbles: true,
        composed: true,
        detail: { code: code, username: this.username },
      }),
    );
  }

  render() {
    return html`
      <form id="loginForm" @submit=${this._verifySingleUseCode}>
        <div class="logo"></div>
        <h2>Skriv inn koden</h2>
        <p>Vi sendte en kode på e-post til kristian@trinnvis.no. Angi koden for å logge deg på.</p>
        <div class="otp">
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
          <input
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @keyup=${this.handleKeyUp}
            @paste=${this.handlePaste}
            type="text"
            class="otp__input"
            maxlength="1"
          />
        </div>

        <input type="submit" class="action" value="Logg på" />
        <div class="alternatives">
          <div @click=${this.goSignin}>Tilbake</div>
        </div>
      </form>
    `;
  }

  protected willUpdate(_changedProperties: PropertyValues) {
    super.willUpdate(_changedProperties);

    this.username = this.getCookieValue('email');
  }

  /**
   * Function to get the value of a cookie by its name.
   * @param {string} name - The name of the cookie to retrieve.
   * @returns {string | null} - The value of the cookie, or null if not found.
   */
  private getCookieValue(name: string): string | null {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];

      // Remove leading spaces
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }

      // Check if the cookie string starts with the name we're looking for
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }

    // Return null if the cookie is not found
    return null;
  }

  private goSignin() {
    window.location.href = '/signin';
  }

  private handlePaste(e: ClipboardEvent) {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    console.log(clipboardData);
    if (!clipboardData) {
      return;
    }

    const pastedText = clipboardData.getData('text');
    if (!/^[0-9]{4}$/.test(pastedText)) {
      return;
    }
    const digits = pastedText.split('');
    const elementsArray = Array.from(this.otpInputs);
    elementsArray.forEach((input, index) => (input.value = digits[index]));
    elementsArray[elementsArray.length - 1].focus();
  }

  private handleFocus(e: FocusEvent) {
    const inputElement = e.target as HTMLInputElement;
    inputElement.select();
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (!/^[0-9]{1}$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  }

  private handleKeyUp(e: KeyboardEvent) {
    const inputElement = e.target as HTMLInputElement;
    const elementsArray = Array.from(this.otpInputs);
    const index = elementsArray.indexOf(inputElement);
    switch (e.key) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case 'ArrowRight':
        if (index < elementsArray.length - 1) {
          // Jump to the next field
          elementsArray[index + 1].focus();
        }
        break;
      case 'ArrowLeft':
      case 'Backspace':
        if (index > 0) {
          // Jump to the previous field
          elementsArray[index - 1].focus();
        }
        break;
      case 'Home':
        // Jump to the first field
        elementsArray[0].focus();
        break;
      case 'End':
        // Jump to the first field
        elementsArray[elementsArray.length - 1].focus();
        break;
      default:
        break;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-verify': DVerify;
  }
}
