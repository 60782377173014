/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountPlanUpdateMessage
 */
export interface AccountPlanUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof AccountPlanUpdateMessage
   */
  invoicePlan?: AccountPlanUpdateMessageInvoicePlanEnum;
  /**
   *
   * @type {string}
   * @memberof AccountPlanUpdateMessage
   */
  featurePlan?: AccountPlanUpdateMessageFeaturePlanEnum;
}

/**
 * @export
 */
export const AccountPlanUpdateMessageInvoicePlanEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
} as const;
export type AccountPlanUpdateMessageInvoicePlanEnum =
  (typeof AccountPlanUpdateMessageInvoicePlanEnum)[keyof typeof AccountPlanUpdateMessageInvoicePlanEnum];

/**
 * @export
 */
export const AccountPlanUpdateMessageFeaturePlanEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
} as const;
export type AccountPlanUpdateMessageFeaturePlanEnum =
  (typeof AccountPlanUpdateMessageFeaturePlanEnum)[keyof typeof AccountPlanUpdateMessageFeaturePlanEnum];

/**
 * Check if a given object implements the AccountPlanUpdateMessage interface.
 */
export function instanceOfAccountPlanUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AccountPlanUpdateMessageFromJSON(json: any): AccountPlanUpdateMessage {
  return AccountPlanUpdateMessageFromJSONTyped(json, false);
}

export function AccountPlanUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountPlanUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    invoicePlan: !exists(json, 'invoicePlan') ? undefined : json['invoicePlan'],
    featurePlan: !exists(json, 'featurePlan') ? undefined : json['featurePlan'],
  };
}

export function AccountPlanUpdateMessageToJSON(value?: AccountPlanUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    invoicePlan: value.invoicePlan,
    featurePlan: value.featurePlan,
  };
}
