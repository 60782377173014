import { css, html, LitElement, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { sortBy } from 'lodash';
import '../../library/elements/d-action.js';
import type { OrganizationReference } from '../../store/api';
import type { User } from '../../store/types.js';

/**
 *
 * Har ikke lenger funksjonalitet for valgt organisasjon. Alle her kan velges. Dette vises kun når ingen organisasjon er valgt
 *
 * Se også account. Denne vises selv om der kun er en organisasjon.
 *
 * Kanskje vi skal ta den vekk. Utenom hvis de har ingen organisasjon?
 *
 * The links are marked `rel="external"` so that they trigger a page reload.
 *
 */
@customElement('d-select-organization')
export class DSelectOrganization extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      -webkit-text-size-adjust: 100%;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      -webkit-padding-start: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    #accountSelector ul {
      list-style-type: none;
      margin: 0 0 12px 0;
      font-weight: 400;
    }

    #accountSelector li a {
      display: block;
      padding: 9px 0 8px 0;
      color: var(--linkColorGray);
      border-bottom: 1px solid var(--borderColorTransparent);
      text-decoration: none;
      cursor: pointer;
    }

    #accountSelector li a[current] {
      color: black;
      font-weight: 500;
      cursor: default;
      pointer-events: none;
    }

    #accountSelector li a:not([current]):hover,
    #accountSelector li a:not([current]):active {
      color: black;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    #accountSelector li:first-child a {
      border-top: 1px solid var(--borderColorTransparent);
    }

    .action {
      padding: 6px 0;
      font-family: var(--small);
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--themeColor);
      cursor: pointer;
    }

    d-action {
      color: white;
    }
  `;
  @property({ type: Array })
  organizations: OrganizationReference[] = [];
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Object })
  user!: User;
  @property({ type: Object })
  currentEmployee?: { firstName: string; lastName: string };

  get sortedOrganizations() {
    const a = this.organizations.slice();
    return sortBy(a, [(item) => (item.name ? item.name.toUpperCase() : '')]);
  }

  private get newOrganizationUrl() {
    let u = 'https://trinnvis.no/signup?r=' + window.origin + '/signup';
    if (this.user?.username) {
      u += '&e=' + encodeURIComponent(this.user.username);
    }
    if (this.currentEmployee?.firstName !== undefined && this.currentEmployee?.lastName !== undefined) {
      u +=
        '&f=' +
        encodeURIComponent(this.currentEmployee.firstName) +
        '&l=' +
        encodeURIComponent(this.currentEmployee.lastName);
    }
    return u;
  }

  _computeUrl(organization) {
    if (organization) {
      const url = '/account/' + organization.id;
      return (
        url +
        '/' +
        (organization.message === 'startup'
          ? 'news'
          : organization.pending
            ? 'prepare'
            : organization.upgradable
              ? 'upgrade'
              : '2300')
      );
    } else {
      return '';
    }
  }

  render() {
    return html`
      <div id="accountSelector">
        <ul>
          ${this.sortedOrganizations.map(
            (organization) =>
              html` <li>
                ${organization.closed
                  ? html` <a>${organization.name} ${organization.ownerEmail} (Avsluttet)</a> `
                  : html`
                      <a
                        href="${this._computeUrl(organization)}"
                        rel="external"
                        ?current=${organization.id === this.organizationId}
                        >${organization.name} ${organization.ownerEmail}
                      </a>
                    `}
              </li>`,
          )}
          ${this.organizations.length === 0
            ? html`
                <li class="noOrgMessage">
                  <a class="clearfix"> Du har ingen organisasjoner. </a>
                </li>
              `
            : nothing}
        </ul>
        <d-action theme-page href="${this.newOrganizationUrl}"> Legg til ny organisasjon </d-action>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-organization': DSelectOrganization;
  }
}
