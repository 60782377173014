import { createSelector } from '@reduxjs/toolkit';
import { findLast, sortBy } from 'lodash';
import { LocalDate } from 'src/utilities/local-date.js';
import type { AssetViewModel, ContactPersonViewModel, SubstanceViewModel } from '../api';
import type { OrganizationState, State } from '../types.js';
import { getOrganization, now } from './organization.js';
import { toList } from './utilities.js';
import { EventRelatedAsset } from 'src/content/event-occurrences/d-event-occurrence-view';
import { EventModel, events } from './organization-events';
import { contactsNotDeleted, employeesShortNamesAndMe, ShortNameEntry } from 'src/store';
import { dateTimeDescription } from 'src/store/utilities';

export interface SubItemForTable {
  classes: string;
  href: string;
  name: string;
}

export interface TaskOrEvent {
  uuid: string;
  type: string;
  classes: string;
  href: string;
  name: string;
  prevInstance: SubItemForTable;
  nextInstance: SubItemForTable;
  dateTimeForOrdering: string;
}

export interface AssetViewModelWithEvents extends AssetViewModel {
  events: EventDataForAsset[];
}
export interface AssetEventOccurrence {
  eventUuid: string;
  dateTimeForSorting: string;
  dateTimeDisplay: string;
  status: 'NONE' | 'OK' | 'NOT_OK' | 'DONE';
  statusText: string;
  href: string;
}

export interface EventDataForAsset {
  name: string;
  prev?: AssetEventOccurrence;
  next?: AssetEventOccurrence;
  occurrences: AssetEventOccurrence[];
}

export function assetsNotDeleted(state: State): AssetViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.assetsById).filter(function (e) {
    return !e.deleted;
  });
}

export function assetsDeleted(state: State): AssetViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.assetsById).filter(function (e) {
    return e.deleted;
  });
}

export function substancesNotDeleted(state: State): SubstanceViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.substancesById).filter(function (e) {
    return !e.deleted;
  });
}

export function substancesDeleted(state: State): SubstanceViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.substancesById).filter(function (e) {
    return e.deleted;
  });
}

export function getDoneBy(uuid, employeeNames, contacts) {
  let doneBy = '';
  let person = employeeNames.find((n) => {
    return n.uuid === uuid;
  });
  if (person) {
    doneBy = person.name;
  } else {
    person = contacts.find((c) => {
      return c.uuid === uuid;
    });
    if (person) {
      doneBy = person.name;
    }
  }
  if (doneBy) {
    doneBy = ' av ' + doneBy;
  }
  return doneBy;
}

export function getAssetControlStatusText(assetControl, employeeNames, contacts) {
  if (assetControl.assetStatus === 'OK' || assetControl.assetStatus === 'NOT_OK') {
    const statusDate = LocalDate.fromString(assetControl.statusLastUpdatedAt).toStringForDisplayWithYear();
    const doneBy = getDoneBy(
      assetControl.statusLastUpdatedByEmployeeUuid || assetControl.statusLastUpdatedByContactUuid,
      employeeNames,
      contacts,
    );
    return 'utført ' + statusDate + doneBy;
  }
  return '';
}

export const assetsWithTasks = createSelector(
  getOrganization,
  assetsNotDeleted,
  events,
  now,
  employeesShortNamesAndMe,
  contactsNotDeleted,
  function (
    organization: OrganizationState | undefined,
    assets: AssetViewModel[],
    events,
    now: LocalDate,
    employeeNames,
    contacts,
  ) {
    const o = organization;
    if (o === undefined) {
      return [];
    }
    return sortBy(assets, ['name', 'number']).map((asset) =>
      toAssetListItem(asset, events, now, employeeNames, contacts),
    );
  },
);

function toAssetListItem(
  originalItem: AssetViewModel,
  events: EventModel[],
  now: LocalDate,
  employeeNames: ShortNameEntry[],
  contacts: ContactPersonViewModel[],
): AssetViewModelWithEvents {
  const nowAsString = now.toString();
  const item: AssetViewModelWithEvents = { ...originalItem, events: [] };
  const eventsWithThisAsset = events.filter((e) => {
    const occurrences = e.eventOccurrences.filter((o) => o.relatedAssets?.map((r) => r.assetUuid).includes(item.uuid));
    return occurrences.length > 0;
  });

  item.events = eventsWithThisAsset
    .filter((e) => {
      return e.name !== '';
    })
    .map((e) => {
      const sortedOccurrences = sortBy(e.eventOccurrences, [(e) => e.date]);
      const occurrences = sortedOccurrences
        .filter((o) => o.relatedAssets?.map((r) => r.assetUuid).includes(item.uuid))
        .map((t) => {
          let status: 'NONE' | 'OK' | 'NOT_OK' | 'DONE' = 'NONE';
          let statusText = '';
          if (t.doneDate !== null && t.doneDate !== undefined && t.doneDate !== '') {
            const doneDate = LocalDate.fromString(t.doneDate).toStringForDisplayWithYear();
            status = 'DONE';
            statusText = 'utført ' + doneDate + getDoneBy(t.doneByEmployeeUuid, employeeNames, contacts);
          }
          const assetControl = (t.relatedAssets ?? []).find((r) => {
            return r.assetUuid === item.uuid;
          });
          if (assetControl) {
            if (assetControl.assetStatus === 'OK' || assetControl.assetStatus === 'NOT_OK') {
              status = assetControl.assetStatus;
            }
            statusText = getAssetControlStatusText(assetControl, employeeNames, contacts);
          }

          return {
            eventUuid: t.uuid,
            dateTimeForSorting: t.date + ' ' + t.time,
            dateTimeDisplay: dateTimeDescription(t.date, t.time ?? ''),
            status: status,
            statusText: statusText,
            href: 'eventOccurrences/' + t.uuid,
          };
        });

      return {
        name: e.name,
        prev: findLast(occurrences, (i) => i.dateTimeForSorting < nowAsString),
        next: occurrences.find((i) => i.dateTimeForSorting >= nowAsString),
        occurrences: occurrences,
      };
    });
  return item;
}

export const assetsNotDiscardedWithTasks = createSelector(assetsWithTasks, function (assets) {
  return assets.filter(function (item) {
    return item.discardedYear === '';
  });
});

export const assetsDiscardedWithTasks = createSelector(assetsWithTasks, function (assets) {
  return assets.filter(function (item) {
    return item.discardedYear !== '';
  });
});

function assetHasPersonalDataItem(item: AssetViewModel, o: OrganizationState): boolean {
  return (
    toList(o.personalDataItemsById)
      .filter((e) => !e.deleted)
      .filter((e) => e.type === 'medicalEquipmentRecords')
      .filter((e) => Array.isArray(e.relatedAssets))
      .filter((e) => e.relatedAssets.indexOf(item.uuid) !== -1).length > 0
  );
}

export function toAssetViewModel(
  item: AssetViewModel,
  o: OrganizationState,
): AssetViewModel & {
  hasPersonalDataItem: boolean;
  ownerDisplay: string;
  notes: string;
  requiredEventInstanceMissing: boolean;
  comments: string;
} {
  return Object.assign({}, item, {
    hasPersonalDataItem: assetHasPersonalDataItem(item, o),
    ownerDisplay: '',
    notes: '',
    requiredEventInstanceMissing: false,
    comments: '',
  });
}

function disbledAssetOption(relatedAssets, asset) {
  let result = false;
  if (relatedAssets.length) {
    const relatedAsset = relatedAssets.find((r) => {
      return r.uuid === asset.uuid;
    });
    if (
      relatedAsset?.assetCheckStatus &&
      (relatedAsset.assetCheckStatus !== 'NONE' || relatedAsset.assetCheckComments !== '')
    ) {
      result = true;
    }
  }
  return result;
}

export function getAssetOptions(state: State, relatedAssets: EventRelatedAsset[]) {
  const notDiscarded = assetsNotDeleted(state)
    .filter((a) => {
      return (!a.isNew && !a.discardedYear) || a.discardedYear === '';
    })
    .map((a) => {
      return {
        value: a.uuid,
        text: a.name + ' ' + a.number,
        disabled: disbledAssetOption(relatedAssets, a),
        discarded: false,
      };
    });
  const discarded = assetsNotDeleted(state)
    .filter((a) => {
      return !a.isNew && a.discardedYear && a.discardedYear !== '';
    })
    .map((a) => {
      return {
        value: a.uuid,
        text: a.name + ' ' + a.number + ' (kassert ' + a.discardedYear + ')',
        disabled: disbledAssetOption(relatedAssets, a),
        discarded: true,
      };
    });
  return sortBy(notDiscarded, ['text']).concat(sortBy(discarded, ['text']));
}
