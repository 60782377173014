import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content';
import type { FormViewItem } from 'src/library/components/d-form-view';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag';
import type { TaskEvent } from 'src/library/lists/utilities';
import '../../library/editors';
import '../../library/fields';
import '../../library/fields/d-view-info';
import '../../library/lists/d-list-section';
import '../../library/lists/d-list-section-attachment';
import '../../library/lists/d-list-section-task-events';
import './d-smart-task-info';
import type { UpdateSectionItem } from 'src/content/d-update-section';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { CreateEntityInput, CreateEventOccurrenceInput } from 'src/layout/parts/d-new-document';

export interface TaskView extends AbstractEntityViewWithRevisions<TaskViewEditItem>, UpdateSectionItem {
  pages: string[];
  responsibleFunctionUuid: string;
  hasEvents: boolean;
  procedures: string;
  type: 'tasks';
  name: string;
  parentEntityId: string;
  assignedFunctionName: string;
  responsibleFunctionName: string;
  situation: string;
  taskEvents: TaskEvent[];
  startTaskText: string;
  availablePages: SelectTagOption[];
  availableEmployees: SelectDropdownOption[];
  availableContacts: SelectDropdownOption[];
  availableFunctions: SelectDropdownOption[];
  availableFunctionsForResponsible: SelectDropdownOption[];
  smartTaskEventText: string;

  /**
   * Tasks can not be selected to show on pages that they already appear on because of the related functions.
   */
  disabledPagesPerFunction: Map<string, Set<number>>;
}

export interface TaskViewEditItem {
  responsibleFunctionUuid: string;
  pages: string[];
  name: string;
  situation: string;
  procedures: string;
  functionUuid: string;
}

/**
 *
 *
 *
 *
 * Displays the task view. The task view shows the routines.p+
 *
 *
 */
@customElement('d-task-view')
export class DTaskView extends EntityContent<TaskView, TaskViewEditItem> {
  static readonly styles = EntityContent.styles;
  assetSelectionOptions = [
    { value: 'COMMON', text: 'Felles oppgaver' },
    { value: 'INDIVIDUAL', text: 'Individuelle oppgaver' },
  ];
  @property({ type: Object })
  organization: any = {};
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: String })
  currentEmployeeUuid = '';
  @property({ type: String })
  currentEmployeeShortName = '';
  @property({ type: Array })
  employeesNotDeleted: any[] = [];
  @property({ type: Array })
  employeesShortNames: any[] = [];
  @property({ type: String })
  entityType = 'tasks';
  @property({ type: String })
  parentEntityType = 'functions';
  @property({ type: String })
  functions = '';
  @property({ type: Array })
  events: any[] = [];
  @property({ type: Object })
  parent = {};
  @property({ type: String })
  conditionDescription = '';
  @property({ type: String })
  defaultFunctionForAssetTask = '';
  /*
    viewHref2(entityId, organizationId, pageId, entityType) {
      return (
        '/account/' + organizationId + '/' + pageId + '/functions/' + this.entityView.parentEntityId + '/tasks/' + this.entityId
      );
    }

     */
  @property({ type: Array })
  relatedEventInstancesLevel3: any[] = [];
  @property({ type: Array })
  relatedEventInstancesLevel4: any[] = [];
  @property({ type: Array })
  relatedEventInstancesLevel5: any[] = [];
  @property({ type: Array })
  startTasks: any[] = [];
  @property({ type: Array })
  pagesForSingleUserVersion: any[] = [];
  @property({ type: Array })
  pagesForSingleUserVersionEditable: number[] = [];
  @property({ type: Boolean })
  includeDetailsInAlert = true;
  @property({ type: String })
  alertMessage = '';

  protected get preventDelete(): boolean {
    return this.entityView.hasEvents;
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    if (!this.singleUserVersion) {
      builder.addText('tasks_function', this.entityView.assignedFunctionName, 'm', true);
      builder.addText('tasks_responsible', this.entityView.responsibleFunctionName, 'm', true);
    }
    builder.addText('tasks_situation', this.entityView.situation, 'l', true);
    if (this.entityView.smartTaskEventText) {
      builder.addCustom(() => {
        return html` <d-smart-task-info
          .functionName=${this.entityView.assignedFunctionName}
          .smartTaskEventText=${this.entityView.smartTaskEventText}
        ></d-smart-task-info>`;
      });
    }
    builder.addHtmlContent('tasks_procedures', this.entityView.procedures);
    return builder.build();
  }

  renderLists(): TemplateResult<1> {
    return html`
      <d-list-section-task-events
        .items=${this.entityView.taskEvents}
        .writeAccess="${this.entityView.currentUserHasWriteAccess}"
        .href=${this.entityView.href}
        .taskUuid=${this.entityView.uuid}
        .startTaskText="${this.entityView.startTaskText}"
        icon="events"
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section-task-events>
    `;
  }

  async initializeEditItem() {
    this.editItem = {
      functionUuid: this.entityView.parentEntityId,
      procedures: this.entityView.procedures,
      responsibleFunctionUuid: this.entityView.responsibleFunctionUuid,
      situation: this.entityView.situation,
      pages: this.entityView.pages,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: TaskViewEditItem): TemplateResult<1> {
    return html`${!this.entityView.hasEvents
        ? nothing
        : html`<d-section>
            <d-view-info field="tasks_hasRelatedEventsText"></d-view-info>
          </d-section>`}

      <d-section>
        <d-edit-text
          field="tasks_name"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      ${this.singleUserVersion
        ? nothing
        : html`<d-section>
            <d-select-dropdown
              field="tasks_function"
              class="minWidth300"
              .options=${this.entityView.availableFunctions}
              .value=${item.functionUuid}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, functionUuid: e.detail.value };
              }}
            >
            </d-select-dropdown>
            <d-select-dropdown
              field="tasks_responsible"
              class="minWidth300"
              .options=${this.entityView.availableFunctionsForResponsible}
              placeholder="Velg ansvarsområde"
              .value=${item.responsibleFunctionUuid}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, responsibleFunctionUuid: e.detail.value };
              }}
            >
            </d-select-dropdown>
          </d-section>`}

      <d-section>
        <d-edit-textarea
          field="tasks_situation"
          .value=${item.situation}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, situation: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-edit-html
        field="tasks_procedures"
        .showChecklist=${true}
        .value=${item.procedures}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, procedures: e.detail.value };
        }}
      ></d-edit-html>

      ${this.singleUserVersion
        ? html`<d-section>
            <d-select-tag
              label="Vis på temaside"
              not-deselectable
              .options=${this.entityView.availablePages}
              .value=${item.pages}
              @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                this.editItem = { ...item, pages: e.detail.value };
              }}
            >
            </d-select-tag>
          </d-section>`
        : html`<d-section>
            <d-select-tag
              label="Vis også på temaside"
              .options=${this.availablePages(item.functionUuid, item.responsibleFunctionUuid)}
              .value=${item.pages}
              @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                this.editItem = { ...item, pages: e.detail.value };
              }}
            >
            </d-select-tag>
          </d-section>`} `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private async onAddEvent(e: CustomEvent) {
    e.preventDefault();
    e.stopPropagation();

    const id = uuid();
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      targetUrl: this.entityView.href + '/eventOccurrences/' + id + '?edit',
      taskUuid: this.entityView.uuid,
    };

    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private availablePages(functionUuid: string, responsibleFunctionUuid: string): SelectTagOption[] {
    const set1 = this.entityView.disabledPagesPerFunction.get(functionUuid) ?? new Set<number>();
    const set2 = this.entityView.disabledPagesPerFunction.get(responsibleFunctionUuid) ?? new Set<number>();
    const disabledPages = new Set<number>([...set1, ...set2]);

    return this.entityView.availablePages.map((x) => ({ ...x, disabled: disabledPages.has(Number(x.value)) }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-task-view': DTaskView;
  }
}
