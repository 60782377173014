/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface MeetingViewModel
 */
export interface MeetingViewModel {
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  procedures?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  functionUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  isNew: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingViewModel
   */
  employeeParticipants?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  draftName?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof MeetingViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof MeetingViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {string}
   * @memberof MeetingViewModel
   */
  classification?: MeetingViewModelClassificationEnum;
  /**
   *
   * @type {boolean}
   * @memberof MeetingViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof MeetingViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingViewModel
   */
  accessControl?: Array<string>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof MeetingViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const MeetingViewModelClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type MeetingViewModelClassificationEnum =
  (typeof MeetingViewModelClassificationEnum)[keyof typeof MeetingViewModelClassificationEnum];

/**
 * Check if a given object implements the MeetingViewModel interface.
 */
export function instanceOfMeetingViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'isNew' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function MeetingViewModelFromJSON(json: any): MeetingViewModel {
  return MeetingViewModelFromJSONTyped(json, false);
}

export function MeetingViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    procedures: !exists(json, 'procedures') ? undefined : json['procedures'],
    name: !exists(json, 'name') ? undefined : json['name'],
    uuid: json['uuid'],
    functionUuid: !exists(json, 'functionUuid') ? undefined : json['functionUuid'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    isNew: json['isNew'],
    employeeParticipants: !exists(json, 'employeeParticipants') ? undefined : json['employeeParticipants'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function MeetingViewModelToJSON(value?: MeetingViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    procedures: value.procedures,
    name: value.name,
    uuid: value.uuid,
    functionUuid: value.functionUuid,
    helpContent: value.helpContent,
    isNew: value.isNew,
    employeeParticipants: value.employeeParticipants,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    classification: value.classification,
    deleted: value.deleted,
    pages: value.pages,
    accessControl: value.accessControl,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
