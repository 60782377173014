import { css, html } from 'lit';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/lists/d-list-section-start-tasks.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import './d-skil-course-section.js';
import { customElement, property } from 'lit/decorators.js';
import type { ListSectionInput } from 'src/library/lists/list-section-input.js';
import type {
  IssuesGroupedListSection,
  OverviewPageViewModel,
  OverviewSection,
  PageTasksListSection,
} from './overview-page-view-model.js';
import type { ItemGroup } from 'src/pages/organization-page/organization-page-view-model.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';

/**
 *
 * STATUS OK
 */
@customElement('d-overview-page-content')
export class DOverviewPageContent extends PageContent<OverviewPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-skil-course-section {
        margin-bottom: 20px;
      }
      d-list-section {
        margin-bottom: 6px;
      }
    `,
  ];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Boolean })
  themePage = true;

  renderListSection(list: ListSectionInput) {
    return html`<d-list-section
      .label=${list.label ?? ''}
      field="${ifDefined(list.field)}"
      .icon=${list.icon}
      .items=${list.items ?? []}
      ?writeAccess=${this.pageView.writeAccess}
      .themePage=${this.themePage}
      .contentStickyTop=${this.contentStickyTop}
      .actions=${list.actions ?? []}
      @action=${(e: CustomEvent) => this.onListAction(e.detail)}
    ></d-list-section>`;
  }

  renderList(list: OverviewSection) {
    switch (list.type) {
      case 'skil-list':
        return html`<d-skil-course-section page-id="79"></d-skil-course-section>`;
      case 'start-tasks':
        return html` <d-list-section-start-tasks
          .items=${list.items}
          .showAll=${true}
          .pageName=${this.pageView.name}
        ></d-list-section-start-tasks>`;
      case 'list':
        return this.renderListSection(list);
      case 'page-tasks':
        return this.renderPageTasksListSection(list);
      case 'issues-grouped':
        return this.renderGroupedListSection(list);
    }
  }

  renderGroupedListSection(list: IssuesGroupedListSection) {
    return html`
      <d-list-section
        theme-page
        .items=${this.asListItem(list.items)}
        icon="issues"
        field="${list.field}"
        organization-id="${this.pageView.organizationId}"
        write-access="${this.writeAccess}"
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }

  renderContent() {
    return html` ${this.pageView.lists.map((list) => this.renderList(list))} `;
  }

  private onListAction(action: string) {
    alert('Unhandled action: ' + action);
  }

  private asListItem(list: ItemGroup[]): ListSectionItemInput[] {
    return list.map((v) => ({
      label: v.label,
      accessible: false,
      sublistToggle: v.filtered,
      sublistHidden: v.filtered,
      items: v.items.map((item) => ({
        label: item.dateDisplay + ' ' + item.label,
        href: item.href,
        locked: item.locked,
        accessible: item.accessible,
        hasDraft: item.hasDraft,
      })),
    }));
  }

  private renderPageTasksListSection(list: PageTasksListSection) {
    const m: ListSectionItemInput[] = list.functionsWithTasks.map((i) => ({
      label: i.name,
      secondaryLabel: i.assignedNames,
      href: i.href,
      updateStatus: i.updateStatus,
      hasDraft: i.hasDraft,
      accessible: true,
      limitItems: 5,
      items: i.tasks.map((t) => ({
        label: t.name,
        locked: false,
        accessible: true,
        updateStatus: t.updateStatus,
        hasDraft: t.hasDraft,
        href: t.href,
      })),
    }));
    return html`
      <d-list-section
        theme-page
        extra-spacing
        .items=${m}
        icon="functions"
        field="organization_functions"
        sublabel="og rutiner"
        organization-id="${this.pageView.organizationId}"
        write-access="${this.writeAccess}"
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-overview-page-content': DOverviewPageContent;
  }
}
