import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { DEditSelectText, Suggestion } from 'src/library/editors/components/d-edit-select-text';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { Occurrence } from 'src/library/lists/d-occurrences-list';

import '../library/elements/d-smooth-resize';
import '../library/elements/d-section';
import '../library/elements/d-label';
import '../library/editors/components/d-edit-select-text';
import '../library/editors/elements/d-select-dropdown';
import './d-confirmation-on-occurrences';
import { levenshteinDistance, uuid } from 'src/utilities/text';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';

export interface ModelForEntityName {
  name: string;
  type: string;
  masterEntityUuid: string; // task or meeting
  futureOccurrences: Occurrence[];
}

/**
 *
 */
@customElement('d-edit-entity-name')
export class DEditEntityName extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    .actions {
      text-align: right;
      margin-right: -8px;
    }
    .interview-suggestions {
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      padding: 12px 0;
    }
    .interview-suggestions ul {
      list-style-type: none;
      margin: 6px 0 0 0;
      padding: 0;
    }
    .interview-suggestions li {
      padding: 4px 0;
      color: var(--themeColor);
      cursor: pointer;
    }
    @media (hover: hover) {
      .interview-suggestions li:hover {
        color: black;
      }
    }
  `;
  @property({ type: Boolean })
  autofocus = false;
  @property({ type: Boolean })
  newItem = false;
  @property({ type: Array })
  assets: string[] = [];
  @property({ type: String })
  entityType = 'event';
  @property({ type: String })
  placeholder = '';
  @property({ type: String })
  parentHref = '';
  @property({ type: String })
  name = '';
  @property({ type: String })
  originalName = '';
  @property({ type: String })
  currentEntityUuid = '';
  @property({ type: String })
  masterEntityUuid = '';
  @property({ type: Array })
  models: ModelForEntityName[] = [];
  @property({ type: Array })
  taskOptions: SelectDropdownOption[] = [];
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Boolean })
  nameConfirmedOnInterview = false;
  @property({ type: Boolean })
  nameConfirmedOnOccurrence = false;
  @property({ type: Boolean })
  bypassConfirmations = false;
  @property({ type: Number })
  organizationId = 0;
  @query('#name')
  nameInput!: DEditSelectText;
  @state()
  private nameSelected = false;

  private get suggestions(): Suggestion[] {
    return this.models.map((e) => {
      return { name: e.name, type: e.type };
    });
  }

  private get taskOptionsAndNone() {
    return [{ value: '', text: 'Ingen' }].concat(this.taskOptions);
  }

  private get sameNameTask() {
    return this.taskOptions.find((t) => {
      return t.text === this.name;
    });
  }

  private get model() {
    return this.models.find((m) => {
      return m.name === this.name;
    });
  }

  private get otherFutureOccurrences(): Occurrence[] {
    if (this.model && this.model.futureOccurrences) {
      return this.model.futureOccurrences.filter((o) => {
        return o.occurrenceUuid !== this.currentEntityUuid;
      });
    }
    return [];
  }

  private get showConfirmationOnInterview() {
    if (this.bypassConfirmations) {
      return false;
    }
    return this.nameSelected && this.inteviewSuggestions.length > 0 && !this.nameConfirmedOnInterview;
  }

  private get showConfirmationOnOccurrences() {
    if (this.bypassConfirmations) {
      return false;
    }
    return (
      this.nameSelected &&
      this.otherFutureOccurrences.length > 0 &&
      !this.showConfirmationOnInterview &&
      !this.nameConfirmedOnOccurrence
    );
  }

  onNameFocused() {
    this.nameSelected = false;
    this.nameConfirmedOnInterview = false;
    this.nameConfirmedOnOccurrence = false;
    this.dispatchEvent(
      new CustomEvent('name-focused', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  onNameChanged(e: CustomEvent<{ value: string }>) {
    this.name = e.detail.value;
  }

  onNameSelected(e: CustomEvent<{ value: string }>) {
    this.name = e.detail.value;
    this.setNameSelected();
  }

  setNameSelected() {
    this.nameSelected = true;
    this.nameInput.dismissOptions();
    if (this.sameNameTask) {
      this.masterEntityUuid = this.sameNameTask.value;
    } else if (this.model) {
      this.masterEntityUuid = this.model.masterEntityUuid;
    }
    if (!this.showConfirmationOnInterview && !this.showConfirmationOnOccurrences) {
      this.done();
    }
    setTimeout(() => {
      this.nameInput.blur();
    }, 0);
  }

  onTaskSelected(e: CustomEvent<{ value: string }>) {
    if (e.detail.value) {
      this.masterEntityUuid = e.detail.value;
      this.name =
        this.taskOptions.find((t) => {
          return t.value === e.detail.value;
        })?.text || '';
      this.setNameSelected();
    }
  }

  onClickNext(e) {
    e.stopPropagation();
    if (!e.target.disabled) {
      this.setNameSelected();
    }
  }

  onCancelOnInterview() {
    setTimeout(() => {
      this.nameInput.focusInput();
    }, 0);
  }

  onConfirmOnOccurrences() {
    this.nameConfirmedOnOccurrence = true;
    this.done();
  }

  private onCancel() {
    this.name = this.originalName;
    this.nameConfirmedOnInterview = true;
    this.nameConfirmedOnOccurrence = true;
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
      }),
    );
    setTimeout(() => {
      this.nameInput.blur();
    }, 0);
  }

  private done() {
    this.dispatchEvent(
      new CustomEvent('done', {
        bubbles: true,
        composed: true,
        detail: {
          name: this.name,
          masterEntityUuid: this.masterEntityUuid,
        },
      }),
    );
  }

  private get interviews(): ModelForEntityName[] {
    return this.models.filter((m) => {
      return m.name.includes('Medarbeidersamtale med ');
    });
  }

  private get inteviewSuggestions(): ModelForEntityName[] {
    if (
      this.interviews
        .map((i) => {
          return i.name;
        })
        .includes(this.name)
    ) {
      return [];
    }
    const nameParts = this.name.toLowerCase().split(' ');
    const matchingInteviews = this.interviews.filter((i) => {
      let result = false;
      const interviewNameParts = i.name.replace('Medarbeidersamtale med ', '').split(' ');
      interviewNameParts.forEach((iPart) => {
        nameParts.forEach((part) => {
          if (levenshteinDistance(part, iPart) < 2) {
            result = true;
          }
        });
      });
      return result;
    });
    if (
      (this.name.toLowerCase().includes('medarbeider') || this.name.toLowerCase().includes('samtale')) &&
      matchingInteviews.length > 0
    ) {
      return matchingInteviews;
    }
    let interviewNoEmployees = false;
    nameParts.forEach((part) => {
      if (levenshteinDistance(part, 'medarbeidersamtale') < 4 && matchingInteviews.length === 0) {
        interviewNoEmployees = true;
      }
    });
    if (interviewNoEmployees) {
      return this.interviews;
    }
    return [];
  }

  selectInterview(name) {
    this.name = name;
    this.setNameSelected();
  }

  createEmployee() {
    const id = uuid();
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: {
          entityType: 'employees',
          entityUuid: id,
          targetUrl: '/account/' + this.organizationId + '/104/employees/' + id + '?edit',
        },
      }),
    );
  }

  render() {
    return html`
      <d-section>
        <d-edit-select-text
          id="name"
          select-on-focus
          label="Navn"
          .autofocus=${this.autofocus}
          placeholder="${this.placeholder}"
          .value=${this.name}
          .items=${this.suggestions}
          @focus=${() => this.onNameFocused()}
          @value-changed=${(e: CustomEvent<{ value: string }>) => this.onNameChanged(e)}
          @value-selected=${(e) => this.onNameSelected(e)}
        ></d-edit-select-text>
      </d-section>
      ${this.taskOptions.length
        ? html`
            <d-section topless>
              <d-select-dropdown
                label="Tilknyttet rutine"
                .labelAction=${this.masterEntityUuid === '' ? '' : 'Fjern'}
                label-action-plain
                .options=${this.taskOptionsAndNone}
                .value=${this.masterEntityUuid}
                @value-changed=${(e: CustomEvent<{ value: string }>) => this.onTaskSelected(e)}
                @label-action=${() => (this.masterEntityUuid = '')}
              >
              </d-select-dropdown>
            </d-section>
          `
        : nothing}
      <d-smooth-resize>
        ${!this.nameSelected && !this.nameConfirmedOnOccurrence && !this.bypassConfirmations
          ? html`
              <d-section topless>
                <div class="actions">
                  <d-action @click=${() => this.onCancel()}>Avbryt</d-action>
                  <d-action ?disabled=${!this.name} @click=${(e) => this.onClickNext(e)}>Neste</d-action>
                </div>
              </d-section>
            `
          : nothing}
      </d-smooth-resize>
      <d-smooth-resize>
        ${this.showConfirmationOnInterview
          ? html`
              <div class="interview-suggestions">
                <d-label label="Vil du lage en medarbeidersamtale?"></d-label>
                <ul>
                  ${this.inteviewSuggestions.map((s) => {
                    return html`<li @click=${() => this.selectInterview(s.name)}>${s.name}</li>`;
                  })}
                  <li @click=${() => this.createEmployee()}>Legg til ny medarbeider</li>
                  <li @click=${() => this.onCancelOnInterview()}>Avbryt</li>
                </ul>
              </div>
            `
          : nothing}
      </d-smooth-resize>
      <d-smooth-resize>
        ${this.showConfirmationOnOccurrences
          ? html`
              <d-confirmation-on-occurrences
                .contentStickyTop=${this.contentStickyTop}
                .newItem=${this.newItem}
                .entityType=${this.entityType}
                .occurrences=${this.otherFutureOccurrences}
                .assets=${this.assets}
                .parentHref=${this.parentHref}
                .entityName=${this.name}
                @confirm=${() => this.onConfirmOnOccurrences()}
                @cancel=${() => this.onCancel()}
              ></d-confirmation-on-occurrences>
            `
          : nothing}
      </d-smooth-resize>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-entity-name': DEditEntityName;
  }
}
