import type { State } from '../types.js';
import type { PageViewModel } from '../api';

export function getPages(state: State): PageViewModel[] {
  return state.pages;
}

export function _pageName(pageId: number, pages: PageViewModel[]): string {
  // Special case: home page
  if (pageId === 2300) {
    return 'Min side';
  }

  const arrayLength = pages.length;

  for (let i = 0; i < arrayLength; i++) {
    if (pages[i].pageId === pageId) {
      return pages[i].name;
    }

    if (pages[i].subPages) {
      const subPagesLength = pages[i].subPages.length;
      for (let j = 0; j < subPagesLength; j++) {
        if (pages[i].subPages[j].pageId === pageId) {
          return pages[i].subPages[j].name;
        }
      }
    }
  }
  return '';
}

export function pageViewModel(pageId: number, pages: PageViewModel[]): PageViewModel {
  // Special case: home page
  if (pageId === 2300) {
    return { pageId: 2300, name: 'Min side', order: 0, helpContent: '', subPages: [] };
  }

  for (const item of pages) {
    if (item.pageId === pageId) {
      return item;
    }

    if (item.subPages) {
      const subPagesLength = item.subPages.length;
      for (let j = 0; j < subPagesLength; j++) {
        if (item.subPages[j].pageId === pageId) {
          return item.subPages[j];
        }
      }
    }
  }
  throw new Error('IllegalState (E902), pageId ' + pageId);
}

export function isPageId(pageId: number, pages: PageViewModel[]): boolean {
  // Special case: home page
  if (pageId === 2300) {
    return true;
  }

  const arrayLength = pages.length;

  for (let i = 0; i < arrayLength; i++) {
    if (pages[i].pageId === pageId) {
      return true;
    }

    if (pages[i].subPages) {
      const subPagesLength = pages[i].subPages.length;
      for (let j = 0; j < subPagesLength; j++) {
        if (pages[i].subPages[j].pageId === pageId) {
          return true;
        }
      }
    }
  }
  return false;
}

export function _parentPage(
  pageId: number,
  pages: PageViewModel[],
  basePath: string,
): { name: string; href: string } | undefined {
  const arrayLength = pages.length;

  for (let i = 0; i < arrayLength; i++) {
    if (pages[i].pageId === pageId) {
      return undefined;
    }

    if (pages[i].subPages) {
      const subPagesLength = pages[i].subPages.length;
      for (let j = 0; j < subPagesLength; j++) {
        if (pages[i].subPages[j].pageId === pageId) {
          return {
            name: pages[i].name,
            href: basePath + '/' + pages[i].pageId,
          };
        }
      }
    }
  }
  return undefined;
}
