import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ReassignFunctionsDialog, ReassignFunctionsResult } from 'src/content/employees/reassign-functions-dialog.js';
import { AbstractEntityView, contentViewBuilder, EntityContent, toLookup } from 'src/content/entity-content.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access.js';
import type { EmployeeFunctionItem } from 'src/library/lists/d-list-section-employee-functions.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import {
  EditWorkSchedulesDialog,
  EditWorkSchedulesResult,
} from 'src/pages/staffing-page/edit-work-schedules-dialog.js';
import type { EmployeeForStaffingCalendar } from 'src/store/selectors';
import { EmployeeViewModelGenderEnum, FunctionViewModel, WorkSchedule } from 'src/store/api';
import { associationTypes } from 'src/store/selectors/selectors';
import 'src/utilities/display-alert';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-access.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section-employee-functions.js';
import '../../library/lists/d-list-section-tutorials.js';
import './d-select-profession.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { ActionInput } from 'src/library/elements/d-action';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';

export interface EmployeeViewEditItem {
  lastName: string;
  expertise: string;
  firstName: string;
  profession: string;
  associationType: string;
  accessLevel: string;
  hprNumber: string;
  herNumber: string;
  status: string;
  nextOfKin: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  address: string;
  gender: EmployeeViewModelGenderEnum;
  notes: string;

  functionAssignments: { functionUuid: string; employeeUuid: string }[];
}

export interface EmployeeView extends AbstractEntityView<EmployeeViewEditItem> {
  speciality: string;
  lastName: string;
  firstName: string;
  expertise: string;
  nextOfKin: string;
  type: 'employees';
  name: string;
  profession: string;
  associationType: string;
  accessLevel: string;
  hprNumber: string;
  herNumber: string;
  status: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  address: string;
  gender: EmployeeViewModelGenderEnum;
  notes: string;
  workHoursDescription: string;
  assignedFunctions: EmployeeFunctionItem[];
  unassignedFunctions: EmployeeFunctionItem[];
  contracts: ListSectionItemInput[];
  tutorials: ListSectionItemInput[];
  isCurrentOwner: boolean;
  isCurrentUser: boolean;
  employeesForReassignment: { uuid: string; firstName: string; lastName: string }[];
  functionsForReassignment: { uuid: string; name: string; type: string }[];
  inactiveEmployeeIdForReassignment: string;
  otherUserEmails: string[];
  forStaffing?: EmployeeForStaffingCalendar;
  currentUserHasStaffingAccess: boolean;
}

/**
 *
 *
 *
 *
 *
 */
@customElement('d-employee-view')
export class DEmployeeView extends EntityContent<EmployeeView, EmployeeViewEditItem> {
  static readonly styles = EntityContent.styles;

  /*
    _nameChanged(firstName, lastName) {
      this.editItemName = firstName + ' ' + lastName;
    }

     */
  genderOptions = [
    { value: EmployeeViewModelGenderEnum.Female.valueOf(), text: 'Kvinne' },
    { value: EmployeeViewModelGenderEnum.Male.valueOf(), text: 'Mann' },
  ];
  statuses = [
    { value: 'ACTIVE', text: 'Aktiv' },
    { value: 'LEAVE', text: 'Permisjon/sykmeldt' },
    { value: 'TERMINATED', text: 'Sluttet' },
  ];
  accessLevelsNoAccess = [{ uuid: 'NONE', value: 'Ingen tilgang' }];
  @property({ type: String })
  entityType = 'employees';
  @property({ type: Boolean })
  hideHerId = false;
  @property({ type: Boolean })
  hideProfessionsSelect = false;
  /*
    beforeSave() {
      if (this._notEmpty(this.editItem.email) && !this._hasValidEmail(this.editItem.email)) {
        dabihAlert('Epostadressen er ikke gyldig');
        return true;
      }

      if (this._hasNoDuplicateEmailInOrganization(this.entityId, this.editItem.email)) {
        dabihAlert('Epostadressen er allerede registrert på en annen bruker');
        return true;
      }

      if (!this.editItem.functionAssignments) {
        this.editItem.functionAssignments = [];
      }

      return false;
    }

   */
  @property({ type: Object })
  user: any = {};
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Array })
  employees = [];
  @property({ type: Array })
  functions: FunctionViewModel[] = [];
  private accessLevelLookup = toLookup({
    NONE: 'Ingen',
    READONLY: 'Lesetilgang',
    USER: 'Skrivetilgang',
  });
  private genderLookup = toLookup({
    FEMALE: 'Kvinne',
    MALE: 'Mann',
  });
  private statusLookup = toLookup({
    ACTIVE: 'Aktiv',
    LEAVE: 'Permisjon/sykmeldt',
    TERMINATED: 'Sluttet',
  });

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    const list: { field: string; value: string | undefined }[] = [];

    list.push({ field: 'employees_profession', value: this.entityView.profession });
    list.push({ field: 'employees_associationType', value: this.entityView.associationType });
    if (!this.singleUserVersion) {
      list.push({ field: 'employees_accessLevel', value: this.accessLevelLookup.get(this.entityView.accessLevel) });
    }
    if (this.entityView.hprNumber) {
      list.push({ field: 'employees_hprNumber', value: this.entityView.hprNumber });
    }
    list.push({ field: 'employees_herNumber', value: this.entityView.herNumber });
    if (!this.singleUserVersion) {
      list.push({ field: 'employees_status', value: this.statusLookup.get(this.entityView.status) });
    }

    builder.addTextList(list, 'm');

    const listRight: { field: string; value: string | undefined }[] = [];

    listRight.push({ field: 'employees_phone', value: this.entityView.phone });
    listRight.push({ field: 'employees_secondaryPhone', value: this.entityView.secondaryPhone });
    listRight.push({ field: 'employees_email', value: this.entityView.email });
    if (this.entityView.address) {
      listRight.push({ field: 'employees_address', value: this.entityView.address });
    }
    if (!this.singleUserVersion) {
      listRight.push({ field: 'employees_gender', value: this.genderLookup.get(this.entityView.gender) });
    }
    builder.addTextList(listRight, 'm');

    builder.addText('employees_nextOfKin', this.entityView.nextOfKin, 'l', true);
    builder.addTextIfNotEmpty('employees_notes', this.entityView.notes, 'l');
    const action = this.entityView.currentUserHasStaffingAccess ? () => this.editWorkSchedules() : undefined;

    builder.addTextWithEditAction('Arbeidstid', this.entityView.workHoursDescription, action);

    return builder.build();
  }

  private get noName(): boolean {
    return !!(
      this.editItem &&
      isEmptyOrInvalidString(this.editItem.firstName) &&
      isEmptyOrInvalidString(this.editItem.lastName)
    );
  }
  renderLists(): TemplateResult<1> {
    const actions: ActionInput[] = [
      {
        name: 'Legg til',
        action: 'create-contract',
        slot: 'top-right',
      },
    ];

    return html`
      ${this.entityView.status === 'TERMINATED'
        ? nothing
        : html`
            <d-list-section-employee-functions
              .assignedFunctions=${this.entityView.assignedFunctions}
              .unassignedFunctions=${this.entityView.unassignedFunctions}
              .employeeStatus=${this.entityView.status}
              .writeAccess=${this.entityView.currentUserHasWriteAccess}
              .contentStickyTop=${this.contentStickyTop}
              @assign-function=${this.onAssignFunction}
              @create-responsibility=${this.onCreateResponsibility}
            ></d-list-section-employee-functions>
          `}
      <d-list-section
        field="organization_contracts"
        icon="contracts"
        .items=${this.entityView.contracts}
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        .actions=${actions}
        .contentStickyTop=${this.contentStickyTop}
        @action=${(e: CustomEvent) => this.onListActionForContract(e.detail)}
      ></d-list-section>
      ${this.entityView.tutorials.length > 0
        ? html` <d-list-section
            label="Kurs"
            icon="contracts"
            .items=${this.entityView.tutorials}
            .writeAccess=${this.entityView.currentUserHasWriteAccess}
            .contentStickyTop=${this.contentStickyTop}
            @item-clicked=${this.tutorialClicked}
          ></d-list-section>`
        : nothing}
    `;
  }

  async initializeEditItem() {
    this.editItem = {
      accessLevel: this.entityView.accessLevel,
      address: this.entityView.address,
      associationType: this.entityView.associationType,
      email: this.entityView.email,
      expertise: this.entityView.expertise,
      firstName: this.entityView.firstName,
      gender: this.entityView.gender,
      herNumber: this.entityView.herNumber,
      hprNumber: this.entityView.hprNumber,
      lastName: this.entityView.lastName,
      notes: this.entityView.notes,
      phone: this.entityView.phone,
      profession: this.entityView.profession,
      secondaryPhone: this.entityView.secondaryPhone,
      status: this.entityView.status,
      nextOfKin: this.entityView.nextOfKin,
      functionAssignments: [],
    };
  }

  editUser(item: EmployeeViewEditItem): UserForAccess {
    return {
      uuid: this.entityView.uuid,
      email: item.email,
      accessLevel: item.accessLevel,
      accessExpires: '',
      name: this.entityView.name,
      partnerName: '',
    };
  }

  doneDisabled(): boolean {
    return this.noName;
  }

  renderEditItem(item: EmployeeViewEditItem): TemplateResult<1> {
    return html`
      <d-section>
        <d-edit-text
          class="minWidth200"
          field="employees_firstName"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="${this.noName ? 'Personen må ha et navn' : ''}"
          ?mark-if-empty="${this.noName}"
          .value=${item.firstName}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, firstName: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="employees_lastName"
          placeholder="${this.noName ? 'Personen må ha et navn' : ''}"
          ?mark-if-empty="${this.noName}"
          .value=${item.lastName}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, lastName: e.detail.value };
          }}
        ></d-edit-text>
        ${this.singleUserVersion
          ? nothing
          : html` <d-select-tag
              style="flex: none"
              field="employees_gender"
              .options=${this.genderOptions}
              .value=${[item.gender]}
              single
              @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                this.editItem = {
                  ...item,
                  gender:
                    e.detail.value.length > 0
                      ? (e.detail.value[0] as EmployeeViewModelGenderEnum)
                      : EmployeeViewModelGenderEnum.Undefined,
                };
              }}
            >
            </d-select-tag>`}
      </d-section>
      ${this.singleUserVersion
        ? nothing
        : html` <d-edit-access
            .isSelf=${this.entityView.isCurrentUser}
            .isOwner=${this.entityView.isCurrentOwner}
            .originalEmail=${this.entityView.email}
            .emailValue=${item.email}
            field="contacts_accessLevel"
            .value=${item.accessLevel}
            .editUser=${this.editUser(item)}
            .otherUserEmails=${this.entityView.otherUserEmails}
            @value-changed=${(e: CustomEvent<{ accessLevel: 'NONE' | 'USER'; email: string }>) => {
              this.editItem = { ...item, accessLevel: e.detail.accessLevel, email: e.detail.email };
            }}
          >
          </d-edit-access>`}

      <d-section>
        <d-edit-text
          class="minWidth200"
          field="employees_phone"
          .value=${item.phone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, phone: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="employees_secondaryPhone"
          .value=${item.secondaryPhone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, secondaryPhone: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-section>
        ${this.hideProfessionsSelect
          ? html` <d-edit-text
              class="minWidth300"
              field="employees_professionalTitle"
              .value=${item.profession}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = {
                  ...item,
                  profession: e.detail.value,
                  expertise: '',
                };
              }}
            ></d-edit-text>`
          : html` <d-select-profession
              class="minWidth300"
              speciality="${item.expertise}"
              .value=${item.profession}
              @value-changed=${(e: CustomEvent<{ value: string; speciality: string }>) => {
                this.editItem = {
                  ...item,
                  profession: e.detail.value,
                  expertise: e.detail.speciality,
                };
              }}
            >
            </d-select-profession>`}

        <d-select-dropdown
          class="minWidth300"
          field="employees_associationType"
          .options="${associationTypes.map((t) => ({ value: t, text: t }))}"
          placeholder="Velg"
          .value=${item.associationType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, associationType: e.detail.value };
          }}
        >
        </d-select-dropdown>
      </d-section>

      <d-section>
        ${this.singleUserVersion
          ? nothing
          : html` <d-select-dropdown
              class="minWidth300"
              ?disabled=${this.entityView.isCurrentUser || this.entityView.isCurrentOwner}
              field="employees_status"
              .options="${this.statuses}"
              .value=${item.status}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.onStatusChanged(e);
              }}
            >
            </d-select-dropdown>`}
        ${this.hideHerId
          ? nothing
          : html` <d-edit-text
              class="minWidth300"
              field="employees_hprNumber"
              .value=${item.hprNumber}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, hprNumber: e.detail.value };
              }}
            >
            </d-edit-text>`}
      </d-section>

      <d-section>
        ${this.hideHerId
          ? nothing
          : html` <d-edit-text
              class="minWidth300"
              field="employees_herNumber"
              .value=${item.herNumber}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, herNumber: e.detail.value };
              }}
            >
            </d-edit-text>`}
      </d-section>
      <d-section>
        <d-edit-textarea
          class="minWidth300"
          field="employees_address"
          .value=${item.address}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, address: e.detail.value };
          }}
        ></d-edit-textarea>
      </d-section>
      <d-section>
        <d-edit-textarea
          class="minWidth300"
          field="employees_nextOfKin"
          .value=${item.nextOfKin}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, nextOfKin: e.detail.value };
          }}
        ></d-edit-textarea>
      </d-section>
      <d-section>
        <d-edit-textarea
          field="employees_notes"
          .value=${item.notes}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, notes: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private async onStatusChanged(evt: CustomEvent<{ value: string }>) {
    const selectedStatus = evt.detail.value;
    const currentEditItem = this.editItem;
    if (currentEditItem && selectedStatus !== currentEditItem.status) {
      if (selectedStatus !== 'ACTIVE' && this.entityView.functionsForReassignment.length > 0) {
        await this.reassignForStatusNotActive(currentEditItem, selectedStatus);
      } else {
        this.editItem = {
          ...currentEditItem,
          status: selectedStatus,
          functionAssignments: [],
          accessLevel: selectedStatus === 'TERMINATED' ? 'NONE' : currentEditItem.accessLevel,
        };
      }
    }
  }

  private async reassignForStatusNotActive(currentEditItem: EmployeeViewEditItem, selectedStatus: string) {
    const result: ReassignFunctionsResult = await ReassignFunctionsDialog.open({
      employees: this.entityView.employeesForReassignment,
      functions: this.entityView.functionsForReassignment,
      inactiveEmployeeId: this.entityView.uuid,
    });
    if (result.action === 'confirm') {
      this.editItem = {
        ...currentEditItem,
        functionAssignments: result.assignments,
        status: selectedStatus,
        accessLevel: selectedStatus === 'TERMINATED' ? 'NONE' : currentEditItem.accessLevel,
      };
    } else {
      const previousStatus = currentEditItem.status;
      this.editItem = {
        ...currentEditItem,
        status: selectedStatus,
      };
      setTimeout(() => {
        this.editItem = {
          ...currentEditItem,
          status: previousStatus ?? 'ACTIVE',
        };
        this.requestUpdate();
      }, 0);
    }
  }

  private async editWorkSchedules() {
    const result: EditWorkSchedulesResult = await EditWorkSchedulesDialog.open({
      employee: this.entityView.forStaffing,
      defaultDate: this.entityView.today.toString(),
    });
    if (result.action === 'done') {
      this.dispatchEvent(
        new CustomEvent<{
          employeeUuid: string;
          workSchedules: WorkSchedule[];
        }>('update-employee-work-schedules', {
          bubbles: true,
          composed: true,
          detail: {
            employeeUuid: this.entityView.uuid,
            workSchedules: result.workSchedules,
          },
        }),
      );
    }
  }

  private tutorialClicked(e: CustomEvent<ListSectionItemInput>) {
    e.preventDefault();
    if (e.detail.clickData !== undefined) {
      this.dispatchEvent(
        new CustomEvent<{ value: string }>('update-tutorial', {
          composed: true,
          bubbles: true,
          detail: { value: e.detail.clickData },
        }),
      );
    }
  }

  private onAssignFunction(e: CustomEvent<{ functionUuid: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ functionUuid: string; employeeUuid: string }>('assign-function', {
        bubbles: true,
        composed: true,
        detail: {
          functionUuid: e.detail.functionUuid,
          employeeUuid: this.entityView.uuid,
        },
      }),
    );
  }

  private onCreateResponsibility(e: CustomEvent) {
    e.stopPropagation();
    const id = uuid();
    const input: CreateEntityInput = {
      entityType: 'functions',
      entityUuid: id,
      targetUrl: this.entityView.href + '/functions/' + id + '?edit',
      employeeId: this.entityView.uuid,
      pageId: 65,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private onListActionForContract(action: string) {
    if (action === 'create-contract') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contracts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
        pageId: 63,
        employeeId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-employee-view': DEmployeeView;
  }
}
