/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GuidelineUpdateMessage
 */
export interface GuidelineUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  content?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GuidelineUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof GuidelineUpdateMessage
   */
  classification?: GuidelineUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof GuidelineUpdateMessage
   */
  accessControl?: Array<string>;
}

/**
 * @export
 */
export const GuidelineUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type GuidelineUpdateMessageClassificationEnum =
  (typeof GuidelineUpdateMessageClassificationEnum)[keyof typeof GuidelineUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the GuidelineUpdateMessage interface.
 */
export function instanceOfGuidelineUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GuidelineUpdateMessageFromJSON(json: any): GuidelineUpdateMessage {
  return GuidelineUpdateMessageFromJSONTyped(json, false);
}

export function GuidelineUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuidelineUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    category: !exists(json, 'category') ? undefined : json['category'],
    template: !exists(json, 'template') ? undefined : json['template'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    content: !exists(json, 'content') ? undefined : json['content'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
  };
}

export function GuidelineUpdateMessageToJSON(value?: GuidelineUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    category: value.category,
    template: value.template,
    name: value.name,
    status: value.status,
    content: value.content,
    pages: value.pages,
    classification: value.classification,
    accessControl: value.accessControl,
  };
}
