/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FunctionRotationUpdateMessage } from './FunctionRotationUpdateMessage';
import {
  FunctionRotationUpdateMessageFromJSON,
  FunctionRotationUpdateMessageFromJSONTyped,
  FunctionRotationUpdateMessageToJSON,
} from './FunctionRotationUpdateMessage';

/**
 *
 * @export
 * @interface FunctionUpdateMessage
 */
export interface FunctionUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  responsibility?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  type: FunctionUpdateMessageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  rotation: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionUpdateMessage
   */
  template?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FunctionUpdateMessage
   */
  employees?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof FunctionUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<FunctionRotationUpdateMessage>}
   * @memberof FunctionUpdateMessage
   */
  rotations?: Array<FunctionRotationUpdateMessage>;
}

/**
 * @export
 */
export const FunctionUpdateMessageTypeEnum = {
  Single: 'SINGLE',
  Rotated: 'ROTATED',
  Simultaneous: 'SIMULTANEOUS',
  Common: 'COMMON',
} as const;
export type FunctionUpdateMessageTypeEnum =
  (typeof FunctionUpdateMessageTypeEnum)[keyof typeof FunctionUpdateMessageTypeEnum];

/**
 * Check if a given object implements the FunctionUpdateMessage interface.
 */
export function instanceOfFunctionUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'rotation' in value;

  return isInstance;
}

export function FunctionUpdateMessageFromJSON(json: any): FunctionUpdateMessage {
  return FunctionUpdateMessageFromJSONTyped(json, false);
}

export function FunctionUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    responsibility: !exists(json, 'responsibility') ? undefined : json['responsibility'],
    type: json['type'],
    description: !exists(json, 'description') ? undefined : json['description'],
    rotation: json['rotation'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    category: !exists(json, 'category') ? undefined : json['category'],
    template: !exists(json, 'template') ? undefined : json['template'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    rotations: !exists(json, 'rotations')
      ? undefined
      : (json['rotations'] as Array<any>).map(FunctionRotationUpdateMessageFromJSON),
  };
}

export function FunctionUpdateMessageToJSON(value?: FunctionUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    responsibility: value.responsibility,
    type: value.type,
    description: value.description,
    rotation: value.rotation,
    name: value.name,
    status: value.status,
    category: value.category,
    template: value.template,
    employees: value.employees,
    pages: value.pages,
    rotations:
      value.rotations === undefined
        ? undefined
        : (value.rotations as Array<any>).map(FunctionRotationUpdateMessageToJSON),
  };
}
