import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';
import { SelectDropdownOption } from '../elements/d-select-dropdown.js';

/**
 *
 * STATUS OK
 */
@customElement('d-select-time')
export class DSelectTime extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: flex;
      }

      d-select-dropdown {
        flex: none;
        margin-right: 4px;
      }

      :host([whole-day-option]) d-select-dropdown:first-child {
        margin-right: 8px;
      }

      d-select-dropdown:last-child,
      :host([small]) d-select-dropdown {
        margin-right: 0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--narrow), sans-serif;
        font-size: 14px;
        font-weight: 300;
      }
    `,
  ];
  /**
   * Klokkeslett. Format '12:35'. Merk at minutter kun har 5 minutts oppløsning
   *
   */
  @property({ type: String })
  value = '00:00';
  /**
   * Min tid.
   */
  @property({ type: String })
  min = '00:00';
  /**
   * Maks tid.
   */
  @property({ type: String })
  max = '23:55';
  @property({ type: Boolean, attribute: 'whole-day-option', reflect: true })
  wholeDayOption = false;
  @property({ type: Boolean, attribute: 'no-arrow', reflect: true })
  noArrow = false;
  @property({ type: Boolean, reflect: true })
  invalid = false;
  @property({ type: Boolean, reflect: true })
  small = false;
  @property({ type: Boolean, reflect: true })
  disabled = false;
  @property({ type: Boolean, reflect: true })
  controller = false;

  zeropad(n) {
    return ('00' + n).slice(-2);
  }

  get hour() {
    if (this.value === 'NONE') {
      return 'NONE';
    }
    return this.value.split(':')[0] || '00';
  }

  get minute() {
    return this.value.split(':')[1] || '00';
  }

  private get hoursNumber() {
    return Number(this.hour);
  }

  private get maxHoursNumber() {
    if (this.max) {
      return Number(this.max.split(':')[0]);
    }
    return 24;
  }

  private get maxMinutesNumber() {
    if (this.max) {
      return Number(this.max.split(':')[1]);
    }
    return 55;
  }

  private get minHoursNumber() {
    if (this.min) {
      return Number(this.min.split(':')[0]);
    }
    return 0;
  }

  private get minMinutesNumber() {
    if (this.min) {
      return Number(this.min.split(':')[1]);
    }
    return 0;
  }

  private get hoursOptions() {
    const result: SelectDropdownOption[] = [];
    let prefix = '';
    if (this.wholeDayOption) {
      result.push({ value: 'NONE', text: 'Heldags' });
      prefix = 'Kl. ';
    }
    let n = 0;
    while (n < this.maxHoursNumber + 1) {
      if (n >= this.minHoursNumber && n <= this.maxHoursNumber) {
        result.push({ value: this.zeropad(n), text: prefix + this.zeropad(n) });
      }
      n++;
    }
    return result;
  }

  addMinutesOption(n) {
    if (this.hoursNumber === this.maxHoursNumber && n > this.maxMinutesNumber) {
      return false;
    }
    return !(this.hoursNumber === this.minHoursNumber && n < this.minMinutesNumber);
  }

  private get minutesOptions() {
    const result: SelectDropdownOption[] = [];
    let n = 0;
    while (n < 60) {
      if (this.addMinutesOption(n)) {
        result.push({ value: this.zeropad(n), text: this.zeropad(n) });
      }
      n += 5;
    }
    return result;
  }

  limitChange() {
    if (this.value !== 'NONE') {
      if (this.min && this.min > this.value) {
        this.value = this.min;
      }
      if (this.max && this.max < this.value) {
        this.value = this.max;
      }
    }
  }

  onHoursValueChanged(e: CustomEvent<{ value: string }>) {
    e.preventDefault();
    e.stopPropagation();
    let hour = 'NONE';
    let minute = '00';
    if (this.value !== 'NONE') {
      hour = this.value.split(':')[0];
      minute = this.value.split(':')[1];
    }
    if (e.detail.value === 'NONE') {
      this.value = 'NONE';
    } else {
      hour = e.detail.value;
      this.value = hour + ':' + minute;
    }
    this.limitChange();
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  onMinutesValueChanged(e: CustomEvent<{ value: string }>) {
    e.preventDefault();
    e.stopPropagation();
    const hour = this.value.split(':')[0];
    const minute = e.detail.value;
    this.value = hour + ':' + minute;
    this.limitChange();
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  protected renderContent() {
    return html`
      <d-select-dropdown
        ?no-arrow="${!this.wholeDayOption || this.noArrow}"
        ?invalid="${this.invalid}"
        ?small=${this.small}
        ?controller=${this.controller}
        .options=${this.hoursOptions}
        .value=${this.hour}
        ?disabled=${this.disabled}
        @value-changed=${this.onHoursValueChanged}
      ></d-select-dropdown>
      ${this.small ? html`<div>:</div>` : nothing}
      ${this.value === 'NONE'
        ? nothing
        : html`
            <d-select-dropdown
              ?no-arrow="${!this.wholeDayOption || this.noArrow}"
              ?invalid="${this.invalid}"
              ?small=${this.small}
              ?controller=${this.controller}
              ?disabled=${this.disabled}
              .options=${this.minutesOptions}
              .value=${this.minute}
              @value-changed=${this.onMinutesValueChanged}
            ></d-select-dropdown>
          `}
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('min') || _changedProperties.has('max')) {
      this.limitChange();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-time': DSelectTime;
  }
}
