import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EventList } from 'src/library/abstracts/event-list.js';
import { leavePeriodStyles } from 'src/library/leave-period-styles.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../pages/home-page/d-pikaweek.js';
import '../elements/d-action.js';
import '../elements/d-tooltip.js';
import '../fields/d-expansion.js';
import './d-list-header.js';
import './d-list-section.js';
import '../components/d-positioner';
import { LeavePeriodSummary } from 'src/store/selectors';
import { LeavePeriodType } from 'src/pages/staffing-page/d-staffing-calendar-data.js';
import {
  CreateEntityInput,
  CreateEventOccurrenceInput,
  CreateMeetingOccurrenceInput,
} from 'src/layout/parts/d-new-document';
import { uuid } from 'src/utilities/text';
import { sortBy } from 'lodash';

export interface DayItem {
  date: string;
  holiday: string;
  tasks: ListSectionItemInput[];
}

/**
 *
 */
@customElement('d-calendar-list-section')
export class DCalendarListSection extends EventList<ListSectionItemInput> {
  static readonly styles = [
    leavePeriodStyles,
    css`
      :host {
        display: block;
      }

      d-pikaweek {
        margin-top: 4px;
      }

      d-list-section {
        margin-top: -1px;
      }

      .day.today {
        background: linear-gradient(
          to right,
          var(--backgroundGray) 0,
          white 10%,
          white 90%,
          var(--backgroundGray) 100%
        );
      }

      .day-header-wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 44px; /* height of calendar header */
        background: var(--backgroundGray);
        padding-left: var(--listPaddingLeft);
        z-index: 1;
      }

      .day.today .day-header-wrapper {
        background: linear-gradient(
          to right,
          var(--backgroundGray) 0,
          white 10%,
          white 90%,
          var(--backgroundGray) 100%
        );
      }

      .day-header {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        padding: 10px 0 9px 0;
        color: var(--linkColorGray);
        cursor: pointer;
      }

      .create-element {
        position: absolute;
        right: 0;
        width: 30px;
        font-size: 24px;
        font-weight: 600;
        color: var(--themeColorDarkerOne);
        text-align: right;
        line-height: 18px;
        text-decoration: none;
        display: none;
        cursor: pointer;
      }

      .create-element:hover,
      .create-element:active {
        color: black;
      }

      .day-header-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .day-header-cover:hover:before {
        content: '+';
        display: inline-block;
        position: absolute;
        left: 5px;
        top: 10px;
        font-size: 24px;
        font-weight: 600;
        color: var(--themeColorDarkerOne);
      }

      .day-header-cover:hover + span {
        color: black;
      }

      .holiday {
        color: var(--holidayColor);
      }

      .weekday-name {
        display: inline-block;
        font-weight: bold;
      }

      .weekday-name:first-letter {
        text-transform: uppercase;
      }

      span.today {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        opacity: 0.8;
        display: inline-block;
      }

      .event-type-selector {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: max-content;
        box-shadow: rgba(0, 0, 0, 0.5) 0 5px 15px -5px;
        background: white;
        z-index: 1;
      }

      .event-type-selector > * {
        width: 100%;
        box-sizing: border-box;
        padding: 8px 14px;
        font-size: 15px;
        color: var(--themeColor);
        cursor: pointer;
        z-index: 100;
        text-decoration: none;
      }

      .event-type-selector > *:not(:last-child) {
        border-bottom: 1px solid var(--borderColorLight);
      }

      .event-type-selector > *:hover {
        background: white;
        color: black;
      }

      /* TODO: remove media query, use responsive container class instead */

      @media only screen and (max-width: 600px) {
        .day-header-wrapper {
          padding-left: 0;
        }

        .day-header-cover:hover:before {
          display: none;
        }

        .create-element {
          display: block;
        }
      }
    `,
  ];

  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Boolean })
  canCreateEvents = false;
  @property({ type: Array })
  items = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  layout = 'normal';
  @property({ type: String })
  weekStart = '';
  @property({ type: Array })
  leavePeriods: LeavePeriodSummary[] = [];
  @property({ type: Array })
  calendarTaskInstances: DayItem[] = [];
  @property({ type: Number })
  listHeaderHeight = 0;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Boolean })
  eventTypeSelector = false;
  @property({ type: String })
  selectedDate = '';
  @property({ type: Object })
  cursorPositionOnClick: { left: number; top: number } = { left: 0, top: 0 };

  private get leavePeriodsFiltered(): ListSectionItemInput[] {
    if (this.leavePeriods) {
      const result: ListSectionItemInput[] = [];
      const leavePeriodsSorted = sortBy(this.leavePeriods, (a) => a.start);
      leavePeriodsSorted.forEach((item) => {
        const hidden = !item.myPeriod && !this.showAll;
        result.push({
          locked: false,
          hidden: hidden,
          accessible: false,
          label: item.text,
          eventCurrentUser: true,
          updateStatus: 'none',
          leavePeriodType: item.type as LeavePeriodType,
        });
      });
      return result;
    }
    return [];
  }

  private get hasNotes() {
    const instances: ListSectionItemInput[] = [];
    this.calendarTaskInstances.forEach((i) => {
      if (i.tasks) {
        i.tasks.forEach((t) => {
          if (this.showAll || t.eventCurrentUser) {
            instances.push(t);
          }
        });
      }
    });
    return this._hasNotes(instances);
  }

  _newEventHref(organizationId, date) {
    return '/account/' + organizationId + '/2300/events/new?newEventDate=' + LocalDate.fromString(date).toString();
  }

  _dayClasses(value) {
    if (LocalDate.fromString(value).isSame(LocalDate.now())) {
      return 'day today';
    }
    return 'day';
  }

  _dayHeaderClasses(value) {
    if (LocalDate.fromString(value).isSame(LocalDate.now())) {
      return 'day-header today';
    }
    return 'day-header';
  }

  _weekdayFormat(value) {
    return value ? LocalDate.fromString(value).getDayOfWeekString() : '';
  }

  _dateFormat(value) {
    return value ? LocalDate.fromString(value).toStringForDisplayWithDayAndMonth() : '';
  }

  _today(value) {
    return LocalDate.fromString(value).isSame(LocalDate.now());
  }

  _dayNameClasses(holiday) {
    if (holiday) {
      return 'holiday';
    }
    return '';
  }

  onPikaweekValueChanged(e: CustomEvent<{ value: string }>) {
    this.dispatchEvent(
      new CustomEvent('week-start-changed', {
        bubbles: true,
        composed: true,
        detail: { value: e.detail.value },
      }),
    );
  }

  _setListHeaderHeight(height) {
    this.listHeaderHeight = height;
  }

  onDayClick(e, date) {
    e.preventDefault();
    e.stopPropagation();
    this.selectedDate = date;
    if (this.singleUserVersion) {
      this.onCreateEventOccurrence(e, this.selectedDate);
    } else {
      this.cursorPositionOnClick = {
        left: e.clientX,
        top: e.clientY,
      };
      this.eventTypeSelector = true;
    }
  }

  hideEventTypeSelector() {
    this.eventTypeSelector = false;
    this.selectedDate = '';
    this.cursorPositionOnClick = {
      left: 0,
      top: 0,
    };
  }

  render() {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <div>
        <d-list-header
          theme-page
          replace-label
          bordered
          .actions=${this._actions(this.hasNotes)}
          @action=${(e) => this._fireAction(e.detail)}
          style=${styleMap(listHeaderStyles)}
          @height-changed=${(e) => this._setListHeaderHeight(e.detail)}
        >
          <d-pikaweek
            slot="label-replacement"
            .layout=${this.layout}
            .weekStart=${this.weekStart}
            @value-changed=${this.onPikaweekValueChanged}
          ></d-pikaweek>
        </d-list-header>

        ${this.singleUserVersion
          ? nothing
          : html` <d-list-section .items=${this.leavePeriodsFiltered} bordered no-header theme-page></d-list-section> `}
        ${this.calendarTaskInstances.map(
          (day) => html`
            <div class="${this._dayClasses(day.date)}">
              <div
                class="day-header-wrapper"
                style=${styleMap({ top: this.contentStickyTop + this.listHeaderHeight - 2 + 'px' })}
              >
                <div class="${this._dayHeaderClasses(day.date)}">
                  ${!this.writeAccess
                    ? nothing
                    : html`
                        <div class="create-element" @click=${(e) => this.onDayClick(e, day.date)}>+</div>
                        <div class="day-header-cover" @click=${(e) => this.onDayClick(e, day.date)}></div>
                      `}
                  <span class="${this._dayNameClasses(day.holiday)}">
                    <span class="weekday-name">${this._weekdayFormat(day.date)}</span>
                    <span>${this._dateFormat(day.date)}</span>
                    <span class="holiday-name">${day.holiday}</span>
                    ${!this._today(day.date) ? nothing : html`<span class="today">i dag</span>`}
                  </span>
                </div>
              </div>
              <d-list-section
                .items=${this._eventsWithFilterProperties(day.tasks)}
                ?showNotes=${this.showNotes}
                class="day-tasks-list"
                bordered
                no-header
                theme-page
              ></d-list-section>
            </div>
          `,
        )}
      </div>
      <d-positioner .cursorPosition=${this.cursorPositionOnClick} .top=${-16} .left=${-8}>
        ${this.eventTypeSelector
          ? html`
              <div class="event-type-selector" @click=${this.hideEventTypeSelector}>
                <a href="#" @click=${(e: MouseEvent) => this.onCreateEventOccurrence(e, this.selectedDate)}
                  >Ny oppgave</a
                >
                <a href="#" @click=${(e: MouseEvent) => this.onCreateMeetingOccurrence(e, this.selectedDate)}
                  >Nytt møte</a
                >
              </div>
            `
          : nothing}
      </d-positioner>
    `;
  }

  private onCreateEventOccurrence(e: MouseEvent, date: string) {
    this.hideEventTypeSelector();
    e.preventDefault();
    e.stopPropagation();
    const id = uuid();
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      instance: date,
      targetUrl: '/account/' + this.organizationId + '/2300/eventOccurrences/' + id + '?edit',
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }
  private onCreateMeetingOccurrence(e: MouseEvent, date: string) {
    this.hideEventTypeSelector();
    e.preventDefault();
    e.stopPropagation();
    const id = uuid();
    const input: CreateMeetingOccurrenceInput = {
      entityType: 'meetingOccurrences',
      entityUuid: id,
      instance: date,
      targetUrl: '/account/' + this.organizationId + '/2300/meetingOccurrences/' + id + '?edit',
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-calendar-list-section': DCalendarListSection;
  }
}
