import { css, html, nothing, TemplateResult } from 'lit';
import { PageContent } from 'src/pages/page-content';
import { customElement } from 'lit/decorators.js';
import type { AssetsPageViewModel } from './assets-page-view-model';
import './d-assets-table';
import { isEmpty } from 'lodash';
import { uuid } from 'src/utilities/text';
import { CreateAssetInput, CreateEntityInput } from 'src/layout/parts/d-new-document';

/**
 *
 */
@customElement('d-assets-page-content')
export class DAssetsPageContent extends PageContent<AssetsPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      .registerItemsPrompt {
        width: 280px;
        margin: 40px auto;
        padding: 270px 20px 20px 20px;
        border: 5px dashed transparent;
        border-radius: 30px;
        text-align: center;
        opacity: 0.5;
        cursor: pointer;
        transition:
          background 0.3s,
          padding 0.3s;
        background: transparent url(/images/assets-gray.svg) 50% 40px no-repeat;
        background-size: 200px 200px;
      }

      .registerItemsPrompt:hover {
        opacity: 0.8;
        border-color: silver;
      }
    `,
  ];

  renderContent() {
    return html`
      ${isEmpty(this.pageView.assetsNotDiscarded) && isEmpty(this.pageView.assetsDiscarded)
        ? html`<div class="registerItemsPrompt" @click=${() => this.onCreateAsset()}>
            <h2>Ingen utstyrsenheter er registrert.</h2>
            ${this.pageView.writeAccess ? html`<p>Trykk for å legge til.</p>` : nothing}
          </div>`
        : nothing}
      ${isEmpty(this.pageView.assetsNotDiscarded)
        ? nothing
        : html` <d-assets-table
            .items=${this.pageView.assetsNotDiscarded}
            .sortBy=${'name'}
            .groupBy=${'location'}
            .filter=${true}
            theme-page
            .contentStickyTop=${this.contentStickyTop}
            ?hideRequiresElectricity=${this.pageView.variantSimplifiedAssets}
          ></d-assets-table>`}
      ${isEmpty(this.pageView.assetsDiscarded)
        ? nothing
        : html`
            <h2>Kassert utstyr</h2>
            <d-assets-table
              .items=${this.pageView.assetsDiscarded}
              .sortBy=${'name'}
              .groupBy=${'location'}
              .filter=${true}
              label="Kasserte utstyrsenheter"
              theme-page
              .contentStickyTop=${this.contentStickyTop}
              ?hideRequiresElectricity=${this.pageView.variantSimplifiedAssets}
            ></d-assets-table>
          `}
    `;
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`
      <d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>
      ${this.singleUserVersion
        ? nothing
        : html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`}
    `;
  }

  private onCreateAsset() {
    if (this.pageView.writeAccess) {
      const id = uuid();
      const input: CreateAssetInput = {
        entityType: 'assets',
        entityUuid: id,
        targetUrl: '/account/' + this.pageView.organizationId + '/110/assets/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-assets-page-content': DAssetsPageContent;
  }
}
