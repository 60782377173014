/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DocumentUpdateMessage
 */
export interface DocumentUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  classification: DocumentUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof DocumentUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  validFromDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  validToDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentUpdateMessage
   */
  type?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentUpdateMessage
   */
  accessControl?: Array<string>;
}

/**
 * @export
 */
export const DocumentUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type DocumentUpdateMessageClassificationEnum =
  (typeof DocumentUpdateMessageClassificationEnum)[keyof typeof DocumentUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the DocumentUpdateMessage interface.
 */
export function instanceOfDocumentUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'classification' in value;

  return isInstance;
}

export function DocumentUpdateMessageFromJSON(json: any): DocumentUpdateMessage {
  return DocumentUpdateMessageFromJSONTyped(json, false);
}

export function DocumentUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    classification: json['classification'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    validFromDate: !exists(json, 'validFromDate') ? undefined : json['validFromDate'],
    validToDate: !exists(json, 'validToDate') ? undefined : json['validToDate'],
    category: !exists(json, 'category') ? undefined : json['category'],
    template: !exists(json, 'template') ? undefined : json['template'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    content: !exists(json, 'content') ? undefined : json['content'],
    type: !exists(json, 'type') ? undefined : json['type'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
  };
}

export function DocumentUpdateMessageToJSON(value?: DocumentUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    classification: value.classification,
    pages: value.pages,
    validFromDate: value.validFromDate === undefined ? undefined : value.validFromDate,
    validToDate: value.validToDate === undefined ? undefined : value.validToDate,
    category: value.category,
    template: value.template,
    name: value.name,
    status: value.status,
    content: value.content,
    type: value.type,
    accessControl: value.accessControl,
  };
}
