import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { ReportSection } from 'src/pages/staffing-page/report-section.js';
import type { EmployeeForStaffingCalendar } from 'src/store';

import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-timekeeping-list-section.js';

export interface EmployeeTimekeeping {
  uuid: string;
  name: string;
  saldo: number;
  periods: ListSectionItemInput[];
  forStaffing: EmployeeForStaffingCalendar;
}

/**
 *
 */
@customElement('d-timekeeping-review')
export class DTimekeepingReview extends ReportSection {
  static readonly styles = [
    ...ReportSection.styles,
    css`
      :host {
        display: block;
      }
      d-timekeeping-list-section {
        max-width: 800px;
        margin-bottom: 8px;
      }
    `,
  ];
  /**
   * The default date for new entries. Normally today.
   */
  @property({ type: String })
  defaultDate = '';
  @property({ type: Array })
  employeesWithPeriods: EmployeeTimekeeping[] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;

  constructor() {
    super('Plusstid og avspasering');
  }

  renderContent() {
    return html`
      ${this.employeesWithPeriods.map(
        (employee) =>
          html`<d-timekeeping-list-section
            .employee=${employee.forStaffing}
            .periods=${employee.periods}
            .saldo=${employee.saldo}
            .listHeader=${employee.name}
            .defaultDate=${this.defaultDate}
            .currentUserHasAccess=${this.currentUserHasAccess}
            review
            .icon=${'employees'}
          >
          </d-timekeeping-list-section>`,
      )}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-timekeeping-review': DTimekeepingReview;
  }
}
