import type { ContentView } from 'src/content/d-content.js';
import { buildAssetView } from 'src/models/content/build-asset-view.js';
import { buildConstitutionalDocumentView } from 'src/models/content/build-constitutional-document-view.js';
import { buildContractView } from 'src/models/content/build-contract-view.js';
import { buildDocumentView } from 'src/models/content/build-document-view.js';
import { buildEmployeeView } from 'src/models/content/build-employee-view.js';
import { buildEventOccurrenceView } from 'src/models/content/build-event-occurrence-view.js';
import { buildFunctionView } from 'src/models/content/build-function-view.js';
import { buildGuidelineView } from 'src/models/content/build-guideline-view.js';
import { buildIssueView } from 'src/models/content/build-issue-view.js';
import { buildMeetingView } from 'src/models/content/build-meeting-view.js';
import { buildContactView, buildPartnerView } from 'src/models/content/build-partner-view.js';
import { buildReportView } from 'src/models/content/build-report-view.js';
import { buildRiskAssessmentView } from 'src/models/content/build-risk-assessment-view.js';
import { buildSubstanceView } from 'src/models/content/build-substance-view.js';
import { buildTaskView } from 'src/models/content/build-task-view.js';
import { buildTutorialView } from 'src/models/content/build-tutorial-view.js';
import type { State } from 'src/store/types.js';
import Bugsnag from '@bugsnag/js';
import { buildMeetingOccurrenceView } from 'src/models/content/build-meeting-occurrence-view';

import { getDocsForLinking, convertInternalLinks } from 'src/models/internal-linking.js';

async function buildContentView(type: string, uuid: string, state: State, currentParent: string): Promise<ContentView> {
  console.log('BUILD', type, uuid);
  switch (type) {
    case 'assets':
      return buildAssetView(uuid, state, currentParent);
    case 'constitutionalDocuments':
      return buildConstitutionalDocumentView(uuid, state, currentParent);
    case 'contracts':
      return buildContractView(uuid, state, currentParent);
    case 'documents':
      return buildDocumentView(uuid, state, currentParent);
    case 'employees':
      return buildEmployeeView(uuid, state, currentParent);
    case 'eventOccurrences':
      return buildEventOccurrenceView(uuid, state, currentParent);
    case 'meetingOccurrences':
      return buildMeetingOccurrenceView(uuid, state, currentParent);
    case 'functions':
      return buildFunctionView(uuid, state, currentParent);
    case 'tasks':
      return buildTaskView(uuid, state, currentParent);
    case 'guidelines':
      return buildGuidelineView(uuid, state, currentParent);
    case 'issues':
      return buildIssueView(uuid, state, currentParent);
    case 'meetings':
      return buildMeetingView(uuid, state, currentParent);
    case 'partners':
      return buildPartnerView(uuid, state, currentParent);
    case 'contacts':
      return buildContactView(uuid, state, currentParent);
    case 'reports':
      return buildReportView(uuid, state, currentParent);
    case 'riskAssessments':
      return buildRiskAssessmentView(uuid, state, currentParent);
    case 'substances':
      return buildSubstanceView(uuid, state, currentParent);
    case 'tutorials':
      return buildTutorialView(uuid, state, currentParent);
    default:
      throw new Error('Illegal state (E180), unexpected document type ' + type);
  }
}

export async function currentContentViews(
  hrefPrefix: string,
  pathSegments: string[],
  state: State,
): Promise<ContentView[]> {
  console.log('VIEWS', hrefPrefix, pathSegments, state);
  let p = pathSegments.slice(1);
  let currentParent = hrefPrefix.substring(0, hrefPrefix.length - 1);
  const result: ContentView[] = [];
  try {
    while (p.length > 0) {
      const type = p[0];
      const uuid = p[1];
      p = p.slice(2);
      const contentView = await buildContentView(type, uuid, state, currentParent);
      if ('procedures' in contentView) {
        contentView.procedures = convertInternalLinks(contentView.procedures, getDocsForLinking(state));
      }
      if ('procedureForTaskEvent' in contentView) {
        contentView.procedureForTaskEvent = convertInternalLinks(
          contentView.procedureForTaskEvent,
          getDocsForLinking(state),
        );
      }
      if ('relatedMeetingProcedures' in contentView) {
        contentView.relatedMeetingProcedures = convertInternalLinks(
          contentView.relatedMeetingProcedures,
          getDocsForLinking(state),
        );
      }
      if ('description' in contentView) {
        contentView.description = convertInternalLinks(contentView.description, getDocsForLinking(state));
      }
      if ('content' in contentView) {
        contentView.content = convertInternalLinks(contentView.content, getDocsForLinking(state));
      }
      if ('agenda' in contentView) {
        contentView.agenda = convertInternalLinks(contentView.agenda, getDocsForLinking(state));
      }
      contentView.docsForLinking = getDocsForLinking(state);
      result.push(contentView);
      currentParent += '/' + type + '/' + uuid;
    }
  } catch (e) {
    console.error(e);
    Bugsnag.notify(new Error('Building content view model ' + e));
  }

  return result;
}
