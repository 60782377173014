import { css, html, LitElement, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { uuid } from 'src/utilities/text';
import { LocalDate } from 'src/utilities/local-date';

export interface CreateAssetInput {
  entityType: 'assets';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateEventOccurrenceInput {
  entityType: 'eventOccurrences';
  instance?: string;
  entityUuid: string;
  assetUuid?: string;
  taskUuid?: string;
  targetUrl: string;
}

export interface CreateMeetingOccurrenceInput {
  entityType: 'meetingOccurrences';
  instance: string;
  entityUuid: string;
  meetingUuid?: string;
  templateMeetingOccurrenceUuid?: string;
  employeeUuid?: string;
  targetUrl: string;
}

export interface CreateConstitutionalDocumentInput {
  entityType: 'constitutionalDocuments';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateContactInput {
  entityType: 'contacts';
  entityUuid: string;
  partnerId: string;
  targetUrl: string;
}

export interface CreateContractInput {
  entityType: 'contracts';
  entityUuid: string;
  pageId: number;
  employeeId?: string;
  partnerId?: string;
  targetUrl: string;
}

export interface CreateDocumentInput {
  entityType: 'documents';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateEmployeeInput {
  entityType: 'employees';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateFunctionInput {
  employeeId?: string;
  entityType: 'functions';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateGuidelineInput {
  entityType: 'guidelines';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateMeetingInput {
  functionId?: string;
  entityType: 'meetings';
  entityUuid: string;
  targetUrl: string;
}

export interface CreatePartnerInput {
  entityType: 'partners';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateReportInput {
  entityType: 'reports';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateRiskAssessmentInput {
  entityType: 'riskAssessments';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
  templateId?: number;
  substanceUuid?: string;
  name?: string;
}

export interface CreateTaskInput {
  entityType: 'tasks';
  entityUuid: string;
  pageId: number;
  functionId: string;
  targetUrl: string;
}

export type CreateEntityInput =
  | CreateAssetInput
  | CreateConstitutionalDocumentInput
  | CreateContactInput
  | CreateContractInput
  | CreateDocumentInput
  | CreateEmployeeInput
  | CreateEventOccurrenceInput
  | CreateMeetingOccurrenceInput
  | CreateFunctionInput
  | CreateGuidelineInput
  | CreateMeetingInput
  | CreatePartnerInput
  | CreateReportInput
  | CreateRiskAssessmentInput
  | CreateTaskInput;

/**
 * Viser en generell + knapp for å legge til nye elementer
 *
 *
 */
@customElement('d-new-document')
export class DNewDocument extends LitElement {
  static readonly styles = css`
    :host {
      z-index: 20;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    :host([storybook]) {
      width: 800px;
      height: 800px;
    }

    #cover {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
    }

    #bottom {
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 0;
    }

    #slider {
      position: relative;
      right: -320px;
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      border-top-left-radius: 12px;
      width: 300px;
      max-height: calc(100vh - 20px);
      max-height: calc((var(--vh, 1vh) * 100) - 20px);
      box-sizing: border-box;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      visibility: hidden;
      transition:
        visibility 0s 0.3s,
        right 0.3s;
    }

    #slider.open {
      visibility: visible;
      right: 0;
      transition:
        visibility 0s,
        right 0.3s;
    }

    :host([storybook]) #slider,
    :host([storybook]) #slider.open {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }

    .header {
      flex: none;
      font-size: 17px;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--borderColor);
    }

    .body {
      flex: 1;
      overflow: auto;
    }

    .footer {
      flex: none;
      margin-bottom: 36px;
    }

    .docTypeList {
      margin-top: -1px;
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      padding: 4px 0;
    }

    .docTypeList a {
      display: block;
      margin-top: 1px;
      padding: 6px 0px 6px 30px;
      background-size: 28px 28px;
      background-position: -2px 2px;
      background-repeat: no-repeat;
      opacity: 0.8;
    }

    a {
      color: var(--linkColorGray);
      text-decoration: none;
      cursor: pointer;
    }

    .docTypeList a:hover {
      color: var(--themeColorDarkerOne);
      opacity: 1;
    }

    a.constitutionalDocuments {
      background-image: url(/images/constitutional-documents-gray.svg);
    }

    a.employees,
    a.contacts {
      background-image: url(/images/employees-gray.svg);
    }

    a.partners {
      background-image: url(/images/partners-gray.svg);
    }

    a.assets {
      background-image: url(/images/assets-gray.svg);
    }

    a.substances {
      background-image: url(/images/substances-gray.svg);
    }

    a.functions {
      background-image: url(/images/functions-gray.svg);
    }

    a.tasks {
      background-image: url(/images/tasks-gray.svg);
    }

    a.meetings,
    a.standaloneMeetings {
      background-image: url(/images/meetings-gray.svg);
    }

    .events,
    .taskEvents {
      background-image: url(/images/events-gray.svg);
    }

    a.guidelines {
      background-image: url(/images/guidelines-gray.svg);
    }

    a.documents {
      background-image: url(/images/documents-gray.svg);
    }

    a.contracts {
      background-image: url(/images/contracts-gray.svg);
    }

    a.reports {
      background-image: url(/images/reports-gray.svg);
    }

    a.issues {
      background-image: url(/images/issues-gray.svg);
    }

    a.riskAssessments {
      background-image: url(/images/risk-assessments-gray.svg);
    }

    a.messages {
      background-image: url(/images/messages-gray.svg);
    }

    .docTypeList a:hover {
      opacity: 1;
    }

    a.constitutionalDocuments:hover {
      background-image: url(/images/constitutional-documents-color.svg);
    }

    a.employees:hover,
    a.contacts:hover {
      background-image: url(/images/employees-color.svg);
    }

    a.partners:hover {
      background-image: url(/images/partners-color.svg);
    }

    a.assets:hover {
      background-image: url(/images/assets-color.svg);
    }

    a.substances:hover {
      background-image: url(/images/substances-color.svg);
    }

    a.functions:hover {
      background-image: url(/images/functions-color.svg);
    }

    a.tasks:hover {
      background-image: url(/images/tasks-color.svg);
    }

    a.meetings:hover,
    a.standaloneMeetings:hover {
      background-image: url(/images/meetings-color.svg);
    }

    .events:hover,
    d-new-document .taskEvents:hover {
      background-image: url(/images/events-color.svg);
    }

    a.guidelines:hover {
      background-image: url(/images/guidelines-color.svg);
    }

    a.documents:hover {
      background-image: url(/images/documents-color.svg);
    }

    a.contracts:hover {
      background-image: url(/images/contracts-color.svg);
    }

    a.reports:hover {
      background-image: url(/images/reports-color.svg);
    }

    a.issues:hover {
      background-image: url(/images/issues-color.svg);
    }

    a.riskAssessments:hover {
      background-image: url(/images/risk-assessments-color.svg);
    }

    .issueMessage,
    .feedback {
      color: white;
      margin-top: 8px;
      padding: 8px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .issueMessage {
      background: var(--alertColor);
    }

    .feedback {
      background: var(--themeColor);
      margin-bottom: 8px;
    }

    .issueMessage:hover {
      color: white;
      background: var(--alertColorDarkerOne);
    }

    .feedback:hover {
      color: white;
      background: var(--themeColorDarkerOne);
    }

    #toggleSlide {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      cursor: pointer;
      transition: box-shadow 0.3s;
    }

    #toggleSlide > div {
      width: 18px;
      height: 18px;
      position: relative;
      transition: transform 0.3s;
    }

    #toggleSlide.open {
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0);
    }

    #toggleSlide.open > div {
      transform: rotate(-45deg);
    }

    #toggleSlide > div > div {
      position: absolute;
      top: 6.5px;
      width: 18px;
      height: 3px;
      background: var(--themeColor);
    }

    #toggleSlide:hover > div > div {
      background: var(--themeColorDarkerTwo);
    }

    #toggleSlide > div > div:last-child {
      transform: rotate(90deg);
    }
  `;
  @property({ type: Boolean })
  /**
   * The menu is open
   */
  open = false;
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  currentPathArray: string[] = [];
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: Number })
  appWidth = 0;

  private generalPages = [357, 361, 65, 355, 365, 7954, 63, 79, 281, 279, 1933];

  private docTypeDefinitions: {
    name: string;
    type: string;
    href?: () => string;
    exclude: () => boolean;
    create?: () => Promise<void>;
  }[] = [
    {
      type: 'tasks',
      name: 'Rutine',
      exclude: () => this.contextDocType !== 'functions' && !this.singleUser,
      create: async () => {
        let pageId = this.contextPage;
        if (this.contextDocType !== 'functions' && (!this.singleUser || this.generalPages.indexOf(pageId) === -1)) {
          pageId = 357;
        }

        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/tasks/' + id + '?edit';
        if (this.singleUser) {
          targetUrl = this.contextBasePath + pageId + '/tasks/' + id + '?edit';
        } else if (this.contextDocType === 'functions') {
          targetUrl = this.contextFullPath + 'tasks/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'tasks',
          entityUuid: id,
          pageId: pageId,
          functionId: this.singleUser ? '' : this.contextDocUuid,
          targetUrl: targetUrl,
        });
      },
    },
    {
      type: 'events',
      name: 'Oppgave',
      exclude: () => false,
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'eventOccurrences',
          entityUuid: id,
          targetUrl: this.contextBasePath + '2300/eventOccurrences/' + id + '?edit',
        });
      },
    },
    {
      type: 'standaloneMeetings',
      name: 'Møte',
      exclude: () => this.singleUser,
      create: async () => {
        const id = uuid();
        const date = LocalDate.now().plusDays(1).toString();
        this.fireCreateEntity({
          entityType: 'meetingOccurrences',
          entityUuid: id,
          instance: date,
          targetUrl: this.contextBasePath + '9772/meetingOccurrences/' + id + '?edit',
        });
      },
    },
    {
      type: 'reports',
      name: 'Referat/rapport',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'reports',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/reports/' + id + '?edit',
        });
      },
    },
    {
      type: 'guidelines',
      name: 'Retningslinje',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'guidelines',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/guidelines/' + id + '?edit',
        });
      },
    },
    {
      type: 'contracts',
      name: 'Avtale',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();

        let targetUrl = this.contextBasePath + pageId + '/contracts/' + id + '?edit';
        if (this.contextDocType === 'employees' || this.contextDocType === 'partners') {
          targetUrl = this.contextFullPath + 'contracts/' + id + '?edit';
        }

        this.fireCreateEntity({
          entityType: 'contracts',
          entityUuid: id,
          pageId: pageId,
          employeeId: this.contextDocType === 'employees' ? this.contextDocUuid : undefined,
          partnerId: this.contextDocType === 'partners' ? this.contextDocUuid : undefined,
          targetUrl: targetUrl,
        });
      },
    },
    {
      type: 'functions',
      name: 'Ansvarsområde',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'functions',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/functions/' + id + '?edit',
        });
      },
    },
    {
      type: 'documents',
      name: 'Måldokument',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'documents',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/documents/' + id + '?edit',
        });
      },
    },
    {
      type: 'contacts',
      name: 'Kontaktperson',
      exclude: () => this.contextDocType !== 'partners',
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'contacts',
          entityUuid: id,
          partnerId: this.contextDocUuid,
          targetUrl: this.contextFullPath + 'contacts/' + id + '?edit',
        });
      },
    },
    {
      type: 'riskAssessments',
      name: 'Risikovurdering',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 279;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'riskAssessments',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/riskAssessments/' + id + '?edit',
        });
      },
    },
    {
      type: 'constitutionalDocuments',
      name: 'Stiftelsesdokument',
      exclude: () => false,
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'constitutionalDocuments',
          entityUuid: id,
          targetUrl: this.contextBasePath + '104/constitutionalDocuments/' + id + '?edit',
        });
      },
    },
    {
      type: 'employees',
      name: 'Personale',
      exclude: () => this.singleUser,
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'employees',
          entityUuid: id,
          targetUrl: this.contextBasePath + '104/employees/' + id + '?edit',
        });
      },
    },
    {
      type: 'partners',
      name: 'Samarbeidspartner',
      exclude: () => false,
      create: async () => {
        let pageId = this.contextPage;
        if (this.generalPages.indexOf(pageId) === -1) {
          pageId = 357;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'partners',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + '104/partners/' + id + '?edit',
        });
      },
    },
    {
      type: 'assets',
      name: 'Utstyrsenhet',
      exclude: () => false,
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'assets',
          entityUuid: id,
          targetUrl: this.contextBasePath + '110/assets/' + id + '?edit',
        });
      },
    },
    {
      type: 'substances',
      name: 'Sikkerhetsdatablad for stoff',
      exclude: () => false,
      href: () => this.contextBasePath + '12368?showSubstancesUploader=true',
    },
  ];

  private get contextPage() {
    return Number(this.currentPathArray[3]);
  }

  private get contextDocType() {
    if (this.currentPathArray.length === 4) {
      return 'page';
    } else if (this.currentPathArray.length === 6) {
      return this.currentPathArray[4];
    } else if (this.currentPathArray.length === 8) {
      return this.currentPathArray[6];
    }
  }

  private get contextDocUuid() {
    if (this.currentPathArray.length === 6) {
      return this.currentPathArray[5];
    } else if (this.currentPathArray.length === 8) {
      return this.currentPathArray[7];
    } else {
      return '';
    }
  }

  private get contextBasePath() {
    return '/' + this.currentPathArray[1] + '/' + this.currentPathArray[2] + '/';
  }

  private get contextFullPath() {
    return '/' + this.currentPathArray.slice(1).join('/') + '/';
  }

  // TODO Missing in doc type definitions: type: taskEvents
  private get docTypes(): { name: string; type: string; href: string; create?: () => Promise<void> }[] {
    if (this.writeAccess) {
      return this.docTypeDefinitions
        .filter((d) => !d.exclude())
        .map((d) => {
          return {
            name: d.name,
            type: d.type,
            href: d.href !== undefined ? d.href() : '#',
            create: d.create,
          };
        });
    }
    return [];
  }

  _sliderClass(open: boolean) {
    if (open) {
      return 'open';
    }
    return '';
  }

  _toggleSlide() {
    this.open = !this.open;
  }

  _openFeedback() {
    this.dispatchEvent(new CustomEvent('display-feedback', { composed: true, bubbles: true, detail: {} }));
  }

  _openIssue() {
    this.dispatchEvent(new CustomEvent('display-new-issue', { composed: true, bubbles: true, detail: {} }));
  }
  render() {
    return html`
      ${!this.open ? nothing : html` <div id="cover" @click=${() => this._toggleSlide()}></div>`}
      <div id="bottom">
        <div id="slider" class="${this._sliderClass(this.open)}" @click=${() => this._toggleSlide()}>
          ${!this.writeAccess ? nothing : html` <div class="header">Legg til nytt element</div>`}
          <div class="body">
            ${!this.writeAccess
              ? nothing
              : html` <div class="docTypeList">
                  ${this.docTypes.map(
                    (item) =>
                      html`<a
                        @click=${(e) => this.onClickCreate(e, item)}
                        href="${item.href ?? ''}"
                        class="${item.type}"
                        >${item.name}</a
                      >`,
                  )}
                </div>`}
          </div>
          <div class="footer">
            <div class="docTypeList">
              <div class="issueMessage" @click=${() => this._openIssue()}>Avviksmelding</div>
              <div class="feedback" @click=${() => this._openFeedback()}>Tilbakemelding til TrinnVis</div>
            </div>
          </div>
        </div>
        ${this.uncoverPageMenu
          ? nothing
          : html` <div id="toggleSlide" class="${this._sliderClass(this.open)}" @click=${() => this._toggleSlide()}>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>`}
      </div>
    `;
  }

  private fireCreateEntity(input: CreateEntityInput) {
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private async onClickCreate(e: MouseEvent, item: { href: string; create?: () => Promise<void> }) {
    if (item.create !== undefined) {
      e.preventDefault();
      await item.create();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-document': DNewDocument;
  }
}
