import '../library/elements/d-section.js';
import './d-graded-leave-day.js';
import type { EmployeeForStaffingCalendar } from 'src/store';
import * as dabihStore from 'src/store';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../library/fields/d-expansion.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { produce } from 'immer';
import type { LeavePeriodDay } from 'src/store/api';
import { getScheduleDayData } from 'src/store';
import { LeavePeriodDayLeaveStatusEnum } from 'src/store/api';
import { minutesToTime } from 'src/utilities/text.js';

interface EditDay {
  day: string;
  displayDate: string;
  displayDateShort: string;
  scheduledWorkHoursText: string;
  scheduledMinutes: number;
  status: string;
  start: string;
  end: string;
}

/**
 * Denne komponenten har for mye logikk. Den skal egentlig bare vise "ferdig" data
 *
 *
 */
/**
 *
 */
@customElement('d-graded-leave')
export class DGradedLeave extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .sectionHeader {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      background: white;
      font-weight: 600;
    }

    .hoursAlert {
      font-size: 14px;
      font-weight: normal;
    }

    .plus {
      color: var(--alertColor);
    }

    .minus {
      color: var(--approveColor);
    }
  `;
  /**
   * The employee.
   */
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  /**
   * The start date. Format yyyy-mm-dd
   */
  @property({ type: String })
  start = '';
  /**
   * The end date. Format yyyy-mm-dd
   */
  @property({ type: String })
  end = '';
  /**
   * The leave grade. 0-100. When less than 100 additional options are shown
   */
  @property({ type: Number })
  grade = 100;
  /**
   *
   * start og end er klokkeslett. For fridager er begge satt til '00:00'
   * day er dato i format i tekst 'yyyy-mm-dd'
   *
   */
  @property({ type: Array })
  days: LeavePeriodDay[] = [];
  private defaultStart = dabihStore.defaultWorkStartTime;
  private defaultEnd = dabihStore.defaultWorkEndTime;
  private holidays = dabihStore.holidaysForStaffingCalendar();

  get editDays(): EditDay[] {
    if (this.grade < 100 && this.start && this.end) {
      const startDate = LocalDate.fromString(this.start);
      const endDate = LocalDate.fromString(this.end);
      return LocalDate.dates(startDate, endDate.plusDays(1)).map((day) => {
        const dayString = day.toString();
        console.log(dayString);
        let editStart = '00:00';
        let editEnd = '00:00';
        let status = 'leave';
        const employeeDaySchedule = dabihStore.getScheduleDayData(day, this.employee);
        const holiday = this.holidays[day.toString()] ?? '';
        let scheduledWorkHoursText = '(Fri)';
        let scheduledMinutes = 0;
        if (employeeDaySchedule.holidays && holiday) {
          scheduledMinutes = 0;
        }
        if (employeeDaySchedule.workHours) {
          scheduledWorkHoursText =
            '(' + employeeDaySchedule.start.replace(':', '.') + '-' + employeeDaySchedule.end.replace(':', '.') + ')';
          status = 'work';
          scheduledMinutes =
            dabihStore.timeInMinutes(employeeDaySchedule.end) - dabihStore.timeInMinutes(employeeDaySchedule.start);
          const endInMinutes =
            dabihStore.timeInMinutes(employeeDaySchedule.start) + (scheduledMinutes * (100 - this.grade)) / 100;
          editStart = employeeDaySchedule.start;
          editEnd = minutesToTime(endInMinutes);
        }
        if (employeeDaySchedule.holidays && holiday) {
          scheduledMinutes = 0;
          scheduledWorkHoursText = '(Fri)';
          editStart = '00:00';
          editEnd = '00:00';
          status = 'leave';
        }
        if (this.days.length) {
          const savedDay = this.days.filter(function (item) {
            return item.date === dayString;
          })[0];
          if (savedDay) {
            if (savedDay.start !== '00:00' && savedDay.end !== '00:00') {
              status = 'work';
            } else {
              status = 'leave';
            }
            editStart = savedDay.start || '00:00';
            editEnd = savedDay.end || '00:00';
          }
        }
        return {
          day: dayString,
          displayDate: day.toStringForDisplayWithDayOfWeekAndYear(),
          displayDateShort: day.toStringForDisplayWithDayAndMonth() + ' ' + day.year(),
          scheduledWorkHoursText: scheduledWorkHoursText,
          scheduledMinutes: scheduledMinutes,
          status: status,
          start: editStart,
          end: editEnd,
        };
      });
    } else {
      return [];
    }
  }

  _scheduledHours(editDays) {
    if (editDays === undefined) return 100;
    let scheduledMinutes = 0;
    editDays.forEach(function (day) {
      scheduledMinutes += day.scheduledMinutes;
    });
    return Math.round((scheduledMinutes / 60 + Number.EPSILON) * 100) / 100;
  }

  _editedHours(editDays) {
    let editedMinutes = 0;
    editDays.forEach(function (day) {
      if (day.status === 'work') {
        editedMinutes += dabihStore.timeInMinutes(day.end) - dabihStore.timeInMinutes(day.start);
      }
    });
    return Math.round((editedMinutes / 60 + Number.EPSILON) * 100) / 100;
  }

  _hoursDifference(editedHours, scheduledHours, grade) {
    const diff = editedHours - (scheduledHours * (100 - grade)) / 100;
    return Math.round((diff + Number.EPSILON) * 100) / 100;
  }

  _hoursDifferenceText(hoursDifference) {
    if (hoursDifference !== 0) {
      let hoursWord = ' timer';
      if (hoursDifference === 1) {
        hoursWord = ' time';
      }
      if (hoursDifference > 0) {
        return hoursDifference.toString().replace('.', ',') + hoursWord + ' for mye';
      }
      return (hoursDifference * -1).toString().replace('.', ',') + hoursWord + ' for lite';
    }
    return '';
  }

  _editedHoursText(editedHours) {
    return editedHours.toString().replace('.', ',') + ' t';
  }

  _editedHoursClasses(hoursDifference) {
    if (hoursDifference >= 1) {
      return 'plus';
    }
    if (hoursDifference <= -1) {
      return 'minus';
    }
    return '';
  }

  render() {
    const scheduledHours = this._scheduledHours(this.editDays);
    const editedHours = this._editedHours(this.editDays);
    const hoursDifference = this._hoursDifference(editedHours, scheduledHours, this.grade);

    return html`
      <d-section sticky>
        <d-wrap split align baseline>
          <d-label big label="Redusert arbeidstid"></d-label>
          <d-wrap right align baseline class="${this._editedHoursClasses(hoursDifference)}">
            <d-inline class="hoursAlert">${this._hoursDifferenceText(hoursDifference)}</d-inline>
            <d-inline big bold>${this._editedHoursText(editedHours)}</d-inline>
          </d-wrap>
        </d-wrap>
      </d-section>
      <div id="daysList">
        ${this.days.map(
          (day, index) =>
            html` <d-graded-leave-day
              .day=${day}
              .scheduledDay=${this.scheduledDay(day.date)}
              .isHoliday=${this.isHoliday(day.date)}
              @day-changed=${(e) => this.onDayChanged(e, index)}
            ></d-graded-leave-day>`,
        )}
      </div>
    `;
  }

  fireDaysChanged() {
    this.dispatchEvent(new CustomEvent('days-changed', { composed: true, bubbles: true, detail: { days: this.days } }));
  }

  private onDayChanged(e: CustomEvent<{ day: LeavePeriodDay }>, index: number) {
    this.days = produce(this.days, (draft) => {
      draft[index] = {
        date: e.detail.day.date,
        leaveStatus: e.detail.day.leaveStatus,
        start: e.detail.day.leaveStatus === LeavePeriodDayLeaveStatusEnum.Leave ? '00:00' : e.detail.day.start,
        end: e.detail.day.leaveStatus === LeavePeriodDayLeaveStatusEnum.Leave ? '00:00' : e.detail.day.end,
      };
    });

    this.fireDaysChanged();
  }

  private scheduledDay(date: string) {
    const localDate = LocalDate.fromString(date);
    return getScheduleDayData(localDate, this.employee);
  }

  private isHoliday(date: string) {
    const holiday = this.holidays[date] ?? '';
    return holiday !== '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-graded-leave': DGradedLeave;
  }
}
