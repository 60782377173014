import type { State } from 'src/store/types.js';
import type { IssueView, IssueViewEditItem } from 'src/content/issues/d-issue-view.js';
import {
  currentUserUuid,
  employeesNotDeleted,
  getEmployeesWithForAccessControl,
  getOrganization,
  isCurrentUserEmployee,
  maritimeDoctor,
  toIssueViewModel,
  writeAccess,
} from 'src/store/selectors';

import { fetchDraft, pagesAsSelectTagOptions, toAttachmentItems } from 'src/models/factory-utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { IssueResolver } from 'src/models/resolvers/issue-resolver.js';
import { assertIsDefined } from 'src/lib';

function nameForEmployee(availableEmployees: { text: string; value: string }[], uuid: string | undefined) {
  return availableEmployees.find((e) => e.value === uuid)?.text ?? '';
}

export function buildIssueView(uuid: string, state: State, currentParent: string): IssueView {
  const organization = getOrganization(state);
  assertIsDefined(organization);
  const item = organization.issuesById[uuid];
  assertIsDefined(item);

  const entity = toIssueViewModel(item);
  const issueResolver = new IssueResolver(entity);
  const availableEmployees = employeesNotDeleted(state)
    .filter((e) => !e.isNew)
    .filter(
      (e) =>
        e.status !== 'TERMINATED' ||
        entity.reportedBy === e.uuid ||
        entity.evaluatedBy === e.uuid ||
        entity.processedBy === e.uuid ||
        entity.responsibleBy === e.uuid,
    )
    .map((p) => ({ value: p.uuid, text: p.name }));
  return {
    docsForLinking: [],
    currentUserIsEmployee: isCurrentUserEmployee(state),
    currentUserHasAccess:
      entity.classification !== 'CONFIDENTIAL' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),

    href: currentParent + '/issues/' + uuid,
    helpContent: '',
    parentHref: currentParent,
    pdfHref: '/api' + '/organizations/' + organization.organizationId + '/issues/' + uuid + '.pdf',

    deleted: entity.deleted === true,
    type: 'issues',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    accessControlOptions: getEmployeesWithForAccessControl(state).map((e) => ({
      value: e.id,
      text: e.value,
      disabled: e.disabled,
    })),
    evaluatedByName: nameForEmployee(availableEmployees, entity.evaluatedBy),
    evaluatedOk: entity.evaluatedOk === true,
    evaluatedDate: entity.evaluatedDate ?? '',
    evaluated: entity.evaluated === true,
    implementedMeasure: entity.implementedMeasure === true,
    identifiedMeasures: entity.identifiedMeasures ?? '',
    requiresMeasures: entity.requiresMeasures === true,
    relateToMaritimeHealthCertificate: entity.relateToMaritimeHealthCertificate === true,
    informationLeakagePossibility: entity.informationLeakagePossibility === true,
    relateToRadiationEquipmentMissing: entity.relateToRadiationEquipmentMissing === true,
    relateToRadiation: entity.relateToRadiation === true,
    relateToEquipment: entity.relateToEquipment === true,
    workRelatedSicknessCausingEnvironment: entity.workRelatedSicknessCausingEnvironment ?? '',
    workRelatedSicknessCausingTaskLocation: entity.workRelatedSicknessCausingTaskLocation ?? '',
    workRelatedSicknessCausingTask: entity.workRelatedSicknessCausingTask ?? '',
    workRelatedInjuryIncidentCause: entity.workRelatedInjuryIncidentCause ?? '',
    workRelatedHealthIssueDescription: entity.workRelatedHealthIssueDescription ?? '',
    workRelatedInjuryTask: entity.workRelatedInjuryTask ?? '',
    workRelatedHealthIssueEmployeeName:
      availableEmployees.find((x) => x.value === entity.workRelatedHealthIssueEmployee)?.text ?? '',
    workRelatedSickness: entity.workRelatedSickness === true,
    workRelatedInjury: entity.workRelatedInjury === true,
    workRelatedHealthIssue: entity.workRelatedHealthIssue === true,
    relateToEmployees: entity.relateToEmployees === true,
    relateToPatient: entity.relateToPatient === true,
    personDamagePossibility: entity.personDamagePossibility === true,
    notes: entity.notes ?? '',
    processedByName: nameForEmployee(availableEmployees, entity.processedBy),
    processedDate: entity.processedDate ?? '',
    uuid: entity.uuid ?? '',
    isNew: entity.isNew,
    furtherMeasures: entity.furtherMeasures ?? '',
    immediateMeasures: entity.immediateMeasures ?? '',
    description: entity.description ?? '',
    reportedToName: availableEmployees.find((x) => x.value === entity.reportedTo)?.text ?? '',
    reportedDate: entity.reportedDate ?? '',
    eventDate: entity.eventDate ?? '',
    attachments: toAttachmentItems('issues', entity.uuid, entity.attachments),
    availablePages: pagesAsSelectTagOptions(state).map((p) => ({ ...p, disabled: p.value === '281' })),
    today: LocalDate.fromString(state.today),
    availableEmployees,
    maritimeDoctor: maritimeDoctor(state),
    deletable: true,
    pages: (entity.pages ?? []).map((x) => '' + x),
    correction: entity.correction ?? '',
    evaluatedBy: entity.evaluatedBy ?? '',
    measuresSatisfactorily: entity.measuresSatisfactorily ?? false,
    processedBy: entity.processedBy ?? '',
    registeredBy: entity.registeredBy ?? '',
    registeredDate: entity.registeredDate ?? '',
    relateToPatientInstitution: entity.relateToPatientInstitution ?? false,
    reportedBy: entity.reportedBy ?? '',
    reportedCountyMedical: entity.reportedCountyMedical ?? false,
    reportedInspectorate: entity.reportedInspectorate ?? false,
    reportedLabourInspection: entity.reportedLabourInspection ?? false,
    reportedSafetyManager: entity.reportedSafetyManager ?? false,
    reportedTo: entity.reportedTo ?? '',
    requireEvaluation: entity.requireEvaluation ?? false,
    responsibleBy: entity.responsibleBy ?? '',
    shallEvaluated: entity.shallEvaluated ?? false,
    workRelatedHealthIssueEmployee: entity.workRelatedHealthIssueEmployee ?? '',
    processed: issueResolver.processed,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft('issues', uuid, organization.organizationId);
      return command.draft as IssueViewEditItem;
    },
  };
}
