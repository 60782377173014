/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateMeetingOccurrenceCommand
 */
export interface UpdateMeetingOccurrenceCommand {
  /**
   * The meeting occurrence uuid.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  uuid: string;
  /**
   * Angir om etterfølgende møter også skal oppdateres
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  saveType: UpdateMeetingOccurrenceCommandSaveTypeEnum;
  /**
   * Møtenavnet. Dersom møte ikke er knyttet til standardmøter er ikke dette navnet redigerbart.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  name: string;
  /**
   * Møtedato.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  date: string;
  /**
   * Klokkeslett for møte. Format "13:15".
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  time?: string;
  /**
   * Varighet for møte. Kan være 0 for møter uten varighet.
   * @type {number}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  durationMinutes: number;
  /**
   * Id for ansvarsområde med ansvar for møtet
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  responsible?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  participants: Array<string>;
  /**
   * Begrenset tilgang til møte.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  classification: UpdateMeetingOccurrenceCommandClassificationEnum;
  /**
   * Dersom `classification` er `NONE` må denne listen være tom.
   * @type {Array<string>}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  accessControl: Array<string>;
  /**
   * Sms påminnelse for møte. Dersom møtet ikke har klokkeslett angitt må denne være `NONE`.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  reminder: UpdateMeetingOccurrenceCommandReminderEnum;
  /**
   * Rik tekst agenda for møtet.
   * @type {string}
   * @memberof UpdateMeetingOccurrenceCommand
   */
  agenda: string;
}

/**
 * @export
 */
export const UpdateMeetingOccurrenceCommandSaveTypeEnum = {
  This: 'THIS',
  Future: 'FUTURE',
} as const;
export type UpdateMeetingOccurrenceCommandSaveTypeEnum =
  (typeof UpdateMeetingOccurrenceCommandSaveTypeEnum)[keyof typeof UpdateMeetingOccurrenceCommandSaveTypeEnum];

/**
 * @export
 */
export const UpdateMeetingOccurrenceCommandClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
} as const;
export type UpdateMeetingOccurrenceCommandClassificationEnum =
  (typeof UpdateMeetingOccurrenceCommandClassificationEnum)[keyof typeof UpdateMeetingOccurrenceCommandClassificationEnum];

/**
 * @export
 */
export const UpdateMeetingOccurrenceCommandReminderEnum = {
  None: 'NONE',
  AtStart: 'AT_START',
  OneHourBefore: 'ONE_HOUR_BEFORE',
  TwoHoursBefore: 'TWO_HOURS_BEFORE',
  ThreeHoursBefore: 'THREE_HOURS_BEFORE',
  FiveHoursBefore: 'FIVE_HOURS_BEFORE',
} as const;
export type UpdateMeetingOccurrenceCommandReminderEnum =
  (typeof UpdateMeetingOccurrenceCommandReminderEnum)[keyof typeof UpdateMeetingOccurrenceCommandReminderEnum];

/**
 * Check if a given object implements the UpdateMeetingOccurrenceCommand interface.
 */
export function instanceOfUpdateMeetingOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'saveType' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'durationMinutes' in value;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'classification' in value;
  isInstance = isInstance && 'accessControl' in value;
  isInstance = isInstance && 'reminder' in value;
  isInstance = isInstance && 'agenda' in value;

  return isInstance;
}

export function UpdateMeetingOccurrenceCommandFromJSON(json: any): UpdateMeetingOccurrenceCommand {
  return UpdateMeetingOccurrenceCommandFromJSONTyped(json, false);
}

export function UpdateMeetingOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateMeetingOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    saveType: json['saveType'],
    name: json['name'],
    date: json['date'],
    time: !exists(json, 'time') ? undefined : json['time'],
    durationMinutes: json['durationMinutes'],
    responsible: !exists(json, 'responsible') ? undefined : json['responsible'],
    participants: json['participants'],
    classification: json['classification'],
    accessControl: json['accessControl'],
    reminder: json['reminder'],
    agenda: json['agenda'],
  };
}

export function UpdateMeetingOccurrenceCommandToJSON(value?: UpdateMeetingOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    saveType: value.saveType,
    name: value.name,
    date: value.date,
    time: value.time,
    durationMinutes: value.durationMinutes,
    responsible: value.responsible,
    participants: value.participants,
    classification: value.classification,
    accessControl: value.accessControl,
    reminder: value.reminder,
    agenda: value.agenda,
  };
}
