import '../elements/d-tooltip.js';
import '../elements/d-label.js';
import './d-list-section-item.js';
import { customElement, property } from 'lit/decorators.js';
import { BaseListSectionElement } from 'src/library/lists/base-list-section-element.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { css, html, nothing, TemplateResult } from 'lit';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import '../elements/d-action.js';
import '../fields/d-expansion.js';
import './d-list-header.js';
import { cloneDeep } from 'lodash';

/**
 *
 * STATUS OK
 */
@customElement('d-list-section')
export class DListSection extends BaseListSectionElement<ListSectionItemInput> {
  static readonly styles = [
    ...BaseListSectionElement.styles,
    css`
      :host {
        display: block;
      }

      .list-content {
        position: relative;
        padding-left: var(--listPaddingLeft);
        font-weight: 400;
        transition: padding var(--transition);
      }

      :host([bordered]) .list-content:after {
        content: '';
        display: block;
        height: 1px;
        margin-bottom: -2px;
        border-bottom: 1px solid var(--borderColor);
      }

      :host([bordered][theme-page]) .list-content:after {
        border-color: var(--borderColorOnGray);
      }

      :host([bordered][start-tasks]) .list-content:after {
        border-color: var(--startTaskBorderColor);
      }

      a span {
        pointer-events: none;
      }

      .list-content a {
        display: block;
        padding: 6px 0;
        color: var(--linkColorGray);
        text-decoration: none;
      }

      .list-content a:hover {
        color: black;
      }

      .no-item {
        color: hsl(0, 0%, 20%);
        cursor: default;
        font-style: italic;
      }

      .add-header {
        padding-left: var(--listPaddingLeft);
      }

      .add-header d-action {
        margin-left: -8px;
      }

      .explanation {
        font-size: 13px;
        font-weight: 300;
      }

      .comment {
        width: 100%;
        font-family: var(--mainSerif), serif;
        line-height: 150%;
        font-weight: 200;
        font-style: italic;
      }

      .secondary-info {
        font-weight: 300;
      }

      .secondary-info.notes {
        font-size: 14px;
      }

      .secondary-info.done-text {
        font-size: 14px;
      }

      .assignee {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .locked,
      .done,
      .ok,
      .not-ok,
      .overdue,
      .rotated .primary:after {
        background-repeat: no-repeat;
        background-position: 1px 3px;
        background-size: 24px auto;
      }

      .locked {
        background-image: url(/images/locked.svg);
      }

      .done {
        background-image: url(/images/check-mini-gray.svg);
      }

      .ok {
        background-image: url(/images/check-mini-blue.svg);
      }

      .not-ok {
        background-image: url(/images/x-fat-orange.svg);
      }

      .overdue {
        background-image: url(/images/alert.svg);
      }

      .overdue .name {
        color: var(--alertColor);
      }

      .overdue .name {
        color: var(--alertColor);
      }

      .rotated .primary:after {
        content: '';
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-top: -7px;
        margin-bottom: -7px;
        margin-right: -4px;
        background-image: url(/images/rotate.svg);
      }

      .in-list-action {
        margin-left: var(--listPaddingLeft);
        padding: 5px 0;
      }

      .list-content .in-list-action {
        margin-left: 0;
      }

      :host([bordered]) .in-list-action {
        border-top: 1px solid var(--borderColor);
        padding-top: 9px;
        padding-bottom: 9px;
      }

      :host([bordered][theme-page]) .in-list-action {
        border-color: var(--borderColorOnGray);
      }

      .in-list-action d-action {
        margin-left: -8px;
      }

      :host([extra-spacing]) d-list-section-item + d-list-section-item {
        margin-top: 12px;
      }

      @media only screen and (max-width: 600px) {
        .list-content,
        .add-header {
          padding-left: 0;
        }

        .in-list-action {
          margin-left: 0;
        }
      }
    `,
  ];
  @property({ type: String, reflect: true, attribute: 'add-item-text' })
  addItemText = '';
  @property({ type: String, reflect: true, attribute: 'no-item-text' })
  noItemText = '';
  @property({ type: Boolean, reflect: true, attribute: 'bordered' })
  bordered = false;
  @property({ type: Boolean, reflect: true, attribute: 'no-header' })
  noHeader = false;
  @property({ type: Boolean, reflect: true, attribute: 'big-label' })
  bigLabel = false;
  @property({ type: Array })
  actions: ActionInput[] = [];
  @property({ type: Number })
  limitItems?: number;
  @property({ type: Boolean })
  showPreItems = false;
  @property({ type: Boolean })
  showPostItems = false;
  @property({ type: Boolean })
  showAll = false;
  @property({ type: Boolean })
  showNotes = false;
  @property({ type: Number })
  listHeaderHeight = 0;

  protected get preItems() {
    return this.items.filter((item) => {
      return item.preItem;
    });
  }

  protected get mainItems() {
    return this.items.filter((item) => {
      return !item.preItem && !item.postItem;
    });
  }

  protected get postItems() {
    return this.items.filter((item) => {
      return item.postItem;
    });
  }

  protected get renderedItems() {
    let items = this.mainItems.filter((i) => {
      return !i.preItem;
    });
    if (this.limitItems && items.length > this.limitItems) {
      items = items.slice(0, this.limitItems);
    }
    return items ?? [];
  }

  protected get moreItems() {
    let items: ListSectionItemInput[] = [];
    if (this.limitItems && this.items.length > this.limitItems) {
      items = cloneDeep(this.items);
      items.splice(0, this.limitItems);
    }
    return items;
  }

  private get renderedActions() {
    return this.actions !== undefined ? [...this.actions] : [];
  }

  private get itemStickyTop() {
    return this.contentStickyTop + this.listHeaderHeight;
  }

  _togglePreItems() {
    this.showPreItems = !this.showPreItems;
  }

  _togglePostItems() {
    this.showPostItems = !this.showPostItems;
  }

  _showAll() {
    this.showAll = true;
  }

  _noItem(noItemText) {
    return {
      label: noItemText,
      accessible: false,
    };
  }

  onAction(e: CustomEvent<string>) {
    console.log(e.detail);
  }

  _setListHeaderHeight(height) {
    this.listHeaderHeight = height;
  }

  renderHeader(): TemplateResult | typeof nothing {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      ${this.noHeader
        ? nothing
        : html`
            <d-list-header
              icon=${ifDefined(this.icon)}
              .field=${this.field}
              .subfield=${this.subfield}
              .label=${this.label}
              .sublabel=${this.sublabel}
              ?theme-page=${this.themePage}
              ?bordered=${this.bordered}
              ?big-label=${this.bigLabel}
              .actions=${this.renderedActions}
              @action=${this.onAction}
              @height-changed=${(e) => this._setListHeaderHeight(e.detail)}
              style=${styleMap(listHeaderStyles)}
            >
            </d-list-header>
          `}
    `;
  }

  renderItemsEmpty(): TemplateResult | typeof nothing {
    return html`
      ${this.noItemText
        ? html`
            <d-list-section-item
              class="no-item"
              .input=${this._noItem(this.noItemText)}
              ?theme-page=${this.themePage}
              ?bordered=${this.bordered}
              .listHeaderHeight=${this.listHeaderHeight}
            ></d-list-section-item>
          `
        : nothing}
    `;
  }

  renderPreItems(): TemplateResult | typeof nothing {
    return html`
      ${this.preItems.length === 0
        ? nothing
        : html`
            <d-expansion ?opened=${this.showPreItems}>
              <div class="list-content">${this.preItems.map((item) => this.renderItem(item))}</div>
            </d-expansion>
            <div class="in-list-action">
              <d-action mini @click=${() => this._togglePreItems()}>
                ${this.showPreItems ? 'Skjul tidligere' : 'Vis tidligere'}
              </d-action>
            </div>
          `}
    `;
  }

  renderItem(item: ListSectionItemInput): TemplateResult | typeof nothing {
    return html` <d-list-section-item
      .input=${item}
      ?showNotes=${this.showNotes}
      ?bordered=${this.bordered}
      ?theme-page=${this.themePage}
      ?start-tasks=${this.startTasks}
      ?big-label=${this.bigLabel}
      .itemStickyTop=${this.itemStickyTop}
    ></d-list-section-item>`;
  }

  renderItems(): TemplateResult | typeof nothing {
    return html`
      <div class="list-content">
        ${this.renderedItems.length === 0
          ? this.renderItemsEmpty()
          : this.renderedItems.map((item) => this.renderItem(item))}
        ${this.moreItems.length === 0
          ? nothing
          : html`
              <d-expansion ?opened=${!this.showAll}>
                <div class="in-list-action">
                  <d-action mini @click=${() => this._showAll()}>Vis alle</d-action>
                </div>
              </d-expansion>
              <d-expansion ?opened=${this.showAll}>
                ${this.moreItems.map((item) => this.renderItem(item))}
              </d-expansion>
            `}
        <slot></slot>
      </div>
    `;
  }

  renderPostItems(): TemplateResult | typeof nothing {
    return html`
      ${this.postItems.length === 0
        ? nothing
        : html`
            <div class="in-list-action">
              <d-action mini @click=${() => this._togglePostItems()}>
                ${this.showPostItems ? 'Skjul senere' : 'Vis senere'}
              </d-action>
            </div>
            <d-expansion ?opened=${this.showPostItems}>
              <div class="list-content">${this.postItems.map((item) => this.renderItem(item))}</div>
            </d-expansion>
          `}
    `;
  }

  renderPreContent(): TemplateResult | typeof nothing {
    return nothing;
  }

  renderContent(): TemplateResult | typeof nothing {
    return html` ${this.renderPreItems()} ${this.renderItems()} ${this.renderPostItems()} `;
  }

  renderPostContent(): TemplateResult | typeof nothing {
    return nothing;
  }

  render() {
    return html`
      ${this.renderHeader()} ${this.renderPreContent()} ${this.renderContent()} ${this.renderPostContent()}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section': DListSection;
  }
}
