/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContractUpdateMessage
 */
export interface ContractUpdateMessage {
  /**
   *
   * @type {Array<string>}
   * @memberof ContractUpdateMessage
   */
  employees?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ContractUpdateMessage
   */
  partners?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof ContractUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  templateId?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  templateEmployeeId?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  documentLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  sideCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  affiliation?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  profession?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  classification?: ContractUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ContractUpdateMessage
   */
  accessControl?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  validFromDate?: string;
  /**
   *
   * @type {string}
   * @memberof ContractUpdateMessage
   */
  validToDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContractUpdateMessage
   */
  required?: boolean;
}

/**
 * @export
 */
export const ContractUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type ContractUpdateMessageClassificationEnum =
  (typeof ContractUpdateMessageClassificationEnum)[keyof typeof ContractUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the ContractUpdateMessage interface.
 */
export function instanceOfContractUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContractUpdateMessageFromJSON(json: any): ContractUpdateMessage {
  return ContractUpdateMessageFromJSONTyped(json, false);
}

export function ContractUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    partners: !exists(json, 'partners') ? undefined : json['partners'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    templateEmployeeId: !exists(json, 'templateEmployeeId') ? undefined : json['templateEmployeeId'],
    documentLocation: !exists(json, 'documentLocation') ? undefined : json['documentLocation'],
    category: !exists(json, 'category') ? undefined : json['category'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sideCode: !exists(json, 'sideCode') ? undefined : json['sideCode'],
    status: !exists(json, 'status') ? undefined : json['status'],
    content: !exists(json, 'content') ? undefined : json['content'],
    affiliation: !exists(json, 'affiliation') ? undefined : json['affiliation'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
    validFromDate: !exists(json, 'validFromDate') ? undefined : json['validFromDate'],
    validToDate: !exists(json, 'validToDate') ? undefined : json['validToDate'],
    required: !exists(json, 'required') ? undefined : json['required'],
  };
}

export function ContractUpdateMessageToJSON(value?: ContractUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employees: value.employees,
    partners: value.partners,
    pages: value.pages,
    templateId: value.templateId,
    templateEmployeeId: value.templateEmployeeId,
    documentLocation: value.documentLocation,
    category: value.category,
    name: value.name,
    sideCode: value.sideCode,
    status: value.status,
    content: value.content,
    affiliation: value.affiliation,
    profession: value.profession,
    classification: value.classification,
    accessControl: value.accessControl,
    validFromDate: value.validFromDate === undefined ? undefined : value.validFromDate,
    validToDate: value.validToDate === undefined ? undefined : value.validToDate,
    required: value.required,
  };
}
