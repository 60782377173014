import {
  Configuration,
  ConfigurationParameters,
  EmployeesApi,
  EmployeeViewModelGenderEnum,
  EmployeeViewModelStaffGroupEnum,
  LeavePeriod,
  PlusTimePeriod,
  TimekeepingCorrection,
  UpdateAccessCommand,
  WorkSchedule,
  WorkScheduleException,
} from 'src/store/api';

function employeeApi() {
  const c: ConfigurationParameters = { fetchApi: fetch };
  const config = new Configuration(c);
  return new EmployeesApi(config);
}

export async function updateEmployeeWorkSchedules(
  organizationId: number,
  employeeUuid: string,
  workSchedules: WorkSchedule[],
) {
  const api = employeeApi();
  await api.updateEmployeeWorkSchedules({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    workSchedulesUpdateMessage: {
      workSchedules: workSchedules,
    },
  });
}

export async function updateEmployeePlusTimePeriod(
  organizationId: number,
  employeeUuid: string,
  plusTimePeriod: PlusTimePeriod,
) {
  const api = employeeApi();
  await api.createOrUpdateEmployeePlusTimePeriod({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: plusTimePeriod.uuid,
    plusTimePeriodUpdateMessage: {
      plusTimePeriod: plusTimePeriod,
    },
  });
}

export async function deleteEmployeePlusTimePeriod(
  organizationId: number,
  employeeUuid: string,
  plusTimePeriodUuid: string,
) {
  const api = employeeApi();
  await api.deleteEmployeePlusTimePeriod({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: plusTimePeriodUuid,
  });
}

export async function updateEmployeeLeavePeriod(
  organizationId: number,
  employeeUuid: string,
  leavePeriod: LeavePeriod,
) {
  const api = employeeApi();
  await api.createOrUpdateEmployeeLeavePeriod({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: leavePeriod.uuid,
    leavePeriodUpdateMessage: {
      leavePeriod: leavePeriod,
    },
  });
}

export async function updateEmployeeAccess(
  organizationId: number,
  employeeUuid: string,
  updateAccessCommand: UpdateAccessCommand,
) {
  const api = employeeApi();
  await api.updateEmployeeAccess({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    updateAccessCommand: updateAccessCommand,
  });
}

export async function deleteEmployeeLeavePeriod(organizationId: number, employeeUuid: string, leavePeriodUuid: string) {
  const api = employeeApi();
  await api.deleteEmployeeLeavePeriod({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: leavePeriodUuid,
  });
}

export async function updateEmployeeTimekeepingCorrection(
  organizationId: number,
  employeeUuid: string,
  timekeepingCorrection: TimekeepingCorrection,
) {
  const api = employeeApi();
  await api.createOrUpdateEmployeeTimekeepingCorrection({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: timekeepingCorrection.uuid,
    timekeepingCorrectionUpdateMessage: {
      timekeepingCorrection: timekeepingCorrection,
    },
  });
}
export async function deleteEmployeeTimekeepingCorrection(
  organizationId: number,
  employeeUuid: string,
  timekeepingCorrectionUuid: string,
) {
  const api = employeeApi();
  await api.deleteEmployeeTimekeepingCorrection({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    periodId: timekeepingCorrectionUuid,
  });
}

export async function updateEmployeeWorkScheduleException(
  organizationId: number,
  employeeUuid: string,
  workScheduleException: WorkScheduleException,
) {
  const api = employeeApi();
  await api.createOrUpdateEmployeeWorkScheduleException({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    date: workScheduleException.date,
    workScheduleExceptionUpdateMessage: {
      workScheduleException: workScheduleException,
    },
  });
}

export async function deleteEmployeeWorkScheduleException(organizationId: number, employeeUuid: string, date: string) {
  const api = employeeApi();
  await api.deleteEmployeeWorkScheduleException({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    date: date,
  });
}

export async function updateEmployeeGender(
  organizationId: number,
  employeeUuid: string,
  gender: EmployeeViewModelGenderEnum,
) {
  const api = employeeApi();
  await api.updateEmployeeGender({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    updateGenderCommand: {
      gender: gender,
    },
  });
}

export async function updateEmployeeStaffGroup(
  organizationId: number,
  employeeUuid: string,
  staffGroup: EmployeeViewModelStaffGroupEnum,
) {
  const api = employeeApi();
  await api.updateEmployeeStaffGroup({
    organizationId: '' + organizationId,
    entityId: employeeUuid,
    updateStaffGroupCommand: {
      staffGroup: staffGroup,
    },
  });
}
