/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QualityReviewMainGoalViewModel } from './QualityReviewMainGoalViewModel';
import {
  QualityReviewMainGoalViewModelFromJSON,
  QualityReviewMainGoalViewModelFromJSONTyped,
  QualityReviewMainGoalViewModelToJSON,
} from './QualityReviewMainGoalViewModel';

/**
 *
 * @export
 * @interface QualityReviewViewModel
 */
export interface QualityReviewViewModel {
  /**
   *
   * @type {string}
   * @memberof QualityReviewViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof QualityReviewViewModel
   */
  name?: string;
  /**
   *
   * @type {Array<QualityReviewMainGoalViewModel>}
   * @memberof QualityReviewViewModel
   */
  mainGoals?: Array<QualityReviewMainGoalViewModel>;
  /**
   *
   * @type {boolean}
   * @memberof QualityReviewViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof QualityReviewViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof QualityReviewViewModel
   */
  draftName?: string;
}

/**
 * Check if a given object implements the QualityReviewViewModel interface.
 */
export function instanceOfQualityReviewViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function QualityReviewViewModelFromJSON(json: any): QualityReviewViewModel {
  return QualityReviewViewModelFromJSONTyped(json, false);
}

export function QualityReviewViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityReviewViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: !exists(json, 'name') ? undefined : json['name'],
    mainGoals: !exists(json, 'mainGoals')
      ? undefined
      : (json['mainGoals'] as Array<any>).map(QualityReviewMainGoalViewModelFromJSON),
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
  };
}

export function QualityReviewViewModelToJSON(value?: QualityReviewViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    mainGoals:
      value.mainGoals === undefined
        ? undefined
        : (value.mainGoals as Array<any>).map(QualityReviewMainGoalViewModelToJSON),
    deleted: value.deleted,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
  };
}
