import { html, nothing, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { FormViewItem } from '../../library/components/d-form-view.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/components/d-edit-event-timing.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/elements/d-section.js';
import '../../library/editors/elements/d-radio-cell.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/components/d-edit-html.js';
import '../../library/editors/components/d-select-time.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface ConstitutionalDocumentViewEditItem {
  partners: string[];
  employees: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface ConstitutionalDocumentView
  extends AbstractEntityViewWithRevisions<ConstitutionalDocumentViewEditItem>,
    UpdateSectionItem {
  availablePartners: SelectTagOption[];
  availableEmployees: SelectTagOption[];
  isEmployee: boolean;
  type: 'constitutionalDocuments';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
  validFromDate: string;
  validToDate: string;
  contractPartyNames: string;
  partners: string[];
  employees: string[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-constitutional-document-view')
export class DConstitutionalDocumentView extends EntityContent<
  ConstitutionalDocumentView,
  ConstitutionalDocumentViewEditItem
> {
  static readonly styles = EntityContent.styles;

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addDate('constitutionalDocuments_validFromDate', this.entityView.validFromDate);
    builder.addDate('constitutionalDocuments_validToDate', this.entityView.validToDate);
    builder.addText('constitutionalDocuments_contractPartyNames', this.entityView.contractPartyNames, 'l', true);
    builder.addHtmlContent('constitutionalDocuments_content', this.entityView.content);
    return builder.build();
  }

  async initializeEditItem() {
    this.editItem = {
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: this.entityView.content,
      employees: this.entityView.employees,
      partners: this.entityView.partners,
      validFromDate: this.entityView.validFromDate,
      validToDate: this.entityView.validToDate,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  _availableEmployees(employees, currentEmployees) {
    return employees.filter(function (employee) {
      return (
        currentEmployees.indexOf(employee.uuid) !== -1 || employee.status === 'ACTIVE' || employee.status === 'LEAVE'
      );
    });
  }

  renderEditItem(item: ConstitutionalDocumentViewEditItem): TemplateResult<1> {
    return html`
      ${this.entityView.isEmployee
        ? html` <d-section>
            <d-edit-classification
              .accessControl=${item.accessControl}
              .accessControlOptions=${this.entityView.accessControlOptions}
              .classification=${item.classification}
              @value-changed=${(
                e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>,
              ) => {
                this.editItem = {
                  ...item,
                  accessControl: e.detail.accessControl,
                  classification: e.detail.classification,
                };
              }}
            ></d-edit-classification>
          </d-section>`
        : nothing}

      <d-section>
        <d-edit-text
          field="constitutionalDocuments_name"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-section>
        <d-select-date
          field="constitutionalDocuments_validFromDate"
          .value=${item.validFromDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validFromDate: e.detail.value };
          }}
        >
        </d-select-date>

        <d-select-date
          field="constitutionalDocuments_validToDate"
          .value=${item.validToDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validToDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>

      <d-section>
        <d-select-tag
          field="constitutionalDocuments_employees"
          .options=${this.entityView.availableEmployees}
          .value=${item.employees}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, employees: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>

      <d-section>
        <d-select-tag
          field="constitutionalDocuments_partners"
          .options=${this.entityView.availablePartners}
          .value=${item.partners}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, partners: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>

      <d-edit-html
        field="constitutionalDocuments_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      >
      </d-edit-html>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-constitutional-document-view': DConstitutionalDocumentView;
  }
}
