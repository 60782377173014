import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { activityCodesAvailable, activityCodesCommon } from 'src/store/selectors';
import '../../library/components/d-activity-codes-container.js';
import '../../library/editors/index.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';

export interface OrganizationEditItem {
  notes: string;
  email: string;
  url: string;
  fax: string;
  phone: string;
  mailAddress: string;
  officeAddress: string;
  herNumber: string;
  organizationNumber: string;
  businessEntityType: string;
  name: string;
  activityCodes: string[];
}

/**
 *
 */
@customElement('d-organization-edit')
export class DOrganizationEdit extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Object })
  editItem!: OrganizationEditItem;
  @property({ type: Array })
  activityCodes = [];
  @property({ type: Boolean })
  hideHerId = false;
  @property({ type: Boolean })
  hideActivityCodes = false;
  businessEntityTypeOptions: SelectDropdownOption[] = [
    'Enkeltpersonforetak',
    'DA',
    'ANS',
    'KS',
    'AS',
    'NUF',
    'Stiftelse',
    'Kommunal enhet',
    'Annet',
  ].map((x) => ({
    value: x,
    text: x,
  }));
  @property({ type: Number })
  contentStickyTop = 0;

  render() {
    return html`
      <d-section>
        <d-edit-text
          theme-page
          field="organization_name"
          .value=${this.editItem.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, name: e.detail.value };
            this.fireEditItemChanged();
          }}
        ></d-edit-text>
      </d-section>

      ${this.hideActivityCodes
        ? nothing
        : html`<d-section>
            <div id="codeContent">
              <d-label field="organization_activityCodes"></d-label>
              <d-activity-codes-container
                theme-page
                .codes=${activityCodesAvailable()}
                .commonActivityCodes=${activityCodesCommon()}
                .activityCodes=${this.editItem.activityCodes}
                @activity-codes-changed=${(e: CustomEvent<{ codes: string[] }>) => {
                  e.stopPropagation();
                  this.editItem = { ...this.editItem, activityCodes: e.detail.codes };
                  this.fireEditItemChanged();
                }}
              ></d-activity-codes-container>
            </div>
          </d-section>`}

      <d-section>
        <d-select-dropdown
          theme-page
          field="organization_businessEntityType"
          .options=${this.businessEntityTypeOptions}
          placeholder="Velg"
          .value=${this.editItem.businessEntityType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, businessEntityType: e.detail.value };
            this.fireEditItemChanged();
          }}
        >
        </d-select-dropdown>

        <d-edit-text
          theme-page
          field="organization_organizationNumber"
          .value=${this.editItem.organizationNumber}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, organizationNumber: e.detail.value };
            this.fireEditItemChanged();
          }}
        >
        </d-edit-text>
        ${this.hideHerId
          ? nothing
          : html`<d-edit-text
              theme-page
              field="organization_herNumber"
              .value=${this.editItem.herNumber}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                e.stopPropagation();
                this.editItem = { ...this.editItem, herNumber: e.detail.value };
                this.fireEditItemChanged();
              }}
            >
            </d-edit-text>`}
      </d-section>
      <d-section>
        <d-edit-textarea
          theme-page
          class="minWidth300"
          field="organization_officeAddress"
          .value=${this.editItem.officeAddress}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, officeAddress: e.detail.value };
            this.fireEditItemChanged();
          }}
        >
        </d-edit-textarea>
        <d-edit-textarea
          theme-page
          class="minWidth300"
          field="organization_mailAddress"
          .value=${this.editItem.mailAddress}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, mailAddress: e.detail.value };
            this.fireEditItemChanged();
          }}
        >
        </d-edit-textarea>
      </d-section>
      <d-section>
        <d-edit-text
          theme-page
          field="organization_phone"
          .value=${this.editItem.phone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, phone: e.detail.value };
            this.fireEditItemChanged();
          }}
        ></d-edit-text>
        <d-edit-text
          theme-page
          field="organization_fax"
          .value=${this.editItem.fax}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, fax: e.detail.value };
            this.fireEditItemChanged();
          }}
        ></d-edit-text>
        <d-edit-text
          theme-page
          field="organization_url"
          .value=${this.editItem.url}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, url: e.detail.value };
            this.fireEditItemChanged();
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-edit-text
          theme-page
          field="organization_email"
          .value=${this.editItem.email}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, email: e.detail.value };
            this.fireEditItemChanged();
          }}
        >
        </d-edit-text>
      </d-section>
      <d-edit-html
        theme-page
        field="organization_notes"
        .contentStickyTop=${this.contentStickyTop}
        standard-label
        .value=${this.editItem.notes}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = { ...this.editItem, notes: e.detail.value };
          this.fireEditItemChanged();
        }}
      >
      </d-edit-html>
    `;
  }

  private fireEditItemChanged() {
    this.dispatchEvent(
      new CustomEvent<OrganizationEditItem>('edit-item-changed', {
        composed: true,
        bubbles: true,
        detail: this.editItem,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-edit': DOrganizationEdit;
  }
}
