import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { OrganizationEditItem } from 'src/pages/organization-page/d-organization-edit.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import type { PageViewModel } from 'src/store/api';
import './d-organization-edit.js';
import './d-organization-view.js';
import type { OrganizationPageViewModel } from './organization-page-view-model.js';

/**
 *
 */
@customElement('d-organization-page-content')
export class DOrganizationPageContent extends PageContent<OrganizationPageViewModel> {
  @property({ type: Object })
  currentPageItem: any = {};
  @property({ type: Object })
  route = {};
  @property({ type: Object })
  queryParams = {};
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Object })
  editItem?: OrganizationEditItem;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  static get observers() {
    return ['_queryModeChanged(queryParams.mode)'];
  }

  _queryModeChanged(mode) {
    if (mode === 'edit') {
      setTimeout(() => {
        window.history.replaceState(null, '', window.location.pathname);
        this.editModeOn();
      }, 50);
    }
  }

  editModeOn() {
    this.editMode = true;
    // TODO this.scroller.scrollTop = 0;
  }

  editModeOff() {
    this.editItem = undefined;
    this.editMode = false;
  }

  _codes(codes, activityCodes) {
    let result = '';

    if (activityCodes && codes) {
      const names: string[] = [];

      codes.forEach((code) => {
        if (activityCodes.indexOf(code.code) !== -1) {
          if (code.codes) {
            const r = this._codes(code.codes, activityCodes);

            if (r && r !== '') {
              names.push(r);
            } else {
              names.push(code.custom || code.name);
            }
          } else {
            names.push(code.custom || code.name);
          }
        }
      });

      result = names.join(', ');
    }

    return result;
  }

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderEditItem(item: OrganizationEditItem): TemplateResult<1> {
    return html`<d-organization-edit
      theme-page
      .contentStickyTop=${this.contentStickyTop}
      .editItem=${item}
      @edit-item-changed=${this.onValueChanged}
    ></d-organization-edit>`;
  }

  renderEditMode() {
    if (this.editItem === undefined) {
      this.initializeEditItem();
    }

    if (this.editItem === undefined) {
      return html``;
    }

    return this.renderEditItem(this.editItem);
  }

  renderContent() {
    return html`
      ${this.editMode
        ? this.renderEditMode()
        : html`<d-organization-view
            .organization=${this.pageView.organization}
            .employeeGroups=${this.pageView.employeeGroups}
            .partners=${this.pageView.partners}
            .functions=${[]}
            .singleUserVersion=${this.singleUserVersion}
            .constitutionalDocuments=${this.pageView.constitutionalDocuments}
            .codes=${this.pageView.availableActivityCodes}
            .contentStickyTop=${this.contentStickyTop}
          ></d-organization-view>`}
    `;
  }

  showEdit() {
    this.editModeOn();
  }

  cancelEdit() {
    this.editModeOff();
  }

  doneEdit() {
    if (this.editItem !== undefined) {
      this.dispatchEvent(
        new CustomEvent<OrganizationEditItem>('save-organization', {
          composed: true,
          bubbles: true,
          detail: this.editItem,
        }),
      );
    }
    this.editModeOff();
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`
      ${this.editMode
        ? html`<d-action @click=${() => this.cancelEdit()}>Avbryt</d-action
            ><d-action @click=${() => this.doneEdit()}>Ferdig</d-action>`
        : html`<d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>
            <d-action @click=${() => this.editModeOn()}>Rediger</d-action>
            ${this.singleUserVersion
              ? nothing
              : html`<d-action @click=${() => this.onShareContent()}>Del</d-action>`} `}
    `;
  }

  private onValueChanged(e: CustomEvent<OrganizationEditItem>) {
    e.stopPropagation();
    this.editItem = { ...e.detail };
  }

  private initializeEditItem() {
    this.editItem = {
      notes: this.pageView.organization.notes ?? '',
      organizationNumber: this.pageView.organization.organizationNumber ?? '',
      activityCodes: [...this.pageView.organization.activityCodes],
      businessEntityType: this.pageView.organization.businessEntityType ?? '',
      mailAddress: this.pageView.organization.mailAddress ?? '',
      officeAddress: this.pageView.organization.officeAddress ?? '',
      url: this.pageView.organization.url ?? '',
      phone: this.pageView.organization.phone ?? '',
      name: this.pageView.organization.name ?? '',
      herNumber: this.pageView.organization.herNumber ?? '',
      fax: this.pageView.organization.fax ?? '',
      email: this.pageView.organization.email ?? '',
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-page-content': DOrganizationPageContent;
  }
}
