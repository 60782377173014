import { Editor } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { TaskList } from '@tiptap/extension-task-list';
import { TaskItem } from '@tiptap/extension-task-item';
import DOMPurify from 'dompurify';
import { css, CSSResultGroup, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../../elements/d-action.js';
import '../../elements/d-label.js';
import './d-edit-html-toolbar.js';
import { Link } from './link';
import { htmlContentStyles } from 'src/library/html-content-styles.js';
import { styleMap } from 'lit/directives/style-map.js';
import { DEditLinkDialog, EditLinkResult } from 'src/library/editors/components/d-edit-link-dialog.js';
import { DocForLinking } from 'src/models/internal-linking.js';

/**
 *
 *
 * STATUS OK
 */

@customElement('d-edit-html')
export class DEditHtml extends LitElement {
  static readonly styles: CSSResultGroup | undefined = [
    css`
      :host {
        display: block;
        border-top: 1px solid var(--borderColor);
        padding-bottom: 10px;
      }

      :host([theme-page]) {
        border-top: 1px solid var(--borderColorOnGray);
      }

      .text-edit-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        z-index: 1;
      }

      :host([theme-page]) .text-edit-header {
        background-color: var(--backgroundGray);
      }

      :host([map-element]) .text-edit-header {
        background-color: var(--map-element-background-color);
      }

      .text-edit-header div {
        margin-bottom: 4px;
      }

      d-label {
        margin-right: 10px;
      }

      d-action {
        margin-top: -6px;
        margin-bottom: -6px;
        margin-left: -8px;
      }

      :host([label-action-plain]) d-action {
        margin-left: -16px;
      }

      d-edit-html-toolbar {
        flex-grow: 1;
      }

      #editor {
        position: relative;
        padding: 20px;
        background-color: var(--inputElementBackground);
        box-shadow: var(--inputElementShadow);
        -webkit-appearance: none;
        border: none;
        font-family: var(--mainSerif), serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;
      }

      #editor > div {
        min-height: 100px;
      }

      #editor p {
        margin-top: 8px;
        margin-bottom: 10px;
      }

      #editor p:first-child,
      #editor h1:first-child,
      #editor h2:first-child,
      #editor ul:first-child,
      #editor ol:first-child {
        margin-top: 0;
      }

      #editor a:not([data-internal-link-doctype]),
      #editor ul a:not([data-internal-link-doctype]),
      #editor ol a:not([data-internal-link-doctype]) {
        border-bottom: 1px solid var(--themeColorDarkerTwoTransparent);
      }

      #editor a.tasks:not(.internal) {
        border-bottom: none;
        color: var(--linkColorGray);
      }

      #editor li {
        margin-bottom: 6px;
      }

      #editor li p {
        margin: 0;
        padding-right: 1px; // Prevents caret from disappearing in empty checklist item
      }

      #editor .ProseMirror {
        outline: none;
      }

      :host([theme-page]) #editor {
        background-color: white;
        box-shadow: none;
        border: 1px solid var(--borderColor);
        border-radius: 4px;
      }

      :host([computers-page]) #editor {
        background-color: white;
        box-shadow: none;
      }
    `,
    htmlContentStyles,
  ];
  @property()
  placeholder = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  value = 'undefined';
  @property({ type: String })
  labelAction = '';
  @property({ type: Boolean, attribute: 'label-action-plain' })
  labelActionPlain = false;
  @property({ type: String })
  href = '';
  @property({ type: Boolean })
  standardLabel = false;
  @property({ type: String })
  headerStyle = 'X';
  @property({ type: Boolean })
  hideLink = true;
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean })
  showChecklist = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Array })
  docsForLinking: DocForLinking[] = [];
  /**
   * Editor HTML Element
   */
  @query('#editor')
  private editorElement?: HTMLDivElement;
  /**
   * TipTap Editor instance
   */
  private editor: Editor | undefined;
  /**
   * Content of the editor
   */
  @state()
  private _content = '';
  @state() private isEditorEmpty = false;

  _hideLinkClasses(hideLink) {
    if (hideLink) {
      return 'hideLink';
    }
    return '';
  }

  _labelClass() {
    const c = this.standardLabel;
    if (c) {
      return 'editLabel';
    } else {
      return 'editLabel labelBigger';
    }
  }

  _valueChanged(newValue: string | undefined) {
    const v = newValue ?? '';
    const currentValue = this._content;
    if (currentValue !== v && this.editor) {
      this.editor.commands.setContent(v);
      return;
    }
    this._content = this.value;
  }

  _styleChanged() {
    if (this.headerStyle === '0') {
      this.p();
    } else if (this.headerStyle === '1') {
      this.h1();
    } else if (this.headerStyle === '2') {
      this.h2();
    }

    this.headerStyle = 'X';
  }

  setContent(value) {
    const config = {
      ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h6', 'ul', 'ol', 'li', 'i', 'em', 'b', 'p', 'a', 'br', 'strong'],
      ALLOWED_ATTR: ['href', 'target', 'checked', 'class'],
      KEEP_CONTENT: true,
      ALLOW_DATA_ATTR: true,
      ALLOW_ARIA_ATTR: false,
    };

    this._content = DOMPurify.sanitize(value, config);
  }

  _labelAction() {
    this.dispatchEvent(new CustomEvent('label-action', { bubbles: true, composed: true }));
  }

  _setStyle(e: CustomEvent<string>) {
    e.stopPropagation();
    switch (e.detail) {
      case 'bold':
        this.bold();
        break;

      case 'italic':
        this.italic();
        break;

      case 'indPlus':
        this.indent();
        break;

      case 'indMinus':
        this.outdent();
        break;

      default:
        break;
    }
  }

  _blockTypeChanged(blockType: string) {
    switch (blockType) {
      case 'p':
        this.p();
        break;

      case 'h1':
        this.h1();
        break;

      case 'h2':
        this.h2();
        break;

      case 'ul':
        this.unorderedlist();
        break;

      case 'ol':
        this.orderedlist();
        break;

      case 'checklist':
        this.checklist();
        break;

      default:
        break;
    }
  }

  bold() {
    this.editor?.chain().toggleBold().focus().run();
  }

  italic() {
    this.editor?.chain().toggleItalic().focus().run();
  }

  unorderedlist() {
    this.editor?.chain().toggleBulletList().focus().run();
  }

  orderedlist() {
    this.editor?.chain().toggleOrderedList().focus().run();
  }

  checklist() {
    this.editor?.chain().focus().toggleTaskList().run();
  }

  indent() {
    this.editor?.chain().focus().sinkListItem('listItem').run();
  }

  outdent() {
    this.editor?.chain().focus().liftListItem('listItem').run();
  }

  p() {
    this.editor?.chain().setParagraph().focus().run();
  }

  h1() {
    this.editor?.chain().setHeading({ level: 1 }).focus().run();
  }

  h2() {
    this.editor?.chain().setHeading({ level: 2 }).focus().run();
  }

  h6() {
    this.editor?.chain().setHeading({ level: 6 }).focus().run();
  }

  _setLink(href, target) {
    this.editor?.commands.setLink({ href: href, target: target });
  }

  _unsetLink() {
    this.editor?.commands.unsetLink();
  }

  _getHref() {
    if (this.editor) {
      return this.editor.getAttributes('link').href;
    }
  }

  _getTarget() {
    if (this.editor) {
      return this.editor.getAttributes('link').target;
    }
  }

  _insertInternalLink(docData) {
    let classifiedAttr = '';
    if (docData.classified) {
      classifiedAttr += 'data-internal-link-classified';
    }
    let href = '';
    const doc = this.docsForLinking.find((d) => {
      return d.uuid === docData.uuid;
    });
    if (doc) {
      href = docData.href;
    }
    const link = `<a href="${href}" ${classifiedAttr} data-internal-link-doctype="${docData.docType}" data-internal-link-id="${docData.uuid}" target="_self">${docData.name}</a>`;
    this.editor?.chain().focus().insertContent(link).run();
  }

  _insertExternalLink(text, href) {
    const link = '<a target="_blank" href="' + href + '">' + text + '</a>';
    this.editor?.chain().focus().insertContent(link).run();
  }

  isDifferent(a, b) {
    if (a === '<p></p>' && b === '') {
      return false;
    }
    if (a === '' && b === '<p></p>') {
      return false;
    }
    return a !== b;
  }

  willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('value')) {
      this._valueChanged(this.value);
    }

    if (_changedProperties.has('standardLabel')) {
      this._labelClass();
    }

    if (_changedProperties.has('headerStyle')) {
      this._styleChanged();
    }
  }

  protected render() {
    const headerStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <div class="text-edit-header" style=${styleMap(headerStyles)}>
        <div>
          <d-label classes="big" field="${this.field}" label="${this.label}" sublabel="${this.sublabel}"></d-label>
          ${this.labelAction
            ? html`
                <d-action ?plain="${this.labelActionPlain}" @click=${() => this._labelAction()}
                  >${this.labelAction}</d-action
                >
              `
            : nothing}
        </div>
        <d-edit-html-toolbar
          ?theme-page="${this.themePage}"
          .showChecklist=${this.showChecklist}
          @block-type=${(e) => this._blockTypeChanged(e.detail)}
          @set-style=${(e) => this._setStyle(e)}
          @show-edit-link-dialog=${() => this.showEditLinkDialog()}
        ></d-edit-html-toolbar>
      </div>
      <div id="editor" class="html editable"></div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.editor = new Editor({
      element: this.editorElement,
      extensions: [
        StarterKit,
        TaskItem,
        TaskList,
        Link.extend({ inclusive: false }).configure({
          autolink: true,
          openOnClick: false,
        }),
      ],
      content: this._content,
      onTransaction: () => {
        if (this.editor) {
          this.isEditorEmpty = this.editor.isEmpty;
          const newValue = this.editor.getHTML();
          if (this.isDifferent(newValue, this._content)) {
            this.setContent(newValue);
            this.dispatchEvent(
              new CustomEvent('value-changed', {
                bubbles: true,
                composed: true,
                detail: {
                  value: this._content,
                },
              }),
            );
          }
        }
      },
    });
  }

  private async showEditLinkDialog() {
    const editor = this.editor;
    if (editor) {
      let href = 'https://';
      if (this._getHref()) {
        href = this._getHref();
      }
      let target = '_self';
      if (this._getTarget()) {
        target = this._getTarget();
      }
      const { view, state } = editor;
      const { from, to } = view.state.selection;
      const linkText = state.doc.textBetween(from, to, '');
      const input = { href, target, linkText, docsForLinking: this.docsForLinking };
      const result: EditLinkResult = await DEditLinkDialog.open(input);
      if (result.action === 'setInternal' && result.selectedDoc) {
        this._insertInternalLink(result.selectedDoc);
      }
      if (result.action === 'setExternal') {
        this._insertExternalLink(result.linkText, result.href);
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-html': DEditHtml;
  }
}
