import { html, nothing } from 'lit';
import 'src/content/entity-content';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-section.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import type { DEditSingleRelatedItem } from '../../pages/computers-page/d-edit-single-related-item.js';
import './d-view-checklist.js';
import '../../library/editors/elements/d-select-checkbox.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment.js';

import { customElement, query } from 'lit/decorators.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { DataItem } from 'src/pages/computers-page/d-data-item-view.js';
import type { RelatedItem } from 'src/pages/computers-page/d-view-related-items.js';

export interface ComputerMapRemoteServer extends DataMapItem {
  personalDataItems: DataItem[];
  connectionType: string;
  simplifiedPrivacy: boolean;
  unitType: 'remoteServer';
  displayName: string;
  supplierName: string;
  supplierUuid: string;
  contractName: string;
  contractUuid: string;
  serviceName: string;
  availableSuppliers: SelectDropdownOption[];
  availableContracts: SelectDropdownOption[];
  attachments: AttachmentItem[];
  /**
   * Remote servers are not deletable if they contain data items.
   */
  deletable: boolean;
}

export interface RemoteServerEditItem {
  connectionType: string;
  supplierUuid: string;
  contractUuid: string;
}

/**
 *
 */
@customElement('d-computer-unit-remote-server')
export class DComputerUnitRemoteServer extends DataMapElement<ComputerMapRemoteServer, RemoteServerEditItem> {
  printerPositionSecureOptions = [
    {
      value: 'true',
      text: 'Skriveren er plassert slik at uvedkommende ikke kan få tilgang til utskrifter',
    },
  ];
  @query('#suppliers-edit-single-related-item')
  EditSingleRelatedItemElement: DEditSingleRelatedItem | undefined;
  private localUnitTypes: SelectDropdownOption[] = [];

  initializeEditItem(): RemoteServerEditItem {
    return {
      connectionType: this.item.connectionType,
      supplierUuid: this.item.supplierUuid,
      contractUuid: this.item.contractUuid,
    };
  }

  _displayTypeName(): string {
    return 'Fjernserver';
  }

  _displayName() {
    return this.item.supplierName;
  }

  _getName(type, uuid) {
    if (Array.isArray(uuid)) {
      uuid = uuid[0];
    }
    for (const item of type) {
      if (item.uuid === uuid) {
        return item.name;
      }
    }
  }

  _operatorTypes(networkConnection) {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    if (networkConnection !== 'NONE' && networkConnection !== '') {
      list.push({ value: 'INHERIT', text: 'Samme som for nettverket' });
    }
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this._operatorTypes('this.networkUuid');
    for (const item of list) {
      if (id === item.value) {
        result = item.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  _isValid(): boolean {
    return this.editItem !== undefined && this.editItem.supplierUuid !== '';
  }

  _saveLinkClasses() {
    if (this._isValid()) {
      return 'action';
    } else {
      return 'action disabled';
    }
  }

  _isEmpty(prop) {
    return prop.length === 0;
  }

  renderUnitView() {
    const contracts: RelatedItem[] = this.item.availableContracts
      .filter((c) => c.value === this.item.contractUuid)
      .map((c) => ({ href: '', name: c.text }));

    return html`<section>
      ${this.item.contractUuid !== ''
        ? html` <d-section>
            <d-view-related-items field="remoteServers_serviceProviderContract" .items=${contracts} type="contracts">
            </d-view-related-items>
          </d-section>`
        : nothing}

      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        write-access="${this.writeAccess}"
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  renderUnitEdit(editItem: RemoteServerEditItem) {
    return html`
      <d-section>
        <d-edit-single-related-item
          id="suppliers-edit-single-related-item"
          class="minWidth300"
          .items=${this.item.availableSuppliers}
          field="remoteServers_serviceProvider"
          @new-item=${this.onNewPartner}
          placeholder="Velg leverandør"
          .relatedItem="${editItem.supplierUuid}"
          @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              supplierUuid: e.detail.relatedItem,
            };
          }}
        >
        </d-edit-single-related-item>
        ${this.item.simplifiedPrivacy
          ? nothing
          : html`<d-select-dropdown
              theme-page
              class="minWidth300"
              field="remoteServers_connectionType"
              .options="${this.connectionTypes}"
              value="${editItem.connectionType}"
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  connectionType: e.detail.value,
                };
              }}
            >
            </d-select-dropdown>`}
      </d-section>
      <d-section>
        <d-edit-single-related-item
          field="remoteServers_serviceProviderContract"
          .items=${this.item.availableContracts}
          @new-item=${this.onNewContract}
          placeholder="Velg avtale"
          .relatedItem=${editItem.contractUuid}
          @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              contractUuid: e.detail.relatedItem,
            };
          }}
        >
        </d-edit-single-related-item>
      </d-section>
    `;
  }

  protected isDeleteDisabled(): boolean {
    return !this.item.deletable;
  }

  protected onNewPartner(e: CustomEvent<{ uuid: string; name: string }>): void {
    super.onNewPartner(e);
    if (this.EditSingleRelatedItemElement?.items) {
      this.item = {
        ...this.item,
        availableSuppliers: [...this.EditSingleRelatedItemElement.items],
      };
    }
  }

  protected typeClasses(): string {
    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computer-unit-remote-server': DComputerUnitRemoteServer;
  }
}
