import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import '../../elements/d-label.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { isEmptyOrInvalidString } from 'src/utilities/text.js';

/**
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 */
@customElement('d-edit-text')
export class DEditText extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      div {
        display: flex;
        position: relative;
      }

      :host([button]) input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      :host([toolbar]) input {
        box-shadow: none;
        background-color: hsl(0, 0%, 92%);
        border-radius: 4px;
      }

      :host([invalid]) input {
        border: 1px solid var(--alertColor);
        margin-left: -1px;
        margin-right: -1px;
      }

      input[type='search'] {
        box-shadow: none;
        border-radius: 15px;
        padding-left: 30px;
        background-image: url(/images/search.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 3px 50%;
        font-family: var(--mainSans), sans-serif;
      }

      :host([theme-page]) input[type='search'] {
        border-radius: 16px;
      }

      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0 8px;
        background: var(--themeColor);
        font-family: var(--small), sans-serif;
        font-size: 11px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
      }

      :host([outskirts]) button,
      :host([toolbar]) button,
      :host([theme-page]) button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      :host([theme-page]) button {
        border: 1px solid var(--themeColorDarkerOne);
        border-left: none;
      }

      :host([outskirts]) button {
        background: var(--themeColorLighterTwo);
      }

      button:hover {
        background: var(--themeColorDarkerOne);
      }

      :host([outskirts]) button:hover {
        background: var(--themeColorLighterOne);
      }

      button:disabled,
      button:disabled:hover {
        background: var(--themeColor);
        opacity: 0.5;
        cursor: default;
      }
    `,
  ];
  @query('#input')
  textInput!: HTMLInputElement;
  @property({ type: String, attribute: 'type' })
  type: 'search' | 'text' | 'password' | 'email' = 'text';
  @property({ type: String })
  autocomplete?: 'new-password';
  @property({ type: String })
  value?: string;
  @property({ type: Boolean, reflect: true })
  disabled = false;
  @property({ type: String })
  placeholder = '';
  @property({ type: Boolean, attribute: 'select-on-focus' })
  selectOnFocus = false;
  @property({ type: Boolean, attribute: 'button', reflect: true })
  button = false;
  @property({ type: String, attribute: 'button-text', reflect: true })
  buttonText = 'OK';
  @property({ type: Boolean, attribute: 'button-disabled', reflect: true })
  buttonDisabled = this.disabled;
  @property({ type: Boolean, reflect: true })
  clear = false;
  @property({ type: Boolean, reflect: true })
  toolbar = false;
  @property({ type: Boolean })
  autofocus = false;
  @property({ type: Boolean, reflect: true })
  invalid = false;
  @property({ type: Boolean, attribute: 'mark-if-empty', reflect: true })
  markIfEmpty = false;

  private get showClear() {
    return (this.clear || this.type === 'search') && this.value && this.value !== '';
  }

  private get inputStyle() {
    if (this.markIfEmpty && isEmptyOrInvalidString(this.value)) {
      return 'border: 1px solid var(--themeColor)';
    }
    return '';
  }

  _focus() {
    const input = this.shadowRoot?.getElementById('input');
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 0);
    }
  }

  _select() {
    this.dispatchEvent(new CustomEvent('is-focused', { bubbles: true, composed: true }));
    if (this.selectOnFocus) {
      this.textInput.select();
    }
  }

  onInput(event) {
    this.updateUserValue(event.target.value);
  }

  onClear() {
    this.updateUserValue('');
  }

  setFocus() {
    this.textInput.focus();
  }

  renderContent() {
    return html`
      <div>
        <input
          id="input"
          autocomplete=${ifDefined(this.autocomplete)}
          .value=${this.value ?? ''}
          ?disabled=${this.disabled}
          placeholder="${this.placeholder}"
          type="${this.type}"
          @input=${this.onInput}
          @focus=${() => this._select()}
          style="${this.inputStyle}"
        />
        ${this.showClear ? html` <div @click=${this.onClear}></div> ` : nothing}
        ${this.button
          ? html` <button ?disabled=${this.buttonDisabled} @click=${this.onButtonClick}>${this.buttonText}</button> `
          : nothing}
      </div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.autofocus) {
      const input = this.shadowRoot?.getElementById('input');
      if (input) {
        input.focus();
      }
    }
  }

  private onButtonClick() {
    if (!this.buttonDisabled) {
      this.dispatchEvent(
        new CustomEvent('button-click', {
          bubbles: true,
          composed: true,
          detail: { value: this.value },
        }),
      );
    }
  }

  private updateUserValue(value: string) {
    this.value = value;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-text': DEditText;
  }
}
