import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../content/issues/d-external-report-item.js';
import '../../content/issues/d-main-section.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/elements/d-select-checkbox.js';
import '../../library/fields/index.js';
import '../../library/fields/d-conditional-bullet.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section-attachment.js';
import { AbstractEntityView, contentViewBuilder, EntityContent } from '../entity-content.js';
import './d-issue-behavior.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface IssueView extends AbstractEntityView<IssueViewEditItem> {
  currentUserIsEmployee: boolean;
  measuresSatisfactorily: boolean;
  relateToPatientInstitution: boolean;
  requireEvaluation: boolean;
  reportedCountyMedical: boolean;
  reportedSafetyManager: boolean;
  reportedInspectorate: boolean;
  reportedLabourInspection: boolean;
  registeredDate: string;
  responsibleBy: string;
  reportedBy: string;
  registeredBy: string;
  correction: string;
  shallEvaluated: boolean;
  evaluatedByName: string;
  evaluatedOk: boolean;
  evaluatedDate: string;
  evaluated: boolean;
  implementedMeasure: boolean;
  identifiedMeasures: string;
  requiresMeasures: boolean;
  relateToMaritimeHealthCertificate: boolean;
  informationLeakagePossibility: boolean;
  relateToRadiationEquipmentMissing: boolean;
  relateToRadiation: boolean;
  relateToEquipment: boolean;
  workRelatedSicknessCausingEnvironment: string;
  workRelatedSicknessCausingTaskLocation: string;
  workRelatedSicknessCausingTask: string;
  workRelatedInjuryIncidentCause: string;
  workRelatedHealthIssueDescription: string;
  workRelatedInjuryTask: string;
  workRelatedHealthIssueEmployeeName: string;
  workRelatedSickness: boolean;
  workRelatedInjury: boolean;
  workRelatedHealthIssue: boolean;
  relateToEmployees: boolean;
  relateToPatient: boolean;
  personDamagePossibility: boolean;
  notes: string;
  processedByName: string;
  processedDate: string;
  uuid: string;
  furtherMeasures: string;
  immediateMeasures: string;
  description: string;
  reportedToName: string;
  reportedDate: string;
  eventDate: string;
  type: 'issues';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  availablePages: SelectTagOption[];
  availableEmployees: SelectDropdownOption[];

  maritimeDoctor: boolean;
  pages: string[];
  reportedTo: string;
  evaluatedBy: string;
  processedBy: string;
  workRelatedHealthIssueEmployee: string;

  processed: boolean;
}

export interface IssueViewEditItem {
  shallEvaluated: boolean;
  correction: string;
  registeredBy: string;
  reportedBy: string;
  responsibleBy: string;
  registeredDate: string;
  reportedLabourInspection: boolean;
  reportedInspectorate: boolean;
  reportedSafetyManager: boolean;
  reportedCountyMedical: boolean;
  relateToPatientInstitution: boolean;
  requireEvaluation: boolean;
  measuresSatisfactorily: boolean;
  reportedTo: string;
  processedBy: string;
  evaluatedBy: string;
  workRelatedHealthIssueEmployee: string;
  pages: string[];
  name: string;
  evaluatedByName: string;
  evaluatedOk: boolean;
  evaluatedDate: string;
  evaluated: boolean;
  implementedMeasure: boolean;
  identifiedMeasures: string;
  requiresMeasures: boolean;
  relateToMaritimeHealthCertificate: boolean;
  informationLeakagePossibility: boolean;
  relateToRadiationEquipmentMissing: boolean;
  relateToRadiation: boolean;
  relateToEquipment: boolean;
  workRelatedSicknessCausingEnvironment: string;
  workRelatedSicknessCausingTaskLocation: string;
  workRelatedSicknessCausingTask: string;
  workRelatedInjuryIncidentCause: string;
  workRelatedHealthIssueDescription: string;
  workRelatedInjuryTask: string;
  workRelatedHealthIssueEmployeeName: string;
  workRelatedSickness: boolean;
  workRelatedInjury: boolean;
  workRelatedHealthIssue: boolean;
  relateToEmployees: boolean;
  relateToPatient: boolean;
  personDamagePossibility: boolean;
  notes: string;
  processedByName: string;
  processedDate: string;
  uuid: string;
  furtherMeasures: string;
  immediateMeasures: string;
  description: string;
  reportedToName: string;
  reportedDate: string;
  eventDate: string;
  type: 'issues';
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
}

/**
 *
 */
@customElement('d-issue-view')
export class DIssueView extends EntityContent<IssueView, IssueViewEditItem> {
  static readonly styles = [
    ...EntityContent.styles,
    css`
      d-section::content #flexWrapper > d-section-element.noTopBorder {
        border-top: none;
        padding-top: 0;
      }

      .indented {
        margin-left: 28px;
        transition: margin-left 0.5s;
      }

      d-view-info ::content #content ::content ul {
        margin-left: 0;
      }

      d-view-info ::content #content ::content ul li {
        padding-left: 28px;
      }

      #workRelatedHealthIssueType .options {
        display: flex;
      }

      d-expansion {
        border-width: 0;
      }

      d-section {
        margin-top: -2px;
      }

      .checklist d-checkbox {
        margin-top: -1px;
        border-top: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
        padding: 12px 0;
      }

      d-view-info {
        padding: 12px 0;
      }

      @media only screen and (max-width: 600px) {
        .indented {
          margin-left: 0;
        }
      }

      .maxWidth600 .indented {
        margin-left: 0;
      }
    `,
  ];
  @property({ type: String })
  entityType = 'issues';
  @property({ type: String })
  itemReportedTo = '';
  @property({ type: String })
  itemProcessedBy = '';
  @property({ type: Array })
  employees = [];
  @property({ type: String })
  itemEvaluatedBy = '';
  @property({ type: Boolean })
  dentist = false;
  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: Boolean })
  simplifiedIssuesChecklist = false;
  @property({ type: String })
  informationLeakagePossibilityTooltipSimplified = 'undefined';
  @property({ type: String })
  externalRecipient = '';
  @property({
    type: Boolean,
  })
  pllParticipant = false;
  @property({
    type: Array,
  })
  externalRecipients = [];

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );

    return builder.build();
  }

  _externalReports(issue) {
    const l = issue.externalIssueMessages ?? [];
    return l.map((x) => {
      return {
        created: x.timestamp + ' 12:00:00',
        senderName: '',
        recipientName: x.recipientName,
        subject: 'Ikke tilgjengelig',
        description: '<h1>Krever innlogging med HelseID</h1>',
      };
    });
  }

  _valueExists(value) {
    return value !== '';
  }

  _valueOrNoValuePhrase(value, noValuePhrase) {
    if (value === '') {
      return noValuePhrase;
    } else {
      return value;
    }
  }

  _availableEmployees(employees, currentEmployee1, currentEmployee2, currentEmployee3) {
    return employees.filter(function (employee) {
      return (
        currentEmployee1 === employee.uuid ||
        currentEmployee2 === employee.uuid ||
        currentEmployee3 === employee.uuid ||
        employee.status === 'ACTIVE'
      );
    });
  }

  _workRelatedHealthIssueTypeText(workRelatedInjury, workRelatedSickness) {
    if (workRelatedInjury) {
      if (workRelatedSickness) {
        return 'Skade og sykdom';
      }
      return 'Skade';
    }
    if (workRelatedSickness) {
      return 'Sykdom';
    }
    return 'Ikke angitt';
  }

  /*

      _personDamagePossibilityClick() {
        this.editItem.relateToPatient = false;
        this.editItem.relateToEmployees = false;
        this.editItem.relateToEquipment = false;
        this.editItem.relateToRadiation = false;
        this.editItem.workRelatedHealthIssue = false;
      }

      _relateToEmployeesClick() {
        this.editItem.workRelatedHealthIssue = false;
      }

      _workRelatedHealthIssueClick() {
        if (this.editItem.workRelatedHealthIssue) {
          this.editItem.classification = 'CONFIDENTIAL';
        }
      }

      _relateToEquipmentClick() {
        this.editItem.relateToRadiation = false;
      }
    */
  _hideEquipmentViewSection(relateToEquipment, relateToRadiation, relateToRadiationEquipmentMissing) {
    return !relateToEquipment && !relateToRadiation && !relateToRadiationEquipmentMissing;
  }

  _sendExternalReport(e) {
    if (e.target.hasAttribute('disabled')) {
      return;
    }
    const fullDescription = this.descriptionForExternal(
      this.entityView.description,
      this.entityView.eventDate,
      this.entityView.immediateMeasures,
      this.entityView.furtherMeasures,
    );
    const returnUrl = window.location.href;
    window.location.href =
      'https://avvik.trinnvis.io/?issue-id=' +
      this.entityView.uuid +
      '&public-recipient=' +
      this.externalRecipient +
      '&public-subject=' +
      encodeURI(this.entityView.name) +
      '&public-description=' +
      encodeURI(fullDescription) +
      '&return-url=' +
      returnUrl;
  }

  _dateFormat(value) {
    return value ? LocalDate.fromString(value).toStringForDisplay() : '';
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderReportView() {
    return html`
      <d-section>
        ${!this._dateFormat(this.entityView.eventDate)
          ? nothing
          : html` <d-view-text
              field="issues_eventDate"
              value="${this._dateFormat(this.entityView.eventDate)}"
            ></d-view-text>`}

        <d-view-text field="issues_reportedDate" value="${this._dateFormat(this.entityView.reportedDate)}">
        </d-view-text>

        ${!this.entityView.reportedToName
          ? nothing
          : html` <d-view-text field="issues_reportedTo" value="${this.entityView.reportedToName}"></d-view-text>`}
      </d-section>

      <d-section>
        <d-view-text field="issues_description" value="${this.entityView.description}"></d-view-text>
      </d-section>

      <d-section>
        ${!this.entityView.immediateMeasures
          ? nothing
          : html` <d-view-text field="issues_immediateMeasures" value="${this.entityView.immediateMeasures}">
            </d-view-text>`}
      </d-section>

      <d-section>
        ${!this.entityView.furtherMeasures
          ? nothing
          : html` <d-view-text field="issues_furtherMeasures" value="${this.entityView.furtherMeasures}">
            </d-view-text>`}
      </d-section>
    `;
  }

  renderViewMode() {
    return html`${super.renderViewMode()}

      <d-main-section field="issues_report"> ${this.renderReportView()} ${this.renderPllReportView()}</d-main-section>

      <!-- AVVIKSBEHANDLING -->

      <d-main-section field="issues_process">
        ${this.entityView.processed
          ? html` <div>
              <d-section>
                <d-view-text field="issues_processedDate" value="${this._dateFormat(this.entityView.processedDate)}">
                </d-view-text>

                <d-view-text field="issues_processedBy" value="${this.entityView.processedByName}"></d-view-text>
              </d-section>

              <!-- PERSONSKADE -->

              ${this.renderPersonDamage()}

              <!-- ARBEIDSRELATERT SKADE ELLER SYKDOM -->

              ${this.renderWorkRelatedHealthIssue()}

              <!-- UTSTYR -->

              ${this.renderEquipment()}

              <!-- PERSONOPPLYSNINGER -->

              ${this.renderInformationLeakage()}

              <!-- HELSE- ELLER UDYKTIGHETSERKLÆRING FOR SJØFOLK -->

              ${this.renderMaritimeDoctorView()}

              <!-- TILTAK -->

              <d-section label="Tiltak" .contentStickyTop=${this.contentStickyTop}>
                <d-conditional-bullet
                  ?condition=${!this.entityView.requiresMeasures}
                  else-field="issues_requiresMeasures"
                  field="issues_requiresMeasuresNOT"
                >
                </d-conditional-bullet>
              </d-section>

              ${this.renderOptionalMeasures()}

              <d-section>
                <d-view-text
                  field="issues_notes"
                  value="${this._valueOrNoValuePhrase(this.entityView.notes, 'Ingen')}"
                ></d-view-text>
              </d-section>
            </div>`
          : html` <div>
              <d-section>
                <d-conditional-bullet ?condition=${true} field="issues_notProcessed"></d-conditional-bullet>
              </d-section>
            </div>`}
      </d-main-section> `;
  }

  ready() {
    this._fetchRecipients();

    if (
      location.href.startsWith('https://app.trinnvis.no/account/2668') ||
      location.href.startsWith('https://app.trinnvis.no/account/971')
    ) {
      this.pllParticipant = true;
    }
  }

  _fetchRecipients() {
    fetch('https://api.trinnvis.io/external-issues/recipients/')
      .then((response) => response.text())
      .then((recipients) => {
        const list = JSON.parse(recipients);
        const options = list.map((item) => ({
          uuid: item.id,
          value: item.description,
        }));
        this.externalRecipients = options;
      });
  }

  descriptionForExternal(description, eventDate, immediateMeasures, furtherMeasures) {
    let html = '<p>' + description + '</p>';
    if (eventDate) {
      html += '<p><strong>Hendelsesdato:</strong> ' + LocalDate.fromString(eventDate).toStringForDisplay() + '</p>';
    }
    if (immediateMeasures) {
      html += '<p><strong>Strakstiltak som er iverksatt:</strong> ' + immediateMeasures + '</p>';
    }
    if (furtherMeasures) {
      html += '<p><strong>Forslag til flere tiltak:</strong> ' + furtherMeasures + '</p>';
    }
    return html;
  }

  formatAsSentTime(date) {
    if (date === undefined) {
      return '';
    }
    const localDate = LocalDate.fromString(date.substring(0, 10));
    const dateAsString = localDate.toStringForDisplayWithDayOfWeekAndYear();
    const timePartHours = date.substring(11, 13);
    const timePartMinutes = date.substring(14, 16);
    const timeAsString = 'kl. ' + timePartHours + '.' + timePartMinutes;

    return dateAsString + ' ' + timeAsString;
  }

  capitalize(text) {
    return text.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }

  async initializeEditItem() {
    this.editItem = {
      reportedTo: this.entityView.reportedTo,
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      description: this.entityView.description,
      evaluated: this.entityView.evaluated,
      evaluatedBy: this.entityView.evaluatedBy,
      evaluatedByName: this.entityView.evaluatedByName,
      evaluatedDate: this.entityView.evaluatedDate,
      evaluatedOk: this.entityView.evaluatedOk,
      eventDate: this.entityView.eventDate,
      furtherMeasures: this.entityView.furtherMeasures,
      identifiedMeasures: this.entityView.identifiedMeasures,
      immediateMeasures: this.entityView.immediateMeasures,
      implementedMeasure: this.entityView.implementedMeasure,
      informationLeakagePossibility: this.entityView.informationLeakagePossibility,
      notes: this.entityView.notes,
      pages: this.entityView.pages,
      personDamagePossibility: this.entityView.personDamagePossibility,
      processedBy: this.entityView.processedBy,
      processedByName: this.entityView.processedByName,
      processedDate: this.entityView.processedDate,
      relateToEmployees: this.entityView.relateToEmployees,
      relateToEquipment: this.entityView.relateToEquipment,
      relateToMaritimeHealthCertificate: this.entityView.relateToMaritimeHealthCertificate,
      relateToPatient: this.entityView.relateToPatient,
      relateToRadiation: this.entityView.relateToRadiation,
      relateToRadiationEquipmentMissing: this.entityView.relateToRadiationEquipmentMissing,
      reportedDate: this.entityView.reportedDate,
      reportedToName: this.entityView.reportedToName,
      requiresMeasures: this.entityView.requiresMeasures,
      type: 'issues',
      uuid: this.entityView.uuid,
      workRelatedHealthIssue: this.entityView.workRelatedHealthIssue,
      workRelatedHealthIssueDescription: this.entityView.workRelatedHealthIssueDescription,
      workRelatedHealthIssueEmployee: this.entityView.workRelatedHealthIssueEmployee,
      workRelatedHealthIssueEmployeeName: this.entityView.workRelatedHealthIssueEmployeeName,
      workRelatedInjury: this.entityView.workRelatedInjury,
      workRelatedInjuryIncidentCause: this.entityView.workRelatedInjuryIncidentCause,
      workRelatedInjuryTask: this.entityView.workRelatedInjuryTask,
      workRelatedSickness: this.entityView.workRelatedSickness,
      workRelatedSicknessCausingEnvironment: this.entityView.workRelatedSicknessCausingEnvironment,
      workRelatedSicknessCausingTask: this.entityView.workRelatedSicknessCausingTask,
      workRelatedSicknessCausingTaskLocation: this.entityView.workRelatedSicknessCausingTaskLocation,
      name: this.entityView.name,
      shallEvaluated: this.entityView.shallEvaluated,
      correction: this.entityView.correction,
      registeredBy: this.entityView.registeredBy,
      reportedBy: this.entityView.reportedBy,
      responsibleBy: this.entityView.responsibleBy,
      registeredDate: this.entityView.registeredDate,
      reportedLabourInspection: this.entityView.reportedLabourInspection,
      reportedInspectorate: this.entityView.reportedInspectorate,
      reportedSafetyManager: this.entityView.reportedSafetyManager,
      reportedCountyMedical: this.entityView.reportedCountyMedical,
      requireEvaluation: this.entityView.requireEvaluation,
      relateToPatientInstitution: this.entityView.relateToPatientInstitution,
      measuresSatisfactorily: this.entityView.measuresSatisfactorily,
    };
  }

  renderEditItem(item: IssueViewEditItem): TemplateResult<1> {
    return html`${this.entityView.currentUserIsEmployee
        ? html` <d-section>
            <d-edit-classification
              .accessControl=${item.accessControl}
              .accessControlOptions=${this.entityView.accessControlOptions}
              .classification=${item.classification}
              @value-changed=${(
                e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>,
              ) => {
                this.editItem = {
                  ...item,
                  accessControl: e.detail.accessControl,
                  classification: e.detail.classification,
                };
              }}
            ></d-edit-classification>
          </d-section>`
        : nothing}

      <d-main-section field="issues_report"> ${this.renderReportView()}</d-main-section>

      <!-- AVVIKSBEHANDLING -->

      <d-main-section field="issues_process">
        <d-section>
          <d-edit-text
            label="Navn på hendelsen eller tilstanden"
            placeholder="Dette feltet må fylles ut"
            mark-if-empty
            .value=${item.name}
            @value-changed=${(e) => (this.editItem = { ...item, name: e.detail.value })}
          ></d-edit-text>
        </d-section>

        <d-section>
          <d-select-date
            class="minWidth200"
            field="issues_processedDate"
            .value=${item.processedDate}
            @value-changed=${(e) => (this.editItem = { ...item, processedDate: e.detail.value })}
          >
          </d-select-date>

          <d-select-dropdown
            class="minWidth200"
            field="issues_processedBy"
            placeholder="Ingen"
            .options=${this.entityView.availableEmployees}
            .value=${item.processedBy}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.editItem = { ...item, processedBy: e.detail.value };
            }}
          >
          </d-select-dropdown>
        </d-section>

        <!-- SJEKKLISTE -->

        <div class="checklist">
          <d-section field="issues_checklist"></d-section>

          <!-- PERSONSKADE -->

          <d-checkbox
            wrap-label
            ?checked="${item.personDamagePossibility}"
            option-field="issues_personDamagePossibility"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              this.editItem = { ...item, personDamagePossibility: e.detail.checked };
            }}
          >
          </d-checkbox>
          <d-expansion ?opened="${item.personDamagePossibility}">
            <div class="indented">
              ${this.simplifiedIssuesChecklist
                ? nothing
                : html` <d-checkbox
                    wrap-label
                    ?checked="${item.relateToPatient}"
                    hide-option-tooltip
                    option-field="issues_relateToPatient"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, relateToPatient: e.detail.checked };
                    }}
                  >
                  </d-checkbox>`}

              <d-expansion ?opened="${item.relateToPatient}">
                <d-view-info field="issues_relateToPatient"></d-view-info>
              </d-expansion>

              <d-checkbox
                wrap-label
                ?checked="${item.relateToEmployees}"
                hide-option-tooltip
                option-field="issues_relateToEmployees"
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  this.editItem = { ...item, relateToEmployees: e.detail.checked };
                }}
              >
              </d-checkbox>
              <d-expansion ?opened="${item.relateToEmployees}">
                <d-view-info field="issues_relateToEmployees"></d-view-info>

                <div class="indented">
                  <d-checkbox
                    wrap-label
                    ?checked="${item.workRelatedHealthIssue}"
                    hide-option-tooltip
                    option-field="issues_workRelatedHealthIssue"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, workRelatedHealthIssue: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-expansion ?opened="${item.workRelatedHealthIssue}">
                    <d-view-info field="issues_workRelatedHealthIssue"></d-view-info>
                  </d-expansion>
                </div>
              </d-expansion>
            </div>
          </d-expansion>

          <!-- MEDISINSK UTSTYR -->

          ${this.simplifiedIssuesChecklist
            ? nothing
            : html` <d-checkbox
                wrap-label
                ?checked="${item.relateToEquipment}"
                hide-option-tooltip
                option-field="issues_relateToEquipment"
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  this.editItem = { ...item, relateToEquipment: e.detail.checked };
                }}
              >
              </d-checkbox>`}
          <d-expansion ?opened="${item.relateToEquipment}">
            <d-view-info field="issues_relateToEquipment"></d-view-info>
          </d-expansion>

          <!-- STRÅLEUTSTYR PÅ AVVEIE -->

          <div>
            ${this.simplifiedIssuesChecklist
              ? nothing
              : html` <d-checkbox
                  wrap-label
                  ?checked="${item.relateToRadiationEquipmentMissing}"
                  hide-option-tooltip
                  option-field="issues_relateToRadiationEquipmentMissing"
                  @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                    this.editItem = { ...item, relateToRadiationEquipmentMissing: e.detail.checked };
                  }}
                >
                </d-checkbox>`}
            <d-expansion ?opened="${item.relateToRadiationEquipmentMissing}">
              <d-view-info field="issues_relateToRadiationEquipmentMissing"></d-view-info>
            </d-expansion>
          </div>

          <!-- PERSONOPPLYSNINGER -->

          <div>
            <d-checkbox
              wrap-label
              ?checked="${item.informationLeakagePossibility}"
              hide-option-tooltip
              option-field="issues_informationLeakagePossibility"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, informationLeakagePossibility: e.detail.checked };
              }}
            >
            </d-checkbox>
            ${this.simplifiedIssuesChecklist
              ? nothing
              : html` <d-expansion ?opened="${item.informationLeakagePossibility}">
                  <d-view-info field="issues_informationLeakagePossibility"></d-view-info>
                </d-expansion>`}
            ${!this.simplifiedIssuesChecklist
              ? nothing
              : html` <d-expansion ?opened="${item.informationLeakagePossibility}">
                  <d-view-info content="${this.informationLeakagePossibilityTooltipSimplified}"></d-view-info>
                </d-expansion>`}
          </div>

          <!-- HELSE- ELLER UDYKTIGHETSERKLÆRING FOR SJØFOLK -->

          ${this.entityView.maritimeDoctor
            ? html`
                <div>
                  <d-checkbox
                    wrap-label
                    ?checked="${item.relateToMaritimeHealthCertificate}"
                    hide-option-tooltip
                    option-field="issues_relateToMaritimeHealthCertificate"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, relateToMaritimeHealthCertificate: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-expansion ?opened="${item.relateToMaritimeHealthCertificate}">
                    <d-view-info field="issues_relateToMaritimeHealthCertificate"></d-view-info>
                  </d-expansion>
                </div>
              `
            : nothing}
        </div>

        <!-- ARBEIDSRELATERT SKADE ELLER SYKDOM -->

        <d-expansion ?opened="${item.workRelatedHealthIssue}">
          <d-section label="Arbeidsrelatert skade eller sykdom" .contentStickyTop=${this.contentStickyTop}></d-section>
          <d-section>
            <d-select-date
              class="minWidth200"
              field="issues_workRelatedHealthIssueDate"
              .value=${item.eventDate}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, eventDate: e.detail.value };
              }}
            ></d-select-date>

            <div id="workRelatedHealthIssueType">
              <d-label label="Type"></d-label>
              <div class="options">
                <d-checkbox
                  wrap-label
                  option-label="Skade"
                  ?checked="${item.workRelatedInjury}"
                  @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                    this.editItem = { ...item, workRelatedInjury: e.detail.checked };
                  }}
                ></d-checkbox>
                <d-checkbox
                  wrap-label
                  option-label="Sykdom"
                  ?checked="${item.workRelatedSickness}"
                  @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                    this.editItem = { ...item, workRelatedSickness: e.detail.checked };
                  }}
                ></d-checkbox>
              </div>
            </div>
          </d-section>
          <d-section>
            <d-select-dropdown
              field="issues_workRelatedHealthIssueEmployee"
              .options="${this.entityView.availableEmployees}"
              placeholder="Velg person"
              .value=${item.workRelatedHealthIssueEmployee}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, workRelatedHealthIssueEmployee: e.detail.value };
              }}
            >
            </d-select-dropdown>
          </d-section>
          <d-section>
            <d-edit-textarea
              field="issues_workRelatedHealthIssueDescription"
              .value=${item.workRelatedHealthIssueDescription}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, workRelatedHealthIssueDescription: e.detail.value };
              }}
            >
            </d-edit-textarea>
          </d-section>

          <d-expansion ?opened="${item.workRelatedInjury}">
            <d-section>
              <d-edit-textarea
                field="issues_workRelatedInjuryTask"
                .value=${item.workRelatedInjuryTask}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, workRelatedInjuryTask: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
            <d-section>
              <d-edit-textarea
                field="issues_workRelatedInjuryIncidentCause"
                .value=${item.workRelatedInjuryIncidentCause}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, workRelatedInjuryIncidentCause: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
          </d-expansion>

          <d-expansion ?opened="${item.workRelatedSickness}">
            <d-section>
              <d-edit-textarea
                field="issues_workRelatedSicknessCausingTask"
                .value=${item.workRelatedSicknessCausingTask}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, workRelatedSicknessCausingTask: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
            <d-section>
              <d-edit-textarea
                field="issues_workRelatedSicknessCausingTaskLocation"
                .value=${item.workRelatedSicknessCausingTaskLocation}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, workRelatedSicknessCausingTaskLocation: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
            <d-section>
              <d-edit-textarea
                field="issues_workRelatedSicknessCausingEnviroment"
                .value=${item.workRelatedSicknessCausingEnvironment}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, workRelatedSicknessCausingEnvironment: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
          </d-expansion>
        </d-expansion>

        <!-- TILTAK -->

        <d-section label="Tiltak" .contentStickyTop=${this.contentStickyTop}></d-section>

        <d-section>
          <d-checkbox
            wrap-label
            .checked=${item.requiresMeasures}
            option-field="issues_requiresMeasures"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              this.editItem = { ...item, requiresMeasures: e.detail.checked };
            }}
          >
          </d-checkbox>
        </d-section>

        <d-expansion ?opened="${item.requiresMeasures}">
          <d-section>
            <d-edit-textarea
              field="issues_identifiedMeasures"
              .value=${item.identifiedMeasures}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, identifiedMeasures: e.detail.value };
              }}
            ></d-edit-textarea>
          </d-section>

          <d-section>
            <d-checkbox
              wrap-label
              .checked=${item.implementedMeasure}
              option-field="issues_implementedMeasure"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, implementedMeasure: e.detail.checked };
              }}
            >
            </d-checkbox>
          </d-section>

          <d-expansion ?opened="${item.implementedMeasure}">
            <d-section>
              <d-checkbox
                wrap-label
                .checked=${item.evaluated}
                option-field="issues_evaluated"
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  this.editItem = { ...item, evaluated: e.detail.checked };
                }}
              >
              </d-checkbox>
            </d-section>

            <d-expansion ?opened="${item.evaluated}">
              <d-section>
                <d-select-date
                  class="minWidth200"
                  field="issues_evaluatedDate"
                  .value=${item.evaluatedDate}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    this.editItem = { ...item, evaluatedDate: e.detail.value };
                  }}
                >
                </d-select-date>

                <d-select-dropdown
                  class="minWidth200"
                  field="issues_evaluatedBy"
                  .options=${this.entityView.availableEmployees}
                  placeholder="Ingen"
                  .value=${item.evaluatedBy}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    this.editItem = { ...item, evaluatedBy: e.detail.value };
                  }}
                >
                </d-select-dropdown>
              </d-section>

              <d-section>
                <d-checkbox
                  wrap-label
                  .checked=${item.evaluatedOk}
                  option-field="issues_evaluatedOk"
                  @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                    this.editItem = { ...item, evaluatedOk: e.detail.checked };
                  }}
                >
                </d-checkbox>
              </d-section>
            </d-expansion>
          </d-expansion>
        </d-expansion>

        <d-section>
          <d-edit-textarea
            field="issues_notes"
            .value="${item.notes}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.editItem = { ...item, notes: e.detail.value };
            }}
          ></d-edit-textarea>
        </d-section>

        <d-section>
          <d-select-tag
            label="Vis på temaside"
            not-deselectable
            lock="281"
            .options=${this.entityView.availablePages}
            .value=${item.pages}
            @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
              this.editItem = { ...item, pages: e.detail.value };
            }}
          ></d-select-tag>
        </d-section>
      </d-main-section>`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private renderPersonDamage() {
    return this.entityView.personDamagePossibility
      ? html` <d-section label="Personskade" .contentStickyTop=${this.contentStickyTop}>
          <div>
            <d-conditional-bullet
              ?condition=${this.entityView.personDamagePossibility}
              field="issues_personDamagePossibility"
              hide-tooltip
            >
            </d-conditional-bullet>
            <d-conditional-bullet
              ?condition=${this.entityView.relateToPatient}
              field="issues_relateToPatient"
              hide-tooltip
            >
            </d-conditional-bullet>
            <d-conditional-bullet
              ?condition=${this.entityView.relateToEmployees}
              field="issues_relateToEmployees"
              hide-tooltip
            >
            </d-conditional-bullet>
            ${this.renderWorkRelatedHealthIssueView()}
          </div>
        </d-section>`
      : nothing;
  }

  private renderWorkRelatedHealthIssueView() {
    return !this.entityView.relateToEmployees
      ? nothing
      : html` <d-conditional-bullet
          ?condition=${this.entityView.workRelatedHealthIssue}
          field="issues_workRelatedHealthIssue"
          hide-tooltip
        >
        </d-conditional-bullet>`;
  }

  private renderWorkRelatedHealthIssue() {
    return this.entityView.workRelatedHealthIssue
      ? html` <div>
          <d-section label="Arbeidsrelatert skade eller sykdom" .contentStickyTop=${this.contentStickyTop}></d-section>
          <d-section class="noTopBorder">
            <d-view-text
              label="Type"
              value="${this._workRelatedHealthIssueTypeText(
                this.entityView.workRelatedInjury,
                this.entityView.workRelatedSickness,
              )}"
            ></d-view-text>
            <d-view-text
              field="issues_workRelatedHealthIssueEmployee"
              value="${this.entityView.workRelatedHealthIssueEmployeeName}"
              hide-tooltip
            ></d-view-text>
          </d-section>
          <d-section>
            <d-view-text
              field="issues_workRelatedHealthIssueDescription"
              value="${this.entityView.workRelatedHealthIssueDescription}"
              hide-tooltip
            ></d-view-text>
          </d-section>
          ${this.renderWorkRelatedInjury()} ${this.renderWorkRelatedSickness()}
        </div>`
      : nothing;
  }

  private renderWorkRelatedSickness() {
    return !this.entityView.workRelatedSickness
      ? nothing
      : html` <div>
          <d-section>
            <d-view-text
              field="issues_workRelatedSicknessCausingTask"
              value="${this.entityView.workRelatedSicknessCausingTask}"
              hide-tooltip
            ></d-view-text>
          </d-section>
          <d-section>
            <d-view-text
              field="issues_workRelatedSicknessCausingTaskLocation"
              value="${this.entityView.workRelatedSicknessCausingTaskLocation}"
              hide-tooltip
            ></d-view-text>
          </d-section>
          <d-section>
            <d-view-text
              field="issues_workRelatedSicknessCausingEnviroment"
              value="${this.entityView.workRelatedSicknessCausingEnvironment}"
              hide-tooltip
            ></d-view-text>
          </d-section>
        </div>`;
  }

  private renderWorkRelatedInjury() {
    return !this.entityView.workRelatedInjury
      ? nothing
      : html` <div>
          <d-section>
            <d-view-text
              field="issues_workRelatedInjuryTask"
              value="${this.entityView.workRelatedInjuryTask}"
              hide-tooltip
            ></d-view-text>
          </d-section>
          <d-section>
            <d-view-text
              field="issues_workRelatedInjuryIncidentCause"
              value="${this.entityView.workRelatedInjuryIncidentCause}"
              hide-tooltip
            ></d-view-text>
          </d-section>
        </div>`;
  }

  private renderInformationLeakage() {
    return !this.entityView.informationLeakagePossibility
      ? nothing
      : html` <d-section label="Personopplysninger" .contentStickyTop=${this.contentStickyTop}>
          <div>
            <d-conditional-bullet
              ?condition=${this.entityView.informationLeakagePossibility}
              field="issues_informationLeakagePossibility"
              hide-tooltip
            >
            </d-conditional-bullet>
          </div>
        </d-section>`;
  }

  private renderEquipment() {
    return this._hideEquipmentViewSection(
      this.entityView.relateToEquipment,
      this.entityView.relateToRadiation,
      this.entityView.relateToRadiationEquipmentMissing,
    )
      ? nothing
      : html` <d-section label="Utstyr" .contentStickyTop=${this.contentStickyTop}>
          <div>
            ${this.renderRelateToEquipmentView()} ${this.renderRelateToRadiationView()}
            <d-conditional-bullet
              ?condition=${this.entityView.relateToRadiationEquipmentMissing}
              field="issues_relateToRadiationEquipmentMissing"
              hide-tooltip
            >
            </d-conditional-bullet>
          </div>
        </d-section>`;
  }

  private renderRelateToRadiationView() {
    return !this.entityView.relateToRadiation
      ? nothing
      : html` <d-conditional-bullet
          ?condition=${this.entityView.relateToRadiation}
          field="issues_relateToRadiation"
          hide-tooltip
        >
        </d-conditional-bullet>`;
  }

  private renderRelateToEquipmentView() {
    return !this.entityView.relateToEquipment
      ? nothing
      : html` <d-conditional-bullet
          ?condition=${this.entityView.relateToEquipment}
          field="issues_relateToEquipment"
          hide-tooltip
        >
        </d-conditional-bullet>`;
  }

  private renderMaritimeDoctorView() {
    if (this.entityView.maritimeDoctor && this.entityView.relateToMaritimeHealthCertificate) {
      return html`
        <d-section label="Sjømannslegetjeneste" .contentStickyTop=${this.contentStickyTop}>
          <div>
            <d-conditional-bullet
              ?condition=${this.entityView.relateToMaritimeHealthCertificate}
              field="issues_relateToMaritimeHealthCertificate"
              hide-tooltip
            >
            </d-conditional-bullet>
          </div>
        </d-section>
      `;
    }
    return nothing;
  }

  private renderOptionalMeasures() {
    return this.entityView.requiresMeasures
      ? html` <div>
          <d-section>
            <d-view-text
              field="issues_identifiedMeasures"
              value="${this._valueOrNoValuePhrase(this.entityView.identifiedMeasures, 'Ingen')}"
            >
            </d-view-text>
          </d-section>

          ${this.renderIdentifiedMeasures()}
        </div>`
      : nothing;
  }

  private renderIdentifiedMeasures() {
    if (this._valueExists(this.entityView.identifiedMeasures)) {
      return html` <div>
        <d-section>
          <d-conditional-bullet
            ?condition=${this.entityView.implementedMeasure}
            else-field="issues_implementedMeasureNOT"
            field="issues_implementedMeasure"
          >
          </d-conditional-bullet>
        </d-section>

        ${this.entityView.implementedMeasure
          ? html` <div>
              <d-section>
                <d-conditional-bullet
                  ?condition=${this.entityView.evaluated}
                  else-field="issues_evaluatedNOT"
                  field="issues_evaluated"
                >
                </d-conditional-bullet>
              </d-section>

              ${this.renderEvaluated()}
            </div>`
          : nothing}
      </div>`;
    } else {
      return nothing;
    }
  }

  private renderEvaluated() {
    return this.entityView.evaluated
      ? html` <d-section>
            <d-view-text
              field="issues_evaluatedDate"
              value="${this._dateFormat(this.entityView.evaluatedDate)}"
            ></d-view-text>
            <d-view-text field="issues_evaluatedBy" value="${this.entityView.evaluatedByName}"> </d-view-text>
          </d-section>
          <d-section>
            <d-conditional-bullet
              ?condition=${this.entityView.evaluatedOk}
              else-field="issues_evaluatedOkNOT"
              field="issues_evaluatedOk"
            >
            </d-conditional-bullet>
          </d-section>`
      : nothing;
  }

  private renderPllReportView() {
    return this.pllParticipant
      ? html` <div class="pllReportField">
          <d-label label="Ekstern melding (under utprøving)"></d-label>
          <div class="externalReportList">
            ${this._externalReports(this.entityView).map(
              (report) =>
                html` <d-external-report-item
                  created-display="${this._dateFormat(report.created)}"
                  created-display-full="${this.formatAsSentTime(report.created)}"
                  recipient-name="${report.recipientName}"
                  sender-name="${this.capitalize(report.senderName)}"
                  subject="${report.subject}"
                  description="${report.description}"
                ></d-external-report-item>`,
            )}
          </div>
          <div class="pllForm">
            <d-select-dropdown
              placeholder="Velg mottaker"
              label=""
              .options="${this.externalRecipients}"
              value="${this.externalRecipient}"
            ></d-select-dropdown>
            <button @click=${(e) => this._sendExternalReport(e)} ?disabled=${!this.externalRecipient}>Send</button>
          </div>
        </div>`
      : nothing;
  }

  private _itemNameForEmployee(reportedTo: string, employees: SelectTagOption[]) {
    return employees.find((e) => e.value === reportedTo)?.text ?? '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-issue-view': DIssueView;
  }
}
