/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MeetingUpdateMessage
 */
export interface MeetingUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof MeetingUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingUpdateMessage
   */
  procedures?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingUpdateMessage
   */
  functionUuid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingUpdateMessage
   */
  employeeParticipants?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof MeetingUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MeetingUpdateMessage
   */
  classification?: MeetingUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingUpdateMessage
   */
  accessControl?: Array<string>;
}

/**
 * @export
 */
export const MeetingUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type MeetingUpdateMessageClassificationEnum =
  (typeof MeetingUpdateMessageClassificationEnum)[keyof typeof MeetingUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the MeetingUpdateMessage interface.
 */
export function instanceOfMeetingUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function MeetingUpdateMessageFromJSON(json: any): MeetingUpdateMessage {
  return MeetingUpdateMessageFromJSONTyped(json, false);
}

export function MeetingUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    procedures: !exists(json, 'procedures') ? undefined : json['procedures'],
    functionUuid: !exists(json, 'functionUuid') ? undefined : json['functionUuid'],
    employeeParticipants: !exists(json, 'employeeParticipants') ? undefined : json['employeeParticipants'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
  };
}

export function MeetingUpdateMessageToJSON(value?: MeetingUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    procedures: value.procedures,
    functionUuid: value.functionUuid,
    employeeParticipants: value.employeeParticipants,
    pages: value.pages,
    classification: value.classification,
    accessControl: value.accessControl,
  };
}
