/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContactPersonUpdateMessage
 */
export interface ContactPersonUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  notes: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  mobilePhone?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  accessLevel: ContactPersonUpdateMessageAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof ContactPersonUpdateMessage
   */
  accessExpires?: string;
}

/**
 * @export
 */
export const ContactPersonUpdateMessageAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type ContactPersonUpdateMessageAccessLevelEnum =
  (typeof ContactPersonUpdateMessageAccessLevelEnum)[keyof typeof ContactPersonUpdateMessageAccessLevelEnum];

/**
 * Check if a given object implements the ContactPersonUpdateMessage interface.
 */
export function instanceOfContactPersonUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'accessLevel' in value;

  return isInstance;
}

export function ContactPersonUpdateMessageFromJSON(json: any): ContactPersonUpdateMessage {
  return ContactPersonUpdateMessageFromJSONTyped(json, false);
}

export function ContactPersonUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContactPersonUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notes: json['notes'],
    email: !exists(json, 'email') ? undefined : json['email'],
    lastName: json['lastName'],
    firstName: json['firstName'],
    telephone: !exists(json, 'telephone') ? undefined : json['telephone'],
    mobilePhone: !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
    accessLevel: json['accessLevel'],
    accessExpires: !exists(json, 'accessExpires') ? undefined : json['accessExpires'],
  };
}

export function ContactPersonUpdateMessageToJSON(value?: ContactPersonUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notes: value.notes,
    email: value.email,
    lastName: value.lastName,
    firstName: value.firstName,
    telephone: value.telephone,
    mobilePhone: value.mobilePhone,
    accessLevel: value.accessLevel,
    accessExpires: value.accessExpires === undefined ? undefined : value.accessExpires,
  };
}
