import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { FormViewItem } from '../../library/components/d-form-view.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, isEmptyHtmlContent } from 'src/utilities/text';
import {
  MeetingMessageDialog,
  MeetingMessageDialogInput,
  MeetingMessageDialogResult,
  MessageForSend,
} from 'src/content/meeting-occurrences/meeting-message-dialog';
import { MessageRecipient } from 'src/content/event-occurrences/d-event-occurrence-view';

export interface MeetingDataForReport {
  uuid: string;
  name: string;
  messageRecipients: MessageRecipient[];
}

export interface ReportView extends AbstractEntityViewWithRevisions<ReportViewEditItem>, UpdateSectionItem {
  hasRelatedMeetingEvent: boolean;
  pages: string[];
  employee: string;
  availablePersons: SelectTagOption[];
  writtenByName: string;
  reportDate: string;
  type: 'reports';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
  availablePages: SelectTagOption[];
  isMeetingReport: boolean;
  meetingData?: MeetingDataForReport;
  meetingOccurrenceUuid?: string;
  meetingOccurrenceDisplay?: string;
  meetingOccurrenceParticipants?: string;
  meetingOccurrenceAgenda?: string;
  currentEmployeeUuid: string;
  secondaryLabel?: string;
}

export interface ReportViewEditItem {
  reportDate: string;
  pages: string[];
  employee: string;
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
}

/**
 *
 * FIX Meeting header with employee and time?
 */
@customElement('d-report-view')
export class DReportView extends EntityContent<ReportView, ReportViewEditItem> {
  @property({ type: Boolean })
  isEmployee = false;
  @property({ type: Object })
  organization: any = {};
  @property({ type: Array })
  contacts = [];
  @property({ type: String })
  currentEmployeeUuid = '';
  @property({ type: String })
  employeeInterviewUuid = '';
  @property({ type: Object })
  relatedMeetingEvent = {};
  @property({ type: String })
  meetingEventHref = '';
  @property({ type: String })
  meetingPresent = '';
  @property({ type: String })
  meetingAgenda = '';

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addDate('reports_reportDate', this.entityView.reportDate);
    builder.addText('reports_employee', this.entityView.writtenByName, 'm', true);
    builder.addHtmlContentBigLabel('reports_content', this.entityView.content);
    return builder.build();
  }

  protected get currentItemSecondaryLabel() {
    return this.entityView.secondaryLabel || '';
  }

  protected get currentItemSublabel() {
    if (this.entityView.isMeetingReport) {
      return this.entityView.meetingOccurrenceDisplay ?? '';
    }
    return '';
  }

  private get participantsAndAgenda() {
    let result = '';
    if (this.entityView.meetingOccurrenceParticipants !== undefined) {
      result = this.entityView.meetingOccurrenceParticipants;
    }
    if (this.entityView.meetingOccurrenceAgenda !== undefined) {
      result += this.entityView.meetingOccurrenceAgenda;
    }
    return result;
  }

  async initializeEditItem() {
    this.editItem = {
      employee: this.entityView.employee ?? this.entityView.currentEmployeeUuid,
      pages: this.entityView.pages,
      reportDate: this.entityView.reportDate ?? this.entityView.today.toString(),
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: this.entityView.content,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: ReportViewEditItem): TemplateResult<1> {
    return html` ${this.entityView.isMeetingReport
        ? nothing
        : html`<d-section>
            <d-edit-text
              field="reports_name"
              .autofocus=${this.entityView.isNew}
              .selectOnFocus=${this.entityView.isNew}
              placeholder="Dette feltet må fylles ut"
              mark-if-empty
              .value="${item.name}"
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, name: e.detail.value };
              }}
            ></d-edit-text>
          </d-section>`}

      <d-section>
        <d-select-date
          field="reports_reportDate"
          value="${item.reportDate}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, reportDate: e.detail.value };
          }}
        ></d-select-date>

        <d-select-dropdown
          field="reports_employee"
          .options=${this.entityView.availablePersons}
          placeholder="Velg person"
          .value=${item.employee}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, employee: e.detail.value };
          }}
        >
        </d-select-dropdown>
      </d-section>

      ${this.isEmployee
        ? html` <d-section>
            <d-edit-classification
              .accessControl=${item.accessControl}
              .accessControlOptions=${this.entityView.accessControlOptions}
              .classification=${item.classification}
              @value-changed=${(
                e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>,
              ) => {
                this.editItem = {
                  ...item,
                  accessControl: e.detail.accessControl,
                  classification: e.detail.classification,
                };
              }}
            ></d-edit-classification>
          </d-section>`
        : nothing}

      <d-edit-html
        field="reports_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        label-action-plain
        .labelAction=${this.reportAction(item)}
        @label-action=${() => this.insertContent(item)}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      ></d-edit-html>

      <d-section>
        <d-select-tag
          field="${this.selectPagesField()}"
          not-deselectable
          .options=${this.entityView.availablePages}
          .value=${item.pages}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, pages: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>`;
  }

  protected onShareContent() {
    if (this.entityView.isMeetingReport) {
      return this.shareMeetingReport();
    } else {
      super.onShareContent();
    }
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private async shareMeetingReport() {
    const meetingData: MeetingDataForReport | undefined = this.entityView.meetingData;
    if (meetingData) {
      const input: MeetingMessageDialogInput = {
        type: 'report',
        name: meetingData.name,
        preventIncludeContent: this.entityView.classification && this.entityView.classification !== 'NONE',
        isEmployeeInterview: meetingData.name.toLowerCase().includes('medarbeidersamtale'),
        recipients: meetingData.messageRecipients,
      };
      const result: MeetingMessageDialogResult = await MeetingMessageDialog.open(input);
      if (result.action === 'send') {
        const recipientsWithAccess = meetingData.messageRecipients.filter((p) => {
          return p.accessLevel !== 'NONE';
        });
        if (recipientsWithAccess.length > 0) {
          const messageData = {
            occurrenceUuid: meetingData.uuid,
            message: result.message,
            includeContent: result.includeContent,
          };
          this.dispatchEvent(
            new CustomEvent<MessageForSend>('send-meeting-report', {
              bubbles: true,
              composed: true,
              detail: messageData,
            }),
          );
        }
      }
    }
  }

  private selectPagesField() {
    if (this.entityView.isMeetingReport) {
      return 'generalFields_showAlsoOnPage';
    }
    return 'generalFields_showOnPage';
  }

  private insertContent(item: ReportViewEditItem) {
    if (this.participantsAndAgenda) {
      this.editItem = {
        ...item,
        content: this.participantsAndAgenda,
      };
    }
  }

  private reportAction(item: ReportViewEditItem) {
    if (
      !isEmptyHtmlContent(this.entityView.meetingOccurrenceAgenda) &&
      (isEmptyHtmlContent(item.content) || item.content === this.entityView.meetingOccurrenceParticipants)
    ) {
      return 'Sett inn agenda';
    }
    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-report-view': DReportView;
  }
}
