import type { State } from '../types.js';
import { _formatDate, toList } from './utilities.js';
import type { IssueViewModel } from '../api';

export function issuesNotDeleted(state: State): IssueViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.issuesById).filter((e) => !e.deleted);
}

export function issuesDeleted(state: State): IssueViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.issuesById).filter((e) => e.deleted);
}

export function _issueIsProcessed(issue: IssueViewModel): boolean {
  if (issue.evaluatedDate && issue.evaluatedBy) {
    return true;
  }
  if (issue.evaluated) {
    // skal evalueres
    return false;
  }
  if (issue.implementedMeasure) {
    return true;
  }
  if (issue.requiresMeasures) {
    return false;
  }
  return !!(issue.processedDate && issue.processedBy);
}

export interface IssueViewModelExtended extends IssueViewModel {
  reportedDateFormatted: string;
  status: string;
}

export function toIssueViewModel(item: IssueViewModel): IssueViewModelExtended {
  return Object.assign({}, item, {
    reportedDateFormatted: _formatDate(item.reportedDate?.toString()),
    status: _issueIsProcessed(item) ? 'ferdigbehandlet' : 'aktiv',
  });
}
