import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import printJS from 'print-js';
import { DPopup } from 'src/library/components/d-popup.js';
import '../../library/components/pdf-viewer/pdf-viewer.js';
import type { PdfViewer } from 'src/library/components/pdf-viewer/pdf-viewer.js';
import '../../library/editors/elements/d-edit-text.js';
import type { DEditText } from 'src/library/editors/elements/d-edit-text.js';
import '../../library/elements/d-section.js';
import type { DialogConfirmResult } from 'src/content/employees/reassign-functions-dialog.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { displayName } from 'src/utilities/text';

export interface FileViewerDocument {
  name: string;
  url: string;

  urlForDownload: string;
  entityType: string;
  uuid: string;
  attachmentId: string;
}

export interface FileViewerInput {
  document: FileViewerDocument;
  writeAccess: boolean;
}

export enum Direction {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export type FileViewerResult = DialogConfirmResult;

/**
 *
 * USAGE:
 *    d-list-section-attachment
 *
 */
@customElement('file-viewer-dialog')
export class FileViewerDialog extends BaseDialog<FileViewerInput, FileViewerResult> {
  static readonly styles = [
    ...DPopup.styles,
    css`
      :host {
        display: block;
        height: 90vh;
        width: 90vw;
      }

      .header {
        margin: 16px 20px 0 20px;
        padding: 0 0 12px;
        border-bottom: 1px solid hsl(1, 0%, 85%);
      }

      pdf-viewer {
        width: 90vw;
        max-width: 1024px;
        min-height: 90vh;
      }

      .footerContent {
        border-top: 1px solid var(--borderColor);
        padding: 12px 0;
        display: flex;
        justify-content: flex-end;
      }

      .flexbox-split {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -4px;
      }

      .navigation button {
        width: 24px;
        height: 24px;
        border: none;
        margin: 0;
        -webkit-appearance: none;
        border-radius: 0;
        opacity: 0.2;
        cursor: pointer;
        background: url('/images/attachment-navigation.svg') 0 0 no-repeat;
        background-size: 96px 24px;
      }

      .navigation button.goPrevious {
        background-position: -24px 0;
      }

      .navigation button.goNext {
        background-position: -48px 0;
      }

      .navigation button.goLast {
        background-position: -72px 0;
      }

      .navigation button:hover {
        opacity: 0.4;
      }
    `,
  ];
  @property({ type: Object })
  document?: FileViewerDocument;
  @property({ type: Boolean })
  editing = false;
  @property({ type: Boolean })
  writeAccess = false;
  /**
   * Used to jump pages
   */
  @property({ type: String })
  jumpTo: Direction = Direction.NEXT;
  @query('pdf-viewer')
  pdfViewer!: PdfViewer;
  @query('d-edit-text')
  editText!: DEditText;
  width = 1000;

  protected get calculatedHeaderActions(): ActionInput[] {
    if (!this.editing) {
      return [
        { name: 'Skriv ut', action: 'print' },
        { name: 'Last ned', action: 'download' },
        { name: 'Rediger', action: 'activateEdit' },
        { name: 'Lukk', action: 'close' },
      ];
    } else {
      const p = this.writeAccess ? [{ name: 'Slett', action: 'deleteAttachment' }] : [];

      return [...p, { name: 'Avbryt', action: 'cancelEdit' }, { name: 'Ferdig', action: 'confirmEdit' }];
    }
  }

  downloadURI(uri = '', name = '') {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    this.appendChild(link);
    link.click();
    this.removeChild(link);
    link.remove();
  }

  _activateEdit() {
    if (this.document) {
      this.editing = true;
    }
  }

  _cancelEdit() {
    this.editing = false;
  }

  _confirmEdit() {
    if (this.editText.value && this.document?.name) {
      this.document = { ...this.document, name: this.editText.value };
      this.dispatchEvent(
        new CustomEvent('rename-attachment', {
          composed: true,
          bubbles: true,
          detail: {
            document: this.document,
          },
        }),
      );
      this.title = this.editText.value;
    }
    this._cancelEdit();
  }

  onDeleteAttachment() {
    if (this.document) {
      this.dispatchEvent(
        new CustomEvent<{ document: FileViewerDocument }>('delete-attachment', {
          composed: true,
          bubbles: true,
          detail: { document: this.document },
        }),
      );
    }
    this.close();
  }

  _goFirst() {
    this.pdfViewer?.jumpToPage(Direction.FIRST);
  }

  _goPrevious() {
    this.pdfViewer?.jumpToPage(Direction.PREVIOUS);
  }

  _goNext() {
    this.pdfViewer?.jumpToPage(Direction.NEXT);
  }

  _goLast() {
    this.pdfViewer?.jumpToPage(Direction.LAST);
  }

  _print() {
    if (this.document?.url) {
      printJS({ printable: this.document.url, type: 'pdf' });
    }
  }

  _download() {
    this.downloadURI(this.document?.urlForDownload, this.document?.name);
  }
  protected onDispatchAction(disabled: boolean, action: string) {
    if (action === 'print') {
      this._print();
      return;
    }
    if (action === 'download') {
      this._download();
      return;
    }
    if (action === 'activateEdit') {
      this._activateEdit();
      return;
    }
    if (action === 'confirmEdit') {
      this._confirmEdit();
      return;
    }
    if (action === 'cancelEdit') {
      this._cancelEdit();
      return;
    }
    if (action === 'deleteAttachment') {
      this.onDeleteAttachment();
    }
    super.onDispatchAction(disabled, action);
  }

  protected renderBody(): TemplateResult<1> {
    return html`
      ${this.editing
        ? html`
            <d-section>
              <d-edit-text label="Navn" value="${this.document?.name ?? ''}"></d-edit-text>
            </d-section>
          `
        : nothing}
      <d-section topless>
        <pdf-viewer .src=${this.document?.url ?? ''} .jumpTo=${this.jumpTo}></pdf-viewer>
      </d-section>
    `;
  }

  protected renderFooter(): TemplateResult<1> {
    return html`
      <div class="footerContent">
        <div class="flexbox-split">
          <div class="navigation">
            <button class="goFirst" ?disabled="${!this.document}" @click="${this._goFirst}"></button>
            <button class="goPrevious" ?disabled="${!this.document}" @click="${this._goPrevious}"></button>
            <button class="goNext" ?disabled="${!this.document}" @click="${this._goNext}"></button>
            <button class="goLast" ?disabled="${!this.document}" @click="${this._goLast}"></button>
          </div>
        </div>
      </div>
    `;
  }

  protected fetchResult(_detail: string | undefined): DialogConfirmResult {
    return {
      action: 'confirm',
    };
  }

  protected initializeDialog(input: FileViewerInput) {
    this.document = input.document;
    this.title = displayName(this.document?.name);
    this.writeAccess = input.writeAccess;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'file-viewer-dialog': FileViewerDialog;
  }
}
