import _, { sortBy } from 'lodash';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { RiskLevels } from 'src/library/lists/utilities.js';
import type { ItemGroup } from 'src/pages/organization-page/organization-page-view-model.js';
import type { OverviewPageViewModel, OverviewSection } from 'src/pages/overview-page/overview-page-view-model.js';
import type { IssueViewModel, PageViewModel } from 'src/store/api';
import { commonFieldsByCode } from 'src/store/fields.js';
import type { EmployeeViewModelWithName, OrganizationState, State, Tutorial } from 'src/store/types.js';
import { sortedByLabel } from 'src/store/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import tutorialsList from '../../store/data/tutorials.json';
import { IssueResolver } from 'src/models/resolvers/issue-resolver.js';
import { BaseTemplateItem, calculateUpdateStatus } from '../resolvers/calculate-update-status.js';
import { currentEmployeeUuid, employeesNotDeleted } from 'src/store/selectors/organization-employees.js';
import { writeAccess } from 'src/store/selectors/user.js';
import {
  functionsWithTasksForCurrentPage,
  startTasksForCurrentPage,
  tasksForCurrentPage,
} from 'src/store/selectors/organization-functions.js';
import { getOrganization, getOrganizationId, medicalDoctor, now } from 'src/store/selectors/organization.js';
import {
  contractsNotDeletedWithParties,
  documentsNotDeleted,
  guidelinesNotDeleted,
  reportsNotDeleted,
} from 'src/store/selectors/organization-documents.js';
import { partnersNotDeleted } from 'src/store/selectors/organization-partners.js';
import { issuesNotDeleted, IssueViewModelExtended, toIssueViewModel } from 'src/store/selectors/organization-issues.js';
import { riskAssessmentsNotDeleted } from 'src/store/selectors/organization-risk-assessments.js';
import { groupBy } from 'src/store/selectors/utilities.js';
import { riskLevelFromRisk } from 'src/models/risk.js';
import { entityNameForLists } from 'src/utilities/text';

interface BaseListItem extends BaseTemplateItem {
  name?: string;
  pages?: Array<number>;
  nameWithParties?: string;
  partners?: Array<string>;
  employees?: Array<string>;
  tasks?: Array<string>;
  reportedDate?: string;
  reportDate?: string;
  classification?: string;
  accessControl?: Array<string>;
  taskCount?: number;
  consequence?: number;
  probability?: number;
  isNew: boolean;
  draftName?: string;
}

function filterByPage<T extends BaseListItem>(value: Array<T>, pageId: number) {
  return sortBy(
    value.filter((item) => {
      if (!item.pages) {
        return false;
      }
      return item.pages.includes(pageId);
    }),
    [(item) => item.name],
  ).map((item) => {
    let riskAssessmentRisk: RiskLevels = undefined;
    if (item.consequence && item.probability) {
      riskAssessmentRisk = riskLevelFromRisk(item.consequence * item.probability);
    }
    return {
      name: item.nameWithParties || item.name,
      uuid: item.uuid,
      templateDeleted: item.templateDeleted,
      hasTemplateUpdate: item.hasTemplateUpdate,
      templateUpdated: item.templateUpdated,
      contentLastModified: item.contentLastModified,
      templateDeclined: item.templateDeclined,
      partners: item.partners ?? [],
      employees: item.employees ?? [],
      tasks: item.tasks ?? [],
      reportDate: item.reportDate,
      classification: item.classification ?? 'NONE',
      accessControl: item.accessControl ?? [],
      taskCount: item.taskCount,
      riskAssessmentRisk: riskAssessmentRisk,
      hasDraft: item.hasDraft,
      isNew: item.isNew,
      draftName: item.draftName ?? '',
    };
  });
}

function addListIfNotEmpty<T extends BaseListItem>(
  lists: OverviewSection[],
  type: string,
  source: Array<T>,
  pageId: number,
  hrefPrefix: string,
  currentEmployeeUuid: string | undefined,
) {
  let filtered = filterByPage(source, pageId);
  if (filtered.length > 0) {
    if (type === 'reports') {
      filtered = sortBy(filtered, ['name', 'reportDate']);
    }
    const items: ListSectionItemInput[] = filtered.map((g) => {
      let label = entityNameForLists(g.name, g.draftName, g.isNew);
      if (type === 'reports' && g.reportDate) {
        label += ' ' + LocalDate.fromString(g.reportDate).toStringForDisplay();
      }
      let href = hrefPrefix + type + '/' + g.uuid;
      if (g.isNew || !g.name) {
        href += '?edit';
      }
      return {
        locked: g.classification !== undefined && g.classification !== 'NONE',
        accessible: g.classification === 'NONE' || (g.accessControl ?? []).includes(currentEmployeeUuid ?? ''),
        updateStatus: type === 'partners' ? 'none' : calculateUpdateStatus(g, LocalDate.now()),
        hasDraft: g.hasDraft,
        label,
        secondaryLabel: '',
        href,
        riskAssessmentRisk: g.riskAssessmentRisk,
      };
    });
    lists.push({
      type: 'list',
      icon: type,
      field: 'organization_' + type,
      items: items,
    });
  }
}

function sortedByDate(list: IssueViewModel[]) {
  return _sortedByDate1(list, false);
}

function _sortedByDate1(list: IssueViewModel[], reverse: boolean) {
  const r = reverse ? 'desc' : 'asc';
  return _.orderBy(list, [(item) => item.reportedDate, (item) => item.name], [r, r]);
}

/**
 *
 * @param value
 * @param pageId Use pageId 0 for all issues
 */
function filterIssuesByPage(value: IssueViewModel[], pageId: number) {
  const list = value.filter(function (item) {
    if (!item.pages) {
      return false;
    }
    return item.pages.includes(pageId) || pageId === 0;
  });

  return sortedByDate(list);
}

function issueItems(issues: IssueViewModelExtended[], pageId: number): IssueViewModelExtended[] {
  return filterIssuesByPage(issues, pageId).map((i) => {
    const issueResolver = new IssueResolver(i);
    return {
      ...i,
      reportedDateFormatted: _formatDate(i.reportedDate),
      status: issueResolver.processed ? 'ferdigbehandlet' : 'aktiv',
    };
  });
}

function _workRelatedHealthIssues(
  issues: IssueViewModelExtended[],
  currentEmployeeUuid: string | undefined,
  employees: EmployeeViewModelWithName[],
): IssueViewModelExtended[] {
  return issueItems(issues, 0)
    .filter((item) => item.workRelatedHealthIssue)
    .map((issue) => {
      let displayName = '';

      if (
        issue.classification &&
        issue.classification !== 'NONE' &&
        issue.accessControl &&
        currentEmployeeUuid !== undefined &&
        issue.accessControl.includes(currentEmployeeUuid)
      ) {
        displayName = issue.name ?? '';
      } else {
        const workRelatedHealthIssueEmployees = employees.filter(
          (employee) => employee.uuid === issue.workRelatedHealthIssueEmployee,
        );
        if (workRelatedHealthIssueEmployees.length) {
          displayName = workRelatedHealthIssueEmployees[0].name;
        } else {
          displayName = 'Navn ikke angitt';
        }
      }

      return {
        ...issue,
        displayName: displayName,
      };
    });
}

function _issues(issues: IssueViewModelExtended[], pageId: number) {
  return issueItems(issues, pageId);
}

function _otherHmsIssues(issues: IssueViewModelExtended[]) {
  return issueItems(issues, 63).filter((item) => !item.workRelatedHealthIssue);
}

function _formatDate(value: string | undefined) {
  if (value) {
    return LocalDate.fromString(value).toStringForDisplay();
  }
  return 'Uten dato';
}

function secondaryLabelForTutorial(tutorial: Tutorial) {
  const totalCountAssigned = tutorial.participants.filter((p) => p.assigned).length;
  const totalCountAssignedAndCompleted = tutorial.participants.filter((p) => p.assigned && p.completed).length;
  return totalCountAssigned === 0
    ? ''
    : '' + totalCountAssignedAndCompleted + ' av ' + totalCountAssigned + ' har gjennomført';
}

function tutorialsAsListItems(organization: OrganizationState, hrefPrefix: string): ListSectionItemInput[] {
  return tutorialsList.map((t) => {
    const tutorial: Tutorial = organization['tutorial_' + t.id];
    return {
      label: t.title,
      secondaryLabel: t.guide ? '' : secondaryLabelForTutorial(tutorial),
      locked: false,
      accessible: true,
      updateStatus: 'none',
      href: hrefPrefix + 'tutorials/' + t.id,
    };
  });
}

function addTutorialsList(organization: OrganizationState, lists: OverviewSection[], hrefPrefix: string) {
  lists.push({
    type: 'list',
    icon: 'competencies',
    label: 'Kurs og veiledere',
    items: sortBy(tutorialsAsListItems(organization, hrefPrefix), (e) => e.label),
  });
}

export function overviewPageView(hrefPrefix: string, viewModel: PageViewModel, state: State): OverviewPageViewModel {
  const viewerWriteAccess = writeAccess(state);
  const employeeUuid = currentEmployeeUuid(state);
  const stickyLists: OverviewSection[] = [];
  const otherLists: OverviewSection[] = [];

  const startTasks = startTasksForCurrentPage(state);
  if (startTasks.length > 0) {
    stickyLists.push({
      type: 'start-tasks',
      multipleEmployees: employeesNotDeleted(state).length > 1,
      items: startTasks.map((t) => ({
        label: t.timeDisplay,
        secondaryLabel: t.name,
        eventCurrentUser: t.myTask,
        href: t.href,
        rightLabel: (t.myTask ? 'Jeg' : t.employeeName) + ' (' + t.functionName + ')',
        rightLabelClass: 'assignee',
        eventDone: t.executed,
        accessible: !t.executed,
        uuid: t.uuid,
      })),
    });
  }

  if (viewModel.pageId === 79 && medicalDoctor(state)) {
    stickyLists.push({ type: 'skil-list' });
  }

  const functionsWithTasks = functionsWithTasksForCurrentPage(state);
  const tasks = tasksForCurrentPage(state);

  if (functionsWithTasks.length > 0 || tasks.length > 0) {
    // d-list-section-page-tasks
    otherLists.push({
      type: 'page-tasks',
      functionsWithTasks: functionsWithTasks.map((f) => {
        let href = hrefPrefix + 'functions/' + f.uuid;
        if (f.isNew) {
          href += '?edit';
        }
        return {
          updateStatus: calculateUpdateStatus(f, LocalDate.fromString(state.today)),
          assignedNames: f.assignedNames,
          name: entityNameForLists(f.name, f.draftName, f.isNew),
          href,
          hasDraft: f.hasDraft,
          tasks: f.tasks.map((t) => {
            let href = hrefPrefix + 'tasks/' + t.uuid;
            if (t.isNew) {
              href += '?edit';
            }
            return {
              name: entityNameForLists(t.name, t.draftName, t.isNew),
              updateStatus: calculateUpdateStatus(t, now(state)),
              hasDraft: t.hasDraft,
              href,
            };
          }),
        };
      }),
      tasks: tasks.map((t) => {
        let href = hrefPrefix + 'tasks/' + t.uuid;
        if (t.isNew) {
          href += '?edit';
        }
        return {
          name: entityNameForLists(t.name, t.draftName, t.isNew),
          updateStatus: calculateUpdateStatus(t, now(state)),
          hasDraft: t.hasDraft,
          href,
        };
      }),
    });
  }

  if (viewModel.pageId === 65) {
    const organization = getOrganization(state);
    if (organization === undefined) {
      throw new Error('Illegal state (E152), organization not found');
    }

    addTutorialsList(organization, otherLists, hrefPrefix);
  }

  addListIfNotEmpty(otherLists, 'guidelines', guidelinesNotDeleted(state), viewModel.pageId, hrefPrefix, employeeUuid);
  addListIfNotEmpty(otherLists, 'documents', documentsNotDeleted(state), viewModel.pageId, hrefPrefix, employeeUuid);
  addListIfNotEmpty(
    otherLists,
    'contracts',
    contractsNotDeletedWithParties(state),
    viewModel.pageId,
    hrefPrefix,
    employeeUuid,
  );
  addListIfNotEmpty(otherLists, 'partners', partnersNotDeleted(state), viewModel.pageId, hrefPrefix, employeeUuid);
  addListIfNotEmpty(otherLists, 'reports', reportsNotDeleted(state), viewModel.pageId, hrefPrefix, employeeUuid);

  const issues = issuesNotDeleted(state).map((i) => toIssueViewModel(i));

  if (viewModel.pageId === 63) {
    const employees = employeesNotDeleted(state);
    const viewerEmployeeUuid = currentEmployeeUuid(state);
    const workRelatedHealthIssues = _workRelatedHealthIssues(issues, viewerEmployeeUuid, employees);
    if (workRelatedHealthIssues.length > 0) {
      otherLists.push({
        type: 'issues-grouped',
        field: 'issues_workRelatedHealthIssueTitle',
        items: toGroupedIssues(hrefPrefix, workRelatedHealthIssues, employeeUuid ?? ''),
      });
    }
    const otherHmsIssues = _otherHmsIssues(issues);
    if (otherHmsIssues.length > 0) {
      otherLists.push({
        type: 'issues-grouped',
        field: 'issues_otherHmsIssuesTitle',
        items: toGroupedIssues(hrefPrefix, otherHmsIssues, employeeUuid ?? ''),
      });
    }
  } else {
    const issueItems = _issues(issues, viewModel.pageId);
    if (issueItems.length > 0) {
      const lists = viewModel.pageId === 281 ? stickyLists : otherLists;
      lists.push({
        type: 'issues-grouped',
        field: 'organization_issues',
        items: toGroupedIssues(hrefPrefix, issueItems, employeeUuid ?? ''),
      });
    }
  }

  addListIfNotEmpty(
    viewModel.pageId === 279 ? stickyLists : otherLists,
    'riskAssessments',
    riskAssessmentsNotDeleted(state),
    viewModel.pageId,
    hrefPrefix,
    employeeUuid,
  );

  const lists: OverviewSection[] = [...stickyLists, ...otherLists];

  return {
    type: 'overview-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: viewerWriteAccess,
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: getOrganizationId(state),
    lists,
  };
}

/**
 * Grouped view of issues based on status. The issues that are "done" are shown in a filtered group.
 *
 *
 * @param issues
 */
function toGroupedIssues(
  hrefPrefix: string,
  issues: IssueViewModelExtended[],
  currentEmployeeUuid: string,
): ItemGroup[] {
  const groupNames = {
    ferdigbehandlet: 'Ferdigbehandlede',
    aktiv: 'Aktive',
  };

  const groupedEmployees = groupBy(issues, (e) => e.status ?? '');

  const result: ItemGroup[] = _.sortBy(groupedEmployees, [(g) => g.group !== 'aktiv', (g) => g.group]).map((g) => {
    return {
      label: groupNames[g.group],
      tooltip: commonFieldsByCode()['issues_closed'].tooltip,
      filtered: g.group === 'ferdigbehandlet',
      items: _.sortBy(g.items, (e) => e.reportedDate).map((e) => {
        const classification = e.classification ?? 'NONE';
        const accessControl = e.accessControl ?? [];
        return {
          uuid: e.uuid,
          label: entityNameForLists(e.name, e.draftName, e.isNew),
          dateDisplay: e.reportedDateFormatted,
          accessControl: accessControl,
          classification: classification,
          locked: classification !== 'NONE',
          accessible: classification === 'NONE' || accessControl.includes(currentEmployeeUuid),
          href: hrefPrefix + 'issues/' + e.uuid,
          relatedItems: [],
          hasDraft: e.hasDraft,
        };
      }),
    };
  });

  return result;
}

interface NamedTemplateItem extends BaseTemplateItem {
  name?: string;
  draftName?: string;
  isNew?: boolean;
  locked?: boolean;
  classification?: string;

  accessControl?: string[];
}

function getAccessible<T extends NamedTemplateItem>(g: T, currentEmployeeUuid: string | undefined) {
  if (g.classification === undefined) {
    return true;
  }
  return g.classification === 'NONE' || (g.accessControl ?? []).includes(currentEmployeeUuid ?? '');
}

export function toListSectionItemInputs<T extends NamedTemplateItem>(
  hrefPrefix: string,
  list: T[],
  today: LocalDate,
  currentEmployeeUuid: string | undefined,
): ListSectionItemInput[] {
  const items: ListSectionItemInput[] = list.map((g) => {
    let href = hrefPrefix + g.uuid;
    if (g.isNew) {
      href += '?edit';
    }
    return {
      locked: g.classification !== undefined && g.classification !== 'NONE',
      accessible: getAccessible(g, currentEmployeeUuid),
      updateStatus: calculateUpdateStatus(g, today),
      label: entityNameForLists(g.name, g.draftName, g.isNew),
      secondaryLabel: '',
      href,
      hasDraft: g.hasDraft ?? false,
    };
  });
  return sortedByLabel(items);
}
