import { css, html, LitElement } from 'lit';

import '../../library/elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';

/**
 *
 * STATUS OK
 */
@customElement('d-main-section')
export class DMainSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      margin-top: -1px;
    }

    #sectionHeader {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      margin: 0 -30px 0 -30px;
      padding: 0 30px 0 30px;
      background: white;
      z-index: 1;
    }

    @media only screen and (max-width: 600px) {
      #sectionHeader {
        margin: 0 -20px 0 -20px;
        padding: 0 20px 0 20px;
      }
    }

    #sectionHeader > div {
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      padding: 12px 0 6px 0;
    }

    #sectionHeader d-label #label {
      font-size: 18px;
      color: var(--themeColor);
      transition: font-size 0.3s;
    }

    #sectionHeader.collapsible d-label #label {
      cursor: pointer;
    }

    body:not(.touch) #sectionHeader d-label #label:hover {
      color: var(--pageHeaderColor);
    }

    #sectionHeader.open d-label #label {
      font-size: 22px;
      color: var(--pageHeaderColor);
    }
  `;
  @property({ type: String })
  label = '';
  @property({ type: String })
  field = '';
  @property({ type: Boolean })
  collapsible = false;
  @property({ type: Boolean })
  open = true;

  _computedOpen(collapsible, open) {
    return !collapsible || open;
  }

  _sectionHeaderClasses(collapsible, computedOpen) {
    let classes = '';
    if (collapsible) {
      classes += 'collapsible';
    }
    if (computedOpen) {
      classes += ' open';
    }
    return classes;
  }

  _toggle() {
    this.open = !this.open;
  }

  render() {
    const computedOpen = this._computedOpen(this.collapsible, this.open);

    return html`
      <div id="sectionHeader" class="${this._sectionHeaderClasses(this.collapsible, computedOpen)}">
        <div>
          <d-label verybig field="${this.field}" label="${this.label}" @click=${this._toggle}></d-label>
        </div>
      </div>
      <d-expansion ?opened=${computedOpen}>
        <slot></slot>
      </d-expansion>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-main-section': DMainSection;
  }
}
