import { css } from 'lit';

export const levelContentStyles = css`
  :host {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  d-page-header {
    flex: none;
  }

  .content {
    flex: 1;
    max-width: 784px;
    box-sizing: border-box;
    margin-top: -1px;
    padding: 0 20px;
    transition: padding 0.5s;
  }

  :host(.width600) .content {
    padding: 0 30px;
  }

  :host([covered]) .content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    direction: rtl;
  }

  .content > * {
    direction: ltr;
  }

  .content::after {
    content: '';
    display: block;
    height: 70px;
  }

  :host([wide-page]) .content {
    max-width: unset;
  }
`;
