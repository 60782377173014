import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/content/entity-content';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

export interface NetworkFirewall extends DataMapItem {
  unitType: 'firewall';
  ghost: boolean;
  firewallType: string;
  firewallName: string;
}

export interface NetworkFirewallEditItem {
  firewallType: string;
  firewallName: string;
}

/**
 *
 */
@customElement('d-network-firewall-view')
export class DNetworkFirewallView extends DataMapElement<NetworkFirewall, NetworkFirewallEditItem> {
  @property({ type: String })
  name = '';
  @property({ type: String })
  uuid = '';
  @property({ type: String })
  type = '';
  @property({ type: String })
  connectionType = '';
  @property({ type: String })
  firewall = '';
  @property({ type: String })
  firewallName = '';
  @property({ type: String })
  supplierUuid = '';
  @property({ type: String })
  systemUpdateOperatorType = '';
  @property({ type: String })
  systemUpdateOperator = '';
  @property({ type: String })
  antiVirusOperatorType = '';
  @property({ type: String })
  antiVirusOperator = '';
  @property({ type: String })
  isp = '';
  @property({ type: String })
  containedNewComputerUuid = '';
  @property({ type: Object })
  containedNewComputer: any = {};
  @property({ type: Boolean })
  connectionOpened = false;
  @property({ type: Boolean })
  firewallOpened = false;
  @property({ type: Boolean })
  networkOpened = false;
  @property({ type: Boolean })
  newElement = false;
  @property({ type: Array })
  partners = [];
  @property({ type: Array })
  networks: any[] = [];
  @property({ type: Array })
  computers: any[] = [];
  @property({ type: Object })
  viewOptions: any = {};
  @property({ type: Boolean })
  hideDetails = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  onlyNew = false;
  @property({ type: Boolean })
  editModeForConnection = false;
  @property({ type: Boolean })
  editModeForFirewall = false;
  @property({ type: Boolean })
  editModeForNetwork = false;
  private firewallOptions: SelectDropdownOption[] = [
    { value: 'NONE', text: 'Ingen' },
    { value: 'NHN', text: 'NHN med NHN samband' },
    { value: 'NHNotherISP', text: 'NHN med selvvalgt samband' },
    { value: 'OTHER', text: 'Annen brannmurtype, spesifiser…' },
  ];

  _operatorIsExternal(operatorType) {
    return operatorType === 'external' || operatorType === 'internalAndExternal';
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this.operatorTypes();
    for (const item1 of list) {
      if (id === item1.value) {
        result = item1.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  _getName(type, uuid) {
    if (Array.isArray(uuid)) {
      uuid = uuid[0];
    }
    for (const item1 of type) {
      if (item1.uuid === uuid) {
        return item1.name;
      }
    }
  }

  renderUnitEdit(editItem: NetworkFirewallEditItem) {
    return html`
      <d-section>
        <d-select-dropdown
          theme-page
          field="firewalls_firewall"
          .options="${this.firewallOptions}"
          value="${editItem.firewallType}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              firewallType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened=${editItem.firewallType === 'OTHER'}>
        <d-section style="border-top: none; padding-top: 0">
          <d-edit-text
            theme-page
            value="${editItem.firewallName}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                firewallName: e.detail.value,
              };
            }}
          >
          </d-edit-text>
        </d-section>
      </d-expansion>
    `;
  }

  _displayTypeName(): string {
    return 'Brannmur';
  }

  _displayName() {
    if (this.item.firewallType === 'NHN') {
      return 'NHN med NHN samband';
    }
    if (this.item.firewallType === 'NHNotherISP') {
      return 'NHN med selvvalgt samband';
    }
    if (this.item.firewallType === 'OTHER') {
      return this.item.firewallName;
    }
    return 'Ingen';
  }

  _isValid() {
    return true;
  }

  initializeEditItem(): NetworkFirewallEditItem {
    return {
      firewallType: this.item.firewallType,
      firewallName: this.item.firewallName,
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-network-firewall-view': DNetworkFirewallView;
  }
}
