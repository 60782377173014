import type { Revision } from 'src/content/entity-content.js';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment.js';
import type { AttachmentViewModel, PageViewModel, SaveDraftCommand } from 'src/store/api';
import { attachment, draft, revisions } from 'src/store/backend.js';
import { getOrganizationId, getPages } from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { getStore } from 'src/store';
import { sortBy } from 'lodash';
import { displayName } from 'src/utilities/text';

export function toAttachmentItems(
  entityType: string,
  entityUuid,
  attachments: AttachmentViewModel[] | undefined,
  parentId?: string,
): AttachmentItem[] {
  const list = (attachments ?? []).map((a) => ({
    name: displayName(a.name),
    uuid: a.uuid,
    fullPath: a.fullPath ?? '',
    previewHref: a.urlForInline ?? '',
    downloadHref: a.url ?? '',
    asDocumentItem: async () => {
      const organizationId = getOrganizationId(getStore().getState());
      const a2 = await attachment(entityType, entityUuid, organizationId, a.uuid, parentId);
      return {
        name: displayName(a2.name),
        url: a2.urlForInline ?? '',
        urlForDownload: a2.url ?? '',
        entityType: entityType,
        attachmentId: a.uuid,
        uuid: entityUuid,
      };
    },
  }));
  return sortBy(list, [(x) => x.name]);
}

export async function fetchRevisions(
  entityType: string,
  entityId: string,
  organizationId: number,
  templateDeleted: boolean,
  parentEntityId?: string,
): Promise<Revision[]> {
  const r = await revisions(entityType, entityId, organizationId, parentEntityId);

  const suggested = r.find((i) => i.status === 'SUGGESTED');
  const revisionsNotSuggested = r.filter(function (e) {
    return e.status !== 'SUGGESTED';
  });
  const isSuggestedInUse =
    suggested !== undefined &&
    revisionsNotSuggested.filter(function (e) {
      return e.name === suggested.name && e.content === suggested.content;
    }).length > 0;
  const excludeSuggested = !templateDeleted && isSuggestedInUse;

  const filtered = excludeSuggested ? revisionsNotSuggested : r;

  return filtered.map((v) => ({
    changedBy: v.changedBy ?? '',
    dateTime: v.dateTime ?? '',
    status: v.status ?? '',
    name: v.name ?? '',
    content: v.content ?? '',
    differences: {
      name: v.nameDifference ?? '',
      content: v.contentDifference ?? '',
    },
  }));
}

export function pagesAsSelectTagOptions(state: State) {
  const p = getPages(state);
  const exclude = [2300, 104, 110, 2250, 20, 3482, 5962, 9772, 12368];

  const selectable = flatten(p).filter((x) => !exclude.includes(x.id));

  return selectable.map((x) => ({ value: '' + x.id, text: x.label }));
}

function flatten(value: PageViewModel[]) {
  const result: { id: number; label: string }[] = [];
  for (const item1 of value) {
    result.push({
      id: item1.pageId,
      label: item1.name,
    });
    if (item1.subPages) {
      for (const item of item1.subPages) {
        result.push({
          id: item.pageId,
          label: item.name,
        });
      }
    }
  }
  return result;
}

export async function fetchDraft(
  entityType: string,
  entityId: string,
  organizationId: number,
): Promise<SaveDraftCommand> {
  return draft(entityType, entityId, organizationId);
}
