import { css, html, LitElement, nothing } from 'lit';
import '../../library/fields/d-view-text';
import '../../library/fields/d-view-html';
import '../../library/lists/d-list-section';
import { formViewBuilder, FormViewItem } from '../../library/components/d-form-view';
import '../../library/lists/d-list-section-item';
import '../../library/elements/d-section';
import { customElement, property } from 'lit/decorators.js';
import type { ActivityCode } from 'src/store/selectors';
import type { EmployeeGroup } from 'src/pages/organization-page/organization-page-view-model';
import type { ListSectionItemInput } from 'src/library/lists/utilities';

export interface OrganizationView {
  url?: string;
  notes?: string;
  email?: string;
  fax?: string;
  phone?: string;
  mailAddress?: string;
  officeAddress?: string;
  herNumber?: string;
  organizationNumber?: string;
  businessEntityType?: string;
  activityCodes: string[];
  name: string;
}

/**
 *
 */
@customElement('d-organization-view')
export class DOrganizationView extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-list-section {
      margin-bottom: 6px;
    }
  `;
  @property({ type: Number })
  pageId = 104;
  @property({ type: Object })
  organization!: OrganizationView;
  @property({ type: Boolean })
  hideHerId = false;
  @property({ type: Boolean })
  hideActivityCodes = false;
  @property({ type: Boolean })
  hideProfessions = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Array })
  codes: ActivityCode[] = [];
  @property({ type: Array })
  constitutionalDocuments: ListSectionItemInput[] = [];
  @property({ type: Array })
  employeeGroups: EmployeeGroup[] = [];
  @property({ type: String })
  singleEmployeeLink = '';
  @property({ type: Array })
  partners: ListSectionItemInput[] = [];
  @property({ type: Array })
  functions: any[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Number })
  contentStickyTop = 0;
  employeeListConfig = {
    sortBy: 'name',
    filter: 'status,TERMINATED,Sluttet',
    columns: 'name',
    columnWidths: '200',
    options: '',
    labels: {
      profession: 'yrke',
      associationType: 'tilknytning',
    },
  };

  private get viewItems(): FormViewItem[] {
    const builder = formViewBuilder();
    builder.addTextVertical('organization_name', this.organization.name, 's', true);
    builder.addTextVertical(
      'organization_activityCodes',
      this._codes(this.codes, this.organization.activityCodes),
      's',
      !this.hideActivityCodes,
    );
    builder.addTextVertical('organization_businessEntityType', this.organization.businessEntityType, 's', true);
    builder.addTextVerticalIfNotEmpty('organization_organizationNumber', this.organization.organizationNumber, 'm');
    builder.addTextVertical(
      'organization_herNumber',
      this.organization.herNumber,
      'm',
      !this.hideHerNumber(this.hideHerId, this.organization.herNumber),
    );
    builder.addTextVerticalIfNotEmpty('organization_officeAddress', this.organization.officeAddress, 'm');
    builder.addTextVerticalIfNotEmpty('organization_mailAddress', this.organization.mailAddress, 'm');
    builder.addTextVerticalIfNotEmpty('organization_phone', this.organization.phone, 'm');
    builder.addTextVerticalIfNotEmpty('organization_fax', this.organization.fax, 'm');
    builder.addTextVerticalIfNotEmpty('organization_url', this.organization.url, 'm');
    builder.addTextVerticalIfNotEmpty('organization_email', this.organization.email, 'm');

    return builder.build();
  }

  private get singleEmployee():
    | { name: string; href: string; associationType: string; profession: string }
    | undefined {
    if (this.singleUserVersion) {
      const groups = this.employeeGroups.filter((g) => g.employees.length > 0);
      if (groups.length !== 1) {
        throw new Error('Illegal state');
      }
      const group = groups[0];
      if (group.employees.length !== 1) {
        throw new Error('Illegal state');
      }
      const e = group.employees[0];
      return {
        name: e.name,
        href: e.href,
        associationType: group.associationTypeName,
        profession: e.profession,
      };
    }
    return undefined;
  }

  private get employeeGroupsAsListItems() {
    return this.employeeGroups.map((group) => {
      const result: ListSectionItemInput = {
        label: group.associationTypeName,
        accessible: false,
        items: group.employees.map((employee) => ({
          label: employee.name,
          labelItems: employee.functions,
          href: employee.href,
          accessible: true,
          hasDraft: employee.hasDraft,
        })),
      };
      if (group.terminated) {
        result.sublistToggle = true;
        result.sublistHidden = true;
      }
      return result;
    });
  }

  _codes(codes: any[], activityCodes: string | any[]) {
    let result: string | undefined = undefined;

    if (activityCodes && codes) {
      const names: string[] = [];

      codes.forEach((code) => {
        if (activityCodes.indexOf(code.code) !== -1) {
          if (code.codes) {
            const r = this._codes(code.codes, activityCodes);

            if (r && r !== '') {
              names.push(r);
            } else {
              names.push(code.custom || code.name);
            }
          } else {
            names.push(code.custom || code.name);
          }
        }
      });

      result = names.join(', ');
    }

    return result;
  }
  render() {
    return html`
      <d-form-view .viewItems=${this.viewItems}> </d-form-view>

      ${this.singleUserVersion && this.singleEmployee
        ? html`<d-section>
            <d-wrap split-reverse baseline>
              <d-action href="${this.singleEmployee.href}">Mer om meg</d-action>
              <d-view-text
                .label="${this.singleEmployee.associationType ?? ''}"
                value="${this.singleEmployee.profession} ${this.singleEmployee.name}"
              >
              </d-view-text>
            </d-wrap>
          </d-section>`
        : nothing}
      ${!this.organization.notes
        ? nothing
        : html`<d-section>
            <d-view-html
              field="organization_notes"
              label-classes="none"
              organization-id="${this.organizationId}"
              value="${this.organization.notes}"
            >
            </d-view-html>
          </d-section>`}

      <d-list-section
        field="organization_constitutionalDocuments"
        icon="constitutionalDocuments"
        write-access="${this.writeAccess}"
        .items=${this.constitutionalDocuments}
        theme-page
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>

      ${this.singleUserVersion
        ? nothing
        : html`<d-list-section
            icon="employees"
            field="organization_employees"
            .items=${this.employeeGroupsAsListItems}
            theme-page
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-list-section>`}

      <d-list-section
        field="organization_partners"
        icon="partners"
        write-access="${this.writeAccess}"
        theme-page
        .items=${this.partners}
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }

  private hideHerNumber(hideHerId: boolean, herNumber: string | undefined) {
    return hideHerId || herNumber === '' || herNumber === undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-view': DOrganizationView;
  }
}
