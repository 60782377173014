import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ActionInput } from 'src/library/elements/d-action';
import 'src/library/elements/d-action';

/**
 * En meny for komandoer det ikke er plass til.
 * STATUS OK
 */
@customElement('d-more-menu')
export class DMoreMenu extends LitElement {
  static readonly styles = css`
    :host {
      display: inline-block;
      position: relative;
      min-width: 32px;
      height: 32px;
    }
    .trigger {
      display: block;
      width: 32px;
      height: 32px;
      background: url(/images/more-menu-icon.svg) 0 0 no-repeat;
      background-size: cover;
      cursor: pointer;
    }
    .trigger:hover {
      background-position-y: -160px;
    }
    d-action {
      padding-right: 6px;
    }
    #menu {
      display: none;
      flex-direction: column;
      align-items: stretch;
      justify-content: right;
      position: absolute;
      top: 0;
      right: 0;
      width: max-content;
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
      border: 1px solid var(--borderColorLight);
      border-bottom: none;
      background: var(--backgroundLightGray);
      background: white;
      text-align: right;
      z-index: 1;
    }
    #menu.open {
      display: flex;
    }
    #backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none;
    }
    #menu.open #backdrop {
      display: block;
    }
    .action {
      width: 100%;
      box-sizing: border-box;
      padding: 6px 14px;
      font-size: 13px;
      color: var(--themeColor);
      z-index: 100;
      text-decoration: none;
      cursor: pointer;
    }
    .action[disabled] {
      cursor: default;
    }
    .action:not(:last-child) {
      border-bottom: 1px solid var(--borderColorLight);
    }
    .action:hover {
      color: black;
    }
    .action[disabled] {
      opacity: 0.5;
    }
    .action[disabled]:hover {
      color: inherit;
    }
    .action.delete:hover {
      border-bottom-color: red;
      background: red;
      color: white;
    }
    .action[disabled]:hover,
    .action.delete[disabled]:hover {
      border-bottom-color: var(--borderColorLight);
      background: white;
      color: var(--themeColor);
    }
  `;

  @property({ type: Boolean })
  opened = false;
  @property({ type: Array })
  actions: ActionInput[] = [];
  @state()
  private readonly ellipsis = false;

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }

  openedClass(opened) {
    return opened ? 'open' : '';
  }

  handleActionClick(action) {
    if (!action.disabled) {
      this.close();
      if (action.href !== '') {
        this.dispatchEvent(new CustomEvent('action', { bubbles: true, composed: true, detail: action.action }));
      }
    }
  }

  render() {
    return html`
      ${this.ellipsis
        ? html` <div class="trigger" @click=${() => this.open()}></div> `
        : html` <d-action @click=${() => this.open()}>Mer</d-action> `}
      <div id="menu" class="${this.openedClass(this.opened)}">
        <div id="backdrop" @click=${() => this.close()}></div>
        ${this.actions.map((action) =>
          action.href === ''
            ? html` <div class="action ${action.classes}" @click=${() => this.handleActionClick(action)}>
                ${action.name}
              </div>`
            : html`<a
                class="action ${action.classes}"
                href="${action.href ?? ''}"
                ?disabled="${action.disabled}"
                target="${action.target ?? ''}"
                @click=${() => this.handleActionClick(action)}
              >
                ${action.name}
              </a>`,
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-more-menu': DMoreMenu;
  }
}
