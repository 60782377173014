import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { levelHeaderStyles } from 'src/library/level-header-styles.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
import '../library/elements/d-smooth-resize.js';

export type IconEnum =
  | undefined
  | ''
  | 'meetings'
  | 'assets'
  | 'personalDataItems'
  | 'infosec'
  | 'substances'
  | 'trash';

/**
 *
 * STATUS OK
 */
@customElement('d-page-header')
export class DPageHeader extends ResponsiveElement {
  @property({ type: String })
  icon: IconEnum = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  href = '';
  @property({ type: Boolean, reflect: true })
  covered = false;
  @property({ type: Boolean })
  top = false;

  @query('.header')
  private _headerEl?: HTMLDivElement;

  static readonly styles = [
    levelHeaderStyles,
    css`
      .header {
        border-bottom: 1px solid var(--borderColorOnGray);
        background-color: var(--backgroundGray);
      }
    `,
  ];

  private get titleStyle() {
    if (this.icon) {
      const typeName = this.icon.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
      return 'background-image: url(/images/' + typeName + '-color' + '.svg)';
    }
    return '';
  }

  private get titleClass() {
    if (this.icon) {
      return 'title icon';
    }
    return 'title';
  }

  onResize() {
    if (this._headerEl) {
      const height = this._headerEl.clientHeight;
      this.dispatchEvent(
        new CustomEvent('height-changed', {
          bubbles: true,
          composed: true,
          detail: height,
        }),
      );
    }
  }

  render() {
    return html`
      <d-smooth-resize>
        <div class="header">
          <div class="actions">
            <slot></slot>
          </div>
          <div style="${this.titleStyle}" class="${this.titleClass}">
            <span class="name">${this.label}</span>
            ${this.covered ? html`<a class="header-link" href="${this.href}"></a>` : nothing}
          </div>
        </div>
      </d-smooth-resize>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-page-header': DPageHeader;
  }
}
