import { css, html, nothing } from 'lit';
import '../elements/d-label.js';
import '../elements/d-wrap.js';
import '../elements/d-action.js';
import { customElement, property } from 'lit/decorators.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

/**
 *
 * @slot - Content placed on right side. Primarily for actions
 * @slot secondary - Content immediately follows the label.
 *
 * STATUS OK
 */
@customElement('d-list-header')
export class DListHeader extends ResponsiveElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        position: -webkit-sticky;
        position: sticky;
        min-height: 32px;
        top: -1px;
        border-top: 1px solid var(--borderColor);
        padding-top: 6px;
        background: white;
        z-index: 2;
      }

      :host([bordered]) {
        margin-bottom: -1px;
      }

      .split {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      :host([theme-page]) {
        border-top: 1px solid var(--borderColorOnGray);
        background: var(--backgroundGray);
      }

      :host([bordered]):after {
        content: '';
        display: block;
        border-top: 1px solid var(--borderColor);
        margin-top: 5px;
        margin-left: var(--listPaddingLeft);
        width: calc(100% - var(--listPaddingLeft));
      }

      :host([theme-page][bordered]):after {
        border-top: 1px solid var(--borderColorOnGray);
      }

      .top-right {
        margin-top: 2px;
        margin-right: -8px;
        text-align: right;
      }

      .label-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        min-height: 28px;
        margin: -1px 0 -2px 0;
        background-size: 30px 30px;
        background-position: -2px 3px;
        background-repeat: no-repeat;
        padding: 8px 0 0 var(--listPaddingLeft);
        font-family: var(--mainSans), sans-serif;
      }

      .label-replacement {
        margin-top: -8px;
      }

      :host([big-label]) d-label {
        position: relative;
        top: -5px;
        font-size: 20px;
        text-transform: none;
        letter-spacing: normal;
      }

      .bottom {
        margin-left: var(--listPaddingLeft);
      }

      /* TODO: replace media query with responsive container style */
      @media only screen and (max-width: 600px) {
        :host([bordered]):after,
        .bottom {
          margin-left: 0;
          width: 100%;
        }
      }
    `,
  ];
  @property({ type: String })
  label = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  field?: string;
  @property({ type: String })
  subfield = '';
  @property({ type: String })
  icon: string | undefined = undefined;
  @property({ type: Boolean, reflect: true, attribute: 'replace-label' })
  replaceLabel = false;
  @property({ type: Boolean, reflect: true, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true, attribute: 'bordered' })
  borderd = false;
  @property({ type: Boolean, reflect: true, attribute: 'big-label' })
  bigLabel = false;
  @property({ type: Array })
  actions: ActionInput[] = [];

  private get iconStyle() {
    if (this.icon) {
      // These icons are exceptions. They are not document type icons and therefore only exist in gray version.
      const specificIcons = ['starttask', 'versions', 'attachments', 'references'];
      if (specificIcons.includes(this.icon)) {
        return 'background-image: url(/images/' + this.icon + '.svg)';
      }
      const typeName = this.icon.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
      return 'background-image: url(/images/' + typeName + '-color.svg)';
    }
    return '';
  }

  _noHeader(label, field, replaceLabel) {
    return !label && !field && !replaceLabel;
  }

  onResize() {
    const height = this.clientHeight;
    this.dispatchEvent(
      new CustomEvent('height-changed', {
        bubbles: true,
        composed: true,
        detail: height,
      }),
    );
  }

  render() {
    if (this._noHeader(this.label, this.field, this.replaceLabel)) {
      return nothing;
    } else {
      const label = this.replaceLabel
        ? html`
            <div class="label-replacement">
              <slot name="label-replacement"></slot>
            </div>
          `
        : html`
            <d-label
              list-header
              .field="${this.field}"
              .subfield="${this.subfield}"
              .label="${this.label}"
              .sublabel="${this.sublabel}"
              ?theme-page=${this.themePage}
              ?big=${this.bigLabel}
              allow-wrap
            ></d-label>
          `;
      return html`
        <div class="split">
          <div class="top-right">
            ${this.renderActions('top-right')}
            <slot name="top-right"></slot>
          </div>
          <div class="label-wrapper" style="${this.iconStyle}">${label}</div>
        </div>
        <div class="bottom">
          ${this.renderActions('bottom')}
          <slot name="bottom"></slot>
        </div>
      `;
    }
  }

  private renderActions(slot: 'bottom' | 'top-right') {
    return this.actions
      .filter((item) => {
        return item.slot === slot;
      })
      .map((item) => {
        return html` <d-action .input=${item}></d-action> `;
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-header': DListHeader;
  }
}
