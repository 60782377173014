import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/presentational/d-sections.js';
import '../d-page-header.js';
import '../../library/components/d-help-section.js';
import './d-staffing-calendar.js';
import type { StaffingCalendar } from './d-staffing-calendar-table.js';
import type { StaffingPageViewModel } from './staffing-page-view-model.js';
import { levelHeaderStyles } from 'src/library/level-header-styles.js';

/**
 *
 *
 * Migration:
 *  - Removed the spinner and 'pageReady' delay. Test first if performance is acceptable
 *  -
 *
 */
@customElement('d-staffing-page-content')
export class DStaffingPageContent extends PageContent<StaffingPageViewModel> {
  static readonly styles = [
    ...super.styles,
    levelHeaderStyles,
    css`
      :host {
        --page-width: 1000px;
        display: block;
        width: var(--page-width);
        min-height: calc(100vh + 1px);
        border-top-left-radius: 12px;
        background: var(--backgroundGray);
      }
      :host .content,
      :host(.width600) .content {
        padding: 0;
      }
      .no-horizontal-scroll > div > div {
        box-sizing: border-box;
        position: -webkit-sticky;
        position: sticky;
        max-width: 100vw;
        left: 0;
        background: var(--backgroundGray);
        transition: padding 0.3s;
      }
      .header-wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        z-index: 3;
      }
      :host(.width845) .header-wrapper > div > div {
        border-top-left-radius: 12px;
      }
      d-calendar-legend {
        margin-top: 12px;
      }
      d-staffing-calendar {
        margin-bottom: 20px;
      }
      d-sections {
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
      }
    `,
  ];
  @property({ type: Number })
  appWidth = 1000;
  @property({ type: Number })
  calendarWidth = 1000;
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Object })
  scrollData = {};
  @property({ type: Object })
  employeeCalendarData!: StaffingCalendar;

  private get horizontalPadding() {
    if (this.appWidth < 600) {
      return 20;
    }
    return 30;
  }

  private get stickyWrapperStyle() {
    return 'width: ' + this.calendarWidth + 'px;';
  }

  private get stickyStyle() {
    let width = this.pageWidth + this.scrollLeft;
    if (width > this.appWidth) {
      width = this.appWidth;
    }
    return (
      'width: ' +
      width +
      'px; padding-left: ' +
      this.horizontalPadding +
      'px; padding-right: ' +
      this.horizontalPadding +
      'px;'
    );
  }

  _onCalenderWidthChanged(e) {
    this.calendarWidth = e.detail;
    this.style.setProperty('--page-width', e.detail + 'px');
  }

  render() {
    return html`
      <div class="no-horizontal-scroll header-wrapper">
        <div style="${this.stickyWrapperStyle}">
          <div style="${this.stickyStyle}">
            ${this.renderHeader('small-header')} ${this.renderHeader('large-header')} ${this.renderHeader('header')}
          </div>
        </div>
      </div>
      <div class="content" id="content">
        <div class="no-horizontal-scroll">
          <div style="${this.stickyWrapperStyle}">
            <div style="${this.stickyStyle}">
              <d-help-section content="${this.pageView.helpContent}"></d-help-section>
              <d-calendar-legend></d-calendar-legend>
            </div>
          </div>
        </div>
        <d-staffing-calendar
          .paddingLeft=${this.horizontalPadding}
          .appWidth=${this.appWidth}
          .pageWidth=${this.pageWidth}
          .contentStickyTop=${34}
          .scrollLeft=${this.scrollLeft}
          .year=${this.pageView.year}
          .showShareVacations=${false}
          .currentUserHasAccess=${this.pageView.currentUserHasExtendedStaffingAccess}
          .leavePeriodEditRestriction=${this.pageView.leavePeriodEditRestriction}
          .employeeCalendarData=${this.pageView.calendarData}
          .calendarDayRange=${this.pageView.calendarDayRange}
          .employees=${this.pageView.employeesForStaffingCalendar}
          .employeeGroups=${this.pageView.employeeGroups}
          .groupGraphData=${this.pageView.groupGraphData}
          .accessEmployees=${this.pageView.accessEmployees}
          .accessList=${this.pageView.accessList}
          .employeesForStatistics=${this.pageView.employeesForStatistics}
          .sicknessAbsenceStats=${this.pageView.sicknessAbsenceStats}
          .writeAccess=${this.pageView.writeAccess}
          .currentEmployeeUuid=${this.pageView.currentEmployeeUuid}
          @calendar-width-changed=${(e) => this._onCalenderWidthChanged(e)}
        >
        </d-staffing-calendar>
        <div class="no-horizontal-scroll">
          <div style="${this.stickyWrapperStyle}">
            <div style="${this.stickyStyle}">
              <d-sections>
                <d-staffing-calendar-access
                  .currentUserHasAccess=${this.pageView.currentUserCanEditStaffingAccess}
                  .leavePeriodEditRestriction=${this.pageView.leavePeriodEditRestriction}
                  .employees=${this.pageView.accessEmployees}
                  .accessList=${this.pageView.accessList}
                ></d-staffing-calendar-access>
                <d-share-staffing
                  .currentUserHasAccess=${this.pageView.currentUserHasExtendedStaffingAccess}
                  .employeesOptions=${this.pageView.employeesForShare}
                  .shares=${this.pageView.vacationSummaryShares}
                  .notes=${this.pageView.vacationSummaryNotes}
                  .selectedEmployees=${this.pageView.vacationSummaryEmployees}
                ></d-share-staffing>
                ${this.pageView.currentUserHasExtendedStaffingAccess
                  ? html`
                      <d-timekeeping-review
                        .employeesWithPeriods=${this.pageView.employeesWithPeriods}
                        .defaultDate=${this.pageView.today.toString()}
                        .currentUserHasAccess=${this.pageView.currentUserHasExtendedStaffingAccess}
                      >
                      </d-timekeeping-review>
                      <d-staffing-statistics
                        .employees=${this.pageView.employeesForStatistics}
                        @period-tap=${this.scrollToPeriod}
                      ></d-staffing-statistics>
                      <d-sickness-absence-statistics
                        .sicknessAbsenceStats=${this.pageView.sicknessAbsenceStats}
                        .employees=${this.pageView.employeesForStaffingCalendar}
                        @period-tap=${this.scrollToPeriod}
                      ></d-sickness-absence-statistics>
                    `
                  : nothing}
              </d-sections>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  private scrollToPeriod() {
    // TODO
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-page-content': DStaffingPageContent;
  }
}
