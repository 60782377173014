import { html, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AbstractEntityView, contentViewBuilder, EntityContent } from 'src/content/entity-content';
import type { ReminderType } from 'src/content/event-occurrences/d-edit-reminder';
import {
  MeetingMessageDialog,
  MeetingMessageDialogInput,
  MeetingMessageDialogResult,
  MessageForSend,
} from './meeting-message-dialog';
import type { FormViewItem } from 'src/library/components/d-form-view';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag';

import './d-meeting-update-section';
import '../../library/components/d-help-section';
import '../../library/fields/d-view-occurrences';
import '../../content/d-edit-entity-name';
import '../../library/elements/d-smooth-resize';
import '../../library/elements/d-section';
import '../../library/editors/components/d-edit-event-timing';
import '../../library/editors/elements/d-select-dropdown';
import '../../content/event-occurrences/d-edit-assignee';
import '../../content/event-occurrences/d-edit-reminder';
import '../../library/editors/components/d-edit-classification';
import '../../library/editors/components/d-edit-html';

import '../../library/fields/d-view-html';
import '../../library/lists/d-list-section';

import { isEmptyHtmlContent, isSameHtmlContent, toDateTimeDuration } from 'src/utilities/text';
import { UpdateSectionItem } from 'src/content/d-update-section';
import {
  RecurrenceOptionsDialog,
  RecurrenceOptionsResult,
} from 'src/content/event-occurrences/recurrence-options-dialog';
import { LocalDate } from 'src/utilities/local-date';
import { DTemplateDialog, TemplateDialogResult } from 'src/layout/parts/d-template-dialog';
import {
  DEditOccurrencesDialog,
  OccurrencesDialogInput,
  OccurrencesDialogResult,
} from 'src/layout/parts/d-edit-occurrences-dialog';
import { MessageRecipient, SaveType } from 'src/content/event-occurrences/d-event-occurrence-view';
import { EventTiming } from 'src/library/editors/components/d-edit-event-timing';
import '../event-occurrences/d-edit-recurrence';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { FunctionViewModel } from 'src/store/api';
import {
  DeleteMeetingOccurrenceDialog,
  DeleteMeetingOccurrenceDialogResult,
} from 'src/content/meeting-occurrences/delete-meeting-occurrence-dialog';
import { dateTimeDescription } from 'src/store/utilities';

export interface MeetingOccurrenceViewEditItem {
  newItem: boolean;
  name: string;
  nameConfirmed: boolean;
  date: string;
  time: string;
  recurrence: string;
  assignedToEmployees: string[];
  reminder: ReminderType;
  accessControl: string[];
  classification: string;
  meetingAgenda: string;
  meetingResponsible: string;
  durationMinutes: number;
  saveType: SaveType;
  meetingUuid: string;
}

export interface ModelMeeting {
  name: string;
  meetingResponsible: string;
  employees: string[];
  contacts: string[];
  notes: string;
  agenda: string;
  meetingUuid: string;
  time: string;
  durationMinutes: number;
  classification: string;
  accessControl: string[];
  futureOccurrences: Occurrence[];
}

export interface TemplateMeeting {
  name: string;
  uuid: string;
  helpContent: string;
  agenda: string;
}

export interface MeetingOccurrenceView extends AbstractEntityView<MeetingOccurrenceViewEditItem> {
  durationMinutes: number;
  newItem: boolean;
  thisEventInstance: string;
  defaultFunction: string;
  defaultEmployee: string;
  reminderText: string;
  assigneeDisplayText: string;
  notes: string;
  timeDescription: string;
  type: 'meetingOccurrences';
  name: string;
  functions: FunctionViewModel[];
  availableEmployees: SelectTagOption[];
  availableContacts: SelectTagOption[];
  availableFunctions: SelectDropdownOption[];
  currentEmployeeUuid: string | undefined;
  currentEmployeeShortName: string | undefined;
  employeeFullNames: { uuid: string; name: string }[];
  messageRecipients: MessageRecipient[];
  occurrences: Occurrence[];
  canUpdateFutureOccurrences: boolean;
  modelMeetings: ModelMeeting[];
  templateMeetings: TemplateMeeting[];
  noticeSentText: string;
  noticeSent: boolean;
  reportSent: string;
  meetingResponsibleDisplayText: string;
  date: string;
  time: string;
  assignedToEmployees: string[];
  reminder: ReminderType;
  accessControl: string[];
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  meetingAgenda: string;
  meetingResponsible: string;
  relatedMeetingProcedures: string;
  isEmployee: boolean;
  accessControlOptions: SelectTagOption[];
  hasReport: boolean;
  agendaDescriptionLabel: string;
  meetingReportWrittenLabel: string;
  meetingReportWrittenUuid?: string;
  meetingReportContent?: string;
  meetingReportWrittenText: string;
  meetingReportIsClassified: boolean;
  lastModified: string;
  templateUpdated: string;
  organizationId: number;
}

/**
 *
 *
 *
 *
 *
 */
@customElement('d-meeting-occurrence-view')
export class DMeetingOccurrenceView extends EntityContent<MeetingOccurrenceView, MeetingOccurrenceViewEditItem> {
  @property({ type: String })
  templateAgenda = '';
  // Temporary fix
  itemLocked = false;
  protected saveType?: SaveType;
  @state()
  private newFromMeeting = false;
  private noName = false;

  protected get currentItemSecondaryLabel() {
    let item: MeetingOccurrenceView | MeetingOccurrenceViewEditItem = this.entityView;
    if (this.editItem) {
      item = this.editItem;
    }
    if (this.isEmployeeInterview(item.name)) {
      const assigneeDisplayText =
        this.entityView.employeeFullNames.find((e) => {
          return e.uuid === item.assignedToEmployees[0];
        })?.name || '';
      return 'med ' + assigneeDisplayText;
    }
    return '';
  }

  protected get currentItemSublabel(): string {
    if (this.editItem) {
      if (this.editItem.nameConfirmed) {
        return dateTimeDescription(this.editItem.date, this.editItem.time, this.editItem.durationMinutes);
      }
      return '';
    }
    return dateTimeDescription(this.entityView.date, this.entityView.time, this.entityView.durationMinutes);
  }

  protected get viewItems(): FormViewItem[] {
    const e = this.entityView;
    const builder = contentViewBuilder();
    builder.addText(
      this.responsibleField(this.isEmployeeInterview(e.name)),
      e.meetingResponsibleDisplayText,
      'l',
      !this.singleUserVersion,
    );
    builder.addText(
      this.participantsField(this.isEmployeeInterview(e.name)),
      e.assigneeDisplayText,
      'l',
      !this.singleUserVersion,
    );
    builder.addTextIfNotEmpty('events_reminder', e.reminderText, 'l');
    builder.addClassification(e.classification, e.accessControl, e.accessControlOptions);
    if (this.showMeetingUpdateSection()) {
      builder.addCustom(
        () =>
          html` <d-meeting-update-section
            @open-template-dialog=${async () => {
              const e = this.calculateEditItem();
              const action = await this.openTemplateDialog(e);
              if (action === 'done') {
                this.dispatchEvent(new CustomEvent('edit', { bubbles: true, composed: true }));
                this.mode = 'edit';
              }
            }}
          ></d-meeting-update-section>`,
      );
    }
    builder.addCustom(
      () =>
        html` <d-view-html
          field="meetings_agenda"
          .sublabel=${isEmptyHtmlContent(e.meetingAgenda) ? 'Ingen agenda' : ''}
          .value=${e.meetingAgenda}
          ?expandable=${!isEmptyHtmlContent(e.meetingAgenda)}
        ></d-view-html>`,
    );
    if (e.noticeSent) {
      builder.addTextWithEditAction(
        'Innkalling sent',
        e.noticeSentText,
        async () => {
          await this.sendMeetingMessage(e, 'notice');
        },
        'Send ny innkalling',
      );
    } else {
      builder.addTextWithEditAction(
        'Innkalling ikke sent',
        '',
        async () => {
          await this.sendMeetingMessage(e, 'notice');
        },
        'Send innkalling',
      );
    }
    if (e.hasReport) {
      builder.addTextWithEditAction(
        e.meetingReportWrittenLabel,
        e.meetingReportWrittenText,
        () => {
          const destination = e.href + '/reports/' + e.meetingReportWrittenUuid;
          this.dispatchEvent(
            new CustomEvent('navigate', {
              bubbles: true,
              composed: true,
              detail: { href: destination },
            }),
          );
        },
        'Åpne referat',
      );
      builder.addTextWithEditAction(
        e.reportSent ? 'Referat sendt' : 'Referat ikke sendt',
        e.reportSent,
        async () => {
          await this.sendMeetingMessage(e, 'report');
        },
        'Del referat' + (e.reportSent ? ' på nytt' : ''),
      );
    } else {
      builder.addTextWithEditAction(
        'Referat ikke skrevet',
        '',
        async () => {
          await this.createMeetingReport();
        },
        'Skriv referat',
      );
    }
    return builder.build();
  }

  editHeaderActions() {
    if (this.editItem && !this.editItem.nameConfirmed) {
      return [];
    }
    return super.editHeaderActions();
  }

  renderHelpSection() {
    if (this.editItem) {
      const meeting = this.getTemplateMeeting(this.editItem.name);
      if (meeting) {
        return html` <d-help-section content="${meeting.helpContent || ''}"></d-help-section> `;
      }
    }
    return super.renderHelpSection();
  }

  async onDeleteConfirmed() {
    let saveType: SaveType = 'single';
    if (!this.entityView.newItem && this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: true,
        deleting: true,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }
    this.fireDeleteEvent(saveType);
  }

  async onDelete() {
    if (!this.entityView.newItem && (this.entityView.noticeSent || this.entityView.hasReport)) {
      const result: DeleteMeetingOccurrenceDialogResult = await DeleteMeetingOccurrenceDialog.open({
        noticeSent: this.entityView.noticeSent,
        reportWritten: this.entityView.hasReport,
      });
      if (result.action === 'cancel') {
        return;
      }
      if (result.action === 'delete') {
        return this.onDeleteConfirmed();
      }
    } else {
      return this.onDeleteConfirmed();
    }
  }

  renderPreContent() {
    return html` ${this.renderMetaSection()} `;
  }

  async initializeEditItem() {
    this.editItem = this.calculateEditItem();
  }

  calculateEditItem() {
    return this.calculateMeetingEditItem(this.entityView, this.templateAgenda);
  }

  calculateMeetingEditItem(view: MeetingOccurrenceView, templateAgenda: string): MeetingOccurrenceViewEditItem {
    if (view.newItem && view.name !== '') {
      this.newFromMeeting = true;
    }
    let meetingAgenda = view.meetingAgenda;
    if (templateAgenda) {
      meetingAgenda = templateAgenda;
    }
    let nameConfirmed = false;
    if (!this.entityView.newItem || this.newFromMeeting) {
      nameConfirmed = true;
    }
    return {
      newItem: view.newItem,
      accessControl: view.accessControl,
      classification: view.classification,
      reminder: view.reminder,
      date: view.date,
      time: view.time,
      durationMinutes: view.durationMinutes,
      assignedToEmployees: view.assignedToEmployees,
      meetingAgenda,
      meetingResponsible: view.meetingResponsible,
      name: view.name,
      recurrence: '',
      saveType: 'single',
      nameConfirmed,
      meetingUuid: '',
    };
  }

  renderEditItem(item: MeetingOccurrenceViewEditItem): TemplateResult<1> {
    return this.renderMeetingEditItem(item);
  }

  doneDisabled(): boolean {
    return this.noName;
  }

  async onDone() {
    if (this.editItem) {
      let name = this.editItem.name;
      let meetingUuid = '';
      if (this.isEmployeeInterview(this.editItem.name)) {
        name = 'Medarbeidersamtale';
      }
      const meetingTemplate: TemplateMeeting | undefined = this.getTemplateMeeting(this.editItem.name);
      if (meetingTemplate) {
        meetingUuid = meetingTemplate.uuid;
      }
      this.editItem = {
        ...this.editItem,
        name,
        meetingUuid,
      };
    }
    return super.onDone();
  }

  protected async saveEditItem(
    item: MeetingOccurrenceViewEditItem,
  ): Promise<MeetingOccurrenceViewEditItem | undefined> {
    let saveType: SaveType = 'single';
    if (this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: false,
        deleting: false,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }

    return {
      ...item,
      saveType: saveType,
    };
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected getIcon(): string {
    return 'meetings';
  }

  private getTemplateMeeting(name: string): TemplateMeeting | undefined {
    return this.entityView.templateMeetings.find((t) => {
      return t.name === name;
    });
  }

  private renderMetaSection() {
    if (!this.entityView.isNew) {
      const e = this.entityView;
      return html`
        <div class="meta-section">
          <d-view-occurrences
            .occurrences=${e.occurrences}
            .eventType=${'meeting'}
            .parentHref=${e.parentHref}
            .currentUuid=${e.uuid}
            style="margin-bottom: -12px"
            @edit-occurrences=${() => this.openRecurrenceDialog()}
          ></d-view-occurrences>
        </div>
      `;
    }
    return nothing;
  }

  private async openRecurrenceDialog() {
    const parentUuid = this.getTemplateMeeting(this.entityView.name)?.uuid || '';
    let eventName = this.entityView.name;
    if (this.isEmployeeInterview(this.entityView.name)) {
      const employee = this.entityView.employeeFullNames.find((e) => {
        return e.uuid === this.entityView.assignedToEmployees[0];
      });
      if (employee) {
        eventName += ' med ' + employee.name;
      }
    }
    const input: OccurrencesDialogInput = {
      parentUuid,
      modelOccurrence: this.entityView.occurrences.find((o) => {
        return o.occurrenceUuid === this.entityView.uuid;
      }) ?? { occurrenceUuid: '', dateTime: '', durationMinutes: 0, classified: false, restricted: false },
      occurrences: this.entityView.occurrences,
      eventType: 'meeting',
      eventName,
    };
    const result: OccurrencesDialogResult = await DEditOccurrencesDialog.open(input);
    if (result.action === 'done') {
      this.fireRepeatMeetingOccurrenceEvent(result.occurrences);
    }
  }

  private async openTemplateDialog(item: MeetingOccurrenceViewEditItem) {
    let content = '';
    const templateMeeting = this.getTemplateMeeting(item.name);
    if (templateMeeting) {
      content = templateMeeting.agenda;
    }
    const result: TemplateDialogResult = await DTemplateDialog.open({
      title: 'Forslag til agenda for ' + this.getTemplateMeeting(item.name)?.name,
      content,
    });
    if (result.action === 'done') {
      this.editItem = { ...item, meetingAgenda: content };
    }
    return result.action;
  }

  private agendaAction(item: MeetingOccurrenceViewEditItem) {
    let templateMeetingAgenda = '';
    const templateMeeting: TemplateMeeting | undefined = this.getTemplateMeeting(item.name);
    if (templateMeeting) {
      templateMeetingAgenda = templateMeeting.agenda;
    }
    if (isEmptyHtmlContent(templateMeetingAgenda)) {
      return '';
    }
    if (!isSameHtmlContent(templateMeetingAgenda, item.meetingAgenda || '')) {
      return 'Se forslag';
    }
    return '';
  }

  private fireDeleteEvent(saveType: SaveType) {
    this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
    this.dispatchEvent(
      new CustomEvent<{ saveType: SaveType }>('delete-meeting-occurrence', {
        bubbles: true,
        composed: true,
        detail: { saveType: saveType },
      }),
    );
  }

  private responsibleField(isEmployeeInterview: boolean): string {
    if (isEmployeeInterview) {
      return 'meetings_employeeInterviewLeader';
    }
    return 'meetings_responsible';
  }

  private participantsField(isEmployeeInterview: boolean): string {
    if (isEmployeeInterview) {
      return 'meetings_employeeInterviewEmployee';
    }
    return 'meetings_participants';
  }

  private async createMeetingReport() {
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; href: string }>('create-meeting-report', {
        bubbles: true,
        composed: true,
        detail: { uuid: this.entityView.uuid, href: this.entityView.href },
      }),
    );
  }

  private showMeetingUpdateSection() {
    const e = this.entityView;
    if (e.date && e.lastModified && e.templateUpdated) {
      const eventDate = e.date;
      const lastModified = e.lastModified.split(' ')[0];
      const templateUpdated = e.templateUpdated.split('T')[0];
      return (
        LocalDate.fromString(eventDate).isSameOrAfter(LocalDate.now()) &&
        LocalDate.fromString(lastModified).isBefore(LocalDate.fromString(templateUpdated))
      );
    }
    return false;
  }

  private async sendMeetingMessage(e: MeetingOccurrenceView, type: 'notice' | 'report') {
    const input: MeetingMessageDialogInput = {
      type: type,
      name: this.entityView.name,
      preventIncludeContent: this.entityView.meetingReportIsClassified,
      isEmployeeInterview: this.isEmployeeInterview(this.entityView.name),
      recipients: this.entityView.messageRecipients,
    };
    const result: MeetingMessageDialogResult = await MeetingMessageDialog.open(input);
    this.handleMeetingResult(type, result);
  }

  private handleMeetingResult(type: string, result: MeetingMessageDialogResult) {
    if (result.action === 'send') {
      this.fireSendMessageEvent(result, type);
    }
  }

  private fireSendMessageEvent(result: { action: 'send'; message: string; includeContent: boolean }, type: string) {
    const recipientsWithAccess = this.entityView.messageRecipients.filter((p) => {
      return p.accessLevel !== 'NONE';
    });
    if (recipientsWithAccess.length > 0) {
      const messageData = {
        occurrenceUuid: this.entityView.uuid,
        message: result.message,
        includeContent: result.includeContent,
      };
      this.fireEvent(type, messageData);
    }
  }

  private fireEvent(type: string, messageData: MessageForSend) {
    if (type === 'notice') {
      this.dispatchEvent(
        new CustomEvent<MessageForSend>('send-meeting-notice', {
          bubbles: true,
          composed: true,
          detail: messageData,
        }),
      );
    }
    if (type === 'report') {
      this.dispatchEvent(
        new CustomEvent<MessageForSend>('send-meeting-report', {
          bubbles: true,
          composed: true,
          detail: messageData,
        }),
      );
    }
  }

  private isEmployeeInterview(name: string): boolean {
    return name.toLowerCase().includes('medarbeidersamtale');
  }

  private onNameFocused(item: MeetingOccurrenceViewEditItem) {
    this.editItem = { ...item, nameConfirmed: false };
  }

  private onNameCancel(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    if (this.entityView.newItem) {
      return this.onDelete();
    } else {
      this.editItem = { ...item, name: this.entityView.name, nameConfirmed: true };
    }
  }

  private onNameDone(e, item: MeetingOccurrenceViewEditItem) {
    e.stopPropagation();
    const name = e.detail.name;
    const meetingUuid = e.detail.masterEntityUuid;
    const editItem = {
      ...item,
      name,
      meetingUuid,
      nameConfirmed: true,
    };
    const modelMeeting: ModelMeeting | undefined = this.entityView.modelMeetings.find((m) => {
      return m.name === e.detail.name;
    });
    if (modelMeeting) {
      editItem.time = modelMeeting.time;
      editItem.durationMinutes = modelMeeting.durationMinutes;
      editItem.meetingAgenda = modelMeeting.agenda;
      editItem.classification = modelMeeting.classification;
      editItem.accessControl = [...modelMeeting.accessControl];
      editItem.meetingResponsible = modelMeeting.meetingResponsible;
      editItem.assignedToEmployees = [...modelMeeting.employees];
      if (this.isEmployeeInterview(name)) {
        editItem.name = 'Medarbeidersamtale';
        editItem.classification = 'CONFIDENTIAL';
        const responsibleFunction = this.entityView.functions.find((f) => {
          return f.uuid === modelMeeting.meetingResponsible;
        });
        const accessControl: string[] = [];
        if (responsibleFunction) {
          accessControl.push(responsibleFunction.employees[0]);
          if (!accessControl.includes(modelMeeting.meetingUuid)) {
            accessControl.push(modelMeeting.meetingUuid);
          }
          if (this.entityView.currentEmployeeUuid && !accessControl.includes(this.entityView.currentEmployeeUuid)) {
            accessControl.push(this.entityView.currentEmployeeUuid);
          }
          editItem.accessControl = accessControl;
        }
      }
    }
    this.editItem = editItem;
  }

  private name(entityView: MeetingOccurrenceView, item: MeetingOccurrenceViewEditItem) {
    let result = item.name;
    if (this.isEmployeeInterview(item.name)) {
      let employeeName = '';
      const employee = entityView.employeeFullNames.find((e) => {
        return e.uuid === item.assignedToEmployees[0];
      });
      if (employee) {
        employeeName = employee.name;
      }
      result = 'Medarbeidersamtale med ' + employeeName;
    }
    return result;
  }

  private renderMeetingEditItem(item: MeetingOccurrenceViewEditItem) {
    return html`
      <d-edit-entity-name
        .autofocus=${!item.nameConfirmed && !this.newFromMeeting}
        .newItem=${this.entityView.newItem}
        .entityType=${'meeting'}
        .placeholder=${'Gi møtet et navn'}
        .parentHref=${this.parentHref}
        .name=${this.name(this.entityView, item)}
        .originalName=${this.name(this.entityView, item)}
        .currentEntityUuid=${this.entityView.uuid}
        .masterEntityUuid=${this.getTemplateMeeting(item.name)?.uuid || ''}
        .models=${this.entityView.modelMeetings.map((m) => {
          return {
            name: m.name,
            type: 'meetings',
            masterEntityUuid: m.meetingUuid,
            futureOccurrences: m.futureOccurrences,
          };
        })}
        .bypassConfirmations=${item.nameConfirmed}
        .organizationId=${this.entityView.organizationId}
        @name-focused=${() => this.onNameFocused(item)}
        @cancel=${(e) => this.onNameCancel(e, item)}
        @done=${(e) => this.onNameDone(e, item)}
      ></d-edit-entity-name>
      <d-smooth-resize>
        ${item.nameConfirmed
          ? html`
              <d-section>
                <d-edit-event-timing
                  .date=${item.date}
                  .time=${item.time === '' ? 'NONE' : item.time}
                  .durationMinutes=${item.durationMinutes}
                  @value-changed=${(e: CustomEvent<EventTiming>) => {
                    e.stopPropagation();
                    if (e.detail.eventType === 'TODO') {
                      throw new Error('Illegal state. Meetings must be scheduled.');
                    } else {
                      this.editItem = {
                        ...item,
                        date: e.detail.date,
                        time: e.detail.time,
                        durationMinutes: e.detail.durationMinutes,
                      };
                    }
                  }}
                  is-meeting
                >
                </d-edit-event-timing>
              </d-section>

              <d-section>
                <d-select-dropdown
                  field="${this.responsibleField(this.isEmployeeInterview(item.name))}"
                  placeholder="Velg ansvarsområde"
                  .options=${this.entityView.availableFunctions}
                  .value=${item.meetingResponsible}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    e.stopPropagation();
                    this.editItem = {
                      ...item,
                      meetingResponsible: e.detail.value,
                    };
                  }}
                >
                </d-select-dropdown>
              </d-section>

              ${this.singleUserVersion
                ? nothing
                : html` ${this.isEmployeeInterview(item.name)
                    ? html`
                        <d-section>
                          <d-view-text
                            inline-label
                            label="Medarbeider"
                            .value=${this.entityView.employeeFullNames.find(
                              (e) => e.uuid === item.assignedToEmployees[0],
                            )?.name || ''}
                          ></d-view-text>
                        </d-section>
                      `
                    : html`
                        <d-section>
                          <d-edit-assignee
                            .assignedToEmployees=${item.assignedToEmployees}
                            .defaultEmployee="${this.entityView.defaultEmployee}"
                            .defaultFunction="${this.entityView.defaultFunction}"
                            .functions=${[]}
                            .employees=${this.entityView.availableEmployees}
                            @assignment-changed=${(
                              e: CustomEvent<{
                                assignedToEmployees: string[];
                                assignedToFunction: string;
                                assignedToContacts: string[];
                              }>,
                            ) => {
                              e.stopPropagation();
                              this.editItem = {
                                ...item,
                                assignedToEmployees: e.detail.assignedToEmployees,
                              };
                            }}
                            ?isMeeting=${true}
                          >
                          </d-edit-assignee>
                        </d-section>
                      `}`}

              <d-section>
                <d-edit-reminder
                  ?disabled=${item.time === 'NONE'}
                  .value=${item.reminder}
                  @value-changed=${(e: CustomEvent<{ value: ReminderType }>) => {
                    e.stopPropagation();
                    this.editItem = { ...item, reminder: e.detail.value };
                  }}
                >
                </d-edit-reminder>
              </d-section>

              ${this.entityView.isEmployee
                ? html`
                    <d-section>
                      <d-edit-classification
                        .accessControl=${item.accessControl}
                        .accessControlOptions=${this.entityView.accessControlOptions}
                        .classification=${item.classification}
                        @value-changed=${(
                          e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>,
                        ) => {
                          e.stopPropagation();
                          this.editItem = {
                            ...item,
                            accessControl: e.detail.accessControl,
                            classification: e.detail.classification,
                          };
                        }}
                      ></d-edit-classification>
                    </d-section>
                  `
                : nothing}

              <d-edit-html
                field="meetings_agenda"
                label-action-plain
                .labelAction=${this.agendaAction(item)}
                .contentStickyTop=${this.contentStickyTop}
                @label-action=${() => this.openTemplateDialog(item)}
                .value=${item.meetingAgenda}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editItem = { ...item, meetingAgenda: e.detail.value };
                }}
                .docsForLinking=${this.entityView.docsForLinking}
              ></d-edit-html>
            `
          : nothing}
      </d-smooth-resize>
    `;
  }

  private fireRepeatMeetingOccurrenceEvent(occurrences: Occurrence[]) {
    const existingDateTimes = this.entityView.occurrences.map((e) => toDateTimeDuration(e));
    const existingUuids = this.entityView.occurrences.map((e) => e.occurrenceUuid);
    const updatedOccurrenceUuids = occurrences.map((e) => e.occurrenceUuid);
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; dateTimesToAdd: string[]; occurrencesToRemove: string[] }>(
        'repeat-meeting-occurrence',
        {
          bubbles: true,
          composed: true,
          detail: {
            uuid: this.entityView.uuid,
            dateTimesToAdd: occurrences.map((e) => toDateTimeDuration(e)).filter((e) => !existingDateTimes.includes(e)),
            occurrencesToRemove: existingUuids.filter((e) => !updatedOccurrenceUuids.includes(e)),
          },
        },
      ),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meeting-occurrence-view': DMeetingOccurrenceView;
  }
}
