import type { PageViewModel } from 'src/store/api';
import type { State } from 'src/store/types.js';
import type { MeetingsPageMeeting, MeetingsPageViewModel } from 'src/pages/meetings-page/meetings-page-view-model.js';
import * as dabihStore from 'src/store';
import { MeetingModel, now } from 'src/store';
import { orderBy } from 'lodash';
import { LocalDate } from 'src/utilities/local-date';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';

function toMeeting(m: MeetingModel, today: LocalDate, hrefPrefix: string): MeetingsPageMeeting {
  let name = m.name;
  if (m.type === 'INTERVIEW_TERMINATED' || m.type === 'INTERVIEW_ACTIVE') {
    name = m.interviewEmployeeName;
  }
  const result = {
    uuid: m.uuid,
    name,
    suggested: m.type === 'SUGGESTED',
    prevDate: '',
    prevText: '',
    prevClassified: false,
    prevRestricted: false,
    prevHref: '',
    nextDate: '',
    nextText: '',
    nextClassified: false,
    nextRestricted: false,
    nextHref: '',
    currentUserHasReadAccess: true, // TODO
    updateStatus: calculateUpdateStatus(m, today), // TODO
    occurrences: m.meetingOccurrences,
    employeeUuid:
      m.type === 'INTERVIEW_TERMINATED' || m.type === 'INTERVIEW_ACTIVE' ? m.interviewEmployeeUuid : undefined,
    meetingUuid: m.type === 'INTERVIEW_TERMINATED' || m.type === 'INTERVIEW_ACTIVE' ? undefined : m.meetingUuid,
  };
  const previousOccurrences = m.meetingOccurrences.filter((e) => LocalDate.fromString(e.date).isBefore(today));
  const prevOccurrence = orderBy(previousOccurrences, [(o) => o.date], ['desc'])[0];
  if (prevOccurrence !== undefined) {
    let prevHref = prevOccurrence.restricted ? '' : hrefPrefix + 'meetingOccurrences/' + prevOccurrence.uuid;
    if (prevHref && prevOccurrence.isNew) {
      prevHref += '?edit';
    }
    result.prevDate = prevOccurrence.date;
    result.prevText = LocalDate.fromString(prevOccurrence.date).toStringForDisplayWithYear();
    result.prevClassified = prevOccurrence.classified;
    result.prevRestricted = prevOccurrence.restricted;
    result.prevHref = prevHref;
  }
  const nextOccurrences = m.meetingOccurrences.filter((e) => LocalDate.fromString(e.date).isSameOrAfter(today));
  const nextOccurrence = orderBy(nextOccurrences, [(o) => o.date], ['asc'])[0];
  if (nextOccurrence !== undefined) {
    let nextHref = nextOccurrence.restricted ? '' : hrefPrefix + 'meetingOccurrences/' + nextOccurrence.uuid;
    if (nextHref && nextOccurrence.isNew) {
      nextHref += '?edit';
    }
    result.nextDate = nextOccurrence.date;
    result.nextText = LocalDate.fromString(nextOccurrence.date).toStringForDisplayWithYear();
    result.nextClassified = nextOccurrence.classified;
    result.nextRestricted = nextOccurrence.restricted;
    result.nextHref = nextHref;
  }
  return result;
}

function toMeetings(m: MeetingModel[], today: LocalDate, hrefPrefix: string): MeetingsPageMeeting[] {
  return m.map((x) => toMeeting(x, today, hrefPrefix));
}

export function meetingsPageView(hrefPrefix: string, viewModel: PageViewModel, state: State): MeetingsPageViewModel {
  const currentEmployeeUuid = dabihStore.currentEmployeeUuid(state);
  const meetings = dabihStore.meetings(state);
  const futureMeetings = meetings.filter((meeting) => {
    return meeting.type === 'FUTURE';
  });
  const pastMeetings = meetings.filter((meeting) => {
    return meeting.type === 'PAST';
  });
  const suggestedMeetings = meetings.filter((meeting) => {
    return meeting.type === 'SUGGESTED';
  });
  const activeEmployeeMeetings = meetings.filter((meeting) => {
    return meeting.type === 'INTERVIEW_ACTIVE';
  });
  const terminatedEmployeeMeetings = meetings.filter((meeting) => {
    return meeting.type === 'INTERVIEW_TERMINATED' && meeting.meetingOccurrences.length > 0;
  });
  const today = now(state);
  return {
    type: 'meetings-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: dabihStore.writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid,
    organizationId: dabihStore.getOrganizationId(state),
    icon: 'meetings',
    key: dabihStore.key(state),
    tasks: dabihStore.tasksNotDeletedWithFunctionName(state),
    meetings: orderBy(
      toMeetings(meetings, today, hrefPrefix),
      ['nextDate', 'prevDate', 'suggested', 'uuid'],
      ['desc', 'desc', 'asc', 'asc'],
    ),
    futureMeetings: orderBy(toMeetings(futureMeetings, today, hrefPrefix), ['nextDate', 'uuid'], ['asc']),
    pastMeetings: orderBy(toMeetings(pastMeetings, today, hrefPrefix), ['prevDate', 'uuid'], ['desc']),
    suggestedMeetings: orderBy(
      toMeetings(suggestedMeetings, today, hrefPrefix),
      [(e) => e.name.toLowerCase(), 'uuid'],
      ['asc'],
    ),
    activeEmployeeInterviews: orderBy(
      toMeetings(activeEmployeeMeetings, today, hrefPrefix),
      [(e) => e.name.toLowerCase(), 'uuid'],
      ['asc'],
    ),
    terminatedEmployeeInterviews: orderBy(
      toMeetings(terminatedEmployeeMeetings, today, hrefPrefix),
      [(e) => e.name.toLowerCase(), 'uuid'],
      ['asc'],
    ),
  };
}
