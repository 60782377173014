import type { PageViewModel } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { getOrganizationId, pageViewModel } from 'src/store';
import { staffingPageView } from 'src/models/pages/staffing-page-view.js';
import type { PageView } from 'src/pages/d-page.js';
import { computersPageView } from 'src/models/pages/computers-page-view.js';
import { meetingsPageView } from 'src/models/pages/meetings-page-view.js';
import { overviewPageView } from 'src/models/pages/overview-page-view.js';
import { assetsPageView } from 'src/models/pages/assets-page-view.js';
import { substancesPageView } from 'src/models/pages/substances-page-view.js';
import { referencesPageView } from 'src/models/pages/references-page-view.js';
import { organizationPageView } from 'src/models/pages/organization-page-view.js';
import { homePageView } from 'src/models/pages/home-page-view.js';
import { trashPageView } from 'src/models/pages/trash-page-view.js';

function addInternalLinks(helpContent: string, pages: PageViewModel[], organizationId: number) {
  let newContent = helpContent;
  for (const element of pages) {
    newContent = fixLinks(newContent, element.pageId, element.name, organizationId);
    if (element.subPages) {
      for (const item of element.subPages) {
        newContent = fixLinks(newContent, item.pageId, item.name, organizationId);
      }
    }
  }
  return newContent;
}

/**
 *
 * @param content
 * @param pageId
 * @param pageName
 * @param organizationId
 */
function fixLinks(content: string, pageId: number, pageName: string, organizationId: number) {
  const regex = new RegExp('href="' + pageName + '"', 'gi');
  const replacement = 'href="/account/' + organizationId + '/' + pageId + '"';
  return content.replace(regex, replacement);
}

export function pageView(hrefPrefix: string, currentPageId: number, pages: PageViewModel[], state: State): PageView {
  const viewModelWithInternalLinks = pageViewModel(currentPageId, pages);
  const viewModel = {
    ...viewModelWithInternalLinks,
    helpContent: addInternalLinks(viewModelWithInternalLinks.helpContent, pages, getOrganizationId(state)),
  };
  switch (currentPageId) {
    case 2300:
      return homePageView(hrefPrefix, viewModel, state);
    case 104:
      return organizationPageView(hrefPrefix, viewModel, state);
    case 5962:
      return staffingPageView(hrefPrefix, viewModel, state);
    case 9772:
      return meetingsPageView(hrefPrefix, viewModel, state);
    case 20:
      return computersPageView(hrefPrefix, viewModel, state);
    case 110:
      return assetsPageView(hrefPrefix, viewModel, state);
    case 12368:
      return substancesPageView(hrefPrefix, viewModel, state);
    case 2250:
      return referencesPageView(hrefPrefix, viewModel, state);
    case 3482:
      return trashPageView(hrefPrefix, viewModel, state);
    default:
      return overviewPageView(hrefPrefix, viewModel, state);
  }
}
