import fields from 'src/store/data/fields.json';

export interface FieldViewModel {
  /**
   *
   * @type {string}
   * @memberof FieldViewModel
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof FieldViewModel
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof FieldViewModel
   */
  tooltip: string;
}

export function filterFieldsByVariant(variantName: string) {
  const result: { [key: string]: FieldViewModel } = {};
  fields.forEach(function (item) {
    if (
      item.mainServiceType === '' ||
      (variantName === 'COMMON' && item.mainServiceType === 'Q') ||
      (variantName === 'BASE' && item.mainServiceType === 'X')
    ) {
      result[item.field] = item;
    }
  });
  return result;
}

export function commonFieldsByCode() {
  return filterFieldsByVariant('COMMON');
}

/**
 * Kan brukes til å sjekke om det fins duplikater i fields
 */
export function duplicateFields() {
  const fieldNames = fields.map((m) => m.field);
  return fieldNames.filter((item, index) => fieldNames.indexOf(item) !== index);
}
