import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { EditPeriodResult, EditPeriodsDialog, EditPeriodsResult } from 'src/pages/edit-periods-dialog.js';
import {
  EditTimekeepingCorrectionDialog,
  EditTimekeepingCorrectionResult,
} from 'src/pages/edit-timekeeping-correction-dialog.js';
import type { EmployeeForStaffingCalendar, TimekeepingPeriod } from 'src/store/selectors';
import { uuid } from 'src/utilities/text.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../fields/d-expansion.js';
import './d-list-header.js';
import './d-list-section.js';
import type { TimekeepingCorrectionCorrectionTypeEnum } from 'src/store/api';

export class UpdateTimeCorrectionEvent extends CustomEvent<{
  correctionType: TimekeepingCorrectionCorrectionTypeEnum;
  hours: number;
  date: string;
  notes: string;
  employeeUuid: string;
  uuid: string;
}> {}
export class DeleteTimeCorrectionEvent extends CustomEvent<{
  uuid: string;
  employeeUuid: string;
}> {}

/**
 *
 *
 * @fires update-time-correction
 *  @fires delete-time-correction
 */
@customElement('d-timekeeping-list-section')
export class DTimekeepingListSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .saldo {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      padding: 5px 0;
      font-weight: 500;
    }

    .saldo div:last-child {
      font-weight: bold;
    }

    .saldo div.plus {
      color: green;
    }

    .saldo div.minus {
      color: #990000;
    }

    .periods {
      max-height: 286px;
      overflow: auto;
    }

    d-action {
      margin-top: 2px;
      margin-left: calc(var(--listPaddingLeft) - 8px);
    }

    @media only screen and (max-width: 600px) {
      d-action {
        padding-left: 0;
      }
    }
  `;

  periodTypes = [
    { value: 'plusTime', name: 'Plusstid' },
    { value: 'timeOff', name: 'Avspasering' },
  ];
  @property({ type: Object })
  organization: any = {};
  @property({ type: Array })
  employees: any[] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: Boolean })
  review = false;
  @property({ type: String })
  listHeader = '';
  @property({ type: String, attribute: 'timekeeping', reflect: true })
  icon = 'timekeeping';
  @property({ type: Array })
  periods: ListSectionItemInput[] = [];
  @property({ type: Boolean })
  showAll = false;
  @property({ type: Number })
  saldo = 0;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  @state()
  editTimeEntry?: {
    day: string;
    type: string;
    newPeriod: boolean;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    confirmed: boolean;
    notes: string;
  };
  /**
   * The default date for new entries. Normally today.
   */
  @property({ type: String })
  defaultDate = '';
  @property({ type: String })
  periodList = '';
  @property({ type: String })
  periodStart = '';
  @property({ type: String })
  correctionDay = '';
  @property({ type: Number })
  contentStickyTop = 0;

  private get lastPeriods() {
    return this.periodsWithAction.slice(0, 2);
  }

  private get olderPeriods() {
    return this.periodsWithAction.slice(2);
  }

  private get balanceType() {
    if (this.saldo > 0) {
      return 'plus';
    } else {
      return 'minus';
    }
  }

  private get actions() {
    const actions: ActionInput[] = [
      {
        name: 'Plusstid',
        action: 'addPlusTime',
        slot: 'top-right',
      },
      {
        name: 'Avspasering',
        action: 'addTimeOff',
        slot: 'top-right',
      },
    ];
    if (this.review) {
      actions.push({
        name: 'Avregning',
        action: 'addCorrection',
        slot: 'top-right',
      });
    }
    return actions;
  }

  private get periodsWithAction(): ListSectionItemInput[] {
    return this.periods.map((p) => ({
      ...p,
      clickHandler: () => this.onEditPeriod(p),
    }));
  }

  render() {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header
        theme-page
        icon=${this.icon}
        .field=${this.review ? undefined : 'staffing_plusAndMinusHours'}
        .label=${this.listHeader}
        .actions=${this.actions}
        @action=${(e) => this.onAction(e.detail)}
        style=${styleMap(listHeaderStyles)}
      >
        ${this.periodsWithAction.length === 0
          ? nothing
          : html`
              <div class="saldo" slot="bottom">
                <div>Saldo</div>
                <div class="${this.balanceType}">${this.balanceDisplay()}</div>
              </div>
            `}
      </d-list-header>
      <div class="periods">
        <d-list-section no-header .items=${this.lastPeriods} no-item-text="Ingen perioder registrert"></d-list-section>
        <d-expansion ?opened=${this.showAll}>
          <d-list-section no-header .items=${this.olderPeriods}></d-list-section>
        </d-expansion>
      </div>
      ${this.olderPeriods.length === 0
        ? nothing
        : html`<d-action mini @click=${() => this.onToggleShowAll()}>
            ${this.showAll ? 'Vis færre' : 'Vis flere'}
          </d-action>`}
    `;
  }

  private balanceDisplay(): string {
    const s = this.saldo.toFixed(2);
    let prefix = '';
    if (this.saldo > 0) {
      prefix = '+ ';
    }
    return prefix + s.replace('-', '- ').replace('.', ',') + ' t';
  }

  private onToggleShowAll() {
    this.showAll = !this.showAll;
  }

  private async onAddPlusTime() {
    await this.doAddTime('plusTime', 'save-timekeeping-period');
  }

  private async doAddTime(type: string, eventName: string) {
    const result: EditPeriodsResult = await EditPeriodsDialog.open({
      application: 'timekeeping',
      newPeriod: true,
      startDate: this.defaultDate,
      endDate: this.defaultDate,
      startTime: 'NONE',
      endTime: 'NONE',
      employee: this.employee,
      notes: '',
      confirmed: false,
      grade: 100,
      type: type,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
      currentUserHasAccess: this.currentUserHasAccess,
    });

    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        grade: result.grade,
        employeeUuid: this.employee.uuid,
        newPeriod: true,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>(eventName, {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
    }
  }

  private async addTimeOff() {
    await this.doAddTime('timeOff', 'save-leave-period');
  }

  private async addCorrection() {
    const result: EditTimekeepingCorrectionResult = await EditTimekeepingCorrectionDialog.open({
      newItem: true,
      day: this.defaultDate,
      hours: 0,
      type: 'minus',
      notes: '',
      employeeName: this.employee.displayName,
      corrections: this.employee.timekeepingCorrections ?? [],
    });
    if (result.action === 'done') {
      this.dispatchEvent(
        new UpdateTimeCorrectionEvent('update-time-correction', {
          composed: true,
          bubbles: true,
          detail: {
            date: result.date,
            correctionType: result.type,
            hours: result.hours,
            notes: result.notes,
            uuid: uuid(),
            employeeUuid: this.employee.uuid,
          },
        }),
      );
    }
  }

  private async editCorrection(period: TimekeepingPeriod) {
    if (period.id) {
      const result: EditTimekeepingCorrectionResult = await EditTimekeepingCorrectionDialog.open({
        newItem: false,
        day: period.start,
        hours: period.minutes / 60,
        type: period.type === 'correction' ? 'minus' : 'plus',
        notes: period.notes,
        employeeName: this.employee.displayName,
        corrections: this.employee.timekeepingCorrections ?? [],
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new UpdateTimeCorrectionEvent('update-time-correction', {
            composed: true,
            bubbles: true,
            detail: {
              date: result.date,
              correctionType: result.type,
              hours: result.hours,
              notes: result.notes,
              uuid: period.id || uuid(),
              employeeUuid: this.employee.uuid,
            },
          }),
        );
      } else if (result.action === 'delete') {
        this.dispatchEvent(
          new DeleteTimeCorrectionEvent('delete-time-correction', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: period.id,
              employeeUuid: this.employee.uuid,
            },
          }),
        );
      }
    }
  }

  private async onEditPeriod(p: ListSectionItemInput) {
    if (p.clickData === undefined) {
      throw new Error('Illegal state (E111), list section timekeeping contains no data ' + JSON.stringify(p));
    }
    const period: TimekeepingPeriod = JSON.parse(p.clickData);
    if (!period.confirmed || this.currentUserHasAccess || !this.leavePeriodEditRestriction) {
      if (period.type === 'correction' || period.type === 'correctionPlus') {
        await this.editCorrection(period);
      } else if (period.type === 'plusTime') {
        await this.doEditPeriod(period, 'save-timekeeping-period', 'delete-timekeeping-period');
      } else {
        await this.doEditPeriod(period, 'save-leave-period', 'delete-leave-period');
      }
    }
  }

  private async doEditPeriod(period: TimekeepingPeriod, saveEventName: string, deleteEventName: string) {
    const result: EditPeriodsResult = await EditPeriodsDialog.open({
      application: 'timekeeping',
      newPeriod: false,
      startDate: period.start,
      endDate: period.end,
      startTime: period.startTime ?? 'NONE',
      endTime: period.endTime ?? 'NONE',
      notes: period.notes,
      confirmed: period.confirmed,
      employee: this.employee,
      type: period.type,
      grade: 100,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
      currentUserHasAccess: this.currentUserHasAccess,
    });
    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        grade: result.grade,
        employeeUuid: this.employee.uuid,
        newPeriod: false,
        periodId: period.id,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>(saveEventName, {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
    }
    if (result.action === 'delete') {
      this.dispatchEvent(
        new CustomEvent<{ employeeUuid: string; periodId: string }>(deleteEventName, {
          bubbles: true,
          composed: true,
          detail: { employeeUuid: this.employee.uuid, periodId: period.id ?? '' },
        }),
      );
    }
  }
  private async onAction(action) {
    if (action === 'addPlusTime') {
      await this.onAddPlusTime();
    }
    if (action === 'addTimeOff') {
      await this.addTimeOff();
    }
    if (action === 'addCorrection') {
      await this.addCorrection();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-timekeeping-list-section': DTimekeepingListSection;
  }
}
