import { css, html, nothing } from 'lit';
import './d-label.js';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

/**
 *
 * A container for fields or editors. Provides spacing and borders between elements.
 * Wraps content based on available width.
 *
 * STATUS OK
 */
@customElement('d-section')
class DSection extends ResponsiveElement {
  static readonly styles = css`
    :root {
      --border-left-width: 4px;
    }

    :host {
      display: block;
      position: relative;
      border-top: 1px solid var(--borderColorTransparent);
    }

    :host([light-border]) {
      border-top: 1px solid var(--borderColorLight);
    }

    :host([topless]) {
      border-top: none;
    }

    :host([borderless]) {
      padding-bottom: 6px;
    }

    :host([sticky]) {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }

    :host([sticky][theme-page]) {
      background: var(--backgroundGray);
    }

    .header {
      display: block;
      flex: none;
      width: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      margin-bottom: -1px;
      border-bottom: 1px solid var(--borderColor);
      padding: 12px 0 10px 0;
      background: white;
      z-index: 1;
    }

    :host([theme-page]) .header {
      background: var(--backgroundGray);
      border-bottom: 1px solid var(--borderColorOnGray);
    }

    :host([map-element]) .header {
      background: var(--map-element-background-color);
    }

    :host([borderless]) .header {
      border-bottom: none;
      background: var(--map-element-background-color);
      padding-bottom: 8px;
    }

    /* Cannot use sticky header in outskirts because background color cannot be determined */
    :host([outskirts]) .header {
      position: relative;
      border-bottom: 1px solid var(--borderColorTransparent);
      background: transparent;
    }

    .cropper {
      overflow: hidden;
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
    }

    :host([vertical]) .wrapper {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    /* styles slotted children with padding and borders top and left */
    ::slotted(*:not(d-expansion[in-section])) {
      flex-grow: 1;
      border-image-source: url(/images/section-element-border-image.svg);
      /* border-image provides border-left */
      border-top-width: 12px;
      /* border-top-width appears as padding-top */
      border-bottom-width: 12px;
      /* border-bottom-width appears as padding-bottom */
      border-left-width: 1px;
      border-right-width: 0;
      border-style: solid;
      border-image-slice: 25%;
      border-image-repeat: repeat;
      padding: 0 12px;
      box-shadow: 0 -1px var(--borderColorTransparent);
      /* box-shadow appears as border-top */
    }

    ::slotted(*.minWidth200) {
      min-width: 200px;
    }

    ::slotted(*.minWidth300) {
      min-width: 300px;
    }

    :host([topless]) ::slotted(*) {
      border-top-width: 0;
      /* border-top-width appears as padding-top */
    }

    :host([borderless]) ::slotted(*) {
      border-top-width: 0;
      border-bottom-width: 6px;
      border-left-width: 0;
      box-shadow: none;
    }
  `;

  private get noHeader() {
    return !this.label && !this.field;
  }

  @property({ type: String })
  field = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  subfield = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: Boolean, reflect: true })
  themePage = false;
  @property({ type: Boolean, reflect: true })
  topless = false;
  @property({ type: Boolean, reflect: true })
  lightBorder = false;
  @property({ type: Boolean, reflect: true })
  sticky = false;
  @property({ type: Number })
  contentStickyTop = 0;

  render() {
    const headerStyles = { top: this.contentStickyTop + 'px' };
    return html`
      ${this.noHeader
        ? nothing
        : html`
            <div class="header" style=${styleMap(headerStyles)}>
              <d-label
                .field=${this.field}
                .label=${this.label}
                .subfield=${this.subfield}
                .sublabel=${this.sublabel}
                big
              ></d-label>
            </div>
          `}
      <div class="cropper">
        <div class="wrapper">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-section': DSection;
  }
}
