import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { DEditText } from '../../editors/elements/d-edit-text';
import '../../elements/d-smooth-resize';
import { LabeledElement } from 'src/library/abstracts/labeled-element';

export interface Suggestion {
  name: string;
  type: string;
}

/**
 *
 */
@customElement('d-edit-select-text')
export class DEditSelectText extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        line-height: var(--lineHeightDefault);
      }
      #options {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-height: 190px;
        margin: 0 -1px;
        border-left: 1px solid var(--borderColorLight);
        border-right: 1px solid var(--borderColorLight);
        border-bottom: 1px solid var(--borderColorLight);
        overflow: auto;
        background: white;
        z-index: 1;
      }
      .option {
        border-top: 1px solid var(--borderColorLight);
        background-size: 22px;
        background-repeat: no-repeat;
        background-position: 4px 5px;
        padding: 6px 14px 6px 30px;
        font-family: var(--mainSerif);
        color: var(--linkColorGray);
        cursor: pointer;
        text-decoration: none;
      }
      .option:focus-visible,
      .option[selected] {
        outline: none;
        background-color: var(--themeColor);
        color: white;
      }
      .option.events {
        background-image: url(/images/events-gray.svg);
      }
      .option.tasks {
        background-image: url(/images/tasks-gray.svg);
      }
      .option.meetings {
        background-image: url(/images/meetings-gray.svg);
      }
      @media (hover: hover) {
        .option:hover {
          color: black;
        }
        .option:focus-visible:hover,
        .option[selected]:hover {
          color: white;
        }
      }
    `,
  ];

  @query('#input')
  editText!: DEditText;
  @property({ type: Boolean })
  button = false;
  @property({ type: Boolean })
  autofocus = false;
  @property({ type: Boolean, attribute: 'select-on-focus' })
  selectOnFocus = false;
  @property({ type: String })
  value = '';
  @property({ type: Array })
  items: Suggestion[] = [];
  @property({ type: Array })
  options: Suggestion[] = [];
  @property({ type: String })
  selectedItem = '';
  @query('d-edit-text')
  inputElm!: DEditText;
  @query('#options')
  optionsElm!: HTMLElement;
  @property({ type: String })
  placeholder = '';
  onKeyDown(event) {
    if (this.options.length) {
      const focusedElm = this.shadowRoot?.activeElement;
      if (focusedElm) {
        if (event.code === 'ArrowDown') {
          this.handleArrowDown(event, focusedElm);
        } else if (event.code === 'ArrowUp') {
          this.handleArrowUp(event, focusedElm);
        } else if (event.code === 'Enter') {
          this.handleEnter(event, focusedElm);
        }
      }
    } else if (event.code === 'Enter') {
      this.dispatchValueSelected();
    }
  }

  focusInput() {
    this.editText.setFocus();
  }

  setValue(e) {
    this.value = e.target.textContent;
    this.dismissOptions();
    this.dispatchValueSelected();
  }

  dispatchValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  dispatchValueSelected() {
    this.dispatchEvent(
      new CustomEvent('value-selected', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  dismissOptions() {
    this.options = [];
  }

  onValueChanged(e) {
    e.stopPropagation();
    this.value = e.detail.value;
    if (this.value.length > 0 && this.items.length) {
      this.options = this.items.filter((item) => {
        return item.name.toUpperCase().includes(this.value.toUpperCase());
      });
    } else {
      this.options = [];
    }
    this.dispatchValueChanged();
  }

  render() {
    return html`
      <d-edit-text
        id="input"
        .autofocus=${this.autofocus}
        select-on-focus
        .button=${this.button}
        mark-if-empty
        .buttonDisabled=${!this.value}
        .label=${this.label}
        .sublabel=${this.sublabel}
        .field=${this.field}
        .placeholder=${this.placeholder}
        .value=${this.value}
        @value-changed=${(e) => this.onValueChanged(e)}
      ></d-edit-text>
      <d-smooth-resize>
        ${this.options.length
          ? html`
              <div id="options">
                ${this.options.map(
                  (item) => html`
                    <a class="option ${item.type}" tabindex="0" @click=${(e) => this.setValue(e)}>${item.name}</a>
                  `,
                )}
              </div>
            `
          : nothing}
      </d-smooth-resize>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.getRootNode().addEventListener('keydown', (e: Event) => this.onKeyDown(e));
  }

  private handleEnter(event, focusedElm: Element) {
    event.preventDefault();
    if (focusedElm.tagName === 'D-EDIT-TEXT' && this.optionsElm.firstElementChild) {
      this.dismissOptions();
    }
    if (focusedElm.classList.contains('option')) {
      this.value = focusedElm.textContent ?? '';
      this.dismissOptions();
    }
    if (this.value) {
      this.dispatchValueSelected();
    }
  }

  private handleArrowUp(event, focusedElm: Element) {
    event.preventDefault();
    if (focusedElm.classList.contains('option')) {
      const nextElm = focusedElm.previousElementSibling as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      } else {
        this.inputElm.setFocus();
      }
    }
  }

  private handleArrowDown(event, focusedElm: Element) {
    event.preventDefault();
    if (focusedElm.tagName === 'D-EDIT-TEXT') {
      const nextElm = this.optionsElm.firstElementChild as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      }
    }
    if (focusedElm.classList.contains('option')) {
      const nextElm = focusedElm.nextElementSibling as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      } else {
        this.inputElm.setFocus();
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-select-text': DEditSelectText;
  }
}
