import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sortedByName } from 'src/store/utilities.js';
import type { User } from 'src/store/types.js';
import '../../layout/parts/d-select-organization.js';

/**
 * Viser en liste med organisasjoner og mulighet til å legge til ny. Brukes ved innlogging hvis
 * ingen organisasjon er tidligere valgt.
 *
 * @fires request-logout
 *
 * STATUS OK
 */
@customElement('d-organizations')
export class DOrganizations extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .outskirts {
      margin: 0 auto;
      height: 100vh;
      overflow: auto;
    }

    .outskirts d-application-header {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader {
      margin: 0 auto;
      padding: 0 20px;
      width: 768px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader > div {
      display: flex;
      justify-content: space-between;
      position: relative;
      color: white;
      height: var(--applicationHeaderHeight);
      box-sizing: border-box;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    }

    .outskirtsHeader > div > div {
      display: flex;
    }

    .outskirtsHeader .logo {
      flex: none;
      width: 137px;
      height: 45px;
      margin-bottom: -5px;
      background: url(/images/trinnvis-logo.svg) -2px 10px no-repeat;
      background-size: 97px 25px;
      text-indent: -10000px;
    }

    .outskirtsHeader a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
      text-decoration: none;
      cursor: hand;
    }

    .outskirtsHeader a:last-child {
      margin-right: -20px;
    }

    .outskirts a {
      color: white;
    }

    .outskirts input,
    .outskirts .selectWrapper,
    .outskirts iron-autogrow-textarea {
      border-radius: 4px;
    }

    .outskirts input,
    .outskirts select,
    .outskirts iron-autogrow-textarea {
      font-family: var(--mainSans);
    }

    .outskirts h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 120%;
      color: white;
      margin-bottom: 20px;
    }

    .outskirts h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts p,
    .outskirts ul {
      font-size: 16px;
      font-weight: 300;
      line-height: 140%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts ul {
      margin-left: 18px;
    }

    .outskirts #label.d-label {
      font-weight: 500;
    }

    .outskirts d-section #flexWrapper {
      margin: 0 -5px;
      border: none;
    }

    .outskirts #flexWrapper > d-section-element,
    .outskirts #flexWrapper > d-edit-text,
    .outskirts #flexWrapper > d-select,
    .outskirts #flexWrapper > d-select-access-level,
    .outskirts #flexWrapper > d-universal-selector,
    .outskirts #flexWrapper > d-edit-textarea,
    .outskirts #flexWrapper > d-prepare-select {
      border: none;
      padding: 8px 0 6px 0;
    }

    .outskirts #flexWrapper > * > .sectionElementInner {
      border: none;
      padding: 0 6px;
    }

    .outskirts d-edit-text[disabled] {
      opacity: 1;
    }

    .outskirts d-edit-text[disabled] input {
      opacity: 0.5;
    }

    .outskirts d-application-header a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
    }

    .outskirts .tooltipTrigger {
      color: var(--themeColorDarkerTwo);
    }

    .outskirtsContent {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .outskirtsContent {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }
  `;
  @property({ type: Object })
  user!: User;

  render() {
    if (this.user.organizations.length === 1) {
      const o = this.user.organizations[0];
      this.dispatchEvent(
        new CustomEvent<{ href: string }>('navigate', {
          composed: true,
          bubbles: true,
          detail: { href: '/account/' + o.id + (o.pending ? '/prepare' : '/2300') },
        }),
      );
    }

    return html`
      <div class="outskirts">
        <div class="outskirtsHeader">
          <div>
            <div class="logo">TrinnVis</div>
            ${!this.user ? nothing : html`<a href="/" @click=${() => this.handleLogout()}>Logg ut</a>`}
          </div>
        </div>
        <div class="outskirtsContent">
          <h1>Velg organisasjon</h1>
          <d-select-organization
            id="account"
            .organizations=${sortedByName(this.user.organizations ?? [])}
            .user=${this.user}
          >
          </d-select-organization>
        </div>
      </div>
    `;
  }

  private handleLogout() {
    this.dispatchEvent(
      new CustomEvent('request-logout', { bubbles: true, composed: true, detail: { redirect: true } }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organizations': DOrganizations;
  }
}
