import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../elements/d-action';
import '../elements/d-label';
import '../fields/d-expansion';
import '../lists/d-occurrences-list';
import { DOccurrencesList, Occurrence } from 'src/library/lists/d-occurrences-list';
import { capitalize, numberWord } from 'src/utilities/text';
import { classMap } from 'lit/directives/class-map.js';

/**
 *
 *
 * USAGE:
 *    d-event-view
 *
 */
@customElement('d-view-occurrences')
export class DViewOccurrences extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
      .header {
        padding-bottom: 12px;
      }
      .header.bordered {
        border-bottom: 1px solid var(--borderColor);
      }
      d-action {
        margin: -6px -4px;
      }
      d-occurrences-list {
        max-height: 292px;
        overflow-y: auto;
        scroll-behavior: smooth;
        margin-top: -1px;
        margin-right: -16px;
        padding-right: 16px;
        mix-blend-mode: multiply;
      }
    `,
  ];
  @property({ type: Boolean })
  opened = false;
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: String })
  eventType = 'standard';
  @property({ type: String })
  parentHref = '';
  @property({ type: String })
  currentUuid = '';

  private get label() {
    if (this.eventType === 'meeting') {
      if (this.occurrences.length < 2) {
        return 'Enkeltmøte';
      }
      return 'Møteserie med ' + numberWord(this.occurrences.length) + '  møter';
    }
    if (this.occurrences.length === 1) {
      return 'Ingen gjentakelser';
    }
    return capitalize(numberWord(this.occurrences.length)) + '  gjentakelser';
  }

  private get editActionText() {
    if (this.eventType === 'meeting') {
      if (this.occurrences.length === 1) {
        return 'Gjenta møtet';
      }
      return 'Rediger møteserie';
    }
    if (this.occurrences.length === 1) {
      return 'Gjenta oppgaven';
    }
    return 'Rediger  gjentakelser';
  }

  edit() {
    this.dispatchEvent(
      new CustomEvent('edit-occurrences', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  toggelList() {
    this.opened = !this.opened;
    if (this.opened) {
      setTimeout(() => {
        const list = this.shadowRoot?.getElementById('list') as DOccurrencesList;
        if (list) {
          list.tellCurrentPosition();
        }
      }, 300);
    }
  }

  scrollList(e) {
    const list = this.shadowRoot?.getElementById('list');
    if (list) {
      const listCenter = list.getBoundingClientRect().top + list.getBoundingClientRect().height / 2;
      list.scrollBy(0, e.detail.value - listCenter);
    }
  }

  render() {
    return html`
      <div
        class="header ${classMap({
          bordered: this.opened,
        })}"
      >
        <d-label semibold .label=${this.label}></d-label>
        ${this.occurrences.length > 1
          ? html`
              <d-action plain theme-page @click=${() => this.toggelList()}>${this.opened ? 'Skjul' : 'Vis'}</d-action>
            `
          : nothing}
        <d-action plain theme-page @click=${() => this.edit()}>${this.editActionText}</d-action>
      </div>
      ${this.occurrences.length > 1
        ? html`
            <d-expansion .opened=${this.opened}>
              <d-occurrences-list
                id="list"
                .occurrences=${this.occurrences}
                .parentHref=${this.parentHref}
                .currentUuid=${this.currentUuid}
                @current-position=${(e) => this.scrollList(e)}
              ></d-occurrences-list>
            </d-expansion>
          `
        : nothing}
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('occurrences') && this.occurrences.length < 2) {
      this.opened = false;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-occurrences': DViewOccurrences;
  }
}
