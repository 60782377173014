import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { PageMenuItem } from 'src/layout/application-view-model.js';
import type { PropertyValues } from 'lit/development';

/**
 *
 *
 *
 *
 *   _noPageAccessClass: function (pageId, accessProfile) {
 *             if (pageId && accessProfile && accessProfile.pages) {
 *                 if (accessProfile.pages.indexOf(pageId) === -1) {
 *                     return 'noPageAccess';
 *                 }
 *                 return '';
 *             }
 *         },
 *
 *
 */
@customElement('d-page-menu')
export class DPageMenu extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      margin-left: 20px;
      font-family: var(--mainSans);
      direction: ltr;
    }

    a {
      display: block;
      padding: 9px 0;
      border-top: 1px solid var(--borderColorTransparent);
      color: white;
      text-decoration: none;
      font-weight: 500;
      cursor: pointer;
    }

    .submenu a {
      font-weight: 200;
      border-top: none;
      padding-top: 0;
    }

    a {
      opacity: 0.7;
    }

    a[selected],
    body:not(.touch) a:hover {
      opacity: 1;
    }
    /* TODO 
    a[no-access] {
      
    }
    */
  `;
  /**
   * Den gjeldende siden som er valgt
   */
  @property({ type: Number })
  pageId = 0;
  /**
   * Sidene som vises i to nivåer
   */
  @property({ type: Array })
  pages?: PageMenuItem[] = [];
  @property({ type: Number })
  setScrolltop = 0;

  handleScroll(e) {
    this.dispatchEvent(
      new CustomEvent('scrolltop-changed', {
        bubbles: true,
        composed: true,
        detail: e.target.scrollTop,
      }),
    );
  }

  render() {
    const p = this.pages ?? [];
    return html` <div class="menu">
      ${p.map(
        (page) =>
          html`<a href="${page.href}" ?selected=${this.selected(this.pageId, page.pageId)}>${page.name} </a>
            <div class="submenu">
              ${page.subPages.map(
                (subPage) =>
                  html`<a href="${subPage.href}" ?selected=${this.selected(this.pageId, subPage.pageId)}
                    >${subPage.name}
                  </a>`,
              )}
            </div>`,
      )}
    </div>`;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.addEventListener('scroll', this.handleScroll);
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('setScrolltop')) {
      this.scrollTo({
        top: this.setScrolltop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  private selected(currentPageId, pageId) {
    return currentPageId === pageId;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-page-menu': DPageMenu;
  }
}
