import { html, TemplateResult } from 'lit';
import { AbstractEntityView, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/elements/d-label.js';
import './d-edit-partner-access.js';
import { customElement } from 'lit/decorators.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import { sortedByName } from 'src/store/utilities.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { ActionInput } from 'src/library/elements/d-action';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';

export interface PartnerView extends AbstractEntityView<PartnerViewEditItem> {
  pages: string[];
  contracts: { href: string; name: string; locked: boolean; accessible: boolean; hasDraft: boolean }[];
  contacts: { href: string; firstName: string; lastName: string; hasDraft: boolean }[];
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
  type: 'partners';
  name: string;
  availablePages: SelectTagOption[];
}

export interface PartnerViewEditItem {
  pages: string[];
  name: string;
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-partner-view')
export class DPartnerView extends EntityContent<PartnerView, PartnerViewEditItem> {
  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addTextIfNotEmpty('partners_industry', this.entityView.industry, 'm');
    builder.addTextIfNotEmpty('partners_phone', this.entityView.phone, 'm');
    builder.addTextIfNotEmpty('partners_address', this.entityView.address, 'm');
    builder.addTextIfNotEmpty('partners_email', this.entityView.email, 'm');
    builder.addTextIfNotEmpty('partners_url', this.entityView.url, 'm');
    builder.addBullets('partners_access', this.computeAccessDescription());
    builder.addTextVertical('partners_notes', this.entityView.notes, 'm', true);
    return builder.build();
  }

  private get contactItems() {
    return this.entityView.contacts.map((item) => {
      return {
        href: item.href,
        label: item.firstName + ' ' + item.lastName,
        accessible: true,
        hasDraft: item.hasDraft,
      };
    });
  }

  private get contractItems() {
    return sortedByName(this.entityView.contracts).map((item) => {
      return {
        locked: item.locked,
        accessible: item.accessible,
        href: item.href,
        label: item.name,
        hasDraft: item.hasDraft,
      };
    });
  }

  renderLists(): TemplateResult<1> {
    const contractActions: ActionInput[] = [
      {
        name: 'Legg til',
        action: 'create-contract',
        slot: 'top-right',
      },
    ];
    const contactActions: ActionInput[] = [
      {
        name: 'Legg til',
        action: 'create-contact',
        slot: 'top-right',
      },
    ];

    return html`<d-list-section
        .actions=${contactActions}
        field="organization_contacts"
        icon="employees"
        .items=${this.contactItems}
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        .contentStickyTop=${this.contentStickyTop}
        @action=${(e: CustomEvent) => this.onListActionForContact(e.detail)}
      ></d-list-section>
      <d-list-section
        .actions=${contractActions}
        field="organization_contracts"
        icon="contracts"
        .items=${this.contractItems}
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        .contentStickyTop=${this.contentStickyTop}
        @action=${(e: CustomEvent) => this.onListActionForContract(e.detail)}
      >
      </d-list-section> `;
  }

  async initializeEditItem() {
    this.editItem = {
      address: this.entityView.address,
      email: this.entityView.email,
      industry: this.entityView.industry,
      notes: this.entityView.notes,
      pages: this.entityView.pages,
      phone: this.entityView.phone,
      physicalAccess: this.entityView.physicalAccess,
      remoteAccess: this.entityView.remoteAccess,
      url: this.entityView.url,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: PartnerViewEditItem): TemplateResult<1> {
    return html`<d-section>
        <d-edit-text
          class="minWidth300"
          field="partners_name"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="partners_phone"
          .value=${item.phone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, phone: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-edit-text
          class="minWidth200"
          field="partners_industry"
          .value=${item.industry}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, industry: e.detail.value };
          }}
        ></d-edit-text>

        <d-edit-textarea
          class="minWidth300"
          field="partners_address"
          .value=${item.address}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, address: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>
      <d-section>
        <d-edit-text
          class="minWidth300"
          field="partners_email"
          .value=${item.email}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, email: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth300"
          field="partners_url"
          .value=${item.url}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, url: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-edit-partner-access
          field="partners_access"
          ?physicalAccessValue=${item.physicalAccess}
          ?remoteAccessValue=${item.remoteAccess}
          @access-changed=${(e: CustomEvent<{ physical: boolean; remote: boolean }>) => {
            this.editItem = { ...item, physicalAccess: e.detail.physical, remoteAccess: e.detail.remote };
          }}
        >
        </d-edit-partner-access>
      </d-section>
      <d-section>
        <d-edit-textarea
          field="partners_notes"
          .value=${item.notes}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, notes: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-select-tag
          label="Vis også på temaside"
          .options="${this.entityView.availablePages}"
          .value=${item.pages}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, pages: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private onListActionForContract(action: string) {
    if (action === 'create-contract') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contracts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
        pageId: 63,
        partnerId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }

  private onListActionForContact(action: string) {
    if (action === 'create-contact') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contacts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contacts/' + id + '?edit',
        partnerId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }

  private computeAccessDescription() {
    const result: string[] = [];
    if (this.entityView.physicalAccess) {
      result.push(commonFieldsByCode()['partners_physicalAccess'].label);
    }
    if (this.entityView.remoteAccess) {
      result.push(commonFieldsByCode()['partners_remoteAccess'].label);
    }
    if (result.length === 0) {
      result.push(commonFieldsByCode()['partners_noAccess'].label);
    }
    return result;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-partner-view': DPartnerView;
  }
}
