import type { FunctionViewModel, PageViewModel } from '../../store/api';
import type { EmployeeViewModelWithName, State } from '../../store/types.js';
import type {
  EmployeeGroup,
  OrganizationPageViewModel,
} from '../../pages/organization-page/organization-page-view-model.js';
import {
  activityCodesAvailable,
  constitutionalDocumentsNotDeleted,
  currentEmployeeUuid,
  employeesNotDeleted,
  functionsNotDeleted,
  getOrganizationId,
  key,
  partnersNotDeleted,
  writeAccess,
} from '../../store';
import _ from 'lodash';
import { toListSectionItemInputs } from 'src/models/pages/overview-page-view.js';
import { LocalDate } from '../../utilities/local-date.js';
import { groupBy } from 'src/store/selectors';
import { displayName, entityNameForLists } from 'src/utilities/text';

export function organizationPageView(
  hrefPrefix: string,
  viewModel: PageViewModel,
  state: State,
): OrganizationPageViewModel {
  const o = state.organization;

  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }

  const nullToUndefined = (v: string | null) => {
    return v === null ? undefined : v;
  };

  return {
    type: 'organization-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: getOrganizationId(state),
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: '/api' + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    organization: {
      name: o.name,
      activityCodes: o.activityCodes,
      url: nullToUndefined(o.url),
      notes: nullToUndefined(o.notes),
      email: nullToUndefined(o.email),
      fax: nullToUndefined(o.fax),
      phone: nullToUndefined(o.phone),
      mailAddress: nullToUndefined(o.mailAddress),
      officeAddress: nullToUndefined(o.officeAddress),
      herNumber: nullToUndefined(o.herNumber),
      organizationNumber: nullToUndefined(o.organizationNumber),
      businessEntityType: o.businessEntityType,
    },
    employeeGroups: toGroupedEmployees(hrefPrefix, employeesNotDeleted(state), functionsNotDeleted(state)),
    partners: toListSectionItemInputs(
      hrefPrefix + 'partners/',
      partnersNotDeleted(state),
      LocalDate.fromString(state.today),
      currentEmployeeUuid(state),
    ).map((p) => ({
      ...p,
      updateStatus: 'none',
    })),
    constitutionalDocuments: toListSectionItemInputs(
      hrefPrefix + 'constitutionalDocuments/',
      constitutionalDocumentsNotDeleted(state),
      LocalDate.fromString(state.today),
      currentEmployeeUuid(state),
    ),
  };
}

function relatedFunctions(employeeId: string, functions: FunctionViewModel[]): string[] {
  const result = functions
    .filter((f) => f.type !== 'COMMON')
    .filter((f) => f.employees.includes(employeeId))
    .map((f) => displayName(f.name));
  return result.sort();
}

/**
 * Groups employees based on association to the organization. The terminated employees are show seperated in a filtered list.
 *
 * For employees the assigned functions are added as related items.
 *
 * @param employees
 */
function toGroupedEmployees(
  hrefPrefix: string,
  employees: EmployeeViewModelWithName[],
  functionsNotDeleted: FunctionViewModel[],
): EmployeeGroup[] {
  const groupedEmployees = groupBy(employees, (e) =>
    e.status === 'TERMINATED' ? 'TERMINATED' : (e.associationType ?? '').toLocaleLowerCase(),
  );
  const result: EmployeeGroup[] = _.sortBy(groupedEmployees, [
    (g) => (g.group === 'TERMINATED' ? 1 : 0),
    (g) => g.group === '',
    (g) => g.group,
  ]).map((g) => {
    return {
      associationTypeName:
        g.group === 'TERMINATED' ? 'Sluttet' : g.items[0].associationType || 'Tilknytning ikke angitt',
      terminated: g.group === 'TERMINATED',
      employees: g.items.map((e) => {
        let href = hrefPrefix + 'employees/' + e.uuid;
        if (e.isNew) {
          href += '?edit';
        }
        return {
          uuid: e.uuid,
          name: entityNameForLists(e.name, e.draftName, e.isNew),
          profession: e.profession ?? '',
          accessControl: [],
          classification: 'NONE',
          locked: false,
          accessible: true,
          href,
          functions: relatedFunctions(e.uuid, functionsNotDeleted),
          hasDraft: e.hasDraft,
        };
      }),
    };
  });

  console.log(result);
  return result;
}
