import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-label.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

export interface SelectTagOption {
  value: string;
  text: string;
  disabled?: boolean;
  styleForSelected?: string;
}

/**
 *
 *
 * If the single property changes the outer element must also ensure only a single tag is selected.
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-select-tag')
export class DSelectTag extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
        margin-bottom: -4px;
      }

      :host > div {
        display: flex;
        flex-wrap: wrap;
      }

      .tag {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: 30px;
        margin-right: 4px;
        margin-bottom: 4px;
        padding: 0 10px;
        height: var(--inputElementHeight);
        background: hsla(0, 0%, 0%, 0.05);
        font-family: var(--mainSans), sans-serif;
        font-size: 13px;
        line-height: 100%;
        color: hsla(0, 0%, 0%, 0.5);
        cursor: pointer;
      }

      :host([disabled]) .tag {
        opacity: 0.5;
        cursor: default;
      }

      :host([controller]) .tag {
        border: 1px solid var(--borderColor);
        border-radius: 4px;
        background: white;
      }

      .tag.toggle-all {
        background-color: hsla(0, 0%, 0%, 0.1);
        font-family: var(--small), sans-serif;
        color: hsla(1, 0%, 0%, 0.7);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 11px;
        font-weight: 500;
      }

      :host:not([disabled]) .tag.toggle-all:hover {
        color: white;
        background-color: hsla(0, 0%, 0%, 0.5);
      }

      .tag[disabled] {
        opacity: 0.5;
        cursor: default;
      }

      .tag[selected] {
        background: var(--themeColor);
        color: white;
      }

      :host([controller]) .tag[selected] {
        border-color: var(--themeColor);
        background: var(--themeColor);
        color: white;
      }

      .tag.toggle-all[selected] {
        color: white;
        background-color: hsla(0, 0%, 0%, 0.5);
      }
    `,
  ];
  @property({ type: Array })
  value: string[] = [];
  @property({ type: Array })
  options: SelectTagOption[] = [];
  @property({ type: Boolean, attribute: 'toggle-all', reflect: true })
  toggleAll = false;
  @property({ type: Boolean, attribute: 'not-deselectable', reflect: true })
  notDeselectable = false;
  @property({ type: Boolean, reflect: true })
  single = false;
  @property({ type: Boolean, reflect: true })
  controller = false;

  private get allSelected() {
    const selectable = this.options.filter((o) => {
      return !o.disabled || this.value.indexOf(o.value) > -1;
    });
    return selectable.length === this.value.length;
  }

  _selected(value) {
    return (this.value ?? []).includes(value);
  }

  _style(item) {
    if (this._selected(item.value) && item.styleForSelected) {
      return item.styleForSelected;
    }
    return '';
  }

  renderContent() {
    return html`
      <div>
        ${this.toggleAll
          ? html`
              <div class="tag toggle-all" ?selected="${this.allSelected}" @click=${() => this.toggleAllItems()}>
                Alle
              </div>
            `
          : nothing}
        ${this.options.map(
          (item) =>
            html`<div
              class="tag"
              ?disabled=${item.disabled}
              @click=${() => this.toggleItem(item)}
              ?selected="${this._selected(item.value)}"
              style="${this._style(item)}"
            >
              ${item.text}
            </div>`,
        )}
      </div>
    `;
  }

  private toggleItem(item: SelectTagOption) {
    if (!this.disabled && !item.disabled) {
      let newValue: string[] = [...this.value];
      if (this.value.indexOf(item.value) === -1) {
        if (this.single) {
          newValue = [item.value];
        } else {
          newValue.push(item.value);
        }
      } else if (!this.notDeselectable || this.value.length > 1) {
        newValue = this.value.filter((i) => {
          return i !== item.value;
        });
      }
      this.value = newValue;
      this.dispatchEvent(
        new CustomEvent('value-changed', {
          bubbles: true,
          composed: true,
          detail: { value: this.value },
        }),
      );
    }
  }

  private toggleAllItems() {
    if (!this.disabled) {
      let newValue: string[] = [];
      if (!this.allSelected) {
        newValue = this.options
          .filter((o) => {
            return !o.disabled || this.value.indexOf(o.value) > -1;
          })
          .map((o) => {
            return o.value;
          });
      } else {
        newValue = this.options
          .filter((o) => {
            return o.disabled && this.value.indexOf(o.value) > -1;
          })
          .map((o) => {
            return o.value;
          });
      }
      this.value = newValue;
      this.dispatchEvent(
        new CustomEvent('value-changed', {
          bubbles: true,
          composed: true,
          detail: { value: this.value },
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-tag': DSelectTag;
  }
}
