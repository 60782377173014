import { html } from 'lit';

import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-section.js';
import '../../library/editors/components/d-edit-access.js';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access.js';
import { UpdateAccessCommandAccessLevelEnum } from 'src/store/api';

export interface EditAccessInput {
  displayName: string;
  otherUserEmails: string[];
  editUser: UserForAccess;
  originalEmail: string;
  isOwner: boolean;
  isSelf: boolean;
}
export type EditAccessResult =
  | DialogCancelResult
  | {
      action: 'done';
      email: string;
      accessLevel: UpdateAccessCommandAccessLevelEnum;
      accessExpires?: string;
    };

/**
 *
 * USAGE:
 *    d-application
 *
 * STATUS OK
 */
@customElement('edit-access-dialog')
export class EditAccessDialog extends BaseDialog<EditAccessInput, EditAccessResult> {
  @property({ type: String })
  displayName = '';

  @property({ type: Array })
  otherUserEmails: string[] = [];
  @property({ type: Object })
  editUser!: UserForAccess;
  @property({ type: String })
  originalEmail = '';
  @property({ type: Boolean })
  isOwner = false;
  @property({ type: Boolean })
  isSelf = false;

  width = 500;

  headerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Ferdig', action: 'done' },
  ];

  protected get calculatedTitle(): string {
    return 'Rediger tilgang for ' + this.displayName;
  }

  renderBody() {
    return html`
      <d-edit-access
        .editUser=${this.editUser}
        .isSelf=${this.isSelf}
        .isOwner=${this.isOwner}
        .originalEmail=${this.originalEmail}
        .otherUserEmails=${this.otherUserEmails}
        @value-changed=${(
          e: CustomEvent<{ accessLevel: UpdateAccessCommandAccessLevelEnum; email: string; accessExpires: string }>,
        ) => {
          this.editUser = {
            ...this.editUser,
            accessLevel: e.detail.accessLevel,
            email: e.detail.email,
            accessExpires: e.detail.accessExpires,
          };
        }}
      ></d-edit-access>
    `;
  }

  protected fetchResult(detail: string | undefined): EditAccessResult {
    if (detail === 'done') {
      return {
        action: 'done',
        email: this.editUser.email,
        accessLevel: this.toAccessLevelEnum(this.editUser.accessLevel),
        accessExpires: this.editUser.accessExpires,
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: EditAccessInput) {
    this.displayName = input.displayName;
    this.isOwner = input.isOwner;
    this.isSelf = input.isSelf;
    this.originalEmail = input.originalEmail;
    this.otherUserEmails = input.otherUserEmails;
    this.editUser = {
      ...input.editUser,
    };
  }

  private toAccessLevelEnum(accessLevel: string): UpdateAccessCommandAccessLevelEnum {
    switch (accessLevel) {
      case 'NONE':
        return UpdateAccessCommandAccessLevelEnum.None;
      case 'READONLY':
        return UpdateAccessCommandAccessLevelEnum.Readonly;
      case 'USER':
        return UpdateAccessCommandAccessLevelEnum.User;
      case 'ASSETS':
        return UpdateAccessCommandAccessLevelEnum.Assets;
      default:
        throw new Error('Illegal state (E723), invalid access level ' + accessLevel);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-access-dialog': EditAccessDialog;
  }
}
