import { GuidelineView, GuidelineViewEditItem } from 'src/content/guidelines/d-guideline-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import {
  currentUserUuid,
  getEmployeesWithForAccessControl,
  getOrganization,
  isCurrentUserEmployee,
  toGuidelineViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';

export async function buildGuidelineView(uuid: string, state: State, currentParent: string): Promise<GuidelineView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.guidelinesById[uuid];
  assertIsDefined(item);

  const entity = toGuidelineViewModel(item);

  return {
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isNew: entity.isNew,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/guidelines/' + uuid,
    parentHref: currentParent,
    pdfHref: '/api' + '/organizations/' + organization.organizationId + '/guidelines/' + uuid + '.pdf',

    deleted: entity.deleted === true,
    type: 'guidelines',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    accessControlOptions: getEmployeesWithForAccessControl(state).map((e) => ({
      value: e.id,
      text: e.value,
      disabled: e.disabled,
    })),
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      'guidelines',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('guidelines', entity.uuid, entity.attachments),
    availablePages: availablePages,
    pages: (entity.pages ?? []).map((x) => '' + x),
    isEmployee: isCurrentUserEmployee(state),
    today: LocalDate.fromString(state.today),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft('guidelines', uuid, organization.organizationId);
      return command.draft as GuidelineViewEditItem;
    },
  };
}
