import { html } from 'lit';

import '../../library/elements/d-label.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/components/d-popup.js';
import '../../library/fields/d-view-info';

import { customElement, property, query } from 'lit/decorators.js';
import type { DPopup } from 'src/library/components/d-popup.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

export interface HideDoneOptionsInput {
  value: HideDoneOptions;
}
export interface HideDoneOptionsResult {
  value: HideDoneOptions;
  action: 'cancel' | 'done';
}

export type HideDoneOptions = '0' | '1' | '7' | '28' | '1000' | '365';

/**
 *
 * USAGE:
 *    d-home-page-content
 *
 * STATUS OK
 */
@customElement('hide-done-options-dialog')
export class HideDoneOptionsDialog extends BaseDialog<HideDoneOptionsInput, HideDoneOptionsResult> {
  @property({ type: String })
  value: HideDoneOptions = '1';
  @query('d-popup')
  popup!: DPopup;
  footerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Ferdig', action: 'save' },
  ];
  width = 400;
  title = 'Valg for utførte oppgaver';
  private options = [
    { value: '0', text: 'straks' },
    { value: '1', text: 'etter en dag' },
    { value: '7', text: 'etter en uke' },
    { value: '28', text: 'etter fire uker' },
    { value: '365', text: 'ett år' },
  ];

  onValueChanged(e: CustomEvent<{ value: '0' | '1' | '7' | '28' | '1000' }>) {
    this.value = e.detail.value;
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info
          .content=${`<p>Utførte oppgaver kan flyttes fra Snarest-listen til ukeplanen. Der vil de vises på den dagen de er utført.</p>`}
        ></d-view-info>
      </d-section>
      <d-section>
        <d-select-dropdown
          label="Flytt utførte oppgaver"
          .options=${this.options}
          .value=${this.value}
          @value-changed=${this.onValueChanged}
        ></d-select-dropdown>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): HideDoneOptionsResult {
    return {
      value: this.value,
      action: detail === 'save' ? 'done' : 'cancel',
    };
  }

  protected initializeDialog(input: HideDoneOptionsInput) {
    this.value = input.value === '1000' ? '365' : input.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'hide-done-options-dialog': HideDoneOptionsDialog;
  }
}
