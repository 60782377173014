import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement, nothing } from 'lit';
import './d-organization-edit-access.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-view-info.js';
import type { UserForAccess } from '../../library/editors/components/d-edit-access.js';

/**
 *
 */
@customElement('d-organization-users')
export class DOrganizationUsers extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Array })
  users: UserForAccess[] = [];
  @property({ type: Object })
  currentUser!: UserForAccess;
  @property({ type: String })
  accountOwnerEmail!: string;
  private noContactsInfo =
    '<p>Ingen kontaktpersoner er registrert. ' +
    'Kontaktpersoner registreres under Samarbeidspartnere på siden <em>Organisasjon</em>.</p>';

  private get employees() {
    return this.users.filter((u) => {
      return u.partnerName === undefined || u.partnerName === '';
    });
  }

  private get contacts() {
    return this.users.filter((u) => {
      return u.partnerName !== undefined && u.partnerName !== '';
    });
  }

  render() {
    return html`
      <d-section label="Personale" outskirts vertical>
      ${this.employees.map(
        (item) => html`
          <d-organization-edit-access
            .users=${this.users}
            .editUser=${item}
            .currentUser=${this.currentUser}
            .ownerEmail=${this.accountOwnerEmail}
          ></d-organization-edit-access>
        `,
      )}
      </d-section>
      <d-section label="Kontaktpersoner" outskirts vertical>
      ${this.contacts.map(
        (item) => html`
          <d-organization-edit-access
            .users=${this.users}
            .editUser=${item}
            .currentUser=${this.currentUser}
            .ownerEmail=${this.accountOwnerEmail}
          ></d-organization-edit-access>
        `,
      )}
      ${this.contacts.length === 0 ? html` <d-view-info .content=${this.noContactsInfo}></d-view-info> ` : nothing}
      </d-section>
      </d-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-users': DOrganizationUsers;
  }
}
