/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QualityReviewIndicatorViewModel } from './QualityReviewIndicatorViewModel';
import {
  QualityReviewIndicatorViewModelFromJSON,
  QualityReviewIndicatorViewModelFromJSONTyped,
  QualityReviewIndicatorViewModelToJSON,
} from './QualityReviewIndicatorViewModel';

/**
 *
 * @export
 * @interface QualityReviewSubGoalViewModel
 */
export interface QualityReviewSubGoalViewModel {
  /**
   *
   * @type {string}
   * @memberof QualityReviewSubGoalViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof QualityReviewSubGoalViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof QualityReviewSubGoalViewModel
   */
  responsibleBy?: string;
  /**
   *
   * @type {Array<QualityReviewIndicatorViewModel>}
   * @memberof QualityReviewSubGoalViewModel
   */
  indicators?: Array<QualityReviewIndicatorViewModel>;
  /**
   *
   * @type {boolean}
   * @memberof QualityReviewSubGoalViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof QualityReviewSubGoalViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof QualityReviewSubGoalViewModel
   */
  draftName?: string;
}

/**
 * Check if a given object implements the QualityReviewSubGoalViewModel interface.
 */
export function instanceOfQualityReviewSubGoalViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function QualityReviewSubGoalViewModelFromJSON(json: any): QualityReviewSubGoalViewModel {
  return QualityReviewSubGoalViewModelFromJSONTyped(json, false);
}

export function QualityReviewSubGoalViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): QualityReviewSubGoalViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    uuid: json['uuid'],
    responsibleBy: !exists(json, 'responsibleBy') ? undefined : json['responsibleBy'],
    indicators: !exists(json, 'indicators')
      ? undefined
      : (json['indicators'] as Array<any>).map(QualityReviewIndicatorViewModelFromJSON),
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
  };
}

export function QualityReviewSubGoalViewModelToJSON(value?: QualityReviewSubGoalViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    uuid: value.uuid,
    responsibleBy: value.responsibleBy,
    indicators:
      value.indicators === undefined
        ? undefined
        : (value.indicators as Array<any>).map(QualityReviewIndicatorViewModelToJSON),
    deleted: value.deleted,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
  };
}
