import { toAttachmentItems } from 'src/models/factory-utilities.js';
import type {
  CloudServiceExternalItem,
  ComputersPageViewModel,
  ConnectedEmployeeExternalItem,
  ConnectedPartnerExternalItem,
  InfosecSettings,
  RemoteServerExternalItem,
} from 'src/pages/computers-page/computers-page-view-model.js';
import type { ComputerMapLocal } from 'src/pages/computers-page/d-computer-unit-local.js';
import type { ComputerMapPrinter } from 'src/pages/computers-page/d-computer-unit-printer.js';
import type { Network } from 'src/pages/computers-page/d-computers-page-content-map.js';
import * as dabihStore from 'src/store';
import {
  assetsNotDeleted,
  computersNotDeleted,
  contractsNotDeletedWithParties,
  currentEmployeeUuid,
  employeesNotDeleted,
  getOrganization,
  getOrganizationId,
  networksNotDeleted,
  partnerNamesById,
  partnersNotDeleted,
  personalDataItemsNotDeleted,
  riskAssessmentsNotDeleted,
  variantSimplifiedPrivacy,
} from 'src/store';
import {
  mobileMedicalDataRequirementsOptions,
  antiVirusRequirementsOptions,
  externalConnectionsForMap,
  networksForMap,
  computersForMap,
  standaloneComputers,
  standalonePrinters,
  offlinePersonalDataItems,
} from 'src/store/selectors';
import {
  ComputerViewModel,
  ExternalConnectionViewModel,
  NetworkViewModel,
  NetworkViewModelNetworkTypeEnum,
  PageViewModel,
  PersonalDataItemViewModel,
  EmployeeViewModelStatusEnum,
} from 'src/store/api';
import type { State } from 'src/store/types.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type {
  InfosecDataItem,
  InfosecExternalConnection,
  InfosecStorageUnit,
  InfosecStorageUnitTypes,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import _ from 'lodash';
import type { DataItem } from 'src/pages/computers-page/d-data-item-view.js';
import { applications, computerTypeOptions } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { displayName } from 'src/utilities/text';
export function dataItemName(
  dataItem,
  computers: InfosecStorageUnit[],
  assets: {
    number: string;
    name: string;
    uuid: string;
  }[],
  locations: InfosecStorageUnit[],
) {
  if (dataItem.application) {
    const apps = applications[dataItem.category];
    if (apps) {
      const application = apps.filter((app) => app.value === dataItem.application);
      if (application?.length) {
        return application[0].text;
      }
    } else {
      return dataItem.application + ' ' + dataItem.category;
    }
  } else {
    if (dataItem.category === 'computers' || dataItem.category === 'assets' || dataItem.category === 'locations') {
      const m = { assets: assets, locations: locations, computers: computers };
      const storageUnits = m[dataItem.category];
      const units = storageUnits.filter((unit: { uuid: any }) => {
        return unit.uuid === dataItem.storageUnit;
      });
      if (units.length) {
        if (dataItem.category === 'computers' && units[0].name === '') {
          return computerTypeOptions.filter((item) => item.value === units[0].computerType)[0].text;
        }
        return units[0].name;
      }
      return dataItem.name;
    }
    return dataItem.name;
  }
  return 'ukjent';
}

function calculateExternalConnectedPartners(state: State): ConnectedPartnerExternalItem[] {
  const externalConnections = externalConnectionsForMap(state);
  return externalConnections
    .filter((c) => c.type === 'Tilkoblet samarbeidspartner')
    .map((c) => ({
      externalConnection: {
        uuid: c.uuid,
        unitType: 'externalConnection',
        type: 'Tilkoblet samarbeidspartner',
        networks: networksNotDeleted(state).map((n) => ({ value: n.uuid, text: n.name || 'Lokaltnettverk uten navn' })),
        connectedViaNhn: c.connectedViaNhn,
        attachments: toAttachmentItems('externalConnections', c.uuid, c.attachments),
        alerts: c.alerts,
        name: c.name ?? '',
        employeeUuid: '',
        partnerUuid: c.partnerUuid ?? '',
        networkUuid: c.networkUuid ?? '',
        connectionType: c.connectionType ?? '',
        riskAssessments: c.riskAssessments,
        otherRiskAssessments: c.otherRiskAssessments,
        riskAssessmentConcludesOk: c.riskAssessmentConcludesOk,
        description: c.description ?? '',
        riskAssessmentsDisplay: riskAssessmentsDisplay(state, c.riskAssessments ?? []),
      },
    }));
}

function calculateExternalConnectedEmployees(state: State): ConnectedEmployeeExternalItem[] {
  const externalConnections = externalConnectionsForMap(state);
  return externalConnections
    .filter((c) => c.type === 'Hjemmekontor')
    .map((c) => ({
      externalConnection: {
        uuid: c.uuid,
        unitType: 'externalConnection',
        type: 'Hjemmekontor',
        networks: networksNotDeleted(state).map((n) => ({ value: n.uuid, text: n.name || 'Lokaltnettverk uten navn' })),
        connectedViaNhn: c.connectedViaNhn,
        attachments: toAttachmentItems('externalConnections', c.uuid, c.attachments),
        alerts: c.alerts,
        name: c.name ?? '',
        employeeUuid: c.employeeUuid ?? '',
        partnerUuid: '',
        networkUuid: c.networkUuid ?? '',
        connectionType: c.connectionType ?? '',
        riskAssessments: c.riskAssessments,
        otherRiskAssessments: c.otherRiskAssessments,
        riskAssessmentConcludesOk: c.riskAssessmentConcludesOk,
        description: c.description ?? '',
        riskAssessmentsDisplay: riskAssessmentsDisplay(state, c.riskAssessments ?? []),
      },
    }));
}

function calculateExternalCloudServices(state: State, infosecSettings: InfosecSettings): CloudServiceExternalItem[] {
  const computers = computersForMap(state);
  const availableSuppliers = partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name }));
  const availableContracts = contractsNotDeletedWithParties(state).map((p) => ({
    value: p.uuid,
    text: p.nameWithParties ?? p.name,
  }));
  return computers
    .filter((c) => c.unitType === 'cloudService')
    .map((c) => ({
      cloudService: {
        uuid: c.uuid,
        personalDataItems: c.personalDataItems.map((p) => mapPersonalDataItem(p, state, infosecSettings)),
        connectionType: c.connectionType ?? 'OTHER',
        unitType: 'cloudService',
        supplierName: availableSuppliers.find((e) => e.value === c.serviceProvider)?.text ?? '',
        supplierUuid: c.serviceProvider ?? '',
        contractName: availableContracts.find((e) => e.value === c.serviceProviderserviceProviderContract)?.text ?? '',
        contractUuid: c.serviceProviderContract ?? '',
        serviceName: c.name ?? '',
        availableSuppliers: availableSuppliers,
        availableContracts: availableContracts,
        attachments: toAttachmentItems('computers', c.uuid, c.attachments),
        alerts: c.alerts ?? [],
        deletable: storageUnitIsDeletable(c.uuid, infosecSettings.dataItems),
      },
    }));
}

function storageUnitIsDeletable(uuid: string, dataItems: InfosecDataItem[]) {
  const f = dataItems.find((i) => i.storageUnit === uuid);
  return f === undefined;
}

function calculateExternalRemoteServers(state: State, infosecSettings: InfosecSettings): RemoteServerExternalItem[] {
  const computers = computersForMap(state);
  const availableSuppliers = partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name }));
  const availableContracts = contractsNotDeletedWithParties(state).map((p) => ({
    value: p.uuid,
    text: p.nameWithParties || p.name,
  }));
  return computers
    .filter((c) => c.unitType === 'remoteServer')
    .map((c) => {
      const supplierName = availableSuppliers.find((e) => e.value === c.serviceProvider)?.text ?? '';
      return {
        remoteServer: {
          uuid: c.uuid,
          personalDataItems: c.personalDataItems.map((p) => mapPersonalDataItem(p, state, infosecSettings)),
          connectionType: c.connectionType || 'OTHER',
          unitType: 'remoteServer',
          supplierName: supplierName,
          supplierUuid: c.serviceProvider ?? '',
          contractName:
            availableContracts.find((e) => e.value === c.serviceProviderserviceProviderContract)?.text ?? '',
          contractUuid: c.serviceProviderContract ?? '',
          serviceName: c.name ?? '',
          availableSuppliers: availableSuppliers,
          availableContracts: availableContracts,
          attachments: toAttachmentItems('computers', c.uuid, c.attachments),
          alerts: c.alerts ?? [],
          simplifiedPrivacy: variantSimplifiedPrivacy(state),
          displayName: supplierName || 'Fjernserver',
          deletable: storageUnitIsDeletable(c.uuid, infosecSettings.dataItems),
        },
      };
    });
}

function calculateNetworks(state: State, infosecSettings: InfosecSettings): Network[] {
  const availableSuppliers = partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name }));
  return networksForMap(state).map((n) => {
    return {
      uuid: n.uuid,
      name: n.name || 'Nettverk uten navn',
      connection: {
        uuid: n.uuid,
        unitType: 'connection',
        partners: availableSuppliers,
        connectionType: n.connectionType ?? 'NONE',
        alerts: [],
        ghost: n.connectionType === 'NONE',
        supplierUuid: n.supplierUuid ?? '',
      },
      firewall: {
        uuid: n.uuid,
        unitType: 'firewall',
        ghost: n.firewall === 'NONE',
        alerts: [],
        firewallType: n.firewall ?? '',
        firewallName: n.firewallName ?? '',
      },
      item: {
        uuid: n.uuid,
        partners: availableSuppliers,
        name: n.name ?? '',
        type: n.networkType || NetworkViewModelNetworkTypeEnum.Cable,
        unitType: 'network',
        attachments: [],
        alerts: n.alerts,
        supplierUuid: n.supplierUuid ?? '',
        antiVirusOperator: n.antiVirusOperator ?? '',
        antiVirusOperatorType: n.antiVirusOperatorType ?? '',
        systemUpdateOperator: n.systemUpdateOperator ?? '',
        systemUpdateOperatorType: n.systemUpdateOperatorType ?? '',
        connectionType: n.connectionType ?? 'NONE',
        firewallType: n.firewall ?? '',
        firewallName: n.firewallName ?? '',
      },
      computers: n.computers.map((c) => ({
        uuid: c.uuid,
        networks: networksNotDeleted(state).map((n) => ({ uuid: n.uuid, name: n.name || 'Lokalt nettverk uten navn' })),
        name: c.name ?? '',
        mobileMedicalDataRequirementsOptions: mobileMedicalDataRequirementsOptions(state).map((o) => ({
          value: o.id,
          text: o.value,
        })),
        partners: availableSuppliers,
        antiVirusRequirementsOptions: antiVirusRequirementsOptions(state).map((o) => ({ value: o.id, text: o.value })),
        systemUpdateOperatorType: c.systemUpdateOperatorType ?? '',
        systemUpdateOperator: c.systemUpdateOperator ?? '',
        antiVirusOperator: c.antiVirusOperator ?? '',
        antiVirusOperatorType: c.antiVirusOperatorType ?? '',
        locked: c.locked ?? false,
        antiVirusRequirements: c.antiVirusRequirements ?? [],
        location: c.location ?? '',
        elevated: c.elevated ?? false,
        restrictedPhysicalAccess: c.restrictedPhysicalAccess ?? false,
        networkUuid: c.networkUuid ?? '',
        mobileMedicalDataRequirements: c.mobileMedicalDataRequirements ?? [],
        notes: c.notes ?? '',
        displayPositionSecure: c.displayPositionSecure ?? false,
        unitType: 'computer',
        type: c.type ?? '',
        displayName: c.name || _displayTypeName(c.unitType, c.type),
        attachments: (c.attachments ?? []).map((x) => displayName(x.name, 'Uten navn')),
        hasMedicalData: hasMedicalData(c.uuid, c.personalDataItems),
        computerIsInNetworkWithMedicalData: networkHasMedicalData(n),
        deletable: storageUnitIsDeletable(c.uuid, infosecSettings.dataItems),
        personalDataItems: c.personalDataItems.map((p) => mapPersonalDataItem(p, state, infosecSettings)),
        alerts: c.alerts,
      })),
    };
  });
}

function hasMedicalData(uuid: string, personalDataItems: PersonalDataItemViewModel[]): boolean {
  for (const item of personalDataItems) {
    if (item.storageUnit === uuid && item.type?.startsWith('patientData')) {
      return true;
    }
  }
  return false;
}

function networkHasMedicalData(
  network: NetworkViewModel & { computers: ComputerViewModel[]; alerts: string[] },
): boolean {
  return network.computers.find((c) => hasMedicalData(c.uuid, c.personalDataItems)) !== undefined;
}

function _displayTypeName(unitType, type) {
  if (unitType === 'remoteServer') {
    return 'Fjernserver';
  }
  if (unitType === 'cloudService') {
    return 'Skytjeneste';
  }
  if (unitType === 'remoteServer') {
    return 'Fjernserver';
  }
  if (unitType === 'personalDataChannel') {
    return 'Pasientkommunikasjon';
  }
  if (unitType === 'printer') {
    return 'Nettverksskriver';
  }
  if (type === 'Server') {
    return 'Server';
  }
  if (type === 'Arbeidsstasjon') {
    return 'Arbeidsstasjon/klient';
  }
  if (type === 'mobile') {
    return 'Bærbar enhet';
  }
  return 'Lokal enhet';
}

function storageUnits(state: State): SelectDropdownOption[] {
  const computers = computersNotDeleted(state);
  const p = partnerNamesById(state);
  const list: SelectDropdownOption[] = [];
  if (computers.length > 0) {
    let is = false;

    for (const item1 of computers) {
      if (item1.unitType === 'computer') {
        is = true;
        let name = '';
        if (item1.name && item1.name.length > 0) {
          name = item1.name;
        } else {
          let type = item1.type;
          if (item1.type === 'UNDEFINED') {
            type = 'enhet';
          }
          name = type + ' uten navn';
        }
        list.push({
          value: item1.uuid,
          text: 'Lokal enhet: ' + name,
        });
      }
    }
    if (is) {
      list.push({ value: 'computer', text: 'Annen lokal enhet' });
    } else {
      list.push({ value: 'computer', text: 'Lokal enhet' });
    }

    is = false;
    for (const item1 of computers) {
      if (item1.unitType === 'remoteServer') {
        is = true;
        const name = item1.serviceProvider ? p[item1.serviceProvider] : '';
        list.push({
          value: item1.uuid,
          text: 'Fjernserver: ' + name,
        });
      }
    }
    if (is) {
      list.push({
        value: 'remoteServer',
        text: 'Annen fjernserver',
      });
    } else {
      list.push({ value: 'remoteServer', text: 'Fjernserver' });
    }
    is = false;
    for (const item1 of computers) {
      if (item1.unitType === 'cloudService') {
        is = true;
        const name = displayName(item1.serviceProvider ? p[item1.serviceProvider] : '', 'Uten navn');
        list.push({
          value: item1.uuid,
          text: 'Skytjeneste: ' + name,
        });
      }
    }
    if (is) {
      list.push({
        value: 'cloudService',
        text: 'Annen skytjeneste',
      });
    } else {
      list.push({ value: 'cloudService', text: 'Skytjeneste' });
    }
    return list;
  }
  return [
    { value: 'computer', text: 'Lokal enhet' },
    { value: 'remoteServer', text: 'Fjernserver' },
    { value: 'cloudService', text: 'Skytjeneste' },
  ];
}

function mapPersonalDataItem(p: PersonalDataItemViewModel, state: State, infosecSettings: InfosecSettings): DataItem {
  const item = infosecSettings.dataItems.find((i) => i.uuid === p.uuid);

  if (item === undefined) {
    throw new Error('Illegal state');
  }

  return {
    ...item,
    availableContracts: contractsNotDeletedWithParties(state).map((p) => ({
      value: p.uuid,
      text: p.nameWithParties || p.name,
    })),
    availableSuppliers: infosecSettings.partners,
    availableCloudServices: infosecSettings.cloudServices,
    availableComputers: infosecSettings.computers,
    availableAssets: infosecSettings.assets.map((a) => ({ value: a.uuid, text: a.name + ' ' + a.number })),
    availableEmployees: infosecSettings.employees,
    availableLocations: infosecSettings.locations,
    sharedMedicalRecordsPartners: p.sharedMedicalRecordsPartners ?? [],
    sharedMedicalRecords: p.sharedMedicalRecords ?? false,
    autoUpdateAddressNhn: p.autoUpdateAddressNhn ?? false,
    autoLookupAddressNhn: p.autoLookupAddressNhn ?? false,
    autoLookupAddress: p.autoLookupAddress ?? false,
    autoLookupPatients: p.autoLookupPatients ?? false,
    messagingSupplier: p.messagingSupplier ?? '',
    messageMonitoring: p.messageMonitoring ?? false,
    messagingTypeOther: p.messagingTypeOther ?? '',
    messagingType: p.messagingType ?? '',
    availableRemoteServers: infosecSettings.remoteServers,
    unitType: 'personalDataItem',
    alerts: p.alerts,
    riskAssessmentConcludesOk: p.riskAssessmentConcludesOk ?? false,
    consentContracts: p.consentContracts ?? [],
    legalBasis: p.legalBasis ?? '',
    purpose: p.purpose ?? '',
    sharedMedicalRecordsContracts:
      p.sharedMedicalRecordsContracts === undefined ? '' : p.sharedMedicalRecordsContracts[0] ?? '',
    journalHasElectronicMessaging: p.journalHasElectronicMessaging ?? false,
    patientDialogueRequirements: p.patientDialogueRequirements ?? [],
    storageUnit: p.storageUnit ?? '',
    relatedAssets: p.relatedAssets ?? [],
    notes: p.notes ?? '',
    name: p.name ?? '',
    personalData: p.personalData ?? false,
  };
}

function calculateOfflinePersonalDataItems(state: State, infosecSettings: InfosecSettings): DataItem[] {
  return offlinePersonalDataItems(state).map((p) => mapPersonalDataItem(p, state, infosecSettings));
}

function calculateStandaloneComputers(state: State, infosecSettings: InfosecSettings): ComputerMapLocal[] {
  const availableSuppliers = partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name }));

  return standaloneComputers(state).map((c) => ({
    uuid: c.uuid,
    networks: networksNotDeleted(state).map((n) => ({ uuid: n.uuid, name: n.name || 'Lokalt nettverk uten navn' })),
    name: c.name ?? '',
    mobileMedicalDataRequirementsOptions: mobileMedicalDataRequirementsOptions(state).map((o) => ({
      value: o.id,
      text: o.value,
    })),
    partners: availableSuppliers,
    antiVirusRequirementsOptions: antiVirusRequirementsOptions(state).map((o) => ({ value: o.id, text: o.value })),
    systemUpdateOperatorType: c.systemUpdateOperatorType ?? '',
    systemUpdateOperator: c.systemUpdateOperator ?? '',
    antiVirusOperator: c.antiVirusOperator ?? '',
    antiVirusOperatorType: c.antiVirusOperatorType ?? '',
    locked: c.locked ?? false,
    antiVirusRequirements: c.antiVirusRequirements ?? [],
    location: c.location ?? '',
    elevated: c.elevated ?? false,
    restrictedPhysicalAccess: c.restrictedPhysicalAccess ?? false,
    networkUuid: c.networkUuid ?? '',
    mobileMedicalDataRequirements: c.mobileMedicalDataRequirements ?? [],
    notes: c.notes ?? '',
    displayPositionSecure: c.displayPositionSecure ?? false,
    unitType: 'computer',
    type: c.type ?? '',
    displayName: '',
    attachments: [],
    hasMedicalData: hasMedicalData(c.uuid, c.personalDataItems),
    computerIsInNetworkWithMedicalData: false,
    deletable: storageUnitIsDeletable(c.uuid, infosecSettings.dataItems),
    personalDataItems: c.personalDataItems.map((p) => mapPersonalDataItem(p, state, infosecSettings)),
    alerts: c.alerts,
  }));
}
function calculateStandalonePrinters(state: State): ComputerMapPrinter[] {
  return standalonePrinters(state).map((p) => ({
    uuid: p.uuid,
    networkUuid: p.networkUuid ?? '',
    name: p.name ?? '',
    notes: p.notes ?? '',
    printerPositionSecure: p.printerPositionSecure,
    location: p.location ?? '',
    unitType: 'printer',
    attachments: [],
    alerts: p.alerts,
  }));
}

function availableRiskAssessments(
  state: State,
): { uuid: string; name: string; pageId?: number; consequence?: number; probability?: number }[] {
  return riskAssessmentsNotDeleted(state).map((r) => ({
    uuid: r.uuid,
    name: r.name ?? '',
    pageId: r.pageId,
    consequence: r.consequence,
    probability: r.probability,
  }));
}

export const mappedComputers = (computers): InfosecStorageUnit[] =>
  computers
    .filter((item) => {
      return !item.deleted && item.unitType === 'computer';
    })
    .map((item) => {
      let computerType = '';
      if (item.type !== '' && item.type !== 'UNDEFINED') {
        computerType = item.type;
      }
      return {
        uuid: item.uuid,
        name: item.name || item.type || 'Datamaskin',
        unitType: 'computers',
        computerType: computerType,
        application: '',
        partnerUuid: '',
        networkUuid: item.networkUuid ?? '',
      };
    });

export const mappedNetworks = (networks): { uuid: string; name: string; type: string; connectionType: string }[] =>
  networks
    .filter((item) => {
      return !item.deleted;
    })
    .map((item) => {
      return {
        uuid: item.uuid,
        name: item.name,
        type: item.type,
        connectionType: item.connectionType,
      };
    });

export const mappedPartners = (partners): { value: string; text: string }[] =>
  partners
    .filter((item) => {
      return !item.deleted && !item.isNew;
    })
    .map((item) => {
      return {
        value: item.uuid,
        text: item.name,
      };
    });

export const mappedCloudServices = (computers): InfosecStorageUnit[] =>
  computers
    .filter((item) => {
      return !item.deleted && item.unitType === 'cloudService';
    })
    .map((item) => {
      return {
        uuid: item.uuid,
        name: item.name ?? '',
        unitType: 'cloudServices',
        computerType: '',
        application: '',
        partnerUuid: item.serviceProvider ?? '',
        networkUuid: item.networkUuid ?? '',
      };
    });

export const mappedRemoteServers = (computers): InfosecStorageUnit[] =>
  computers
    .filter((item) => {
      return !item.deleted && item.unitType === 'remoteServer';
    })
    .map((item) => {
      return {
        uuid: item.uuid,
        name: item.name ?? '',
        unitType: 'remoteServers',
        computerType: '',
        application: '',
        partnerUuid: item.serviceProvider ?? '',
        networkUuid: item.networkUuid ?? '',
      };
    });
export const mappedDataItems = (personalDataItems, computers): InfosecDataItem[] =>
  personalDataItems
    .filter((dataItem) => {
      return !dataItem.deleted;
    })
    .filter(
      (dataItem) => dataItem.type && dataItem.type.split('.').length === 2 && dataItem.type !== 'undefined.undefined',
    )
    .map((dataItem) => {
      const [dataType, category] = dataItem.type.split('.');
      let storageUnitType: InfosecStorageUnitTypes = '';
      if (dataItem.storageUnit) {
        const storageUnits = computers.filter((c) => {
          return c.uuid === dataItem.storageUnit;
        });
        if (storageUnits.length) {
          if (storageUnits[0].unitType === 'cloudService') {
            storageUnitType = 'cloudServices';
          }
          if (storageUnits[0].unitType === 'remoteServer') {
            storageUnitType = 'remoteServers';
          }
          if (storageUnits[0].unitType === 'computer') {
            storageUnitType = 'computers';
          }
        }
      }

      const result: InfosecDataItem = {
        uuid: dataItem.uuid,
        name: dataItem.name,
        application: dataItem.application,
        dataType: dataType,
        category: category,
        personalData: false,
        dataProcessor: dataItem.dataProcessor,
        accessingEmployees: dataItem.accessingEmployees ?? [],
        accessingPartners: dataItem.accessingPartners ?? [],
        assets: dataItem.relatedAssets,
        storageUnit: dataItem.storageUnit ?? '',
        storageLocation: dataItem.storageLocation ?? '',
        storageUnitType: storageUnitType,
        backupFrequency: dataItem.backupFrequency ?? '',
        backupResponsible: dataItem.backupResponsible ?? '',
        noBackupReason: dataItem.noBackupReason ?? '',
        createdDateTime: dataItem.createdDateTime ?? '',
      };
      return result;
    });

function calculateInfosecSettings(state: State) {
  const locations = _.uniqBy(
    personalDataItemsNotDeleted(state)
      .filter((p) => p.type?.endsWith('.paperDocuments'))
      .map((p): InfosecStorageUnit => {
        return {
          uuid: p.storageLocation ?? '',
          name: p.storageLocation ?? '',
          unitType: 'locations',
          computerType: '',
          application: '',
          partnerUuid: '',
          networkUuid: '',
        };
      }),
    (e) => e.uuid,
  );
  const assets = assetsNotDeleted(state)
    .filter((a) => !a.isNew)
    .map((a) => {
      return {
        uuid: a.uuid,
        name: a.name ?? '',
        number: a.number ?? '',
      };
    });
  const computers = mappedComputers(computersNotDeleted(state));
  return {
    computers: computers,

    dataItems: _.orderBy(mappedDataItems(personalDataItemsNotDeleted(state), computersNotDeleted(state)), [
      (o) => o.createdDateTime,
      (o) => {
        const dataItemName1 = dataItemName(o, computers, assets, locations);
        if (dataItemName1 === null) {
          console.log(o);
          return '?';
        }
        return dataItemName1.toLowerCase();
      },
      (o) => o.uuid,
    ]),
    employees: employeesNotDeleted(state)
      .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated && !e.isNew)
      .map((p) => ({ value: p.uuid, text: p.name })),
    partners: mappedPartners(partnersNotDeleted(state)),
    remoteServers: mappedRemoteServers(computersNotDeleted(state)),
    cloudServices: mappedCloudServices(computersNotDeleted(state)),
    locations: locations,
    assets: assets,
    networks: mappedNetworks(networksNotDeleted(state)),
    externalConnections: externalConnectionsForMap(state).map(
      (e: ExternalConnectionViewModel): InfosecExternalConnection => {
        if (e.type === 'Hjemmekontor') {
          return {
            type: 'Hjemmekontor',
            uuid: e.uuid,
            connectionType: e.connectionType ?? 'NONE',
            networkUuid: e.networkUuid ?? '',
            employeeUuid: e.employeeUuid ?? '',
          };
        } else {
          return {
            type: 'Tilkoblet samarbeidspartner',
            uuid: e.uuid,
            connectionType: e.connectionType ?? 'NONE',
            networkUuid: e.networkUuid ?? '',
            partnerUuid: e.partnerUuid ?? '',
          };
        }
      },
    ),
  };
}

export function computersPageView(hrefPrefix: string, viewModel: PageViewModel, state: State): ComputersPageViewModel {
  const infosecSettings = calculateInfosecSettings(state);
  return {
    organizationName: displayName(getOrganization(state)?.name),
    antiVirusRequirementsOptions: antiVirusRequirementsOptions(state).map((o) => ({ value: o.id, text: o.value })),
    type: 'computers-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: dabihStore.writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: dabihStore.getOrganizationId(state),
    icon: '',
    connectedPartners: calculateExternalConnectedPartners(state),
    connectedEmployees: calculateExternalConnectedEmployees(state),
    cloudServices: calculateExternalCloudServices(state, infosecSettings),
    remoteServers: calculateExternalRemoteServers(state, infosecSettings),
    networks: calculateNetworks(state, infosecSettings),
    offlinePersonalDataItems: calculateOfflinePersonalDataItems(state, infosecSettings),
    standaloneComputers: calculateStandaloneComputers(state, infosecSettings),
    standalonePrinters: calculateStandalonePrinters(state),
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => !e.isNew)
      .map((e) => ({ value: e.uuid, text: e.name })),
    availablePartners: partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name })),
    availableContracts: contractsNotDeletedWithParties(state).map((p) => ({
      value: p.uuid,
      text: p.nameWithParties || p.name,
    })),
    availableRiskAssessments: availableRiskAssessments(state).map((r) => ({
      name: r.name,
      uuid: r.uuid,
      riskDisplay: riskDisplay(r.probability, r.consequence),
      pageId: r.pageId,
    })),
    availableStorageUnits: storageUnits(state),
    patientDialogueRequirementsOptions: [],
    infosecSettings: infosecSettings,
  };
}

export function riskDisplay(probability: number | undefined, consequence: number | undefined) {
  if (probability === undefined || consequence === undefined) {
    return 'Ikke vurdert';
  }
  const risk = probability * consequence;
  if (risk === 0) {
    return 'Ikke vurdert';
  }
  if (risk > 0 && risk < 5) {
    return 'Lav risiko';
  }
  if (risk > 4 && risk < 9) {
    return 'Moderat risiko';
  }
  if (risk > 8 && risk < 13) {
    return 'Høy risiko';
  }
  if (risk > 12) {
    return 'Svært høy risiko';
  }
  throw new Error('Illegal state');
}

function riskAssessmentsDisplay(
  state: State,
  riskAssessments: string[],
): { name: string; riskDisplay: string; href: string }[] {
  const o = getOrganizationId(state);
  const a = availableRiskAssessments(state);
  return a
    .filter((r) => riskAssessments.includes(r.uuid))
    .map((r) => ({
      name: r.name,
      href: '/account/' + o + '/20/riskAssessments/' + r.uuid,
      riskDisplay: riskDisplay(r.probability, r.consequence),
    }));
}
