import type { DayItem } from '../../library/lists/d-calendar-list-section.js';
import type { ListSectionItemInput } from '../../library/lists/utilities.js';
import type { TodoListItem } from '../../library/lists/d-todo-list-section.js';
import { DayOfWeek, LocalDate } from '../../utilities/local-date.js';
import type { FunctionViewModel, PageViewModel } from '../../store/api';
import type { EmployeeFunction, HomePageViewModel } from '../../pages/home-page/home-page-view-model.js';
import { sortedByLabel } from '../../store/utilities.js';
import type { State } from '../../store/types.js';
import {
  calendarTaskInstances,
  contractsForCurrentUser,
  currentEmployeeForStaffingCalendar,
  currentEmployeeUuid,
  currentUserHasStaffingCalendarAccess,
  employeesNotDeleted,
  functionsNotDeletedWithTaskCount,
  getOrganization,
  getOrganizationId,
  isCurrentUserEmployee,
  leavePeriodsConfirmed,
  singleUserVersion,
  startTaskInstances,
  timekeepingPeriodsForCurrentEmployee,
  todoList,
  todoListHideAfterDaysOption,
  weekStart,
  writeAccess,
} from '../../store';
import { calculateBalance } from '../../models/pages/staffing-page-view.js';
import type { TutorialListItem } from '../../library/lists/d-list-section-tutorials.js';
import { isGuideActive, isSingleTutorialActive } from '../../models/tutorials.js';
import tutorialsList from '../../store/data/tutorials.json';
import { calculateUpdateStatus } from '../resolvers/calculate-update-status.js';
import { displayName, entityNameForLists } from 'src/utilities/text';

interface FunctionViewModelWithTaskCount extends FunctionViewModel {
  taskCount: number;
}

function taskCountDescription(n: number): string {
  switch (n) {
    case 0:
      return 'Ingen rutiner';
    case 1:
      return '1 rutine >';
    default:
      return '' + n + ' rutiner >';
  }
}

/**
 * Calculates the next date this current user will be assigned this function. Only applicable for rotated
 * functions. For other functions the method returns an empty string. Also, if the function is rotated but
 * only assigned one employee.
 *
 * For rotated functions the rotation can be "R-0" for user defined. This returns the string "Rotasjonen er ikke definert".
 * Else it is a 1-6 week rotation. In that case the days this week when this employee is assigned is returned.
 * If no days this week the first assigned date is returned
 *
 * If no date is assigned we return empty string.
 *
 */
function _nextDateForFunction(item: FunctionViewModelWithTaskCount, employeeId: string, localDate: LocalDate) {
  if (item.type === 'ROTATED') {
    if (item.employees.length === 1) {
      return '';
    }

    if (item.rotation === 'R-0') {
      return 'Rotasjonen er ikke definert';
    }

    if (!item.rotation) {
      return '-';
    }

    const weeks = parseInt(item.rotation.split('-')[1], 10);

    const daysThisWeek: string[] = [];
    const thisWeek = localDate.withPreviousOrSame(DayOfWeek.MONDAY);

    for (let i = 0; i < 7; i++) {
      const date = thisWeek.plusDays(i);
      if (_isAssigned(item, employeeId, date, weeks)) {
        daysThisWeek.push(date.getDayOfWeekString());
      }
    }

    if (daysThisWeek.length > 0) {
      let dayList;
      if (daysThisWeek.length > 1) {
        const lastDay = daysThisWeek[Object.keys(daysThisWeek)[Object.keys(daysThisWeek).length - 1]];
        daysThisWeek.splice(-1, 1);
        dayList = daysThisWeek.join(', ') + ' og ' + lastDay;
        if (dayList === 'mandag, tirsdag, onsdag, torsdag og fredag') {
          dayList = 'mandag - fredag';
        }
        if (dayList === 'mandag, tirsdag, onsdag, torsdag, fredag, lørdag og søndag') {
          dayList = 'alle dager';
        }
      } else {
        dayList = daysThisWeek;
      }
      return 'Denne uken: ' + dayList;
    }

    const daysToCheck = weeks * 7;
    for (let j = 0; j < daysToCheck; j++) {
      const date = localDate.plusDays(j);
      if (_isAssigned(item, employeeId, date, weeks)) {
        return 'Neste gang: ' + date.toStringForDisplayWithDayOfWeek();
      }
    }
    return '';
  } else {
    return '';
  }
}

function _isAssigned(
  item: FunctionViewModelWithTaskCount,
  employeeId: string,
  date: LocalDate,
  weeks: number,
): boolean {
  const rotationsForEmployee = item.rotations.filter(function (r) {
    return r.employee === employeeId;
  });
  const d = date.withPreviousOrSame(DayOfWeek.MONDAY);
  const s1 = d;
  const rotationsForEmployeeAndWeek = rotationsForEmployee.filter(function (r) {
    const s2 = LocalDate.fromString(r.startDate);
    const weekOffset = Math.floor(s2.until(s1) / 7);
    return Math.abs(weekOffset % weeks) === 0;
  });
  if (rotationsForEmployeeAndWeek.length === 1) {
    const rotation = rotationsForEmployeeAndWeek[0];
    const dayNumber = date.dayOfWeek().valueOf();
    const key = 'day' + dayNumber;
    return rotation[key] === 1;
  }
  return false;
}

function functionsFilteredByEmployeeId(
  hrefPrefix: string,
  value: FunctionViewModelWithTaskCount[],
  uuid: string | undefined,
  today: LocalDate,
): EmployeeFunction[] {
  if (value.length === 0 || uuid === undefined) {
    return [];
  }
  const list: EmployeeFunction[] = [];
  const common: EmployeeFunction = {
    locked: false,
    accessible: true,
    updateStatus: 'none',
    secondaryLabel: '',
    href: undefined,
    label: 'Fellesansvar',
  };
  value.forEach((item) => {
    if (item.type === 'COMMON') {
      item.name = 'Fellesansvar';
      common.updateStatus = calculateUpdateStatus(item, today);
      common.rightLabel = taskCountDescription(item.taskCount);
      common.href = hrefPrefix + 'functions/' + item.uuid;
    } else if (item.employees?.includes(uuid)) {
      const f = {
        locked: false,
        accessible: true,
        updateStatus: calculateUpdateStatus(item, today),
        secondaryLabel: _nextDateForFunction(item, uuid, today),
        href: hrefPrefix + 'functions/' + item.uuid,
        label: displayName(item.name),
        rightLabel: taskCountDescription(item.taskCount),
        functionRotated: item.type === 'ROTATED' && item.employees.length > 1,
      };
      list.push(f);
    }
  });

  const sortedList = sortedByLabel(list);
  sortedList.unshift(common);
  return sortedList;
}

function toListItems(todoList: TodoListItem[]): ListSectionItemInput[] {
  return todoList.map((e): ListSectionItemInput => {
    return {
      locked: false,
      label: entityNameForLists(e.name, e.draftName, e.isNew),
      eventCurrentUser: e.myTask ?? false,
      href: e.href,
      accessible: true,
      secondaryLabel: e.doneText,
      rightLabel: e.assignee,
      rightLabelClass: 'assignee',
      notes: e.eventNotes ?? '',
      updateStatus: 'none',
      eventDone: e.done,
      eventOverdue: e.overdue,
      hasDraft: e.hasDraft,
    };
  });
}

interface DayItemTask {
  eventNotes: string;
  myTask: boolean;
  href: string;
  accessible: boolean;
  locked: boolean;
  eventType?: string;
  timeOfDayDisplay: string;
  doneText: string;
  assignee: string;
  name: string;
  hasDraft: boolean;
  meeting?: boolean;
  isNew: boolean;
  draftName?: string;
}

function toDayItems(
  calendarTaskInstances: {
    tasks: DayItemTask[];
    date: string;
    holiday: string;
  }[],
): DayItem[] {
  return calendarTaskInstances.map((e) => ({
    date: e.date,
    holiday: e.holiday,
    tasks: e.tasks.map((t): ListSectionItemInput => {
      const name = entityNameForLists(t.name, t.draftName, t.isNew);
      return {
        eventDone: t.doneText !== '',
        locked: t.locked,
        accessible: t.accessible,
        notes: t.eventNotes,
        eventCurrentUser: t.myTask,
        href: t.href,
        updateStatus: 'none',
        label: (t.timeOfDayDisplay + ' ' + name).trim(),
        secondaryLabel: t.doneText,
        rightLabelClass: 'assignee',
        rightLabel: t.assignee,
        hasDraft: t.hasDraft,
        meeting: t.meeting,
      };
    }),
  }));
}

function findActiveTutorials(state: State): TutorialListItem[] {
  const organizationState = state.organization;
  if (organizationState) {
    const result: TutorialListItem[] = [];

    const uuid = currentEmployeeUuid(state);

    if (isGuideActive(organizationState.tutorial_state_1)) {
      result.push({
        uuid: '1',
        name: tutorialsList.find((t) => t.id === '1')?.title ?? '',
        assigned: '',
        due: '',
        completed: '',
      });
    }

    if (uuid && isSingleTutorialActive(organizationState.tutorial_2, uuid)) {
      const tutorial = tutorialsList.find((t) => t.id === '2');
      if (tutorial === undefined) {
        throw new Error('Illegal state');
      }
      const f = organizationState.tutorial_2.participants.find((p) => p.uuid === uuid);
      if (f && f.completed === '') {
        result.push({
          uuid: '2',
          name: tutorial.title,
          assigned: f.assigned ?? '',
          due: f.due ?? '',
          completed: f.completed,
        });
      }
    }
    return result;
  }

  return [];
}

export function homePageView(hrefPrefix: string, viewModel: PageViewModel, state: State): HomePageViewModel {
  let leavePeriodEditRestriction = false;
  const organization = getOrganization(state);
  if (organization) {
    leavePeriodEditRestriction = organization.leavePeriodEditRestriction;
  }
  return {
    type: 'home-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: writeAccess(state),
    leavePeriodEditRestriction: leavePeriodEditRestriction,
    currentUserHasExtendedStaffingAccess: currentUserHasStaffingCalendarAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: getOrganizationId(state),
    singleUserVersion: singleUserVersion(state),
    employees: employeesNotDeleted(state),
    employeeId: currentEmployeeUuid(state),
    isEmployee: isCurrentUserEmployee(state),
    currentFunctions: functionsFilteredByEmployeeId(
      hrefPrefix,
      functionsNotDeletedWithTaskCount(state),
      currentEmployeeUuid(state),
      LocalDate.fromString(state.today),
    ),
    contracts: contractsForCurrentUser(state).map((g) => {
      return {
        locked: false,
        accessible: true,
        updateStatus: calculateUpdateStatus(g, LocalDate.fromString(state.today)),
        label: displayName(g.name),
        secondaryLabel: '',
        href: hrefPrefix + 'contracts/' + g.uuid,
      };
    }),
    timekeepingPeriods: timekeepingPeriodsForCurrentEmployee(state),
    timekeepingBalance: calculateBalance(timekeepingPeriodsForCurrentEmployee(state)),
    timekeepingEmployee: currentEmployeeForStaffingCalendar(state),
    todoList: toListItems(todoList(state)),
    hideAfterDays: todoListHideAfterDaysOption(state),
    weekStart: weekStart(state),
    leavePeriodsConfirmed: leavePeriodsConfirmed(state),
    startTaskInstances: startTaskInstances(state),
    calendarTaskInstances: toDayItems(calendarTaskInstances(state)),
    activeTutorials: findActiveTutorials(state),
    today: LocalDate.fromString(state.today),
  };
}
