import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-label.js';
import '../elements/d-select-dropdown.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

export interface SelectDropdownOption {
  value: string;
  text: string;
}

/**
 *
 *    The value event is passed through from the underlying dropdown
 *
 */
@customElement('d-select-dropdown-or-add')
export class DSelectDropdownOrAdd extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      div {
        height: var(--inputElementHeight);
        box-sizing: border-box;
        border: 1px solid var(--borderColor);
        background: white;
        padding: 5px 10px 0 10px;
        color: var(--placeholderColor);
        font-family: var(--mainSerif), serif;
        white-space: nowrap;
        cursor: pointer;
      }
      :host([theme-page]) div {
        height: calc(var(--inputElementHeight) + 2px);
        border-radius: var(--inputElementBorderRadius);
      }
      div:hover {
        color: black;
      }
    `,
  ];
  @property({ type: String })
  placeholder = '';
  @property({ type: String })
  firstItemText = '';
  @property({ type: String })
  additionalItemText = '';
  @property({ type: Boolean })
  unselectablePlaceholder = false;
  @property({ type: String })
  value = '';
  @property({ type: Boolean })
  disabled = false;
  @property({ type: Array })
  options: SelectDropdownOption[] = [];
  @property({ type: Boolean, reflect: true })
  controller = false;
  @property({ type: Boolean, reflect: true })
  toolbar = false;

  private get optionsWithAdd() {
    return [...this.options, { value: 'NEW', text: this.additionalItemText }];
  }

  _selectValueChanged(value) {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: value },
      }),
    );
  }

  protected renderContent() {
    if (this.options.length) {
      return html`
        <d-select-dropdown
          ?theme-page=${this.themePage}
          .placeholder=${this.placeholder}
          ?unselectablePlaceholder=${this.unselectablePlaceholder}
          .options=${this.optionsWithAdd}
          .value=${this.value}
          ?disabled=${this.disabled}
        ></d-select-dropdown>
      `;
    }
    return html` <div @click=${() => this._selectValueChanged('NEW')}>${this.firstItemText}</div> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-dropdown-or-add': DSelectDropdownOrAdd;
  }
}
