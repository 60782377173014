/**
 * Koden her kan kanskje flyttes slik at den er en del av d-application.ts eller ligger parallelt med den.
 */
import type {
  ApplicationViewModel,
  LoadedApplicationViewModel,
  PageMenuItem,
} from 'src/layout/application-view-model.js';
import type { StartTask } from 'src/layout/parts/d-starttask-field.js';
import { currentContentViews } from 'src/models/content/content-view-factory.js';
import { pageView } from 'src/models/pages/page-view.js';
import { sortedByName } from 'src/store/utilities.js';
import { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';
import type { PageViewModel } from '../store/api';
import type { OrganizationState, State } from '../store/types.js';
import { buildCurrentTutorialContent, findAvailableTutorials, isTutorialActive } from 'src/models/tutorials.js';
import type { SettingsItem } from 'src/layout/parts/d-organization-settings.js';
import {
  currentEmployeeAsViewModel,
  currentEmployeeUuid,
  employeesDeleted,
  employeesNotDeleted,
  getEmployeesAsUsers,
  getEmployeesWithAccessForAdministrator,
  isCurrentUserEmployee,
} from 'src/store/selectors/organization-employees.js';
import { getContactsAsUsers } from 'src/store/selectors/organization-partners.js';
import { getOrganization, getOrganizationId, singleUserVersion } from 'src/store/selectors/organization.js';
import { getPages } from 'src/store/selectors/pages.js';
import { currentEntityTypeName, splitPath } from 'src/store/selectors/path.js';
import { displayedUserName, requiresSecureLogin } from 'src/store/selectors/user.js';
import { startTasks } from 'src/store/selectors/organization-functions.js';
import { currentRobotAlert, functionsByTemplateId, showRobotAlert } from 'src/store/selectors/robot-alert.js';
import { breadCrumbSelectedName } from 'src/store/selectors/breadcrumbs.js';
import { loaded } from 'src/store/selectors/selectors';
import { createClient } from 'src/store';
import { getDocsForLinking, convertInternalLinks } from 'src/models/internal-linking.js';

function menuPages(pages: PageViewModel[], organizationId: number, singleUserVersion: boolean): PageMenuItem[] {
  const homepage: PageMenuItem = {
    pageId: 2300,
    name: 'Min side',
    href: '/account/' + organizationId + '/2300',
    subPages: [],
  };
  return [
    homepage,
    ...pages.map((p) => {
      return {
        pageId: p.pageId,
        name: p.name,
        href: '/account/' + organizationId + '/' + p.pageId,
        subPages: p.subPages
          .filter((s) => !(singleUserVersion && s.pageId === 5962))
          .map((s) => {
            return {
              pageId: s.pageId,
              name: s.name,
              href: '/account/' + organizationId + '/' + s.pageId,
            };
          }),
      };
    }),
  ];
}

export function getUsersForAccess(state: State) {
  return [
    ...getEmployeesAsUsers(state).map((u) => ({
      name: u.name,
      uuid: u.uuid,
      email: u.email ?? '',
      accessLevel: u.accessLevel?.toString() ?? 'NONE',
      accessExpires: '',
    })),
    ...getContactsAsUsers(state).map((u) => ({
      name: u.name,
      uuid: u.uuid,
      email: u.email ?? '',
      accessLevel: u.accessLevel?.toString() ?? 'NONE',
      partnerName: u.partnerName,
      partnerUuid: u.partnerUuid,
      accessExpires: u.accessExpires ?? '',
    })),
  ];
}

function getSettings(
  state: State,
  currentEmployee,
  currentUserIsOwner: boolean,
  organization: OrganizationState,
): SettingsItem[] {
  const api = createClient();
  const result: SettingsItem[] = [];
  result.push({
    name: 'Brukervalg',
    id: 'profile',
    type: 'profile',
    isEmployee: isCurrentUserEmployee(state),
    user: {
      criiptoAuthDescription: state.user?.criiptoAuthDescription ?? '',
      authDescription: state.user?.authDescription ?? '',

      username: state.user?.username ?? '',
    },
    reminders: currentEmployee?.receiveReminders ?? 'NONE',
    alerts: currentEmployee?.summaries ?? 'NONE',
  });
  if (currentUserIsOwner) {
    result.push({
      name: 'Abonnement',
      id: 'subscription',
      type: 'subscription',
      singleUserVersionAvailable: _singleUserVersionAvailable(state),
      administratorEmployeesList: getEmployeesWithAccessForAdministrator(state).map((a) => ({
        name: a.name,
        email: a.email ?? '',
      })),
      subscription: {
        invoiceAddress: organization?.invoiceAddress ?? '',
        invoiceLocality: organization?.invoiceLocality ?? '',
        invoiceOrganizationNumber: organization?.invoiceOrganizationNumber ?? '',
        invoicePostcode: organization?.invoicePostcode ?? '',
        invoiceReceiver: organization?.invoiceReceiver ?? '',
        invoiceReference: organization?.invoiceReference ?? '',
        invoiceSendMethod: organization?.invoiceSendMethod === 'EHF' ? 'EHF' : 'MANUAL',
        ownerEmail: organization?.ownerEmail ?? '',
        specialTerms: organization?.specialTerms ?? '',
        singleUser: singleUserVersion(state),
        requiresSecureLogin: requiresSecureLogin(state) ?? false,
      },
      elmaLookup: async (q) => {
        const p = await api.accounts.searchElmaRegistry({ query: q });
        return p.map((x) => ({ value: x.identifier, text: x.identifier + ' ' + x.name }));
      },
    });
  }
  result.push({
    name: 'Brukere',
    id: 'users',
    type: 'users',
    users: getUsersForAccess(state),
    ownerEmail: organization?.ownerEmail ?? '',
    currentUserEmail: state.user?.username ?? '',
  });
  result.push({
    name: 'Organisasjoner',
    id: 'organizations',
    type: 'organizations',
    organizations: sortedByName(state.user?.organizations ?? []),
  });

  return result;
}

export async function loadedApplicationViewModel(state: State): Promise<LoadedApplicationViewModel> {
  const pages = getPages(state);
  const organizationId = getOrganizationId(state);
  const singleUser = singleUserVersion(state);
  const pathSegments = splitPath(state).slice(3);
  const currentPageId = pathSegments.length > 0 ? Number(pathSegments[0] ?? '2300') : 2300;
  const hrefPrefix = '/account/' + organizationId + '/' + currentPageId + '/';
  const currentUserId = currentEmployeeUuid(state);
  const currentEmployee = currentEmployeeAsViewModel(state);
  const organization = getOrganization(state);
  const now = LocalDate.fromString(state.today);
  const monday = now.withPreviousOrSame(DayOfWeek.MONDAY);

  function currentStartTask(state: State): StartTask | undefined {
    const tasks = startTasks(state);
    const predicate =
      state.selectedStartTask !== '' ? (t) => t.uuid === state.selectedStartTask : (t) => t.myTask && !t.executed;

    const myTasks = tasks.filter(predicate);

    return myTasks.map((t) => ({
      uuid: t.uuid,
      weeksFromNow: Math.floor(monday.until(t.time) / 7),
      weekDisplay: 'Uke ' + t.time.toStringForDisplayWeekAndYear(),
      functionName: t.functionName,
      href: t.href,
      name: t.name,
      helpContent: t.helpContent,
      procedures: convertInternalLinks(t.procedures, getDocsForLinking(state)),
      relatedItems: t.relatedItems ?? [],
    }))[0];
  }

  const robotAlert = showRobotAlert(state) ? currentRobotAlert(state) : undefined;

  if (organization === undefined) {
    throw new Error('Illegal state (E121), organization not found');
  }

  const currentUserIsOwner = (currentEmployee?.email?.toUpperCase() ?? '') === organization.ownerEmail.toUpperCase();

  const currentTutorialContent = buildCurrentTutorialContent(state.currentTutorialId, organization, currentEmployee);
  return {
    availableTutorials: findAvailableTutorials(state, organization, currentEmployee),
    loaded: true,
    tutorialActive: isTutorialActive(organization, currentEmployee),
    displayedUserName: displayedUserName(state),
    pageMenu: menuPages(pages, organizationId, singleUser),
    currentOrganizationId: organizationId,
    currentEmployeeUuid: currentEmployeeUuid(state),
    currentPageId: currentPageId,
    currentPageView: pageView(hrefPrefix, currentPageId, pages, state),
    currentPathArray: splitPath(state),
    headerStartTask: {
      startTaskExpanded: state.selectedStartTask !== '',
      startTask: currentStartTask(state),
      userTotal: startTasks(state).filter((item) => item.myTask).length,
      userExecuted: startTasks(state).filter((item) => item.myTask && item.executed).length,
    },
    singleUserVersion: singleUser,
    helpViewerOpen: state.helpViewerOpen,
    tutorialViewerOpen: state.tutorialViewerOpen,
    currentTutorialContent: currentTutorialContent,
    currentHelpPage: state.currentHelpPage,
    contentViews: await currentContentViews(hrefPrefix, pathSegments, state),
    employeesForShare: employeesNotDeleted(state)
      .filter((e) => e.uuid !== currentUserId && e.status === 'ACTIVE' && e.accessLevel !== 'NONE')
      .map((e) => ({ uuid: e.uuid, name: e.name, disabled: false })),
    selectedEntityName: breadCrumbSelectedName(state),
    selectedEntityType: currentEntityTypeName(state),
    settings: getSettings(state, currentEmployee, currentUserIsOwner, organization),

    currentRobotAlert: robotAlert,
    functionsByTemplateId: functionsByTemplateId(state),
  };
}

function _singleUserVersionAvailable(state: State) {
  return employeesNotDeleted(state).length === 1 && employeesDeleted(state).length === 0;
}

export async function applicationViewModel(state: State): Promise<ApplicationViewModel> {
  const l = loaded(state);

  if (l) {
    try {
      return await loadedApplicationViewModel(state);
    } catch (e) {
      console.error(e);
      return {
        loaded: false,
      };
    }
  } else {
    return {
      loaded: false,
    };
  }
}
