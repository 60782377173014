import { html, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import 'src/content/entity-content';
import '../../library/editors/index.js';
import '../../library/editors/elements/d-select-checkbox.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-html.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import './d-view-checklist.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';
import type { Network } from 'src/pages/computers-page/d-computers-page-content-map.js';

export interface ComputerMapPrinter extends DataMapItem {
  networkUuid: string;
  name: string;
  notes: string;
  printerPositionSecure: boolean;
  location: string;
  unitType: 'printer';
  attachments: string[];
}

export interface PrinterEditItem {
  networkUuid: string;
  name: string;
  notes: string;
  printerPositionSecure: boolean;
  location: string;
}

/**
 *
 */
@customElement('d-computer-unit-printer')
export class DComputerUnitPrinter extends DataMapElement<ComputerMapPrinter, PrinterEditItem> {
  static readonly styles = [...DataMapElement.styles];
  printerPositionSecureOptions = [
    {
      value: 'true',
      text: 'Skriveren er plassert slik at uvedkommende ikke kan få tilgang til utskrifter',
    },
  ];
  @property({ type: Array })
  networks: Network[] = [];

  initializeEditItem(): PrinterEditItem {
    return {
      name: this.item.name,
      networkUuid: this.item.networkUuid,
      notes: this.item.notes,
      printerPositionSecure: this.item.printerPositionSecure,
      location: this.item.location,
    };
  }

  _displayTypeName(): string {
    return 'Nettverksskriver';
  }

  _displayName() {
    return this.item.name;
  }

  _operatorTypes(networkConnection) {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    if (networkConnection !== 'NONE' && networkConnection !== '') {
      list.push({ value: 'INHERIT', text: 'Samme som for nettverket' });
    }
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this._operatorTypes(this.item.networkUuid);
    for (const item of list) {
      if (id === item.value) {
        result = item.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  _isValid(): boolean {
    return true;
  }

  renderUnitView() {
    return html`<section view="">
      <d-section>
        ${!this.item.location
          ? nothing
          : html`<d-view-text field="printers_location" value="${this.item.location}"> </d-view-text>`}
      </d-section>

      ${!this.item.notes
        ? nothing
        : html`<d-section>
            <d-view-html field="printers_notes" value="${this.item.notes}"> </d-view-html>
          </d-section>`}
      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        write-access="${this.writeAccess}"
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  renderUnitEdit(editItem: PrinterEditItem) {
    return html`
      <d-section>
        <d-select-dropdown
          theme-page
          field="printers_network"
          .options="${this._networkConnections(this.networks)}"
          value="${editItem.networkUuid}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              networkUuid: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
        <d-edit-text
          theme-page
          field="printers_name"
          value="${editItem.name}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              name: e.detail.value,
            };
          }}
        >
        </d-edit-text>
      </d-section>
      <d-section>
        <d-edit-text
          theme-page
          field="printers_location"
          placeholder="Beskriv hvor skriveren er plassert"
          value="${editItem.location}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              location: e.detail.value,
            };
          }}
        >
        </d-edit-text>
      </d-section>
      <d-section topless>
        <d-select-checkbox
          theme-page
          deselectable="true"
          field=""
          .options=${this.printerPositionSecureOptions}
          single
          .value=${[editItem.printerPositionSecure ? 'true' : 'false']}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              printerPositionSecure: e.detail.value === 'true',
            };
          }}
        >
        </d-select-checkbox>
      </d-section>
      <d-edit-html
        theme-page
        map-element
        .contentStickyTop=${this.contentStickyTop}
        field="printers_notes"
        value="${editItem.notes}"
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = {
            ...editItem,
            notes: e.detail.value,
          };
        }}
      >
      </d-edit-html>
    `;
  }

  protected typeClasses(): string {
    return '';
  }

  private _networkConnections(networks: { name: string; uuid: string }[]): SelectDropdownOption[] {
    const list = [
      { value: '', text: 'Velg nettverkstilknytning' },
      { value: 'NONE', text: 'Ingen nettverkstilknytning' },
    ];
    let unregisteredLocalNetworkTerm = 'Lokalt nettverk';
    if (networks.length > 0) {
      unregisteredLocalNetworkTerm = 'Annet lokalt nettverk';
      for (const element of networks) {
        let value = 'Lokalt nettverk uten navn';
        if (element.name !== '') {
          value = 'Lokalt nettverk: ' + element.name;
        }
        list.push({ value: element.uuid, text: value });
      }
    }
    list.push({ value: 'OTHER', text: unregisteredLocalNetworkTerm });
    return list;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computer-unit-printer': DComputerUnitPrinter;
  }
}
