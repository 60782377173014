import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import type { IssueUpdateMessage } from 'src/store/api';
import { LocalDate } from 'src/utilities/local-date.js';

export function descriptionForExternal(description, eventDate, immediateMeasures, furtherMeasures) {
  let html = '<p>' + description + '</p>';
  if (eventDate) {
    html += '<p><strong>Hendelsesdato:</strong> ' + LocalDate.fromString(eventDate).toStringForDisplay() + '</p>';
  }
  if (immediateMeasures) {
    html += '<p><strong>Strakstiltak som er iverksatt:</strong> ' + immediateMeasures + '</p>';
  }
  if (furtherMeasures) {
    html += '<p><strong>Forslag til flere tiltak:</strong> ' + furtherMeasures + '</p>';
  }
  return html;
}

export class DIssueBehavior extends LitElement {
  @property({
    type: Boolean,
  })
  pllParticipant = false;
  @property({
    type: Array,
  })
  externalRecipients = [];

  defaultIssue(): IssueUpdateMessage {
    return {
      eventDate: undefined,
      immediateMeasures: '',
      reportedSafetyManager: false,
      registeredDate: undefined,
      correction: '',
      reportedCountyMedical: false,
      description: '',
      evaluated: false,
      reportedInspectorate: false,
      shallEvaluated: false,
      identifiedMeasures: '',
      evaluatedDate: undefined,
      reportedLabourInspection: false,
      notes: '',
      processedBy: '',
      reportedTo: '',
      furtherMeasures: '',
      reportedDate: '',
      requireEvaluation: false,
      pages: [281],
      relateToPatientInstitution: false,
      name: '',
      processedDate: undefined,
      informationLeakagePossibility: false,
      requiresMeasures: false,
      measuresSatisfactorily: false,
      relateToRadiation: false,
      relateToRadiationEquipmentMissing: false,
      personDamagePossibility: false,
      relateToEmployees: false,
      workRelatedHealthIssue: false,
      workRelatedHealthIssueEmployee: '',
      workRelatedInjury: false,
      workRelatedSickness: false,
      workRelatedHealthIssueDescription: '',
      workRelatedInjuryTask: '',
      workRelatedInjuryIncidentCause: '',
      workRelatedSicknessCausingTask: '',
      workRelatedSicknessCausingTaskLocation: '',
      workRelatedSicknessCausingEnvironment: '',
      relateToPatient: false,
      relateToEquipment: false,
      relateToMaritimeHealthCertificate: false,
      implementedMeasure: false,
      evaluatedBy: '',
      evaluatedOk: false,
      accessControl: [],
      classification: 'NONE',
    };
  }

  ready() {
    this._fetchRecipients();

    if (location.href.startsWith('https://app.trinnvis.no/account/2668')) {
      this.pllParticipant = true;
    }
  }

  _fetchRecipients() {
    fetch('https://api.trinnvis.io/external-issues/recipients/')
      .then((response) => response.text())
      .then((recipients) => {
        const list = JSON.parse(recipients);
        const options = list.map((item) => ({
          uuid: item.id,
          value: item.description,
        }));
        this.externalRecipients = options;
      });
  }

  formatAsSentTime(date) {
    if (date === undefined) {
      return '';
    }
    const localDate = LocalDate.fromString(date.substring(0, 10));
    const dateAsString = localDate.toStringForDisplayWithDayOfWeekAndYear();
    const timePartHours = date.substring(11, 13);
    const timePartMinutes = date.substring(14, 16);
    const timeAsString = 'kl. ' + timePartHours + '.' + timePartMinutes;

    return dateAsString + ' ' + timeAsString;
  }

  capitalize(text) {
    return text.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }
}
