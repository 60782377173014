import type { EmployeeViewModelWithName, OrganizationState, State } from '../types.js';
import type { ContractViewModel, EmployeeViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { _sortByFirstAndLastName, _sortByName, _sortByValue, isCurrentUser, toList } from './utilities.js';
import { getCurrentUserEmail } from './session.js';
import { getOrganization } from './organization.js';

export function _filterByEmployeeId(list: ContractViewModel[], uuid?: string): ContractViewModel[] {
  if (!list || !uuid) {
    return [];
  }

  return list
    .filter((item) => {
      if (!item.employees) {
        return false;
      }
      return item.employees.includes(uuid);
    })
    .sort(_sortByName);
}

function getEmployeesById(state: State): { [uuid: string]: EmployeeViewModel } {
  return state.organization !== undefined ? state.organization.employeesById : {};
}

const getEmployees = createSelector(getEmployeesById, (employeesById): EmployeeViewModel[] => toList(employeesById));

export const getEmployeesWithForAccessControl = createSelector(
  getEmployees,
  getCurrentUserEmail,
  (employees, currentUserEmail) =>
    employees
      .filter((e) => !e.deleted)
      .filter((e) => !e.isNew)
      .filter((e) => e.status !== 'TERMINATED')
      .map((e) => ({
        id: e.uuid,
        value: e.firstName + ' ' + e.lastName,
        disabled: isCurrentUser(e.email, currentUserEmail),
      }))
      .sort(_sortByValue),
);

export const currentEmployeeUuid = createSelector(getEmployees, getCurrentUserEmail, (employees, currentUserEmail) => {
  const find = employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .filter(function (e) {
      return isCurrentUser(e.email, currentUserEmail);
    });

  if (find.length === 1) {
    return find[0].uuid;
  } else {
    return undefined;
  }
});

export const currentEmployeeAsViewModel = createSelector(
  getEmployees,
  getCurrentUserEmail,
  (employees, currentUserEmail) => {
    const find = employees
      .filter(function (e) {
        return !e.deleted;
      })
      .filter(function (e) {
        return e.status !== 'TERMINATED';
      })
      .filter(function (e) {
        return isCurrentUser(e.email, currentUserEmail);
      });

    if (find.length === 1) {
      return find[0];
    }

    return undefined;
  },
);

export const isCurrentUserEmployee = createSelector(currentEmployeeUuid, function (uuid) {
  return uuid !== null && uuid !== undefined && uuid !== '';
});

export const getEmployeesAsUsers = createSelector(getEmployees, function (employees) {
  return employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .map(function (e) {
      return {
        name: e.firstName + ' ' + e.lastName,
        accessLevel: e.accessLevel,
        uuid: e.uuid,
        email: e.email,
      };
    })
    .sort(_sortByName);
});

export const getEmployeesWithAccessForLocked = createSelector(getEmployees, function (employees) {
  return employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .map(function (e) {
      return {
        name: e.firstName + ' ' + e.lastName,
        accessLevel: e.accessLevel,
        uuid: e.uuid,
        email: e.email,
      };
    })
    .sort(_sortByName);
});

export const getEmployeesWithAccessForAdministrator = createSelector(getEmployees, function (employees) {
  return employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .filter(function (e) {
      return e.accessLevel !== 'NONE';
    })
    .filter(function (e) {
      return e.email;
    })
    .map(function (e) {
      return {
        name: e.firstName + ' ' + e.lastName,
        firstName: e.firstName,
        lastName: e.lastName,
        uuid: e.uuid,
        email: e.email,
      };
    })
    .sort(_sortByName);
});
interface EmployeePeriod {
  start: string;
  startTime?: string;
  end: string;
  endTime?: string;
  type: string;
  grade: number;
  subPeriods?: EmployeePeriod[];
  confirmed: boolean;
  notes: string;
  id?: string;
}

export const employeesNotDeleted = createSelector(getOrganization, (organization): EmployeeViewModelWithName[] => {
  if (organization === undefined) {
    return [];
  }
  return toList(organization.employeesById)
    .filter(function (e) {
      return !e.deleted;
    })
    .sort(_sortByFirstAndLastName);
});

export function employeesDeleted(state: State): EmployeeViewModelWithName[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.employeesById).filter(function (e) {
    return e.deleted;
  });
}

export const employeeNamesById = createSelector(getOrganization, function (organization) {
  const result: { [uuid: string]: string } = {};

  if (organization) {
    toList(organization.employeesById).forEach(function (e) {
      result[e.uuid] = e.firstName + ' ' + e.lastName;
    });
  }
  return result;
});

export function employeeNamesAsText(item: { employees?: string[] }, o: OrganizationState): string {
  if (item.employees === undefined) {
    return 'Ingen';
  }
  const a = item.employees
    .map((id) => o.employeesById[id])
    .filter((e) => e && !e.deleted)
    .map((e) => e.name)
    .sort();

  if (a.length === 0) {
    return 'Ingen';
  } else {
    return a.join(', ');
  }
}

export const employeesShortNames = createSelector(employeesNotDeleted, function (employees: EmployeeViewModel[]): {
  uuid: string;
  status: string;
  name: string;
}[] {
  const result: { uuid: string; status: string; name: string }[] = [];
  employees.forEach(function (e) {
    let status = '';
    if (e.status) {
      status = e.status;
    }
    let firstName = e.firstName ?? '';
    const firstNames = firstName.split(' ');
    if (firstNames.length > 1) {
      firstName = firstNames[0];
      for (let i = 0; i < firstNames.length; i++) {
        if (i !== 0 && firstNames[i]) {
          firstName += ' ' + firstNames[i].substring(0, 1) + '.';
        }
      }
    }
    let shortName = firstName;
    const othersWithSameFirstName = employees.filter(function (employee) {
      return firstName === employee.firstName && e.uuid !== employee.uuid;
    });
    if (othersWithSameFirstName.length) {
      shortName = firstName + ' ' + e.lastName.substring(0, 1) + '.';
      const othersWithSameFirstNameAndLastInitial = othersWithSameFirstName.filter(function (employee) {
        return e.lastName.startsWith(employee.lastName.substring(0, 1));
      });
      if (othersWithSameFirstNameAndLastInitial.length) {
        shortName = firstName + ' ' + e.lastName;
      }
    }
    result.push({ uuid: e.uuid, status: status, name: shortName });
  });
  return result;
});

export interface ShortNameEntry {
  uuid: string;
  status: string;
  name: string;
}

function employeesShortNamesAndCurrentUserPronoun(
  employeesShortNames: ShortNameEntry[],
  currentEmployeeUuid: string,
  currentUserPronoun: string,
): ShortNameEntry[] {
  if (currentEmployeeUuid === undefined) {
    return employeesShortNames;
  }
  const result = employeesShortNames.filter(function (item) {
    return item.uuid !== currentEmployeeUuid;
  });
  const currentEmployee = employeesShortNames.filter(function (item) {
    return item.uuid === currentEmployeeUuid;
  })[0];
  result.push({
    uuid: currentEmployeeUuid,
    status: currentEmployee.status,
    name: currentUserPronoun,
  });
  return result;
}

export const employeesShortNamesAndI = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (employeesShortNames: ShortNameEntry[], currentEmployeeUuid): ShortNameEntry[] {
    if (currentEmployeeUuid === undefined) {
      return employeesShortNames;
    }
    return employeesShortNamesAndCurrentUserPronoun(employeesShortNames, currentEmployeeUuid, 'jeg');
  },
);

export const employeesShortNamesAndMe = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (
    employeesShortNames: { uuid: string; status: string; name: string }[],
    currentEmployeeUuid,
  ): { uuid: string; status: string; name: string }[] {
    if (currentEmployeeUuid === undefined) {
      return employeesShortNames;
    }
    return employeesShortNamesAndCurrentUserPronoun(employeesShortNames, currentEmployeeUuid, 'meg');
  },
);

export const currentEmployeeShortName = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (employees: { uuid: string; status: string; name: string }[], currentEmployeeUuid): string | undefined {
    const names = employees.filter(function (e) {
      return e.uuid === currentEmployeeUuid;
    });
    if (names.length) {
      return names[0].name;
    }
    return undefined;
  },
);
