import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { joinWithAnd } from 'src/utilities/text.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/elements/d-section.js';
import type { ReportViewModel } from '../../store/api';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { MessageRecipient } from 'src/content/event-occurrences/d-event-occurrence-view';

export interface MessageForSend {
  occurrenceUuid: string;
  message: string;
  includeContent: boolean;
}

export interface MeetingMessageDialogInput {
  type: 'notice' | 'report';
  isEmployeeInterview: boolean;
  name?: string;
  recipients: MessageRecipient[];
  preventIncludeContent: boolean;
}

export type MeetingMessageDialogResult =
  | {
      action: 'send';
      message: string;
      includeContent: boolean;
    }
  | DialogCancelResult;

/**
 *
 * USAGE:
 *    d-event-view
 *
 */
@customElement('meeting-message-dialog')
export class MeetingMessageDialog extends BaseDialog<MeetingMessageDialogInput, MeetingMessageDialogResult> {
  @property({ type: String })
  uuid = '';
  @property({ type: String })
  instance = '';
  @property({ type: String })
  type: 'notice' | 'report' = 'notice';
  @property({ type: String })
  name = '';
  @property({ type: String })
  templateName = '';
  @property({ type: Boolean })
  isEmployeeInterview = false;
  @property({ type: Array })
  relatedReports!: ReportViewModel[];
  @property({ type: String })
  agenda = '';
  @property({ type: Array })
  recipients: MessageRecipient[] = [];
  @property({ type: Object })
  currentEmployee: { uuid: string; name: string } = { uuid: '', name: '' };
  @property({ type: String })
  meetingMessageComment = '';
  @property({ type: Boolean })
  messageIncludeContent = false;
  @property({ type: Boolean })
  preventIncludeContent = false;

  width = 600;

  protected get calculatedTitle(): string {
    const prefix = this.type === 'notice' ? 'Innkalling til ' : 'Referat fra ';
    return prefix + this.name;
  }

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.noRecipientsHaveAccess },
    ];
  }

  private get recipientNamesWithoutAccess(): string[] {
    return this.recipients
      .filter((p) => {
        return p.accessLevel === 'NONE';
      })
      .map((p) => {
        return p.name;
      });
  }

  private get noRecipientsHaveAccess() {
    return (
      this.recipients.filter((r) => {
        return r.accessLevel !== 'NONE';
      }).length === 0
    );
  }

  private get noAccessMessage() {
    if (this.recipientNamesWithoutAccess.length) {
      if (this.noRecipientsHaveAccess) {
        return '<p><i>Ingen møtedeltakere har tilgang til TrinnVis.</i></p>';
      }
      return (
        '<p><i>NB! ' +
        joinWithAnd(this.recipientNamesWithoutAccess) +
        ' mangler tilgang og kan ikke motta epost gjennom TrinnVis.</i></p>'
      );
    }
    return '';
  }

  private get includeContentText() {
    return this.type === 'notice' ? 'Send med agenda' : 'Send med referat';
  }

  private get prompt() {
    return this.type === 'notice' ? this.promptForNotice : this.promptForReport;
  }

  private get includeReportContentPromptPart() {
    if (this.preventIncludeContent) {
      return 'Du kan ikke legge ved selve referatet, fordi det har tilgangsbegrensning';
    }
    return 'Du kan også legge ved selve referatet, men husk at personopplysninger ikke må sendes på epost.';
  }

  private get promptForNotice() {
    return this.isEmployeeInterview
      ? '<p>Send innkalling på epost til både medarbeideren og lederen. ' +
          'Du kan eventuelt legge til en kommentar i eposten. ' +
          'Husk at personopplysninger ikke må sendes på epost.</p>'
      : '<p>Send innkalling på epost til alle møtedeltakere. ' +
          'Du kan eventuelt legge til en kommentar i eposten. ' +
          'Du kan også legge ved agendaen, men husk at personopplysninger ikke må sendes på epost.</p>';
  }

  private get promptForReport() {
    return this.isEmployeeInterview
      ? '<p>Send en epost til både medarbeideren og lederen med lenke til referatet. ' +
          'Du kan eventuelt legge til en kommentar i eposten, ' +
          'men husk at personopplysninger ikke må sendes på epost.</p>'
      : '<p>Send epost til alle møtedeltakere med lenke til referatet. ' +
          'Du kan eventuelt legge til en kommentar i eposten. ' +
          this.includeReportContentPromptPart +
          '</p>';
  }

  onMeetingMessageCommentChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.meetingMessageComment = e.detail.value;
  }

  onMessageIncludeContentChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    this.messageIncludeContent = e.detail.checked;
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.prompt}></d-view-info>
      </d-section>
      <d-section topless>
        <d-edit-textarea
          class="alertMessage"
          max-rows="50"
          placeholder="Kommentar"
          .value="${this.meetingMessageComment}"
          @value-changed=${this.onMeetingMessageCommentChanged}
        ></d-edit-textarea>
      </d-section>
      ${this.preventIncludeContent
        ? nothing
        : html`
            <d-section topless>
              <d-checkbox
                .checked=${this.messageIncludeContent}
                .optionLabel=${this.includeContentText}
                @checked-changed=${this.onMessageIncludeContentChanged}
              ></d-checkbox>
            </d-section>
          `}
      ${!this.noAccessMessage
        ? nothing
        : html`
            <d-section topless>
              <d-view-info .content=${this.noAccessMessage}></d-view-info>
            </d-section>
          `}
    `;
  }

  protected fetchResult(detail: string | undefined): MeetingMessageDialogResult {
    if (detail === 'send') {
      return {
        action: 'send',
        message: this.meetingMessageComment,
        includeContent: this.messageIncludeContent,
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: MeetingMessageDialogInput) {
    this.type = input.type;
    this.isEmployeeInterview = input.isEmployeeInterview;
    this.name = input.name ?? '';
    this.recipients = input.recipients;
    this.preventIncludeContent = input.preventIncludeContent;
    this.setDefaultValues();
  }

  private setDefaultValues() {
    this.meetingMessageComment = '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meeting-message-popup': MeetingMessageDialog;
  }
}
