import { html, nothing, TemplateResult } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { AbstractEntityView, ContentViewBuilder, contentViewBuilder, EntityContent } from 'src/content/entity-content';
import type { ReminderType } from 'src/content/event-occurrences/d-edit-reminder';
import type { FormViewItem } from 'src/library/components/d-form-view';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag';
import type { AssetCheckStatuses, ListSectionItemInput } from 'src/library/lists/utilities';
import { groupBy, ModelEvent } from 'src/store/selectors';
import { isEmptyHtmlContent, isSameHtmlContent, joinWithAnd, toDateTimeDuration } from 'src/utilities/text';
import { UpdateSectionItem } from 'src/content/d-update-section';
import { shortenPath } from 'src/utilities/path';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import type { EventTiming } from 'src/library/editors/components/d-edit-event-timing';
import {
  RecurrenceOptionsDialog,
  RecurrenceOptionsResult,
} from 'src/content/event-occurrences/recurrence-options-dialog';
import { assertIsDefined } from 'src/lib';
import { uniq, xor } from 'lodash';
import { AlertsOnChangeDialog, AlertsOnChangeResult } from 'src/content/alerts-on-change-dialog';
import { DEditOccurrencesDialog, OccurrencesDialogResult } from 'src/layout/parts/d-edit-occurrences-dialog';

import '../../library/fields/d-view-html';
import '../../library/fields/d-view-occurrences';
import 'src/library/editors/elements/d-select-checkbox';
import './d-edit-recurrence';
import './../d-edit-entity-name';
import './d-edit-assignee';
import './d-edit-reminder';
import '../../library/lists/d-list-section';
import '../../library/elements/d-smooth-resize';
import { DEditEntityName, ModelForEntityName } from 'src/content/d-edit-entity-name';
import { LocalDate } from 'src/utilities/local-date';

export type SaveType = 'single' | 'singleRepeated' | 'followingRepeated';

export interface EventOccurrenceViewEditItem {
  type: 'standard';
  taskUuid: string;
  name: string;
  date: string;
  time: string;
  durationMinutes: number;
  persistent: boolean;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  assignedToFunction: string;
  reminder: ReminderType;
  notes: string;
  procedures: string;
  assets: string[];
  nameConfirmed: boolean;
  recurrence: string;
  alert: boolean;
  message: string;
  includeDetailsInAlert: boolean;
  saveType: SaveType;
  existingRecurrences: 'KEEP' | 'REPLACE';
}

export interface EventRelatedAsset {
  name: string;
  location: string;
  notes: string;
  assetCheck: boolean;
  assetCheckStatus: AssetCheckStatuses;
  assetCheckDoneText: string;
  assetCheckComments: string;
  href: string;
  uuid: string;
}

export interface MessageRecipient {
  uuid: string;
  name: string;
  accessLevel: string;
}

export interface EventOccurrenceView extends AbstractEntityView<EventOccurrenceViewEditItem> {
  durationMinutes: number;
  eventUuid: string;
  eventInstance?: string;
  thisEventInstance: string;
  defaultFunction: string;
  defaultEmployee: string;
  hasAssets: boolean;
  doneDate: string;
  doneLabel: string;
  doneText: string;
  reminderText: string;
  assigneeDisplayText: string;
  notes: string;
  scheduleDescription: string;
  timeDescription: string;
  type: 'eventOccurrences';
  name: string;
  relatedAssets: EventRelatedAsset[];
  availableEmployees: SelectTagOption[];
  availableContacts: SelectTagOption[];
  availableFunctions: (SelectDropdownOption & { employees: string[] })[];
  commonFunctionUuid: string | undefined;
  currentEmployeeUuid: string | undefined;
  currentEmployeeShortName: string | undefined;
  messageRecipients: MessageRecipient[];
  newItem: boolean;
  procedures: string;
  eventType: 'standard';

  /**
   * date as '2024-01-15' or ''
   */
  date: string;

  /**
   * Time as '09:35' or 'NONE'. If date is '' then must be 'NONE'
   */
  time: string;
  persistent: boolean;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  assignedToFunction: string;
  reminder: ReminderType;
  taskUuid: string;
  taskName: string;
  taskHref?: string;
  assetOptions: { value: string; text: string; disabled: boolean; discarded: boolean }[];
  modelEvents: ModelEvent[];
  occurrences: Occurrence[];
  canUpdateFutureOccurrences: boolean;
  taskOptions: SelectDropdownOption[];
}

/**
 *
 *
 *
 *
 *
 */
@customElement('d-event-occurrence-view')
export class DEventOccurrenceView extends EntityContent<EventOccurrenceView, EventOccurrenceViewEditItem> {
  @query('d-edit-entity-name')
  nameEditor!: DEditEntityName;
  @state()
  private assetListOpened = false;
  @state()
  private newFromTask = false;

  get relatedAssetsAsListSectionItemInput(): ListSectionItemInput[] {
    const hasLocation = this.entityView.relatedAssets.find((a) => {
      return a.location && a.location !== '';
    });
    if (hasLocation) {
      const grouped = groupBy(this.entityView.relatedAssets, (a) => a.location);
      const located: ListSectionItemInput[] = grouped
        .filter((g) => {
          return g.group && g.group !== '';
        })
        .map((g) => {
          return {
            writeAccess: true,
            updateStatus: 'none',
            label: g.group,
            accessible: false,
            items: this.getAssetListItems(g.items),
          };
        });
      const unlocated: ListSectionItemInput[] = grouped
        .filter((g) => {
          return !g.group || g.group === '';
        })
        .map((g) => {
          return {
            writeAccess: true,
            updateStatus: 'none',
            label: 'Plassering ikke angitt',
            accessible: false,
            items: this.getAssetListItems(g.items),
          };
        });

      return located.concat(unlocated);
    }
    return this.getAssetListItems(this.entityView.relatedAssets);
  }

  protected get currentItemLabel() {
    return this.editItem?.name ?? this.entityView.name;
  }

  protected get currentItemSublabel() {
    return this.entityView.newItem ? '' : this.entityView.timeDescription;
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    this.addTimeAndAssignees(this.entityView, builder);
    const actionLabel = this.entityView.doneDate ? 'Merk som ikke utført' : 'Merk som utført';
    builder.addTextWithEditAction(
      this.entityView.doneLabel,
      this.entityView.doneText,
      () => this.handleToggleDone(),
      actionLabel,
    );
    builder.addHtmlContentIfNotEmpty('tasks_procedures', this.entityView.procedures, false);
    builder.addTextVertical('events_notes', this.entityView.notes, 'l', true);
    return builder.build();
  }

  editHeaderActions() {
    if (this.editItem && !this.editItem.nameConfirmed) {
      return [];
    }
    return super.editHeaderActions();
  }

  renderPreContent() {
    return html`${this.renderMetaSection()}`;
  }

  getAssetListItems(items: EventRelatedAsset[]) {
    return items.map((item) => ({
      writeAccess: true,
      label: item.name,
      notes: item.notes,
      assetCheck: item.assetCheck,
      assetCheckStatus: item.assetCheckStatus,
      assetCheckDoneText: item.assetCheckDoneText,
      assetCheckComments: item.assetCheckComments,
      href: this.entityView.href + '/assets/' + item.uuid,
      accessible: true,
      clickData: JSON.stringify({ eventOccurrenceUuid: this.entityView.uuid, assetUuid: item.uuid }),
    }));
  }

  async onDelete() {
    let saveType: SaveType = 'single';
    if (this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: false,
        deleting: true,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }

    let sendAlerts = false;
    const originalEditItem = this.calculateEditItem();

    if (this.qualifiesForDeleteAlerts(originalEditItem)) {
      const r = this.alertOptionsAccessSettings(originalEditItem);
      const result: AlertsOnChangeResult = await AlertsOnChangeDialog.open({
        taskEvent: false,
        noAccessMessage: r.noAccessMessage,
        alertsDisabled: r.alertsDisabled,
        noDetails: !this.hasDetails(originalEditItem),
        isDelete: false,
        newItem: this.entityView.newItem,
      });
      if (result.action === 'save-and-alert') {
        sendAlerts = true;
      } else if (result.action === 'cancel') {
        return;
      }
    }

    this.fireDeleteEvent(saveType, sendAlerts);
  }

  hasDetails(item: EventOccurrenceViewEditItem): boolean {
    return item.notes !== '';
  }

  async initializeEditItem() {
    this.editItem = this.calculateEditItem();
  }

  calculateEditItem(): EventOccurrenceViewEditItem {
    let assignedToEmployees = this.entityView.assignedToEmployees;
    if (this.entityView.newItem && this.entityView.taskUuid) {
      this.newFromTask = true;
      assignedToEmployees = [];
    }
    let nameConfirmed = false;
    if (!this.entityView.newItem || this.newFromTask) {
      nameConfirmed = true;
    }
    return {
      alert: false,
      includeDetailsInAlert: false,
      message: '',
      saveType: 'single',
      type: 'standard',
      name: this.entityView.name,
      date: this.entityView.date,
      time: this.entityView.time,
      durationMinutes: this.entityView.durationMinutes,
      assignedToEmployees,
      assignedToFunction: this.entityView.assignedToFunction,
      assignedToContacts: this.entityView.assignedToContacts,
      procedures: this.entityView.procedures,
      notes: this.entityView.notes,
      taskUuid: this.entityView.taskUuid,
      assets: this.entityView.relatedAssets.map((a) => a.uuid),
      persistent: this.entityView.persistent,
      reminder: this.entityView.reminder,
      recurrence: '',
      existingRecurrences: 'KEEP',
      nameConfirmed,
    };
  }

  renderLists(): TemplateResult<1> {
    return html`
      ${this.entityView.relatedAssets.length
        ? html`<d-list-section
            class="related-assets"
            field="tasks_assets"
            sublabel="Trykk på enheten for å sette status"
            icon="assets"
            .items=${this.relatedAssetsAsListSectionItemInput}
            .writeAccess=${this.entityView.currentUserHasWriteAccess}
            bordered
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-list-section>`
        : nothing}
    `;
  }

  renderEditItem(item: EventOccurrenceViewEditItem): TemplateResult<1> {
    return html`
      <d-edit-entity-name
        .autofocus=${!item.nameConfirmed && !this.newFromTask}
        .newItem=${this.entityView.newItem}
        .assets=${item.assets}
        .placeholder=${'Gi oppgaven et navn'}
        .parentHref=${this.parentHref}
        .name=${item.name}
        .originalName=${this.entityView.name}
        .currentEntityUuid=${this.entityView.uuid}
        .masterEntityUuid=${item.taskUuid}
        .models=${this.entityView.modelEvents.map((m): ModelForEntityName => {
          return {
            name: m.name,
            type: !m.taskUuid ? 'events' : 'tasks',
            masterEntityUuid: m.taskUuid,
            futureOccurrences: m.futureNotDoneOccurrences,
          };
        })}
        .taskOptions=${this.entityView.taskOptions}
        .bypassConfirmations=${item.nameConfirmed}
        @name-focused=${() => this.onNameFocused(item)}
        @cancel=${(e) => this.onNameCancel(e, item)}
        @done=${(e) => this.onNameDone(e, item)}
      ></d-edit-entity-name>
      <d-smooth-resize>
        ${item.nameConfirmed
          ? html`
              <d-section topless>
                <d-edit-event-timing
                  .time=${item.time || 'NONE'}
                  .date=${item.date}
                  .durationMinutes=${item.durationMinutes}
                  .persistent=${item.persistent}
                  @value-changed=${(e: CustomEvent<EventTiming>) => {
                    e.stopPropagation();
                    if (e.detail.eventType === 'TODO') {
                      this.editItem = {
                        ...item,
                        date: '',
                        time: 'NONE',
                        durationMinutes: 0,
                        persistent: false,
                      };
                    } else {
                      this.editItem = {
                        ...item,
                        date: e.detail.date,
                        time: e.detail.time,
                        durationMinutes: e.detail.durationMinutes,
                        persistent: e.detail.persistent,
                      };
                    }
                  }}
                >
                </d-edit-event-timing>
              </d-section>
              ${this.singleUserVersion
                ? nothing
                : html`<d-section>
                    <d-edit-assignee
                      .assignedToFunction=${item.assignedToFunction}
                      .assignedToEmployees=${item.assignedToEmployees}
                      .assignedToContacts=${item.assignedToContacts}
                      .defaultEmployee="${this.entityView.defaultEmployee}"
                      .defaultFunction="${this.entityView.defaultFunction}"
                      .functions=${this.entityView.availableFunctions}
                      .employees=${this.entityView.availableEmployees}
                      .contacts=${this.entityView.availableContacts}
                      @assignment-changed=${(
                        e: CustomEvent<{
                          assignedToEmployees: string[];
                          assignedToFunction: string;
                          assignedToContacts: string[];
                        }>,
                      ) => {
                        e.stopPropagation();
                        this.editItem = {
                          ...item,
                          assignedToEmployees: e.detail.assignedToEmployees,
                          assignedToFunction: e.detail.assignedToFunction,
                          assignedToContacts: e.detail.assignedToContacts,
                        };
                      }}
                    >
                    </d-edit-assignee>
                  </d-section>`}
              <d-section>
                <d-edit-reminder
                  ?disabled=${item.time === 'NONE'}
                  .value=${item.reminder}
                  @value-changed=${(e: CustomEvent<{ value: ReminderType }>) => {
                    e.stopPropagation();
                    this.editItem = { ...item, reminder: e.detail.value };
                  }}
                >
                </d-edit-reminder>
              </d-section>

              <d-edit-html
                label-action-plain
                .contentStickyTop=${this.contentStickyTop}
                .showChecklist=${true}
                label="Prosedyre"
                .docsForLinking=${this.entityView.docsForLinking}
                sublabel="${this.procedureSubLabel(item)}"
                .value=${item.procedures}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editItem = { ...item, procedures: e.detail.value };
                }}
                .labelAction=${this.procedureAction(item)}
                @label-action=${() => {
                  this.editItem = { ...item, procedures: this.relatedTaskProcedure(item.taskUuid) };
                }}
              ></d-edit-html>

              <d-section topless>
                <d-edit-textarea
                  field="events_notes"
                  .value=${item.notes}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    e.stopPropagation();
                    this.editItem = { ...item, notes: e.detail.value };
                  }}
                ></d-edit-textarea>
              </d-section>
              ${this.entityView.assetOptions.length > 0
                ? html`
                    <d-section topless>
                      <d-select-checkbox
                        checklist
                        label-action-plain
                        label="Relaterte utstyrsenheter"
                        .opened=${this.showAssets(item)}
                        .labelAction=${this.assetsLabelAction(item)}
                        .options=${this.entityView.assetOptions.map((o) => {
                          return {
                            value: o.value,
                            text: o.text,
                            disabled: o.disabled,
                          };
                        })}
                        .value=${item.assets}
                        @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                          e.stopPropagation();
                          this.editItem = { ...item, assets: e.detail.value };
                        }}
                        @label-action=${() => this.handleAssetLabelAction(item)}
                      ></d-select-checkbox>
                    </d-section>
                  `
                : nothing}
            `
          : nothing}
      </d-smooth-resize>
    `;
  }

  handleAssetLabelAction(item: EventOccurrenceViewEditItem) {
    if (!this.assetListOpened) {
      this.assetListOpened = true;
    } else if (this.deselectableDiscardedAssets(item).length > 0) {
      const assets = item.assets.filter((a) => {
        return !this.deselectableDiscardedAssets(item).includes(a);
      });
      this.editItem = { ...item, assets: assets };
    }
  }

  doneDisabled(): boolean {
    return this.editItem?.nameConfirmed === false || (this.editItem?.name ?? '') === '';
  }

  async openRecurrenceDialog() {
    const eventName = this.entityView.name;
    const parentUuid = '';
    let currentOccurrenceDateTime = this.entityView.date;
    if (this.entityView.time) {
      currentOccurrenceDateTime += ' ' + this.entityView.time;
    }
    const result: OccurrencesDialogResult = await DEditOccurrencesDialog.open({
      parentUuid,
      modelOccurrence: this.entityView.occurrences.find((o) => {
        return o.dateTime === currentOccurrenceDateTime;
      }) || { dateTime: '', classified: false, restricted: false },
      occurrences: this.entityView.occurrences,
      eventType: 'standard',
      eventName,
    });
    if (result.action === 'done') {
      this.fireRepeatEventOccurrenceEvent(result.occurrences);
    }
  }

  protected async checkForEditMode() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);
    if (params.has('name')) {
      const name = params.get('name');
      if (params.has('edit') && this.entityView.href === location.pathname) {
        history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
        await this.onEditModeOn();
        if (this.editItem) {
          this.editItem = { ...this.editItem, name: decodeURI(name || '') };
          setTimeout(() => {
            this.nameEditor.setNameSelected();
          }, 0);
        }
      }
    } else {
      await super.checkForEditMode();
    }
  }

  protected async saveEditItem(item: EventOccurrenceViewEditItem): Promise<EventOccurrenceViewEditItem | undefined> {
    let saveType: SaveType = 'single';
    if (this.entityView.canUpdateFutureOccurrences) {
      const result: RecurrenceOptionsResult = await RecurrenceOptionsDialog.open({
        isMeeting: false,
        deleting: false,
      });
      if (result.action === 'cancel') {
        return;
      } else if (result.action === 'following') {
        saveType = 'followingRepeated';
      } else {
        saveType = 'singleRepeated';
      }
    }
    let alert = false;
    let includeDetailsInAlert = false;
    let message = '';
    const originalEditItem = this.calculateEditItem();
    if (this.qualifiesForAlerts(originalEditItem)) {
      const r = this.alertOptionsAccessSettings(originalEditItem);
      const result: AlertsOnChangeResult = await AlertsOnChangeDialog.open({
        noAccessMessage: r.noAccessMessage,
        alertsDisabled: r.alertsDisabled,
        noDetails: !this.hasDetails(item),
        isDelete: false,
        newItem: this.entityView.newItem,
      });
      if (result.action === 'save-and-alert') {
        alert = true;
        includeDetailsInAlert = result.includeDetails;
        message = result.message;
      }
    }

    return {
      ...item,
      alert: alert,
      includeDetailsInAlert: includeDetailsInAlert,
      message: message,
      saveType: saveType,
    };
  }

  protected getIcon(): string {
    return 'events';
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private renderMetaSection() {
    if (!this.entityView.isNew) {
      const e: EventOccurrenceView = this.entityView;
      return html`
        <div class="meta-section">
          ${e.taskHref
            ? html`
                <div class="related-task">
                  <d-label semibold inline-label label="Tilknyttet rutinen" .value=${e.taskName}></d-label>
                  <d-action plain theme-page href="${shortenPath(e.taskHref)}">${e.taskName}</d-action>
                </div>
              `
            : nothing}
          <d-view-occurrences
            .occurrences=${e.occurrences}
            .eventType=${'standard'}
            .parentHref=${e.parentHref}
            .currentUuid=${e.uuid}
            style="margin-bottom: -12px"
            @edit-occurrences=${() => this.openRecurrenceDialog()}
          ></d-view-occurrences>
        </div>
      `;
    }
    return nothing;
  }

  private deselectableDiscardedAssets(item: EventOccurrenceViewEditItem): string[] {
    return this.entityView.assetOptions
      .filter((o) => {
        return !o.disabled && o.discarded && item.assets.includes(o.value);
      })
      .map((o) => {
        return o.value;
      });
  }

  private assetsLabelAction(item: EventOccurrenceViewEditItem) {
    if (!this.showAssets(item)) {
      return 'Legg til';
    }
    if (this.deselectableDiscardedAssets(item).length > 0) {
      return 'Fjern kasserte enheter';
    }
    return '';
  }

  private onNameFocused(item) {
    this.editItem = { ...item, nameConfirmed: false };
  }

  private onNameCancel(e, item) {
    e.stopPropagation();
    if (this.entityView.newItem) {
      this.onDelete();
    } else {
      this.editItem = { ...item, name: this.entityView.name, nameConfirmed: true };
    }
  }

  private onNameDone(e, item) {
    e.stopPropagation();
    const name = e.detail.name;
    const taskUuid = e.detail.masterEntityUuid;
    const editItem = {
      ...item,
      name,
      taskUuid,
      nameConfirmed: true,
    };
    if (name !== this.entityView.name || taskUuid !== this.entityView.taskUuid) {
      let modelEvent: ModelEvent | undefined = undefined;
      if (name !== this.entityView.name) {
        modelEvent = this.entityView.modelEvents.find((m) => {
          return m.name === e.detail.name;
        });
      }
      if (modelEvent) {
        editItem.taskUuid = modelEvent.taskUuid;
        editItem.procedures = modelEvent.procedures;
        editItem.notes = modelEvent.notes;
        editItem.time = modelEvent.time;
        editItem.durationMinutes = modelEvent.durationMinutes;
        if (editItem.time !== 'NONE') {
          editItem.date = LocalDate.now().plusDays(1).toString();
        }
        if (modelEvent.assignedToFunction) {
          editItem.assignedToFunction = modelEvent.assignedToFunction;
          editItem.assignedToEmployees = [];
          editItem.assignedToContacts = [];
        }
        if (modelEvent.assignedToEmployees.length) {
          editItem.assignedToFunction = '';
          editItem.assignedToEmployees = modelEvent.assignedToEmployees;
          editItem.assignedToContacts = [];
        }
        if (modelEvent.assignedToContacts.length) {
          editItem.assignedToFunction = '';
          editItem.assignedToEmployees = [];
          editItem.assignedToContacts = modelEvent.assignedToContacts;
        }
      }
      this.editItem = editItem;
    }
  }

  private fireRepeatEventOccurrenceEvent(occurrences: Occurrence[]) {
    const existingDateTimes = this.entityView.occurrences.map((e) => toDateTimeDuration(e));
    const existingUuids = this.entityView.occurrences.map((e) => e.occurrenceUuid);
    const updatedOccurrenceUuids = occurrences.map((e) => e.occurrenceUuid);
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; dateTimesToAdd: string[]; occurrencesToRemove: string[] }>(
        'repeat-event-occurrence',
        {
          bubbles: true,
          composed: true,
          detail: {
            uuid: this.entityView.uuid,
            dateTimesToAdd: occurrences.map((e) => toDateTimeDuration(e)).filter((e) => !existingDateTimes.includes(e)),
            occurrencesToRemove: existingUuids.filter((e) => !updatedOccurrenceUuids.includes(e)),
          },
        },
      ),
    );
  }

  private handleToggleDone() {
    this.dispatchEvent(
      new CustomEvent('toggle-event-done', {
        bubbles: true,
        composed: true,
        detail: { eventUuid: this.entityView.eventUuid, eventInstance: this.entityView.eventInstance },
      }),
    );
  }

  private onlyAssignedToCurrentUser(originalEditItem: EventOccurrenceViewEditItem) {
    return (
      this.currentAndPreviousAssignees(originalEditItem).length === 1 &&
      this.currentAndPreviousAssignees(originalEditItem)[0] === this.entityView.currentEmployeeUuid
    );
  }

  private currentAndPreviousAssignees(originalEditItem: EventOccurrenceViewEditItem) {
    const allAssignees = [
      ...this.assignedEmployeesIncludingFromFunction(originalEditItem),
      ...this.assignedEmployeesIncludingFromFunction(this.editItem),
    ];
    return uniq(allAssignees);
  }

  private editsShouldAlert(item: EventOccurrenceViewEditItem) {
    const originalEditItem = item;
    return (
      originalEditItem !== undefined &&
      this.editItem !== undefined &&
      (originalEditItem.name !== this.editItem.name ||
        originalEditItem.date !== this.editItem.date ||
        originalEditItem.time !== this.editItem.time ||
        originalEditItem.durationMinutes !== this.editItem.durationMinutes ||
        originalEditItem.notes !== this.editItem.notes ||
        originalEditItem.procedures !== this.editItem.procedures)
    );
  }

  private procedureSubLabel(item: EventOccurrenceViewEditItem) {
    if (
      item.taskUuid &&
      !isEmptyHtmlContent(this.relatedTaskProcedure(item.taskUuid)) &&
      isSameHtmlContent(item.procedures, this.relatedTaskProcedure(item.taskUuid))
    ) {
      return 'fra tilknyttet rutine';
    }
    return '';
  }

  private procedureAction(item: EventOccurrenceViewEditItem) {
    if (
      item.taskUuid &&
      !isEmptyHtmlContent(this.relatedTaskProcedure(item.taskUuid)) &&
      !isSameHtmlContent(item.procedures, this.relatedTaskProcedure(item.taskUuid))
    ) {
      return 'Hent fra tilknyttet rutine';
    }
    return '';
  }

  private showAssets(item: EventOccurrenceViewEditItem): boolean {
    return this.assetListOpened || item.assets.length > 0;
  }

  private assignedEmployeesIncludingFromFunction(item?: EventOccurrenceViewEditItem) {
    if (item === undefined) return [];
    if (item.assignedToEmployees.length > 0) {
      return [...item.assignedToEmployees];
    } else if (item.assignedToFunction !== '') {
      if (item.assignedToFunction === this.entityView.commonFunctionUuid) {
        return this.entityView.availableEmployees.map((e) => {
          return e.value;
        });
      }
      return this.entityView.availableFunctions
        .filter((x) => x.value === item.assignedToFunction)
        .flatMap((x) => x.employees);
    }
    console.error('Unassigned event occurrence');
    return [];
  }

  private qualifiesForAlerts(originalEditItem: EventOccurrenceViewEditItem): boolean {
    assertIsDefined(this.editItem);
    if (
      (this.entityView.newItem || this.editsShouldAlert(originalEditItem)) &&
      !this.onlyAssignedToCurrentUser(originalEditItem)
    ) {
      return true;
    }
    return this.otherAssigneesChanged(originalEditItem);
  }

  private otherAssigneesChanged(originalEditItem: EventOccurrenceViewEditItem) {
    assertIsDefined(this.editItem);

    const prevExCurrentUser = this.assignedEmployeesIncludingFromFunction(originalEditItem).filter(
      (item) => item !== this.entityView.currentEmployeeUuid,
    );
    const newExCurrentUser = this.assignedEmployeesIncludingFromFunction(this.editItem).filter(
      (item) => item !== this.entityView.currentEmployeeUuid,
    );

    return xor(prevExCurrentUser, newExCurrentUser).length !== 0;
  }

  private relatedTaskName(uuid: string) {
    if (uuid) {
      const task = this.entityView.modelEvents.find((t) => {
        return t.taskUuid === uuid;
      });
      if (task) {
        return task.name;
      }
    }
    return '';
  }

  private relatedTaskProcedure(uuid: string) {
    if (uuid) {
      const task = this.entityView.modelEvents.find((t) => {
        return t.taskUuid === uuid;
      });
      if (task) {
        return task.procedures;
      }
    }
    return '';
  }

  private fireDeleteEvent(saveType: SaveType, alert: boolean) {
    this.dispatchEvent(
      new CustomEvent<{ saveType: SaveType; alert: boolean }>('delete-event', {
        bubbles: true,
        composed: true,
        detail: { saveType: saveType, alert: alert },
      }),
    );
    this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
  }

  private qualifiesForDeleteAlerts(originalEditItem: EventOccurrenceViewEditItem): boolean {
    return !this.onlyAssignedToCurrentUser(originalEditItem) && !this.entityView.newItem;
  }

  private alertOptionsAccessSettings(originalEditItem: EventOccurrenceViewEditItem): {
    noAccessMessage: string;
    alertsDisabled: boolean;
  } {
    let message = '';
    const allAssigned = this.currentAndPreviousAssignees(originalEditItem);
    let alertsDisabled = false;
    const accessRecipients = allAssigned
      .filter((id) => id !== this.entityView.currentEmployeeUuid)
      .filter((id) => {
        const p = this.entityView.messageRecipients.find((x) => x.uuid === id);
        assertIsDefined(p);
        return p.accessLevel !== 'NONE';
      });
    const noAccessRecipients = allAssigned
      .filter((id) => id !== this.entityView.currentEmployeeUuid)
      .filter((id) => {
        const p = this.entityView.messageRecipients.find((x) => x.uuid === id);
        assertIsDefined(p);
        return p.accessLevel === 'NONE';
      });

    if (noAccessRecipients.length) {
      const list = this.entityView.messageRecipients
        .filter((p) => noAccessRecipients.includes(p.uuid))
        .map((p) => p.name);
      message = 'NB! ' + joinWithAnd(list) + ' mangler tilgang og kan ikke varsles.';
    }
    if (!accessRecipients.length) {
      message += ' Ingen berørte kan varsles.';
      alertsDisabled = true;
    }
    return {
      alertsDisabled,
      noAccessMessage: message,
    };
  }

  private addTimeAndAssignees(e: EventOccurrenceView, builder: ContentViewBuilder) {
    const list: { field: string; value: string | undefined }[] = [];
    if (!this.singleUserVersion) {
      list.push({ field: 'events_assignee', value: e.assigneeDisplayText });
    }
    if (e.reminderText) {
      list.push({ field: 'events_reminder', value: e.reminderText });
    }
    builder.addTextList(list, 'l');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-event-occurrence-view': DEventOccurrenceView;
  }
}
