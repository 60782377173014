import { css, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { HideDoneOptionsDialog, HideDoneOptionsResult } from 'src/pages/home-page/hide-done-options-dialog.js';
import { PageContent } from 'src/pages/page-content.js';
import type { TimekeepingPeriod } from 'src/store/selectors';
import '../../library/lists/d-calendar-list-section.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import '../../library/lists/d-timekeeping-list-section.js';
import '../../library/lists/d-todo-list-section.js';
import '../../library/lists/d-list-section-tutorials.js';
import type { HomePageViewModel } from './home-page-view-model.js';

/**
 *
 */
@customElement('d-home-page-content')
export class DHomePageContent extends PageContent<HomePageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-calendar-list-section,
      d-timekeeping-list-section,
      d-list-section {
        margin-top: 30px;
      }
      d-list-section-tutorials {
        margin-bottom: 12px;
      }
    `,
  ];

  private get contractsHeaderField() {
    if (this.pageView.singleUserVersion) {
      return '';
    }
    return 'organization_myContracts';
  }

  asListSectionItemInput(list: TimekeepingPeriod[]): ListSectionItemInput[] {
    return list.map((l) => ({
      locked: false,
      label: l.timeDisplay,
      accessible: this.isAccessible(l),
      rightLabel: l.hoursDisplay,
      updateStatus: 'none',
      clickData: this.isCorrection(l) ? undefined : JSON.stringify(l),
    }));
  }

  isCorrection(l: TimekeepingPeriod) {
    return l.type === 'correction' || l.type === 'correctionPlus';
  }

  isAccessible(l: TimekeepingPeriod) {
    return (
      !this.isCorrection(l) &&
      (this.pageView.currentUserHasExtendedStaffingAccess || !this.pageView.leavePeriodEditRestriction || !l.confirmed)
    );
  }

  renderContent() {
    return html` ${this.renderIfEmployee()} `;
  }

  private async onTodoListAction(e: CustomEvent<string>) {
    if (e.detail === 'showOptions') {
      const result: HideDoneOptionsResult = await HideDoneOptionsDialog.open({
        value: '' + this.pageView.hideAfterDays,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent('save-hide-done-option', {
            bubbles: true,
            composed: true,
            detail: {
              value: result.value,
            },
          }),
        );
      }
    }
  }

  private renderIfEmployee() {
    return this.pageView.isEmployee
      ? html`
          ${this.renderTutorials()}
          <d-todo-list-section
            .organizationId=${this.pageView.organizationId}
            .employees=${this.pageView.employees}
            .items=${this.pageView.todoList}
            .hideAfterDays=${this.pageView.hideAfterDays}
            @action=${this.onTodoListAction}
            .contentStickyTop=${this.contentStickyTop}
          ></d-todo-list-section>

          <d-calendar-list-section
            .organizationId=${this.pageView.organizationId}
            .singleUserVersion=${this.pageView.singleUserVersion}
            .employees=${this.pageView.employees}
            .writeAccess=${this.pageView.writeAccess}
            .weekStart=${this.pageView.weekStart}
            .leavePeriods=${this.pageView.leavePeriodsConfirmed}
            .startTaskInstances=${this.pageView.startTaskInstances}
            .calendarTaskInstances=${this.pageView.calendarTaskInstances}
            .contentStickyTop=${this.contentStickyTop}
          ></d-calendar-list-section>
          ${this.renderTimekeeping()} ${this.renderResponsibilities()}

          <d-list-section
            class="contracts"
            label="Avtaler"
            .field=${this.contractsHeaderField}
            icon="contracts"
            sort="1"
            .items=${this.pageView.contracts}
            no-item-text="Ingen avtaler"
            theme-page
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-list-section>
        `
      : nothing;
  }

  private renderResponsibilities() {
    return this.pageView.singleUserVersion
      ? nothing
      : html`<d-list-section
          field="organization_myFunctions"
          icon="functions"
          theme-page
          .items=${this.pageView.currentFunctions}
          .contentStickyTop=${this.contentStickyTop}
        >
        </d-list-section>`;
  }

  private renderTimekeeping() {
    return this.pageView.writeAccess && this.pageView.timekeepingEmployee && this.pageView.employeeId
      ? html`
          <d-timekeeping-list-section
            .defaultDate=${this.pageView.today.toString()}
            .employeeUuid=${this.pageView.employeeId}
            .employee=${this.pageView.timekeepingEmployee}
            .periods=${this.asListSectionItemInput(this.pageView.timekeepingPeriods)}
            .saldo=${this.pageView.timekeepingBalance}
            ?currentUserHasAccess=${this.pageView.writeAccess}
            .leavePeriodEditRestriction=${this.pageView.leavePeriodEditRestriction}
            .currentUserHasAccess=${this.pageView.currentUserHasExtendedStaffingAccess}
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-timekeeping-list-section>
        `
      : nothing;
  }

  private renderTutorials() {
    return this.pageView.activeTutorials.length > 0
      ? html`
          <d-list-section-tutorials
            theme-page
            label="Aktive kurs og veiledere"
            .tutorials=${this.pageView.activeTutorials}
            .contentStickyTop=${this.contentStickyTop}
            @item-clicked=${this.onTutorialItemClicked}
            .today=${this.pageView.today.toString()}
          ></d-list-section-tutorials>
        `
      : nothing;
  }

  private onTutorialItemClicked(e: CustomEvent<ListSectionItemInput>) {
    e.stopPropagation();
    if (e.detail.clickData !== undefined) {
      this.dispatchEvent(
        new CustomEvent<{ value: string }>('update-tutorial', {
          composed: true,
          bubbles: true,
          detail: { value: e.detail.clickData },
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-home-page-content': DHomePageContent;
  }
}
