import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { numberWord } from 'src/utilities/text.js';
import '../editors/elements/d-checkbox.js';
import '../editors/elements/d-edit-textarea.js';
import '../editors/elements/d-select-date.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../elements/d-section.js';
import '../elements/d-tooltip.js';
import '../fields/d-expansion.js';
import '../fields/d-view-info.js';
import './d-list-header.js';
import './d-list-section-item.js';
import { DListSection } from 'src/library/lists/d-list-section.js';
import { cloneDeep } from 'lodash';

export interface TutorialParticipant {
  uuid: string;
  name: string;
  hasAccess: boolean;
  assigned: string;
  due: string;
  completed: string;
  statusComment?: string;
}

/**
 *
 */
@customElement('d-list-section-tutorial-participants')
export class DListSectionTutorialParticipants extends LitElement {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
      }
      d-action {
        margin-left: 0;
      }
      .edit {
        margin-top: 8px;
        padding-left: var(--listPaddingLeft);
      }
      .edit-header {
        margin-bottom: 12px;
      }
      d-edit-textarea {
        margin: 12px 0;
      }
      d-wrap d-action {
        margin-left: -6px;
      }
      d-wrap > *:last-child {
        flex: none;
      }
      .common-date {
        display: flex;
      }
      /* TODO: remove media query, use responsive container class instead */
      @media only screen and (max-width: 600px) {
        .edit {
          margin-left: 0;
        }
      }
    `,
  ];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  employees: TutorialParticipant[] = [];
  @property({ type: Array })
  employeesForEdit: TutorialParticipant[] = [];
  @property({ type: Boolean })
  showEdit = false;
  @property({ type: Boolean })
  isCommonDate = true;
  /**
   * date format: yyyy-mm-dd
   */
  @property({ type: String })
  commonDate = '';
  @property({ type: String })
  comment = '';
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: String })
  today = LocalDate.now().toString();

  private get canEdit() {
    return this.writeAccess;
  }

  private get participants() {
    return this.employees
      .filter((e) => {
        return e.assigned;
      })
      .map((e) => {
        let eventDone = false;
        let secondaryLabel = 'påmeldt ' + LocalDate.fromString(e.assigned).toStringForDisplay();
        let eventOverdue = false;
        if (e.completed) {
          const completedDate = LocalDate.fromString(e.completed);
          eventDone = true;
          secondaryLabel = 'gjennomført ' + completedDate.toStringForDisplay();
        } else if (e.due) {
          const now = LocalDate.fromString(this.today);
          const dueDate = LocalDate.fromString(e.due);
          if (dueDate.isBefore(now)) {
            eventOverdue = true;
            const daysOverdue = dueDate.until(now);
            let daysTerm = ' dager';
            if (daysOverdue === 1) {
              daysTerm = ' dag';
            }
            secondaryLabel += ', ' + numberWord(dueDate.until(now)) + daysTerm + ' på overtid';
          } else {
            secondaryLabel += ', frist ' + dueDate.toStringForDisplay();
          }
        }
        return {
          label: e.name,
          accessible: false,
          secondaryLabel: secondaryLabel,
          eventDone: eventDone,
          eventOverdue: eventOverdue,
        };
      });
  }

  private get employeesForSelection() {
    return this.employeesForEdit.map((employee) => {
      let statusComment = '';
      if (!employee.hasAccess) {
        statusComment = ' har ikke tilgang til TrinnVis';
      }
      if (employee.completed) {
        statusComment = ' har fullført kurset';
      }
      employee.statusComment = statusComment;
      return employee;
    });
  }

  _toggleString(showEdit, a, b) {
    return showEdit ? a : b;
  }

  _edit() {
    this.showEdit = true;
  }

  _cancel() {
    this.employeesForEdit = [];
    this.showEdit = false;
  }

  _done() {
    this.employees = [...this.employeesForEdit];
    this.employeesForEdit = [];
    this.showEdit = false;
    this.dispatchEvent(
      new CustomEvent<{
        participants: TutorialParticipant[];
        isCommonDate: boolean;
        commonDate: string;
        comment: string;
      }>('participants-changed', {
        bubbles: true,
        composed: true,
        detail: {
          participants: this.employees,
          isCommonDate: this.isCommonDate,
          commonDate: this.commonDate,
          comment: this.comment,
        },
      }),
    );
  }

  _selectAll(e: CustomEvent) {
    e.stopPropagation();
    const now = LocalDate.fromString(this.today).toString();

    this.employeesForEdit = this.employeesForEdit.map((e) => {
      return {
        ...e,
        assigned: e.hasAccess && !e.assigned ? now : e.assigned,
      };
    });
  }

  _setDatesForAll() {
    this.employeesForEdit = this.employeesForEdit.map((e) => ({
      ...e,
      due: e.assigned && !e.completed ? this.commonDate : e.due,
    }));
  }

  _toggleCommonDate(e: CustomEvent) {
    e.stopPropagation();
    if (this.isCommonDate) {
      this.isCommonDate = false;
    } else {
      this.isCommonDate = true;
      this._setDatesForAll();
    }
  }

  _commentChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.comment = e.detail.value;
  }

  _commonDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.commonDate = e.detail.value;
    if (this.isCommonDate) {
      this._setDatesForAll();
    }
  }

  _toggleParticipant(index: number, e: CustomEvent) {
    e.stopPropagation();
    if (this.employeesForEdit[index].assigned) {
      this.employeesForEdit[index].assigned = '';
      this.employeesForEdit[index].due = '';
    } else {
      this.employeesForEdit[index].assigned = LocalDate.fromString(this.today).toString();
      if (this.isCommonDate) {
        this.employeesForEdit[index].due = this.commonDate ?? '';
      }
    }
    this.requestUpdate();
  }

  _participantDateChanged(index: number, e: CustomEvent) {
    e.stopPropagation();
    this.employeesForEdit[index].due = e.detail.value;
    this.requestUpdate();
  }

  render() {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header icon="employees" label="Deltakere" style=${styleMap(listHeaderStyles)}>
        ${this.canEdit
          ? html`
              ${!this.showEdit
                ? html` <d-action slot="top-right" @click=${() => this._edit()}>Rediger</d-action> `
                : html`
                    <d-action slot="top-right" @click=${() => this._cancel()}>Avbryt</d-action>
                    <d-action slot="top-right" @click=${() => this._done()}>Ferdig</d-action>
                  `}
            `
          : nothing}
      </d-list-header>

      <d-expansion ?opened=${this.showEdit}>
        <div class="edit">
          <div class="edit-header">
            <d-view-info
              content=${'<p>Nye påmeldte får melding om kurset på epost. Hvis du vil, kan du også skrive en kommentar nedenfor.</p>'}
            ></d-view-info>
            <d-edit-textarea
              placeholder="Skriv en kommentar til påmeldingen her"
              .value=${this.comment}
              @value-changed=${(e) => this._commentChanged(e)}
            ></d-edit-textarea>
            <d-wrap split center>
              <div>
                <d-action href="#" @click=${(e) => this._selectAll(e)}>Velg alle</d-action>
              </div>
              <div class="common-date">
                <d-checkbox
                  option-label="Felles frist"
                  .checked=${this.isCommonDate}
                  @checked-changed="${(e) => this._toggleCommonDate(e)}"
                ></d-checkbox>
                <d-select-date
                  ?disabled=${!this.isCommonDate}
                  .value=${this.commonDate}
                  @value-changed=${(e) => this._commonDateChanged(e)}
                  clear
                ></d-select-date>
              </div>
            </d-wrap>
          </div>
          ${this.employeesForSelection.map((participant, index) => {
            return html`
              <d-section>
                <d-wrap split center>
                  <d-wrap baseline>
                    <d-checkbox
                      .optionLabel=${participant.name + participant.statusComment}
                      .checked=${participant.assigned !== ''}
                      ?disabled=${participant.completed !== '' || !participant.hasAccess}
                      @checked-changed=${(e) => this._toggleParticipant(index, e)}
                    ></d-checkbox>
                  </d-wrap>
                  <d-select-date
                    .value=${participant.due}
                    @value-changed=${(e) => this._participantDateChanged(index, e)}
                    ?disabled=${this.isCommonDate || participant.assigned === '' || participant.completed !== ''}
                    clear
                  ></d-select-date>
                </d-wrap>
              </d-section>
            `;
          })}
        </div>
      </d-expansion>

      <d-expansion ?opened=${!this.showEdit}>
        <d-list-section no-header .items=${this.participants} no-item-text="Ingen deltakere"></d-list-section>
      </d-expansion>
    `;
  }

  protected willUpdate() {
    if (this.showEdit && this.employeesForEdit.length === 0) {
      this.employeesForEdit = cloneDeep(this.employees);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-tutorial-participants': DListSectionTutorialParticipants;
  }
}
