import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as dabihStore from 'src/store';
import '../../elements/d-section.js';
import '../../elements/d-smooth-resize.js';
import '../../elements/d-wrap.js';
import '../../fields/d-view-info.js';
import '../elements/d-edit-text.js';
import '../elements/d-select-date.js';
import '../elements/d-select-dropdown.js';

export interface UserForAccess {
  uuid: string;
  name: string;
  partnerName?: string;
  email: string;
  accessLevel: string;
  accessExpires: string;
}

/**
 *
 *
 * STATUS OK
 */
@customElement('d-edit-access')
export class DEditAccess extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-section > d-section > * {
      border-bottom-width: 0;
    }
    d-select-date {
      flex: none;
    }
  `;
  @property({ type: Array })
  otherUserEmails: string[] = [];
  @property({ type: Object })
  editUser!: UserForAccess;
  @property({ type: String })
  originalEmail = '';
  @property({ type: Boolean })
  isOwner = false;
  @property({ type: Boolean })
  isSelf = false;

  private get isContact() {
    return this.editUser.partnerName !== '' && this.editUser.partnerName !== undefined;
  }

  private get accessField() {
    if (this.isContact) {
      return 'contacts_accessLevel';
    }
    return 'employees_accessLevel';
  }

  private get hasRegisteredEmail() {
    return this.originalEmail !== '';
  }

  private get emailInputIsUnique() {
    return (
      this.otherUserEmails.filter((e) => {
        return e.toUpperCase() === this.editUser.email.toUpperCase();
      }).length === 0
    );
  }

  private get emailInputIsValid() {
    return dabihStore.isEmailValid(this.editUser.email);
  }

  private get emailInputIsValidAndUnique() {
    return this.emailInputIsUnique && this.emailInputIsValid;
  }

  private get accessSelectDisabled() {
    return this.isOwner || this.isSelf || !this.emailInputIsValidAndUnique || this.editUser.email === '';
  }

  private get accessOptions() {
    let userType = 'employees';
    if (this.isContact) {
      userType = 'contacts';
    }
    return dabihStore
      .accessProfiles()
      .filter((a) => {
        return a.userTypes.indexOf(userType) > -1 && (a.name !== 'ADMIN' || this.isOwner);
      })
      .map((a) => {
        return { value: a.name, text: a.displayName };
      });
  }

  private get accessDescription() {
    const accessProfile = dabihStore.accessProfiles().filter((a) => {
      return a.name === this.editUser.accessLevel;
    });
    if (accessProfile.length) {
      return accessProfile[0].description;
    }
    return '';
  }

  render() {
    return html`
      <d-section id="form">
        <d-edit-text
          class="minWidth300"
          ?disabled=${this.hasRegisteredEmail}
          field="employees_email"
          .value=${this.editUser.email}
          @value-changed=${this.onEmailChanged}
        ></d-edit-text>
        <d-section topless>
          <d-select-dropdown
            class="minWidth200"
            ?disabled=${this.accessSelectDisabled}
            .field=${this.accessField}
            .options=${this.accessOptions}
            .value=${this.editUser.accessLevel}
            @value-changed=${this.onAccessSelectValueChanged}
          >
          </d-select-dropdown>
          ${this.isContact
            ? html`
                <d-select-date
                  .value=${this.editUser.accessExpires ?? ''}
                  label="Utløper"
                  clear
                  @value-changed=${this.onAccessExpiresChanged}
                ></d-select-date>
              `
            : nothing}
        </d-section>
      </d-section>
      <d-section>
        <d-smooth-resize>
          <d-view-info .content=${this.accessDescription}></d-view-info>
        </d-smooth-resize>
      </d-section>
    `;
  }

  private onEmailChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editUser = {
      ...this.editUser,
      email: e.detail.value,
    };

    this.fireValueChanged();
  }

  private fireValueChanged() {
    this.dispatchEvent(
      new CustomEvent<{ email: string; accessLevel: string; accessExpires: string }>('value-changed', {
        bubbles: true,
        composed: true,
        detail: {
          email: this.editUser.email,
          accessLevel: this.editUser.accessLevel,
          accessExpires: this.editUser.accessExpires,
        },
      }),
    );
  }

  private onAccessSelectValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editUser = {
      ...this.editUser,
      accessLevel: e.detail.value,
    };

    this.fireValueChanged();
  }

  private onAccessExpiresChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editUser = {
      ...this.editUser,
      accessExpires: e.detail.value,
    };

    this.fireValueChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-access': DEditAccess;
  }
}
