import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../elements/d-label.js';

/**
 * Shows alternative texts based on value true or false.
 *
 *
 *
 * STATUS OK
 */
@customElement('d-conditional-bullet')
export class DConditionalBullet extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  _classes(alert) {
    if (alert) {
      return 'bullet alert';
    }
    return 'bullet';
  }

  @property({ type: Boolean })
  condition = false;
  @property({ type: String })
  label = '';
  @property({ type: String })
  elseLabel = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  elseField = '';
  @property({ type: Boolean })
  hideTooltip = false;
  @property({ type: Boolean })
  alert = false;

  render() {
    if (this.condition) {
      return html` <d-label
        .classes=${this._classes(this.alert)}
        .field=${this.field}
        .hideTooltip=${this.hideTooltip}
        .label=${this.label}
      ></d-label>`;
    } else if (this.elseField !== '') {
      return html` <d-label
        .classes=${this._classes(this.alert)}
        .field=${this.elseField}
        .hideTooltip=${this.hideTooltip}
        .label=${this.elseLabel}
      ></d-label>`;
    } else {
      return nothing;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-conditional-bullet': DConditionalBullet;
  }
}
