import { css } from 'lit';

export const iconStyles = css`
  .tasks {
    background-image: url(/images/tasks-gray.svg);
  }

  .guidelines {
    background-image: url(/images/guidelines-gray.svg);
  }

  .documents {
    background-image: url(/images/documents-gray.svg);
  }

  .constitutionalDocuments {
    background-image: url(/images/constitutional-documents-gray.svg);
  }

  .contracts {
    background-image: url(/images/contracts-gray.svg);
  }

  .reports {
    background-image: url(/images/reports-gray.svg);
  }

  .partners {
    background-image: url(/images/partners-gray.svg);
  }
  .employees {
    background-image: url(/images/employees-gray.svg);
  }

  .issues {
    background-image: url(/images/issues-gray.svg);
  }

  .riskAssessments {
    background-image: url(/images/risk-assessments-gray.svg);
  }

  .meetings {
    background-image: url(/images/meetings-gray.svg);
  }

  .related {
    background-image: url(/images/related-gray.svg);
  }

  .events {
    background-image: url(/images/events-gray.svg);
  }

  .functions {
    background-image: url(/images/functions-gray.svg);
  }

  .references {
    background-image: url(/images/references.svg);
  }

  .startTasks {
    background-image: url(/images/starttask.svg);
  }
`;
