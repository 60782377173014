import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';

import { customElement, property, state } from 'lit/decorators.js';
import * as dabihStore from 'src/store';
import { displayAlert } from 'src/utilities/display-alert.js';

/**
 * Viser innloggingskjemaet, inkludert alternativer for å registrere ny konto eller få
 * tilsendt nytt passord
 *
 * @fires request-login submit login request
 * @fires request-password submit password request
 *
 */
@customElement('d-login')
export class DLogin extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    h2,
    p {
      color: white;
      text-align: left;
    }

    #loginForm {
      max-width: 300px;
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #loginForm > * {
      margin-bottom: 10px;
    }

    #loginForm .logo {
      width: 146px;
      height: 37px;
      background: url(/images/trinnvis-logo.svg) 50% 50% no-repeat;
      background-size: contain;
      margin-bottom: 14px;
    }

    #loginForm input[type='email'],
    #loginForm input[type='password'] {
      font-family: var(--mainSans);
      font-size: 15px;
      text-align: center;
      border-radius: 4px;
    }

    #loginForm input[type='submit'] {
      margin-top: 10px;
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
    }

    #loginForm input[type='button'] {
      margin-top: 10px;
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
    }

    body:not(.touch) #loginForm input[type='submit']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    body:not(.touch) #loginForm input[type='button']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    body:not(.touch) #loginForm input[type='submit']:hover {
      background-color: hsla(0, 0%, 0%, 0.3);
    }

    #loginForm .alternatives {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-weight: 200;
      color: white;
      cursor: pointer;
    }

    #loginForm .alternatives > * {
      margin-bottom: 10px;
      opacity: 0.8;
    }

    body:not(.touch) #loginForm .alternatives > *:hover {
      opacity: 1;
    }

    input {
      border: none;
      box-shadow: none;
      padding: 5px 10px 6px 10px;
      resize: none;
      border-radius: 0;
      appearance: none;
      writing-mode: horizontal-tb;
      width: 100%;
      box-sizing: border-box;
      font-family: var(--mainSans), sans-serif;
      font-weight: 200;
      font-size: 15px;
      line-height: 160%;
      color: black;
    }
    input[type='submit'] {
      cursor: pointer;
      text-transform: uppercase;
    }

    .secureLogin {
      margin-top: 10px;
      border: 1px solid hsla(0, 0%, 100%, 0.4);
      border-radius: 12px;
      padding: 106px 30px 30px 30px;
      background: transparent url(/images/personal-data-items-gray.svg) 50% 20px no-repeat;
      background-size: 70px 70px;
      text-align: center;
    }

    #loginForm .secureLogin h2 {
      font-size: 20px;
      line-height: 120%;
      color: white;
    }

    #loginForm p {
      font-size: 15px;
      line-height: 150%;
      text-align: center;
    }

    #loginForm .secureLogin input {
      margin: 10px;
    }
  `;
  /**
   * Display login failed message.
   */
  @property({ type: Boolean })
  loginFailed = false;
  /**
   * The mode. Used when linking to a secure authentication method. If this value is `link` then use
   */
  @property({ type: String })
  mode = '';
  /**
   * The location search. Set this to value of `window.location.search` when mode is `link`.
   */
  @property({ type: String })
  locationSearch = '';
  @state()
  private username = '';
  @state()
  private password = '';
  @state()
  private code = '';
  @state()
  private loginErrorInvalidEmail = false;
  @state()
  private loginErrorMissingPassword = false;
  @state()
  private useCode = true;

  get _usernameInput(): Element {
    const slotted = this.shadowRoot?.querySelector('slot#loginName');
    let element = (slotted as HTMLSlotElement)?.assignedElements()[0];

    // To execute the while loop a maximum of 10 times
    // If an input element is found within the first 10 iterations, the function will return that element. Otherwise, it will return undefined.
    let loopCount = 0;
    while (element?.tagName !== 'INPUT' && loopCount < 10) {
      element = (element as HTMLSlotElement)?.assignedElements()[0];
      loopCount++;
    }

    return element;
  }

  get _passwordInput(): Element {
    const slotted = this.shadowRoot?.querySelector('slot#password');
    let element = (slotted as HTMLSlotElement)?.assignedElements()[0];

    // To execute the while loop a maximum of 10 times
    // If an input element is found within the first 10 iterations, the function will return that element. Otherwise, it will return undefined.
    let loopCount = 0;
    while (element?.tagName !== 'INPUT' && loopCount < 10) {
      element = (element as HTMLSlotElement)?.assignedElements()[0];
      loopCount++;
    }

    return element;
  }

  private get authDescription() {
    const urlParams = new URLSearchParams(this.locationSearch);
    let encoded = urlParams.get('u');
    if (encoded !== null) {
      encoded = encoded.replace('-', '+').replace('_', '/');
      while (encoded.length % 4) encoded += '=';

      const userInformation = JSON.parse(atob(encoded));

      console.log(userInformation);

      return userInformation.name;
    }

    return '';
  }

  private get verify(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const encoded = urlParams.get('v');
    return encoded === 'true';
  }

  private get provider() {
    const urlParams = new URLSearchParams(this.locationSearch);
    const encoded = urlParams.get('p');
    if (encoded !== null) {
      return encoded;
    }

    return '';
  }

  private get providerDescription() {
    const providerDescriptions = {
      oidc: 'HelseID',
      criipto: 'BankID',
    };

    return providerDescriptions[this.provider] ?? '';
  }

  private get isLinkMode() {
    return this.mode === 'link';
  }

  async _verifySingleUseCode() {
    window.location.href =
      '/auth/email/callback?go=true&t=' +
      encodeURIComponent(window.location.origin) +
      '&code=' +
      encodeURIComponent(this.code);
  }

  _sendPassword() {
    if (!dabihStore.isEmailValid(this.username)) {
      displayAlert('Skriv inn gyldig e-post for å få tilsendt nytt passord');
      return;
    }
    this.dispatchEvent(
      new CustomEvent('request-password', { bubbles: true, composed: true, detail: { email: this.username } }),
    );

    const message =
      'Dersom du har tilgang til Trinnvis er nytt passord sendt til ' +
      this.username +
      '. Om du ikke mottar et passord må du sjekke spam-filteret ditt.';
    displayAlert(message);
  }

  _loginAuth() {
    window.location.href = 'https://auth.trinnvis.io/helseid/auth?go=true&t=' + window.location.origin;
  }

  _loginAuthCriipto() {
    window.location.href = 'https://auth.trinnvis.io/bankid/auth?go=true&t=' + window.location.origin;
  }

  _signup() {
    window.location.href = 'https://trinnvis.no/signup';
  }

  _gotoSite() {
    window.location.href = 'https://trinnvis.no';
  }

  _onLoginNameChange(e) {
    this.username = e.target.value;
  }

  _onPasswordChange(e) {
    this.password = e.target.value;
  }

  _getInputStyle() {
    return 'border: none;box-shadow: none;padding: 5px 10px 6px 10px;resize: none;border-radius: 0;appearance: none;writing-mode: horizontal-tb;width: 100%;box-sizing: border-box;font-family: var(--mainSans), sans-serif;font-weight: 200;font-size: 15px;line-height: 160%; color: black; font-size: 15px;text-align: center;border-radius: 4px; margin-bottom: 10px;';
  }

  render() {
    return this.renderForm(this.useCode);
  }

  renderForm(useCode: boolean) {
    if (this._passwordInput !== undefined && this._usernameInput !== undefined) {
      const usernameInput = this._usernameInput as HTMLInputElement;
      const passwordInput = this._passwordInput as HTMLInputElement;
      const inputStyle = this._getInputStyle();

      usernameInput.style.cssText = inputStyle;
      passwordInput.style.cssText = inputStyle;

      usernameInput.addEventListener('input', (e) => this._onLoginNameChange(e));
      passwordInput.addEventListener('input', (e) => this._onPasswordChange(e));
    }

    return html`
      <form id="loginForm" @submit=${this.login}>
        <div class="logo"></div>

        ${this.isLinkMode
          ? html`<div>
              Skriv inn brukernavn (epost) og passord for å koble TrinnVis-kontoen til din ${this.providerDescription}
            </div>`
          : nothing}
        ${useCode
          ? html` <h2>Logg inn</h2>
              <p>Det sendes en kode på e-post for å logge deg på.</p>
              <input
                id="loginName"
                placeholder="epost"
                type="email"
                name="loginName"
                .value=${this.username}
                @input=${(event) => (this.username = event.target.value)}
                autocomplete="username"
              />
              ${this.loginErrorInvalidEmail ? html` <p class="error">Epostadressen er ugyldig</p> ` : nothing}

              <input type="submit" class="action" value="Send engangskode" />`
          : html`
              <!-- <input
          id="loginName"
          placeholder="epost"
          type="email"
          name="loginName"
          .value=this.username
          @input=(event) => (this.username = event.target.value)}
          autocomplete="username"
        /> -->
              <slot name="usernameInput" id="loginName"></slot>
              ${this.loginErrorInvalidEmail ? html` <p class="error">Epostadressen er ugyldig</p> ` : nothing}

              <slot id="password" name="passwordInput"></slot>
              <!-- <input
          id="password"
          placeholder="passord"
          name="password"
          type="password"
          .value=this.password}
          autocomplete="current-password"
          @input=(event) => (this.password = event.target.value)}
        /> -->
              ${this.loginErrorMissingPassword ? html` <div>Skriv inn et passord</div> ` : nothing}
              ${this.loginFailed ? html` <div>Innloggingen mislyktes. Prøv igjen.</div> ` : nothing}

              <input
                type="submit"
                class="action"
                value="${this.isLinkMode ? 'Koble til ' + this.providerDescription : 'Logg inn'}"
              />
            `}

        <div class="alternatives">
          <div @click=${this.toggleUseCode}>${useCode ? 'Bruk passord' : 'Bruk engangskode'}</div>
          ${useCode ? nothing : html` <div @click=${this._sendPassword}>Send meg nytt passord</div>`}
          <div @click=${this._signup}>Ingen konto? Prøv gratis</div>
          <div @click=${this._gotoSite}>Les mer om TrinnVis</div>
        </div>

        ${!this.isLinkMode
          ? html`<div class="secureLogin">
              <h2>Sikrere innlogging</h2>
              <p>Innlogging med BankID eller HelseID gjør TrinnVis-kontoen din tryggere mot angrep.</p>
              <input class="action" @click=${this._loginAuthCriipto} type="button" value="BankID" />
              <input class="action" @click=${this._loginAuth} type="button" value="HelseID" />
            </div>`
          : nothing}
      </form>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this._passwordInput !== undefined && this._usernameInput !== undefined) {
      const usernameInput = this._usernameInput as HTMLInputElement;
      const passwordInput = this._passwordInput as HTMLInputElement;
      const inputStyle = this._getInputStyle();

      usernameInput.style.cssText = inputStyle;
      passwordInput.style.cssText = inputStyle;

      usernameInput.addEventListener('input', (e) => this._onLoginNameChange(e));
      passwordInput.addEventListener('input', (e) => this._onPasswordChange(e));
    }
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('username') && this._usernameInput !== undefined) {
      (this._usernameInput as HTMLInputElement).value = this.username;
    }

    if (_changedProperties.has('password') && this._passwordInput !== undefined) {
      (this._passwordInput as HTMLInputElement).value = this.password;
    }

    if (this._passwordInput !== undefined && this._usernameInput !== undefined) {
      const usernameInput = this._usernameInput as HTMLInputElement;
      const passwordInput = this._passwordInput as HTMLInputElement;
      const inputStyle = this._getInputStyle();

      usernameInput.style.cssText = inputStyle;
      passwordInput.style.cssText = inputStyle;

      usernameInput.addEventListener('input', (e) => this._onLoginNameChange(e));
      passwordInput.addEventListener('input', (e) => this._onPasswordChange(e));
    }
  }

  private async login(event: SubmitEvent) {
    event.preventDefault();
    if (this.useCode) {
      await this.sendSingleUseCode();
    } else {
      this.loginWithPassword(event);
    }
  }

  private loginWithPassword(event: SubmitEvent) {
    console.log('Login', this.username);
    this.loginErrorInvalidEmail = false;
    this.loginErrorMissingPassword = false;
    this.loginFailed = false;
    if (!dabihStore.isEmailValid(this.username)) {
      this.loginErrorInvalidEmail = true;
      return;
    }
    if (!this.password.length) {
      this.loginErrorMissingPassword = true;
      return;
    }

    if (this.mode === 'link') {
      const user = {
        username: this.username,
        password: this.password,
        provider: this.provider,
      };

      this.dispatchEvent(new CustomEvent('request-link', { bubbles: true, composed: true, detail: user }));
    } else {
      const user = {
        username: this.username,
        password: this.password,
      };
      this.dispatchEvent(
        new CustomEvent('request-login', {
          bubbles: true,
          composed: true,
          detail: { user, loginFailed: () => (this.loginFailed = true) },
        }),
      );
    }
  }

  private async sendSingleUseCode() {
    this.loginErrorInvalidEmail = false;

    if (!dabihStore.isEmailValid(this.username)) {
      this.loginErrorInvalidEmail = true;
      return;
    }

    this.dispatchEvent(
      new CustomEvent('request-authorize', {
        bubbles: true,
        composed: true,
        detail: { email: this.username },
      }),
    );
  }

  private toggleUseCode() {
    this.useCode = !this.useCode;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-login': DLogin;
  }
}
