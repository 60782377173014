import type { TutorialView } from 'src/content/tutorials/d-tutorial-view.js';
import { currentEmployeeUuid, employeesNotDeleted, getOrganization, writeAccess } from 'src/store/selectors';
import type { State, Tutorial } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import tutorialsList from '../../store/data/tutorials.json';
import { assertIsDefined } from 'src/lib';

export function buildTutorialView(uuid: string, state: State, currentParent: string): TutorialView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const item = tutorialsList.find((t) => t.id === uuid);
  assertIsDefined(item);

  const tutorial: Tutorial = organization['tutorial_' + uuid];

  const employeeUuid = currentEmployeeUuid(state) ?? '';

  const participants = employeesNotDeleted(state)
    .filter((e) => e.status === 'ACTIVE')
    .map((e) => {
      const p = tutorial.participants.find((q) => q.uuid === e.uuid) ?? { assigned: '', due: '', completed: '' };
      return {
        uuid: e.uuid,
        name: e.name,
        hasAccess: e.accessLevel !== 'NONE',
        assigned: p.assigned,
        due: p.due,
        completed: p.completed,
      };
    });
  return {
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    attachments: [],
    guide: item.guide,
    helpContent: '',
    content: item.description,
    name: item.title,
    today: LocalDate.fromString(state.today),
    type: 'tutorials',
    uuid: uuid,
    isNew: false,
    href: currentParent + '/tutorials/' + uuid,
    pdfHref: '',
    parentHref: currentParent,
    deleted: false,
    isCommonDate: tutorial.isCommonDate,
    commonDate: tutorial.commonDate,
    comment: tutorial.comment,
    employees: participants,
    currentUserAsParticipant: participants.find((p) => p.uuid === employeeUuid),
    deletable: true,
    hasDraft: false,
    fetchDraft: () => Promise.reject(new Error('No draft')),
  };
}
