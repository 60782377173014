/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AccountStateUpdateMessage,
  ClearDraftCommand,
  CompleteOrganizationViewModel,
  OrganizationCreateForNewUserMessage,
  OrganizationCreateMessage,
  OrganizationSettingsUpdateMessage,
  OrganizationUpdateMessage,
  OrganizationViewModel,
  PageViewModel,
  ReferenceViewModel,
  RequestSignaturesCommand,
  RestoreCommand,
  RestoreRevisionCommand,
  SaveDraftCommand,
  SearchResultHit,
  SendChangedEventCommand,
  SendChangedTaskCommand,
  SendMessageCommand,
  SendShareVacationCommand,
  SharedVacationsViewModel,
  StopShareVacationCommand,
  UpdateSecureLoginRequest,
  UpdateStaffingAccessCommand,
  UpdateTutorialCommand,
  UpdateTutorialStateCommand,
  WebhookSignaturesCommand,
} from '../models';
import {
  AccountStateUpdateMessageFromJSON,
  AccountStateUpdateMessageToJSON,
  ClearDraftCommandFromJSON,
  ClearDraftCommandToJSON,
  CompleteOrganizationViewModelFromJSON,
  CompleteOrganizationViewModelToJSON,
  OrganizationCreateForNewUserMessageFromJSON,
  OrganizationCreateForNewUserMessageToJSON,
  OrganizationCreateMessageFromJSON,
  OrganizationCreateMessageToJSON,
  OrganizationSettingsUpdateMessageFromJSON,
  OrganizationSettingsUpdateMessageToJSON,
  OrganizationUpdateMessageFromJSON,
  OrganizationUpdateMessageToJSON,
  OrganizationViewModelFromJSON,
  OrganizationViewModelToJSON,
  PageViewModelFromJSON,
  PageViewModelToJSON,
  ReferenceViewModelFromJSON,
  ReferenceViewModelToJSON,
  RequestSignaturesCommandFromJSON,
  RequestSignaturesCommandToJSON,
  RestoreCommandFromJSON,
  RestoreCommandToJSON,
  RestoreRevisionCommandFromJSON,
  RestoreRevisionCommandToJSON,
  SaveDraftCommandFromJSON,
  SaveDraftCommandToJSON,
  SearchResultHitFromJSON,
  SearchResultHitToJSON,
  SendChangedEventCommandFromJSON,
  SendChangedEventCommandToJSON,
  SendChangedTaskCommandFromJSON,
  SendChangedTaskCommandToJSON,
  SendMessageCommandFromJSON,
  SendMessageCommandToJSON,
  SendShareVacationCommandFromJSON,
  SendShareVacationCommandToJSON,
  SharedVacationsViewModelFromJSON,
  SharedVacationsViewModelToJSON,
  StopShareVacationCommandFromJSON,
  StopShareVacationCommandToJSON,
  UpdateSecureLoginRequestFromJSON,
  UpdateSecureLoginRequestToJSON,
  UpdateStaffingAccessCommandFromJSON,
  UpdateStaffingAccessCommandToJSON,
  UpdateTutorialCommandFromJSON,
  UpdateTutorialCommandToJSON,
  UpdateTutorialStateCommandFromJSON,
  UpdateTutorialStateCommandToJSON,
  WebhookSignaturesCommandFromJSON,
  WebhookSignaturesCommandToJSON,
} from '../models';

export interface ClearDraftRequest {
  organizationId: string;
  clearDraftCommand: ClearDraftCommand;
}

export interface CreateOrganizationRequest {
  organizationCreateMessage: OrganizationCreateMessage;
}

export interface CreateOrganizationForNewUserRequest {
  organizationCreateForNewUserMessage: OrganizationCreateForNewUserMessage;
}

export interface DeleteMessageRequest {
  message: string;
  organizationId: string;
}

export interface FetchDraftRequest {
  entityId: string;
  entityType: string;
  organizationId: string;
}

export interface FetchOrganizationRequest {
  organizationId: string;
}

export interface FetchOrganizationWithEntitiesRequest {
  organizationId: string;
}

export interface FetchSharedVacationsLinkRequest {
  organizationId: number;
  email: string;
}

export interface ListPagesRequest {
  organizationId: string;
}

export interface ListReferencesRequest {
  organizationId: string;
}

export interface RequestSignaturesRequest {
  organizationId: string;
  requestSignaturesCommand: RequestSignaturesCommand;
}

export interface RestoreDeletedEntityRequest {
  organizationId: string;
  restoreCommand: RestoreCommand;
}

export interface RestoreRevisionRequest {
  organizationId: string;
  restoreRevisionCommand: RestoreRevisionCommand;
}

export interface SaveDraftRequest {
  organizationId: string;
  saveDraftCommand: SaveDraftCommand;
}

export interface SearchOrganizationRequest {
  organizationId: string;
  limit?: number;
  query?: string;
}

export interface SendChangedEventRequest {
  organizationId: string;
  sendChangedEventCommand: SendChangedEventCommand;
}

export interface SendChangedTaskRequest {
  organizationId: string;
  sendChangedTaskCommand: SendChangedTaskCommand;
}

export interface SendMessageRequest {
  organizationId: string;
  sendMessageCommand: SendMessageCommand;
}

export interface SendShareVacationRequest {
  organizationId: string;
  sendShareVacationCommand: SendShareVacationCommand;
}

export interface StopShareVacationRequest {
  organizationId: string;
  stopShareVacationCommand: StopShareVacationCommand;
}

export interface UpdateOrganizationRequest {
  organizationId: string;
  organizationUpdateMessage: OrganizationUpdateMessage;
}

export interface UpdateOrganizationSettingsRequest {
  organizationId: string;
  organizationSettingsUpdateMessage: OrganizationSettingsUpdateMessage;
}

export interface UpdatePendingOrganizationStateRequest {
  organizationId: string;
  accountStateUpdateMessage: AccountStateUpdateMessage;
}

export interface UpdateSecureLoginOperationRequest {
  organizationId: string;
  updateSecureLoginRequest: UpdateSecureLoginRequest;
}

export interface UpdateStaffingAccessRequest {
  organizationId: string;
  updateStaffingAccessCommand: UpdateStaffingAccessCommand;
}

export interface UpdateTutorialRequest {
  organizationId: string;
  updateTutorialCommand: UpdateTutorialCommand;
}

export interface UpdateTutorialStateRequest {
  organizationId: string;
  updateTutorialStateCommand: UpdateTutorialStateCommand;
}

export interface WebhookSignaturesRequest {
  organizationId: string;
  entityType: string;
  entityUuid: string;
  webhookSignaturesCommand: WebhookSignaturesCommand;
}

/**
 *
 */
export class OrganizationApi extends runtime.BaseAPI {
  /**
   * This endpoint is used to clear a draft.
   * Clear draft.
   */
  async clearDraftRaw(
    requestParameters: ClearDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling clearDraft.',
      );
    }

    if (requestParameters.clearDraftCommand === null || requestParameters.clearDraftCommand === undefined) {
      throw new runtime.RequiredError(
        'clearDraftCommand',
        'Required parameter requestParameters.clearDraftCommand was null or undefined when calling clearDraft.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:clear-draft`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ClearDraftCommandToJSON(requestParameters.clearDraftCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to clear a draft.
   * Clear draft.
   */
  async clearDraft(
    requestParameters: ClearDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.clearDraftRaw(requestParameters, initOverrides);
  }

  /**
   * Create an account for an existing user.
   */
  async createOrganizationRaw(
    requestParameters: CreateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationCreateMessage === null ||
      requestParameters.organizationCreateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationCreateMessage',
        'Required parameter requestParameters.organizationCreateMessage was null or undefined when calling createOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationCreateMessageToJSON(requestParameters.organizationCreateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create an account for an existing user.
   */
  async createOrganization(
    requestParameters: CreateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrganizationRaw(requestParameters, initOverrides);
  }

  /**
   * Temporary method to be merged with the POST on /organizations when transition is completed.
   * Create an account for a new user.
   */
  async createOrganizationForNewUserRaw(
    requestParameters: CreateOrganizationForNewUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.organizationCreateForNewUserMessage === null ||
      requestParameters.organizationCreateForNewUserMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationCreateForNewUserMessage',
        'Required parameter requestParameters.organizationCreateForNewUserMessage was null or undefined when calling createOrganizationForNewUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/:create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationCreateForNewUserMessageToJSON(requestParameters.organizationCreateForNewUserMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Temporary method to be merged with the POST on /organizations when transition is completed.
   * Create an account for a new user.
   */
  async createOrganizationForNewUser(
    requestParameters: CreateOrganizationForNewUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrganizationForNewUserRaw(requestParameters, initOverrides);
  }

  /**
   * Marks the message as deleted so it will not be shown again.
   * Deletes a message.
   */
  async deleteMessageRaw(
    requestParameters: DeleteMessageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.message === null || requestParameters.message === undefined) {
      throw new runtime.RequiredError(
        'message',
        'Required parameter requestParameters.message was null or undefined when calling deleteMessage.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteMessage.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/messages/{message}`
          .replace(`{${'message'}}`, encodeURIComponent(String(requestParameters.message)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Marks the message as deleted so it will not be shown again.
   * Deletes a message.
   */
  async deleteMessage(
    requestParameters: DeleteMessageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteMessageRaw(requestParameters, initOverrides);
  }

  /**
   * Get the draft of an entity.
   * Draft for an entity.
   */
  async fetchDraftRaw(
    requestParameters: FetchDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SaveDraftCommand>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchDraft.',
      );
    }

    if (requestParameters.entityType === null || requestParameters.entityType === undefined) {
      throw new runtime.RequiredError(
        'entityType',
        'Required parameter requestParameters.entityType was null or undefined when calling fetchDraft.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchDraft.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/drafts/{entityType}/{entityId}`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'entityType'}}`, encodeURIComponent(String(requestParameters.entityType)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SaveDraftCommandFromJSON(jsonValue));
  }

  /**
   * Get the draft of an entity.
   * Draft for an entity.
   */
  async fetchDraft(
    requestParameters: FetchDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SaveDraftCommand> {
    const response = await this.fetchDraftRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get organization for specified organization id.
   */
  async fetchOrganizationRaw(
    requestParameters: FetchOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrganizationViewModel>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationViewModelFromJSON(jsonValue));
  }

  /**
   * Get organization for specified organization id.
   */
  async fetchOrganization(
    requestParameters: FetchOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrganizationViewModel> {
    const response = await this.fetchOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists fetch.
   */
  async fetchOrganizationWithEntitiesRaw(
    requestParameters: FetchOrganizationWithEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CompleteOrganizationViewModel>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchOrganizationWithEntities.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/fetch`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CompleteOrganizationViewModelFromJSON(jsonValue));
  }

  /**
   * Lists fetch.
   */
  async fetchOrganizationWithEntities(
    requestParameters: FetchOrganizationWithEntitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CompleteOrganizationViewModel> {
    const response = await this.fetchOrganizationWithEntitiesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * The link includes a token that gives access to all shared vacations for the email.
   * Fetch location link to shared vacations.
   */
  async fetchSharedVacationsLinkRaw(
    requestParameters: FetchSharedVacationsLinkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SharedVacationsViewModel>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchSharedVacationsLink.',
      );
    }

    if (requestParameters.email === null || requestParameters.email === undefined) {
      throw new runtime.RequiredError(
        'email',
        'Required parameter requestParameters.email was null or undefined when calling fetchSharedVacationsLink.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/shared-vacations`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SharedVacationsViewModelFromJSON(jsonValue));
  }

  /**
   * The link includes a token that gives access to all shared vacations for the email.
   * Fetch location link to shared vacations.
   */
  async fetchSharedVacationsLink(
    requestParameters: FetchSharedVacationsLinkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SharedVacationsViewModel> {
    const response = await this.fetchSharedVacationsLinkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get pages from word press.
   * Lists pages.
   */
  async listPagesRaw(
    requestParameters: ListPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<PageViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listPages.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/pages`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PageViewModelFromJSON));
  }

  /**
   * Get pages from word press.
   * Lists pages.
   */
  async listPages(
    requestParameters: ListPagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<PageViewModel>> {
    const response = await this.listPagesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get reference links.
   * Lists references.
   */
  async listReferencesRaw(
    requestParameters: ListReferencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ReferenceViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listReferences.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/references`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferenceViewModelFromJSON));
  }

  /**
   * Get reference links.
   * Lists references.
   */
  async listReferences(
    requestParameters: ListReferencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ReferenceViewModel>> {
    const response = await this.listReferencesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint is used to request signatures, ie. signing a PDF version of the entity.
   * Request signatures from users.
   */
  async requestSignaturesRaw(
    requestParameters: RequestSignaturesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling requestSignatures.',
      );
    }

    if (
      requestParameters.requestSignaturesCommand === null ||
      requestParameters.requestSignaturesCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'requestSignaturesCommand',
        'Required parameter requestParameters.requestSignaturesCommand was null or undefined when calling requestSignatures.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:request-signatures`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RequestSignaturesCommandToJSON(requestParameters.requestSignaturesCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to request signatures, ie. signing a PDF version of the entity.
   * Request signatures from users.
   */
  async requestSignatures(
    requestParameters: RequestSignaturesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.requestSignaturesRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to restore an deleted entity.
   * Restore a deleted entity.
   */
  async restoreDeletedEntityRaw(
    requestParameters: RestoreDeletedEntityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling restoreDeletedEntity.',
      );
    }

    if (requestParameters.restoreCommand === null || requestParameters.restoreCommand === undefined) {
      throw new runtime.RequiredError(
        'restoreCommand',
        'Required parameter requestParameters.restoreCommand was null or undefined when calling restoreDeletedEntity.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:restore`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RestoreCommandToJSON(requestParameters.restoreCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to restore an deleted entity.
   * Restore a deleted entity.
   */
  async restoreDeletedEntity(
    requestParameters: RestoreDeletedEntityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.restoreDeletedEntityRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to restore a previous revision.
   * Restore a previous revision.
   */
  async restoreRevisionRaw(
    requestParameters: RestoreRevisionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling restoreRevision.',
      );
    }

    if (requestParameters.restoreRevisionCommand === null || requestParameters.restoreRevisionCommand === undefined) {
      throw new runtime.RequiredError(
        'restoreRevisionCommand',
        'Required parameter requestParameters.restoreRevisionCommand was null or undefined when calling restoreRevision.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:restore-revision`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RestoreRevisionCommandToJSON(requestParameters.restoreRevisionCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to restore a previous revision.
   * Restore a previous revision.
   */
  async restoreRevision(
    requestParameters: RestoreRevisionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.restoreRevisionRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to save draft while editing.
   * Save draft.
   */
  async saveDraftRaw(
    requestParameters: SaveDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling saveDraft.',
      );
    }

    if (requestParameters.saveDraftCommand === null || requestParameters.saveDraftCommand === undefined) {
      throw new runtime.RequiredError(
        'saveDraftCommand',
        'Required parameter requestParameters.saveDraftCommand was null or undefined when calling saveDraft.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:save-draft`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SaveDraftCommandToJSON(requestParameters.saveDraftCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to save draft while editing.
   * Save draft.
   */
  async saveDraft(
    requestParameters: SaveDraftRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.saveDraftRaw(requestParameters, initOverrides);
  }

  /**
   * Search content from cloud search server.
   * Lists search.
   */
  async searchOrganizationRaw(
    requestParameters: SearchOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SearchResultHit>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling searchOrganization.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/search`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchResultHitFromJSON));
  }

  /**
   * Search content from cloud search server.
   * Lists search.
   */
  async searchOrganization(
    requestParameters: SearchOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SearchResultHit>> {
    const response = await this.searchOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This endpoint is used to send summary.
   * Send message to other users.
   */
  async sendChangedEventRaw(
    requestParameters: SendChangedEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendChangedEvent.',
      );
    }

    if (requestParameters.sendChangedEventCommand === null || requestParameters.sendChangedEventCommand === undefined) {
      throw new runtime.RequiredError(
        'sendChangedEventCommand',
        'Required parameter requestParameters.sendChangedEventCommand was null or undefined when calling sendChangedEvent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:send-changed-event`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendChangedEventCommandToJSON(requestParameters.sendChangedEventCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to send summary.
   * Send message to other users.
   */
  async sendChangedEvent(
    requestParameters: SendChangedEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendChangedEventRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to send a message to other employees assigned to future events based on the given task.
   * Send message to other users when task changes.
   */
  async sendChangedTaskRaw(
    requestParameters: SendChangedTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendChangedTask.',
      );
    }

    if (requestParameters.sendChangedTaskCommand === null || requestParameters.sendChangedTaskCommand === undefined) {
      throw new runtime.RequiredError(
        'sendChangedTaskCommand',
        'Required parameter requestParameters.sendChangedTaskCommand was null or undefined when calling sendChangedTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:send-changed-task`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendChangedTaskCommandToJSON(requestParameters.sendChangedTaskCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to send a message to other employees assigned to future events based on the given task.
   * Send message to other users when task changes.
   */
  async sendChangedTask(
    requestParameters: SendChangedTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendChangedTaskRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to send summary.
   * Send message to other users.
   */
  async sendMessageRaw(
    requestParameters: SendMessageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendMessage.',
      );
    }

    if (requestParameters.sendMessageCommand === null || requestParameters.sendMessageCommand === undefined) {
      throw new runtime.RequiredError(
        'sendMessageCommand',
        'Required parameter requestParameters.sendMessageCommand was null or undefined when calling sendMessage.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:send-message`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendMessageCommandToJSON(requestParameters.sendMessageCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to send summary.
   * Send message to other users.
   */
  async sendMessage(
    requestParameters: SendMessageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendMessageRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to send summary.
   * Send and publish the shared vacation plan for 2021.
   */
  async sendShareVacationRaw(
    requestParameters: SendShareVacationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendShareVacation.',
      );
    }

    if (
      requestParameters.sendShareVacationCommand === null ||
      requestParameters.sendShareVacationCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'sendShareVacationCommand',
        'Required parameter requestParameters.sendShareVacationCommand was null or undefined when calling sendShareVacation.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:send-share-vacation`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendShareVacationCommandToJSON(requestParameters.sendShareVacationCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to send summary.
   * Send and publish the shared vacation plan for 2021.
   */
  async sendShareVacation(
    requestParameters: SendShareVacationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendShareVacationRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to stop sharing vacation.
   * Stop sharing vacation.
   */
  async stopShareVacationRaw(
    requestParameters: StopShareVacationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling stopShareVacation.',
      );
    }

    if (
      requestParameters.stopShareVacationCommand === null ||
      requestParameters.stopShareVacationCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'stopShareVacationCommand',
        'Required parameter requestParameters.stopShareVacationCommand was null or undefined when calling stopShareVacation.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:stop-share-vacation`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StopShareVacationCommandToJSON(requestParameters.stopShareVacationCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to stop sharing vacation.
   * Stop sharing vacation.
   */
  async stopShareVacation(
    requestParameters: StopShareVacationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.stopShareVacationRaw(requestParameters, initOverrides);
  }

  /**
   * Update organization for specified organization id.
   */
  async updateOrganizationRaw(
    requestParameters: UpdateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganization.',
      );
    }

    if (
      requestParameters.organizationUpdateMessage === null ||
      requestParameters.organizationUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationUpdateMessage',
        'Required parameter requestParameters.organizationUpdateMessage was null or undefined when calling updateOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationUpdateMessageToJSON(requestParameters.organizationUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update organization for specified organization id.
   */
  async updateOrganization(
    requestParameters: UpdateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateOrganizationRaw(requestParameters, initOverrides);
  }

  /**
   * This operation will currently fail if the tripletex customer is EHF and the user changes to postal address
   * Updates settings for an organization.
   */
  async updateOrganizationSettingsRaw(
    requestParameters: UpdateOrganizationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationSettings.',
      );
    }

    if (
      requestParameters.organizationSettingsUpdateMessage === null ||
      requestParameters.organizationSettingsUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationSettingsUpdateMessage',
        'Required parameter requestParameters.organizationSettingsUpdateMessage was null or undefined when calling updateOrganizationSettings.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/settings`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationSettingsUpdateMessageToJSON(requestParameters.organizationSettingsUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This operation will currently fail if the tripletex customer is EHF and the user changes to postal address
   * Updates settings for an organization.
   */
  async updateOrganizationSettings(
    requestParameters: UpdateOrganizationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateOrganizationSettingsRaw(requestParameters, initOverrides);
  }

  /**
   * The state is only used during preparing a new account.
   * Updates state for an organization.
   */
  async updatePendingOrganizationStateRaw(
    requestParameters: UpdatePendingOrganizationStateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updatePendingOrganizationState.',
      );
    }

    if (
      requestParameters.accountStateUpdateMessage === null ||
      requestParameters.accountStateUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'accountStateUpdateMessage',
        'Required parameter requestParameters.accountStateUpdateMessage was null or undefined when calling updatePendingOrganizationState.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/state`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AccountStateUpdateMessageToJSON(requestParameters.accountStateUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * The state is only used during preparing a new account.
   * Updates state for an organization.
   */
  async updatePendingOrganizationState(
    requestParameters: UpdatePendingOrganizationStateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePendingOrganizationStateRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used to send summary.
   * Update secure login settings.
   */
  async updateSecureLoginRaw(
    requestParameters: UpdateSecureLoginOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateSecureLogin.',
      );
    }

    if (
      requestParameters.updateSecureLoginRequest === null ||
      requestParameters.updateSecureLoginRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'updateSecureLoginRequest',
        'Required parameter requestParameters.updateSecureLoginRequest was null or undefined when calling updateSecureLogin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:update-secure-login`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSecureLoginRequestToJSON(requestParameters.updateSecureLoginRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used to send summary.
   * Update secure login settings.
   */
  async updateSecureLogin(
    requestParameters: UpdateSecureLoginOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateSecureLoginRaw(requestParameters, initOverrides);
  }

  /**
   * Updates staffing access for an organization.
   */
  async updateStaffingAccessRaw(
    requestParameters: UpdateStaffingAccessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateStaffingAccess.',
      );
    }

    if (
      requestParameters.updateStaffingAccessCommand === null ||
      requestParameters.updateStaffingAccessCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateStaffingAccessCommand',
        'Required parameter requestParameters.updateStaffingAccessCommand was null or undefined when calling updateStaffingAccess.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:update-staffing-access`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateStaffingAccessCommandToJSON(requestParameters.updateStaffingAccessCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates staffing access for an organization.
   */
  async updateStaffingAccess(
    requestParameters: UpdateStaffingAccessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateStaffingAccessRaw(requestParameters, initOverrides);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorialRaw(
    requestParameters: UpdateTutorialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateTutorial.',
      );
    }

    if (requestParameters.updateTutorialCommand === null || requestParameters.updateTutorialCommand === undefined) {
      throw new runtime.RequiredError(
        'updateTutorialCommand',
        'Required parameter requestParameters.updateTutorialCommand was null or undefined when calling updateTutorial.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:update-tutorial`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTutorialCommandToJSON(requestParameters.updateTutorialCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorial(
    requestParameters: UpdateTutorialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTutorialRaw(requestParameters, initOverrides);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorialStateRaw(
    requestParameters: UpdateTutorialStateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateTutorialState.',
      );
    }

    if (
      requestParameters.updateTutorialStateCommand === null ||
      requestParameters.updateTutorialStateCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateTutorialStateCommand',
        'Required parameter requestParameters.updateTutorialStateCommand was null or undefined when calling updateTutorialState.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:update-tutorial-state`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTutorialStateCommandToJSON(requestParameters.updateTutorialStateCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorialState(
    requestParameters: UpdateTutorialStateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTutorialStateRaw(requestParameters, initOverrides);
  }

  /**
   * This endpoint is used for callback to webhook signatures.
   * Webhook for signatures.
   */
  async webhookSignaturesRaw(
    requestParameters: WebhookSignaturesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling webhookSignatures.',
      );
    }

    if (requestParameters.entityType === null || requestParameters.entityType === undefined) {
      throw new runtime.RequiredError(
        'entityType',
        'Required parameter requestParameters.entityType was null or undefined when calling webhookSignatures.',
      );
    }

    if (requestParameters.entityUuid === null || requestParameters.entityUuid === undefined) {
      throw new runtime.RequiredError(
        'entityUuid',
        'Required parameter requestParameters.entityUuid was null or undefined when calling webhookSignatures.',
      );
    }

    if (
      requestParameters.webhookSignaturesCommand === null ||
      requestParameters.webhookSignaturesCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'webhookSignaturesCommand',
        'Required parameter requestParameters.webhookSignaturesCommand was null or undefined when calling webhookSignatures.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.entityType !== undefined) {
      queryParameters['entityType'] = requestParameters.entityType;
    }

    if (requestParameters.entityUuid !== undefined) {
      queryParameters['entityUuid'] = requestParameters.entityUuid;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/:webhook-signatures`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: WebhookSignaturesCommandToJSON(requestParameters.webhookSignaturesCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This endpoint is used for callback to webhook signatures.
   * Webhook for signatures.
   */
  async webhookSignatures(
    requestParameters: WebhookSignaturesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.webhookSignaturesRaw(requestParameters, initOverrides);
  }
}
