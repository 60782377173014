import { css, html, LitElement, nothing } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import './infosec-procedure/d-infosec-data.js';
import './infosec-procedure/d-infosec-computers-networks.js';
import './infosec-procedure/d-infosec-access.js';
import './infosec-procedure/d-infosec-backup.js';
import type { ComputersPageViewModel } from './computers-page-view-model.js';
import '../../library/elements/d-menu.js';
import './d-computers-page-content-map.js';

/**
 *
 * Displays the computers page. The page may have a query parameter `tab` that sets the initial value for the main tab component.
 *
 */
@customElement('d-computers-page-content-inner')
export class DComputersPageContentInner extends LitElement {
  static readonly styles = [
    css`
      .wrapper {
        max-width: 724px;
      }
    `,
  ];
  newContainingComputer = '';
  newContainingNetwork = '';
  @property({ type: Object })
  pageView!: ComputersPageViewModel;
  @property({ type: String })
  tab = 'data';
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: String })
  highlightComputer?: string;
  @state()
  private creatingNewElements = false;

  get mapElementsExist() {
    return (
      this.pageView.remoteServers.length > 0 ||
      this.pageView.cloudServices.length > 0 ||
      this.pageView.connectedPartners.length > 0 ||
      this.pageView.connectedEmployees.length > 0 ||
      this.pageView.networks.length > 0
    );
  }

  private get tabs() {
    return [
      {
        value: 'data',
        text: 'Databehandling',
      },
      {
        value: 'computers-networks',
        text: 'Dataanlegg',
      },
      {
        value: 'access',
        text: 'Tilganger',
      },
      {
        value: 'backup',
        text: 'Sikkerhetskopiering',
      },
      {
        value: 'map',
        text: 'Datakart',
      },
    ];
  }

  savePersonalDataItem(event) {
    if (event.detail.storageUnit) {
      this.newContainingComputer = event.detail;
    }
  }

  saveComputer(event) {
    if (event.detail.networkUuid) {
      this.newContainingNetwork = event.detail;
    }
  }

  onCreatingNewElements(e: CustomEvent<{ value: boolean }>) {
    e.stopPropagation();
    this.creatingNewElements = e.detail.value;
  }

  render() {
    console.log('Current tab', this.tab);

    return html`
      <div class="wrapper">
        <d-menu .items=${this.tabs} .value=${this.tab} @value-changed=${(e) => (this.tab = e.detail.value)}></d-menu>
        ${this.tab === 'data'
          ? html`
              <d-infosec-data
                .computers=${this.pageView.infosecSettings.computers}
                .dataItems=${this.pageView.infosecSettings.dataItems}
                .partners=${this.pageView.infosecSettings.partners}
                .remoteServers=${this.pageView.infosecSettings.remoteServers}
                .cloudServices=${this.pageView.infosecSettings.cloudServices}
                .locations=${this.pageView.infosecSettings.locations}
                .assets=${this.pageView.infosecSettings.assets}
                .networks=${this.pageView.infosecSettings.networks}
              ></d-infosec-data>
            `
          : nothing}
        ${this.tab === 'computers-networks'
          ? html`
              <d-infosec-computers-networks
                .computers=${this.pageView.infosecSettings.computers}
                .dataItems=${this.pageView.infosecSettings.dataItems}
                .employees=${this.pageView.infosecSettings.employees}
                .partners=${this.pageView.infosecSettings.partners}
                .remoteServers=${this.pageView.infosecSettings.remoteServers}
                .cloudServices=${this.pageView.infosecSettings.cloudServices}
                .locations=${this.pageView.infosecSettings.locations}
                .assets=${this.pageView.infosecSettings.assets}
                .networks=${this.pageView.infosecSettings.networks}
                .externalConnections=${this.pageView.infosecSettings.externalConnections}
              ></d-infosec-computers-networks>
            `
          : nothing}
        ${this.tab === 'access'
          ? html`
              <d-infosec-access
                .computers=${this.pageView.infosecSettings.computers}
                .dataItems=${this.pageView.infosecSettings.dataItems}
                .employees=${this.pageView.infosecSettings.employees}
                .partners=${this.pageView.infosecSettings.partners}
                .remoteServers=${this.pageView.infosecSettings.remoteServers}
                .cloudServices=${this.pageView.infosecSettings.cloudServices}
                .locations=${this.pageView.infosecSettings.locations}
                .assets=${this.pageView.infosecSettings.assets}
              ></d-infosec-access>
            `
          : nothing}
        ${this.tab === 'backup'
          ? html`
              <d-infosec-backup
                .dataItems=${this.pageView.infosecSettings.dataItems}
                .employees=${this.pageView.infosecSettings.employees}
                .partners=${this.pageView.infosecSettings.partners}
                .computers=${this.pageView.infosecSettings.computers}
                .remoteServers=${this.pageView.infosecSettings.remoteServers}
                .cloudServices=${this.pageView.infosecSettings.cloudServices}
                .assets=${this.pageView.infosecSettings.assets}
                .organizationName=${this.pageView.organizationName}
              ></d-infosec-backup>
            `
          : nothing}
        ${this.tab === 'map'
          ? html`
              <d-computers-page-content-map
                ?creatingNewElements=${this.creatingNewElements}
                @save-computer=${(e) => this.saveComputer(e)}
                @save-personal-data-item=${(e) => this.savePersonalDataItem(e)}
                ?writeAccess="${this.pageView.writeAccess}"
                .contentStickyTop=${this.contentStickyTop}
                .connectedPartners=${this.pageView.connectedPartners}
                .connectedEmployees=${this.pageView.connectedEmployees}
                .cloudServices=${this.pageView.cloudServices}
                .remoteServers=${this.pageView.remoteServers}
                .networks=${this.pageView.networks}
                .assets=${this.pageView.infosecSettings.assets}
                .organizationName=${this.pageView.organizationName}
                .offlinePersonalDataItems=${this.pageView.offlinePersonalDataItems}
                .standaloneComputers=${this.pageView.standaloneComputers}
                .standalonePrinters=${this.pageView.standalonePrinters}
                .employees=${this.pageView.availableEmployees}
                .partners=${this.pageView.availablePartners}
                .availableRiskAssessments=${this.pageView.availableRiskAssessments}
                .highlightComputer=${this.highlightComputer}
              >
              </d-computers-page-content-map>
            `
          : nothing}
      </div>
    `;
  }

  protected willUpdate() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);

    console.log(params, query, location);

    if (params.has('tab')) {
      this.tab = params.get('tab') ?? 'data';

      if (params.has('c')) {
        this.highlightComputer = params.get('c') ?? undefined;
        setTimeout(() => {
          this.highlightComputer = undefined;
        }, 15000);
      }
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computers-page-content-inner': DComputersPageContentInner;
  }
}
