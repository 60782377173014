import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/components/d-popup.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import { descriptionForExternal } from './d-issue-behavior.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import type { IssueUpdateMessage } from 'src/store/api';
import { LocalDate } from 'src/utilities/local-date.js';
import { createUuid } from 'src/store';

export interface NewIssueInput {
  organizationId: number;
  createIssueAction: (_id: string, _message: IssueUpdateMessage) => void;
}
export interface NewIssueResult {
  action: 'done';
}

/**
 *
 * USAGE:
 *    d-application
 *
 */
@customElement('new-issue-dialog')
export class NewIssueDialog extends BaseDialog<NewIssueInput, NewIssueResult> {
  @property({ type: Boolean })
  issueRegistered = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  currentEmployeeUuid?: string = '';
  @property({ type: String })
  description = '';
  @property({ type: String })
  eventDate = '';
  @property({ type: String })
  immediateMeasures = '';
  @property({ type: String })
  furtherMeasures = '';
  @property({ type: String })
  issueUuid = '';
  @property({ type: Boolean })
  descriptionOpen = true;
  @property({ type: Boolean })
  additionalInfoOpen = false;
  @property({ type: String })
  processing = 'DEFAULT';

  width = 600;
  private moreInfoPrompt = '<p>Hvis du vil, kan du gi utfyllende informasjon og velge videre behandling her.</p>';
  private processingOptions = [
    {
      value: 'DEFAULT',
      text: 'Avviket skal behandles senere',
    },
    {
      value: 'ASAP',
      text: 'Jeg vil behandle avviket med det samme',
    },
    {
      value: 'CLOSE',
      text: 'Avviket trenger ingen videre behandling',
    },
  ];

  protected get calculatedTitle(): string {
    if (this.issueRegistered) {
      return 'Avviksmeldingen er registrert';
    }
    return 'Avviksmelding';
  }

  protected get calculatedHeaderActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.newIssueDisabled },
    ];
  }

  private get newIssueDisabled() {
    return (!this.description || this.description.trim() === '') && !this.issueRegistered;
  }

  _name(description) {
    if (description.length < 42) {
      return description;
    }
    return description.substring(0, 40) + '…';
  }

  _externalIssueReport(issueId, recipient, subject, description, returnUrl, afterSend) {
    window.location.href =
      'https://avvik.trinnvis.io/?issue-id=' +
      issueId +
      '&public-recipient=' +
      recipient +
      '&public-subject=' +
      encodeURI(subject) +
      '&public-description=' +
      encodeURI(description) +
      '&return-url=' +
      returnUrl +
      '&extra-after-send=' +
      afterSend;
  }

  _newIssue() {
    const issueItem = this.defaultIssue();
    this.issueUuid = createUuid();
    const id = this.issueUuid;
    issueItem.description = this.description;
    issueItem.name = this._name(this.description);
    issueItem.reportedDate = LocalDate.now().toString();
    this.issueRegistered = true;
    this.dispatchEvent(
      new CustomEvent<{ id: string; message: IssueUpdateMessage }>('create-issue', {
        composed: true,
        bubbles: true,
        detail: { id: id, message: issueItem },
      }),
    );
  }

  defaultIssue(): IssueUpdateMessage {
    return {
      eventDate: '',
      immediateMeasures: '',
      reportedSafetyManager: false,
      registeredDate: LocalDate.now().toString(),
      correction: '',
      reportedCountyMedical: false,
      description: '',
      evaluated: false,
      reportedInspectorate: false,
      shallEvaluated: false,
      identifiedMeasures: '',
      evaluatedDate: '',
      reportedLabourInspection: false,
      notes: '',
      processedBy: '',
      reportedTo: '',
      furtherMeasures: '',
      reportedDate: '',
      requireEvaluation: false,
      pages: [281],
      relateToPatientInstitution: false,
      name: '',
      processedDate: '',
      informationLeakagePossibility: false,
      requiresMeasures: false,
      measuresSatisfactorily: false,
      relateToRadiation: false,
      relateToRadiationEquipmentMissing: false,
      personDamagePossibility: false,
      relateToEmployees: false,
      workRelatedHealthIssue: false,
      workRelatedHealthIssueEmployee: '',
      workRelatedInjury: false,
      workRelatedSickness: false,
      workRelatedHealthIssueDescription: '',
      workRelatedInjuryTask: '',
      workRelatedInjuryIncidentCause: '',
      workRelatedSicknessCausingTask: '',
      workRelatedSicknessCausingTaskLocation: '',
      workRelatedSicknessCausingEnvironment: '',
      relateToPatient: false,
      relateToEquipment: false,
      relateToMaritimeHealthCertificate: false,
      implementedMeasure: false,
      evaluatedBy: '',
      evaluatedOk: false,
      accessControl: [],
      classification: 'NONE',
    };
  }

  _saveAdditionalInformation() {
    if (
      this.eventDate === '' &&
      (!this.immediateMeasures || this.immediateMeasures.trim() === '') &&
      (!this.furtherMeasures || this.furtherMeasures.trim() === '') &&
      this.processing === 'DEFAULT'
    ) {
      this.close();
    } else {
      const id = this.issueUuid;
      const issueItem = this.defaultIssue();
      issueItem.description = this.description;
      issueItem.name = this._name(this.description);
      const today = LocalDate.now().toString();
      issueItem.reportedDate = today;
      issueItem.eventDate = this.eventDate;
      issueItem.immediateMeasures = this.immediateMeasures;
      issueItem.furtherMeasures = this.furtherMeasures;
      if (this.processing === 'CLOSE') {
        issueItem.processedDate = today;
        issueItem.processedBy = this.currentEmployeeUuid;
      }
      this.dispatchEvent(
        new CustomEvent<{ id: string; message: IssueUpdateMessage }>('create-issue', {
          composed: true,
          bubbles: true,
          detail: { id: id, message: issueItem },
        }),
      );
      this._finish();
    }
  }

  _finish() {
    const recipient = '';
    const subject = this._name(this.description);
    const description = descriptionForExternal(
      this.description,
      this.eventDate,
      this.immediateMeasures,
      this.furtherMeasures,
    );
    const processing = this.processing;
    let returnUrl = window.location.href;
    const issuePath = '/account/' + this.organizationId + '/281/issues/' + this.issueUuid;
    let afterSend = '';
    if (processing === 'ASAP') {
      returnUrl =
        window.location.href.split('//')[0] + '//' + window.location.href.split('//')[1].split('/')[0] + issuePath;
    }
    if (processing === 'CLOSE') {
      afterSend = 'close';
    }
    if (recipient !== '') {
      this._externalIssueReport(this.issueUuid, recipient, subject, description, returnUrl, afterSend);
    } else if (processing === 'ASAP') {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: issuePath },
        }),
      );
    }
  }

  renderBody() {
    return html`
      <d-expansion ?opened=${!this.issueRegistered}>
        <d-section>
          <d-edit-textarea
            max-rows="50"
            placeholder="Hva skjedde?"
            .value=${this.description}
            @value-changed=${this.onDescriptionChanged}
          ></d-edit-textarea>
        </d-section>
      </d-expansion>
      <d-expansion ?opened=${this.issueRegistered}>
        <d-section>
          <d-view-info .content=${this.moreInfoPrompt}></d-view-info>
        </d-section>
        <d-section>
          <d-select-date
            in-dialog
            field="issues_eventDate"
            .value=${this.eventDate}
            @value-changed=${this.onEventDateChanged}
          ></d-select-date>
        </d-section>
        <d-section>
          <d-edit-textarea
            field="issues_immediateMeasures"
            value="${this.immediateMeasures}"
            @value-changed=${this.onImmediateMeasuresChanged}
          >
          </d-edit-textarea>
        </d-section>
        <d-section>
          <d-edit-textarea
            field="issues_furtherMeasures"
            value="${this.furtherMeasures}"
            @value-changed=${this.onFurtherMeasuresChanged}
          >
          </d-edit-textarea>
        </d-section>
        <d-section>
          <d-select-dropdown
            label="Avviksbehandling"
            .options=${this.processingOptions}
            .value=${this.processing}
            @value-changed=${this.onProcessingChanged}
          ></d-select-dropdown>
        </d-section>
      </d-expansion>
    `;
  }

  protected onDispatchAction(disabled: boolean, action: string) {
    if (action === 'send' && !this.issueRegistered) {
      this._newIssue();
      return;
    }

    if (action === 'send' && this.issueRegistered) {
      this._saveAdditionalInformation();
    }
    super.onDispatchAction(disabled, action);
  }

  protected fetchResult(_detail: string | undefined): NewIssueResult {
    return {
      action: 'done',
    };
  }

  protected initializeDialog(input: NewIssueInput) {
    this.organizationId = input.organizationId;
  }

  private onDescriptionChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.description = e.detail.value;
  }

  private onEventDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.eventDate = e.detail.value;
  }

  private onImmediateMeasuresChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.immediateMeasures = e.detail.value;
  }

  private onFurtherMeasuresChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.furtherMeasures = e.detail.value;
  }

  private onProcessingChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.processing = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-issue-dialog': NewIssueDialog;
  }
}
