import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityView, contentViewBuilder, EntityContent, toLookup } from 'src/content/entity-content.js';
import 'src/utilities/display-alert';
import type { FormViewItem } from '../../library/components/d-form-view.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-access.js';
import type { UserForAccess } from '../../library/editors/components/d-edit-access.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface ContactView extends AbstractEntityView<ContactViewEditItem> {
  lastName: string;
  firstName: string;
  notes: string;
  accessLevel: 'NONE' | 'USER' | 'READONLY' | 'ASSETS';
  email: string;
  telephone: string;
  mobilePhone: string;
  type: 'contacts';
  name: string;
  parentEntityId: string;
  partnerName: string;

  accessExpires: string;
}

export interface ContactViewEditItem {
  lastName: string;
  firstName: string;
  name: string;
  accessLevel: 'NONE' | 'USER' | 'READONLY' | 'ASSETS';
  email: string;
  telephone: string;
  mobilePhone: string;
  notes: string;

  partnerId: string;

  accessExpires: string;
}

/**
 *
 */
@customElement('d-contact-view')
export class DContactView extends EntityContent<ContactView, ContactViewEditItem> {
  static readonly styles = EntityContent.styles;
  @property({ type: String })
  entityType = 'contacts';

  @property({ type: String })
  parentEntityType = 'partners';

  @property({ type: String })
  currentUserEmail = '';

  private accessLevelLookup = toLookup({
    NONE: 'Ingen',
    READONLY: 'Lesetilgang',
    USER: 'Skrivetilgang',
  });

  static get observers() {
    return ['_nameChanged(editItem.firstName,editItem.lastName)'];
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addText('contacts_partner', this.entityView.partnerName, 's', true);
    builder.addTextIfNotEmpty('contacts_mobilePhone', this.entityView.mobilePhone, 's');
    builder.addTextIfNotEmpty('contacts_telephone', this.entityView.telephone, 's');
    builder.addTextIfNotEmpty('contacts_email', this.entityView.email, 'm');

    builder.addTextLookup('contacts_accessLevel', this.entityView.accessLevel, 'l', this.accessLevelLookup);
    builder.addTextVerticalIfNotEmpty('contacts_notes', this.entityView.notes, 'l');
    return builder.build();
  }

  _isCurrentUser(currentUserEmail, email) {
    return currentUserEmail === email;
  }

  doneDisabled(): boolean {
    return this.noName;
  }
  private get noName(): boolean {
    return !!(
      this.editItem &&
      isEmptyOrInvalidString(this.editItem.firstName) &&
      isEmptyOrInvalidString(this.editItem.lastName)
    );
  }

  async initializeEditItem() {
    this.editItem = {
      firstName: this.entityView.firstName,
      lastName: this.entityView.lastName,
      accessLevel: this.entityView.accessLevel,
      email: this.entityView.email,
      mobilePhone: this.entityView.mobilePhone,
      notes: this.entityView.notes,
      telephone: this.entityView.telephone,
      name: this.entityView.name,
      partnerId: this.entityView.parentEntityId,
      accessExpires: this.entityView.accessExpires,
    };
  }

  editUser(item: ContactViewEditItem): UserForAccess {
    return {
      uuid: this.entityView.uuid,
      email: item.email,
      accessLevel: item.accessLevel,
      accessExpires: item.accessExpires,
      name: this.entityView.name,
      partnerName: this.entityView.partnerName,
    };
  }

  renderEditItem(item: ContactViewEditItem): TemplateResult<1> {
    return html`<d-section>
        <d-edit-text
          field="contacts_firstName"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="${this.noName ? 'Personen må ha et navn' : ''}"
          ?mark-if-empty="${this.noName}"
          .value=${item.firstName}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, firstName: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          field="contacts_lastName"
          placeholder="${this.noName ? 'Personen må ha et navn' : ''}"
          ?mark-if-empty="${this.noName}"
          .value=${item.lastName}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, lastName: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-edit-text
          field="contacts_mobilePhone"
          .value=${item.mobilePhone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, mobilePhone: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          field="contacts_telephone"
          .value=${item.telephone}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, telephone: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-edit-access
        disabled="${this._isCurrentUser(this.currentUserEmail, item.email)}"
        email-value="${item.email}"
        field="contacts_accessLevel"
        .value=${item.accessLevel}
        .editUser=${this.editUser(item)}
        .otherUserEmails=${[]}
        .currentUserUuid=${'TODO'}
        @value-changed=${(e: CustomEvent<{ accessLevel: 'NONE' | 'USER'; email: string; accessExpires: string }>) => {
          this.editItem = {
            ...item,
            accessLevel: e.detail.accessLevel,
            accessExpires: e.detail.accessExpires,
            email: e.detail.email,
          };
        }}
      >
      </d-edit-access>
      <d-section>
        <d-edit-textarea
          field="contacts_notes"
          .value=${item.notes}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, notes: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-contact-view': DContactView;
  }
}
