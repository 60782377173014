import type { MeetingView } from 'src/content/meetings/d-meeting-view.js';
import { getOrganization, getOrganizationId, MeetingModel, meetings } from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { toDateTime } from 'src/store/utilities';

const defaultNewMeeting: MeetingView = {
  docsForLinking: [],
  fetchDraft(): Promise<Record<string, never>> {
    throw new Error('Illegal state, not applicable');
  },
  hasDraft: false,
  isNew: false,
  currentUserHasAccess: true,
  helpContent: '',
  attachments: [],
  deleted: false,
  href: '',
  name: '',
  parentHref: '',
  pdfHref: '',
  type: 'meetings',
  uuid: '',
  occurrences: [],
  today: LocalDate.now(),
  revisions: [],
  interviewedEmployeeUuid: '',
  interviewedEmployeeName: '',
  deletable: true,
  currentUserHasWriteAccess: true,
};

function toMeetingView(state: State, currentParent: string, meeting: MeetingModel): MeetingView {
  const occurrences: Occurrence[] = meeting.meetingOccurrences.map((e) => {
    return {
      occurrenceUuid: e.uuid,
      dateTime: toDateTime(e.date, e.time),
      durationMinutes: e.durationMinutes,
      href: e.restricted
        ? ''
        : '/account/' + getOrganizationId(state) + '/9772/meetings/' + meeting.uuid + '/meetingOccurrences/' + e.uuid,
      classified: e.classified,
      restricted: e.restricted,
      meetingStatus: e.meetingStatus,
    };
  });
  let name = meeting.name;
  if (meeting.type === 'INTERVIEW_ACTIVE' || meeting.type === 'INTERVIEW_TERMINATED') {
    name = 'Medarbeidersamtaler med ' + meeting.interviewEmployeeName;
  }
  return {
    ...defaultNewMeeting,
    parentHref: currentParent,
    uuid: meeting.uuid,
    name,
    today: LocalDate.fromString(state.today),
    occurrences,
  };
}

// TODO uklarhet om hvordan håndtere tidspunkt (klokkeslett)

export async function buildMeetingView(uuid: string, state: State, currentParent: string): Promise<MeetingView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const list = meetings(state);
  const meeting = list.find((m) => m.uuid === uuid);
  assertIsDefined(meeting);

  return toMeetingView(state, currentParent, meeting);
}
