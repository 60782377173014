import { createSelector } from '@reduxjs/toolkit';
import type { OrganizationState, State } from '../types.js';
import { LocalDate } from 'src/utilities/local-date.js';

export function getOrganization(state: State): OrganizationState | undefined {
  return state.organization;
}

export function now(state: State): LocalDate {
  return LocalDate.fromString(state.today);
}

export function getOrganizationId(state: State): number {
  if (state.id === null) {
    return 0;
  }
  return state.id;
}

export const organizationName = createSelector(getOrganization, function (organization) {
  if (organization !== undefined) {
    return organization.name;
  } else {
    return '';
  }
});

export const medicalDoctor = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.21') || organization.activityCodes.includes('86.22');
});

export const maritimeDoctor = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.21.01');
});

export const dentist = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.23');
});

export function hasOrganizationNotPending(state: State): boolean {
  return state.organization !== undefined && !state.organization.pending;
}

export function singleUserVersion(state: State): boolean {
  return state.organization?.singleUser === true;
}
