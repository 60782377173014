import { css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { AbstractEntityView, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { GestureType } from 'src/library/fields/d-spinner-robot.js';
import type { RelatedRiskAssessment } from 'src/library/lists/d-list-section-related-risk-assessments.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { ghsLabels } from 'src/store/selectors';
import '../../content/substances/d-inline-robot-alert.js';
import '../../content/substances/d-view-document.js';
import '../../library/editors/index.js';
import '../../library/elements/d-section.js';
import '../../library/elements/d-wrap.js';
import '../../library/fields/d-view-text.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section-related-risk-assessments.js';
import './d-ghs-hazard-label.js';
import Bugsnag from '@bugsnag/js';
import { riskLevelFromRisk } from 'src/models/risk.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { CreateEntityInput, CreateRiskAssessmentInput } from 'src/layout/parts/d-new-document';
import { ActionInput } from 'src/library/elements/d-action';
import { UploadedFile } from 'src/pages/substances-page/d-substances-page-content';
import { displayAlert } from 'src/utilities/display-alert';

export interface SubstanceView extends AbstractEntityView<SubstanceViewEditItem> {
  supplierUuid: string;
  relatedRiskAssessments: RelatedRiskAssessment[];
  location: string;
  supplierName: string;
  notes: string;
  applications: string;
  hazardLabels: string[];
  archived: boolean;
  type: 'substances';
  name: string;
  availablePartners: SelectDropdownOption[];
  pdf: Promise<string>;
  unreadable: boolean;
}

export interface SubstanceViewEditItem {
  name: string;
  hazardLabels: string[];
  archived: boolean;
  notes: string;
  applications: string;
  supplierUuid: string;
  location: string;
}

/**
 *
 */
@customElement('d-substance-view')
export class DSubstanceView extends EntityContent<SubstanceView, SubstanceViewEditItem> {
  static readonly styles = [
    ...EntityContent.styles,
    css`
      d-view-document {
        min-height: 1000px;
      }
    `,
  ];

  @property({ type: Boolean, attribute: 'no-animation' })
  noAnimation = false;

  @state()
  private pdfLink = '';
  private pdfLinkSubstanceUuid = '';
  @query('#fileInput')
  private fileInput!: HTMLInputElement;

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    if (!this.entityView.unreadable) {
      builder.addText('substances_archived', '', 'l', this.entityView.archived);

      builder.addCustom(() => {
        return html`<d-wrap
          >${this.entityView.hazardLabels.map(
            (label) => html`<d-ghs-hazard-label .type=${label}></d-ghs-hazard-label>`,
          )}</d-wrap
        >`;
      });

      builder.addText('substances_applications', this.entityView.applications, 'l', true);
      builder.addText('substances_supplier', this.entityView.supplierName, 'l', true);
      builder.addText('substances_location', this.entityView.location, 'l', true);

      builder.addText('substances_notes', this.entityView.notes, 'l', true);
    }
    return builder.build();
  }

  private get hazardLabelOptions(): SelectTagOption[] {
    return ghsLabels().map(function (item) {
      return { value: item.type, text: item.name };
    });
  }

  _suggestedRiskAssessments(hazardLabels, suggestedDocuments) {
    return hazardLabels.map(function (label) {
      return suggestedDocuments.filter(function (template) {
        return template.hazardLabel === label;
      })[0];
    });
  }

  _riskAssessmentClasses(r) {
    if (r.suggestion) {
      return 'sticker suggestion';
    }
    if (r.risk) {
      const risk = r.risk;
      if (risk > 0 && risk < 5) {
        return 'sticker risk1';
      }
      if (risk > 4 && risk < 9) {
        return 'sticker risk2';
      }
      if (risk > 8 && risk < 13) {
        return 'sticker risk3';
      }
      if (risk > 12) {
        return 'sticker risk4';
      }
    }
  }

  _getPhrases(phrases, codes) {
    let result = '';
    phrases.forEach(function (item) {
      if (codes.indexOf(item.id) > -1) {
        result += item.norwegian.replaceAll('[', '(').replaceAll(']', ')') + ' ';
      }
    });
    return result;
  }

  _supplierName(organization, item) {
    if (item.supplierUuid && organization.partnersById[item.supplierUuid]) {
      return organization.partnersById[item.supplierUuid].name;
    }
    return '';
  }

  _unreadable(item) {
    return item.uploadStatus === 'UNREADABLE';
  }

  renderPreContent() {
    const gesture: GestureType[] = ['blink', 'surprised'];
    return this.entityView.unreadable
      ? html`<d-section>
          <d-inline-robot-alert .gesture=${gesture} field="substances_unreadable"></d-inline-robot-alert>
        </d-section>`
      : html``;
  }

  asListSectionItemInputs(list: RelatedRiskAssessment[]): ListSectionItemInput[] {
    return list.map((l) => {
      const clickHandler = l.suggestion
        ? () => {
            const id = uuid();
            const input: CreateRiskAssessmentInput = {
              entityType: 'riskAssessments',
              entityUuid: id,
              pageId: 279,
              targetUrl: this.entityView.href + '/riskAssessments/' + id + '?edit',
              name: l.name,
              templateId: l.templateId,
              substanceUuid: this.entityView.uuid,
            };
            this.dispatchEvent(
              new CustomEvent<CreateEntityInput>('create-entity', {
                bubbles: true,
                composed: true,
                detail: input,
              }),
            );
          }
        : undefined;
      const href = l.suggestion ? undefined : this.entityView.href + '/riskAssessments/' + l.uuid;
      return {
        accessible: true,
        label: l.name,
        updateStatus: l.suggestion ? 'suggestion' : 'none',
        riskAssessmentRisk: riskLevelFromRisk(l.risk),
        href,
        clickHandler,
        hasDraft: l.hasDraft,
      };
    });
  }

  renderLists(): TemplateResult<1> {
    return html`<d-list-section-related-risk-assessments
      field="substances_riskAssessments"
      .items=${this.asListSectionItemInputs(this.entityView.relatedRiskAssessments)}
      .contentStickyTop=${this.contentStickyTop}
      .relatedItemUuid=${this.entityView.uuid}
      .relatedItemHref=${this.entityView.href}
    ></d-list-section-related-risk-assessments> `;
  }

  renderAfterListsContent() {
    return html`<d-view-document
        ?no-animation=${this.noAnimation}
        label="Sikkerhetsdatablad"
        .href=${this.pdfLink}
        .downloadHref=${this.pdfLink}
        .name=${this.entityView.name}
      ></d-view-document>
      <input
        accept=".pdf"
        id="fileInput"
        style="display: none;"
        @change=${(e) => this._handleFileInputChange(e)}
        type="file"
      /> `;
  }

  _handleFileInputChange(e: InputEvent) {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files = target.files;

    this._handleFiles(files);
  }

  _handleFiles(files) {
    console.log('files', files);
    this.dispatchEvent(
      new CustomEvent<{ files: File[]; substanceId: string; callback: (_data: UploadedFile[]) => void }>(
        'update-substance-file',
        {
          composed: true,
          bubbles: true,
          detail: {
            files: files,
            substanceId: this.entityView.uuid,
            callback: (data) => {
              if (data.length === 1 && data[0].duplicate) {
                if (data[0].uuid === this.entityView.uuid) {
                  displayAlert('Ingen endring. Allerede oppdatert.');
                } else {
                  displayAlert('Dette databladet er allerede registrert på ett annet stoff.');
                }
              }
            },
          },
        },
      ),
    );
  }

  async initializeEditItem() {
    this.editItem = {
      applications: this.entityView.applications,
      archived: this.entityView.archived,
      hazardLabels: this.entityView.hazardLabels,
      location: this.entityView.location,
      notes: this.entityView.notes,
      supplierUuid: this.entityView.supplierUuid,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: SubstanceViewEditItem): TemplateResult<1> {
    return html` <d-section>
        <d-edit-text
          field="substances_name"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-select-tag
          field="substances_hazardLabels"
          .options=${this.hazardLabelOptions}
          .value=${item.hazardLabels}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, hazardLabels: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>
      <d-section>
        <d-edit-textarea
          field="substances_applications"
          .value=${item.applications}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, applications: e.detail.value };
          }}
        ></d-edit-textarea>
      </d-section>
      <d-section>
        <d-select-dropdown
          field="substances_supplier"
          .options="${this.entityView.availablePartners}"
          .value=${item.supplierUuid}
          placeholder="Velg leverandør"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, supplierUuid: e.detail.value };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-section>
        <d-edit-text
          field="substances_location"
          .value=${item.location}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, location: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-edit-textarea
          field="substances_notes"
          .value=${item.notes}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, notes: e.detail.value };
          }}
        ></d-edit-textarea>
      </d-section>
      <d-section>
        <d-checkbox
          option-field="substances_archived"
          ?checked=${item.archived}
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            this.editItem = { ...item, archived: e.detail.checked };
          }}
        ></d-checkbox>
      </d-section>

      ${this.renderAfterListsContent()}`;
  }

  mainHeaderMoreActions(): ActionInput[] {
    return [
      ...super.mainHeaderMoreActions(),
      {
        name: 'Oppdater datablad PDF',
        action: 'updatePdf',
      },
    ];
  }

  protected handleAction(action: string) {
    if (action === 'updatePdf') {
      this.fileInput.value = '';
      this.fileInput.click();
    }
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected updated(changedProperties: PropertyValues<this>) {
    super.updated(changedProperties);
    if (changedProperties.has('entityView')) {
      this.updatePdfLink();
    }
  }

  protected renderAttachments(): TemplateResult<1> {
    return html``;
  }

  private updatePdfLink() {
    if (this.pdfLinkSubstanceUuid !== this.entityView.uuid) {
      this.pdfLinkSubstanceUuid = this.entityView.uuid;
      this.pdfLink = '';
    }
    this.entityView.pdf
      .then((p) => (this.pdfLink = p))
      .catch((e) => {
        Bugsnag.notify(e);
        this.pdfLink = '';
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-substance-view': DSubstanceView;
  }
}
