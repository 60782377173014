import type { EventOccurrenceViewModel, FunctionViewModel, TaskViewModel } from '../api';
import { endYear, EventModel, ShortNameEntry } from 'src/store';
import { sortBy } from 'lodash';
import { LocalDate } from 'src/utilities/local-date';
import { RecurrenceRule } from '../recurrence-rule/recurrence-rule';
import type { OrganizationState } from '../types';
import { listNamesAsText } from './employee-selector-functions';
import { ContactsAsUsers, listContactNamesWithPartnerNames } from './organization-partners';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { dateTimeDescription, eventAssigneesText } from 'src/store/utilities';

export interface Event {
  name?: string;
  uuid: string;
  rrule?: string;
  persistent?: boolean;
  employees?: string[];
  assets?: string[];
  functionUuid?: string;
  contacts?: string[];
  taskUuid?: string;
  doneDate?: string;
  doneByEmployeeUuid?: string;
  doneByContactPersonUuid?: string;
  taskProcedure?: string;
  notes?: string;
  meetingUuid?: string;
  meetingResponsible?: string;
  meetingAgendaCustom?: boolean;
  meetingAgenda?: string;
  accessControl?: string[];
  classification?: string;
}

export interface EventInstance {
  uuid: string;
  event: Event;
  time: string;
  sortTime?: string;
  dateAndTimeDisplay?: string;
  eventUuid: string;
  eventName: string;
  eventNotes?: string;
  href: string;
  dayTask: boolean;
  overdue?: boolean;
  persistent?: boolean;
  employees?: string[];
  employeeNames: string;
  functionUuid?: string;
  functionName: string;
  contacts?: string[];
  contactNames?: string;
  assignee?: string;
  myTask: boolean;
  timeOfDayDisplay: string;
  dateTimeForOrdering: string;
  doneDate?: string;
  doneByEmployeeUuid?: string;
  doneByContactPersonUuid?: string;
  doneText?: string;
  locked: boolean;
  accessible: boolean;
  hasDraft: boolean;
}
export interface ModelEvent {
  name: string;
  time: string;
  durationMinutes: number;
  assignedToFunction: string;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  notes: string;
  procedures: string;
  taskUuid: string;
  futureNotDoneOccurrences: Occurrence[];
}

export function isRestricted(event, currentEmployeeUuid) {
  return !(
    !event.classification ||
    event.classification === 'NONE' ||
    event.accessControl?.includes(currentEmployeeUuid)
  );
}

function findClosestInstance(occurrences: EventOccurrenceViewModel[], date: LocalDate): EventOccurrenceViewModel {
  const datedOccurrences = occurrences.filter((o) => o.date !== undefined);
  const sortedDatedOccurrences = sortBy(datedOccurrences, (o) => LocalDate.fromString(o.date));
  const previous = sortedDatedOccurrences.findLast((o) => LocalDate.fromString(o.date).isSameOrBefore(date));
  if (previous !== undefined) return previous;
  const next = sortedDatedOccurrences.find((o) => LocalDate.fromString(o.date).isAfter(date));
  if (next !== undefined) return next;
  const undated = occurrences.find((o) => o.date === undefined);
  if (undated === undefined) {
    throw new Error('No occurrence found in EventModel');
  }
  return undated;
}

export function getModelEvents(
  events: EventModel[],
  eventOccurrences: EventOccurrenceViewModel[],
  tasks: TaskViewModel[],
  functionsNotDeleted: FunctionViewModel[],
  employeesShortNamesAndI: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
  relatedAssetForNew: string,
): ModelEvent[] {
  const now = LocalDate.now();
  const eventModels: ModelEvent[] = events.map((e) => {
    const o = findClosestInstance(e.eventOccurrences, now);
    return {
      name: o.name ?? '',
      durationMinutes: o.durationMinutes,
      assignedToFunction: o.functionUuid ?? '',
      assignedToEmployees: o.employees ?? [],
      assignedToContacts: o.contacts ?? [],
      notes: o.notes,
      time: o.time || 'NONE',
      procedures: o.description ?? '',
      taskUuid: o.taskUuid ?? '',
      futureNotDoneOccurrences: sortBy(
        e.eventOccurrences
          .filter((o) => {
            return !o.doneDate && (o.date === '' || LocalDate.fromString(o.date).isSameOrAfter(now));
          })
          .map((o) => {
            let assetRelated = false;
            if (
              o.relatedAssets?.find((a) => {
                return a.assetUuid === relatedAssetForNew;
              }) !== undefined
            ) {
              assetRelated = true;
            }
            return {
              occurrenceUuid: o.uuid,
              dateTime: (o.date ?? '') + (o.time ?? ''),
              durationMinutes: o.durationMinutes,
              classified: false,
              restricted: false,
              displayDate: dateTimeDescription(o.date ?? '', o.time ?? ''),
              assignees: eventAssigneesText(e, functionsNotDeleted, employeesShortNamesAndI, contactsAsUsers),
              assetRelated,
            };
          }),
        (x) => x.dateTime,
        (x) => x.occurrenceUuid,
      ),
    };
  });

  const taskModels = tasks
    .filter((t) => {
      return (
        eventOccurrences.find((e) => {
          return e.taskUuid === t.uuid;
        }) === undefined
      );
    })
    .map((t) => {
      return {
        name: t.name ?? '',
        time: 'NONE',
        durationMinutes: 0,
        assignedToFunction: '',
        assignedToEmployees: [],
        assignedToContacts: [],
        notes: '',
        procedures: t.procedures ?? '',
        taskUuid: t.uuid,
        futureNotDoneOccurrences: [],
      };
    });

  const modelEvents = [...eventModels, ...taskModels];

  return sortBy(modelEvents, ['name']);
}
export function doneInformation(
  event: { doneDate?: string; doneByEmployeeUuid?: string; doneByContactPersonUuid?: string },
  singleUserVersion: boolean,
  employeeNamesMe: { uuid: string; status: string; name: string }[],
  contactsAsUsers: ContactsAsUsers[],
) {
  let sortTime = '1000-01-01';
  let doneText = '';
  if (event.doneDate && event.doneByEmployeeUuid) {
    let doneByText = '';
    sortTime = event.doneDate;
    if (!singleUserVersion) {
      const employeesListDone = [event.doneByEmployeeUuid];
      const employeeNamesListDone = listNamesAsText(employeeNamesMe, employeesListDone);
      doneByText = ' av ' + employeeNamesListDone;
    }
    doneText = 'merket som utført ' + LocalDate.fromString(event.doneDate).toStringForDisplay() + doneByText;
  }
  if (event.doneDate && event.doneByContactPersonUuid) {
    let doneByText = '';
    sortTime = event.doneDate;
    if (!singleUserVersion) {
      const contactsListDone = [event.doneByContactPersonUuid];
      const contactNamesListDone = listContactNamesWithPartnerNames(contactsListDone, contactsAsUsers);
      doneByText = ' av ' + contactNamesListDone;
    }
    doneText = 'merket som utført ' + LocalDate.fromString(event.doneDate).toStringForDisplay() + doneByText;
  }
  return { doneText, sortTime };
}

export function taskEventTodoItemsFunction(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState,
  employeeNamesI: { uuid: string; status: string; name: string }[],
  employeeNamesMe: { uuid: string; status: string; name: string }[],
  functions,
  contacts: ContactsAsUsers[],
  organizationId: number,
  currentUserUuid: string,
  singleUserVersion: boolean,
): EventInstance[] {
  const result: EventInstance[] = [];
  events.forEach(function (event) {
    if (event.date === null) {
      let functionName = '';
      let employeeNamesDisplay = '';
      let myTask = true;
      if (!singleUserVersion) {
        const employeesList = event.employees ?? [];
        const employeeNamesList = listNamesAsText(employeeNamesI, employeesList);
        employeeNamesDisplay = employeeNamesList.charAt(0).toUpperCase() + employeeNamesList.slice(1);
        if (event.functionUuid) {
          functionName = organization.functionsById[event.functionUuid].name ?? '';
        }
        myTask = employeesList.includes(currentUserUuid) || event.functionUuid !== '';
      }
      if (event.contacts && event.contacts.length) {
        myTask = event.contacts.includes(currentUserUuid);
      }

      const __ret = doneInformation(event, singleUserVersion, employeeNamesMe, contacts);
      const doneText = __ret.doneText;
      const sortTime = __ret.sortTime;

      const occurrence = event;
      if (occurrence) {
        result.push({
          uuid: occurrence.uuid,
          event,
          time: '',
          sortTime: sortTime,
          dateAndTimeDisplay: 'Snarest',
          eventUuid: event.uuid,
          eventName: event.name !== undefined ? event.name : '',
          eventNotes: '', // TODO event.notes,
          href: '/account/' + organizationId + '/' + 2300 + '/eventOccurrences/' + occurrence.uuid,
          dayTask: false,
          employees: event.employees,
          employeeNames: employeeNamesDisplay,
          functionUuid: event.functionUuid,
          functionName: functionName,
          contacts: event.contacts,
          assignee: eventAssigneesText(event, functions, employeeNamesI, contacts),
          myTask: myTask,
          timeOfDayDisplay: '',
          dateTimeForOrdering: '1000-01-01 00:00',
          doneDate: event.doneDate,
          doneByEmployeeUuid: event.doneByEmployeeUuid,
          doneByContactPersonUuid: event.doneByContactPersonUuid,
          doneText: doneText,
          accessible: true,
          locked: false,
          hasDraft: occurrence.hasDraft,
        });
      }
    }
  });
  return result;
}

export function eventOccurrences(event, currentEmployeeUuid) {
  const result: Occurrence[] = [];
  if (event.rrule !== undefined && event.rrule !== '') {
    const r = RecurrenceRule.fromString(event.rrule);
    const start = LocalDate.fromString('1000-01-01');
    const end = LocalDate.fromString(endYear + '-12-31');
    let x = r.sameOrAfter(start);
    let timeOfDay = '';
    if (r.hasTime()) {
      timeOfDay = ' ' + r.timeDisplay();
    }
    while (x?.isSameOrBefore(end)) {
      result.push({
        occurrenceUuid: event.uuid,
        dateTime: x.toString() + timeOfDay,
        durationMinutes: event.durationMinutes,
        classified: event.classification !== 'NONE',
        restricted: isRestricted(event, currentEmployeeUuid),
      });
      x = r.after(x);
    }
  } else {
    let dateTime = '';
    if (event.doneDate) {
      dateTime = event.doneDate;
    }
    return [
      {
        uuid: event.uuid,
        dateTime,
        classified: event.classification !== 'NONE',
        restricted: isRestricted(event, currentEmployeeUuid),
      },
    ];
  }
  return result;
}

export function taskEventInstancesFunction(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState,
  employeeNamesI: { uuid: string; status: string; name: string }[],
  employeeNamesMe: { uuid: string; status: string; name: string }[],
  contacts: ContactsAsUsers[],
  functions: FunctionViewModel[],
  organizationId: number,
  currentUserUuid: string,
  today: LocalDate,
  singleUserVersion: boolean,
  context: string,
): EventInstance[] {
  const result: EventInstance[] = [];
  events.forEach((event: EventOccurrenceViewModel): void => {
    if (event.date !== null) {
      let functionName = '';
      let employeeNamesDisplay = '';
      let myTask = true;
      if (!singleUserVersion) {
        const employeesList = event.employees ?? [];
        if (event.functionUuid) {
          functionName = organization.functionsById[event.functionUuid].name ?? '';
        }
        const employeeNamesList = listNamesAsText(employeeNamesI, employeesList);
        employeeNamesDisplay = employeeNamesList.charAt(0).toUpperCase() + employeeNamesList.slice(1);
        myTask =
          employeesList.includes(currentUserUuid) ||
          (event.contacts && event.contacts.length > 0 && event.contacts.includes(currentUserUuid)) ||
          event.functionUuid !== '';
      }
      const locked = false;
      const accessible = true;
      const dayTask = event.time === undefined;
      let hour = event.time ?? '';
      if (hour !== '') {
        hour = ' kl. ' + hour.replace(':', '.');
      }
      const date = LocalDate.fromString(event.date).toStringForDisplayWithDayOfWeekAndYear();
      const dateAndTimeDisplay = date.charAt(0).toUpperCase() + date.slice(1) + hour;
      let sortTime = event.date ?? '';
      let doneText = '';
      if (event.doneDate && event.doneByEmployeeUuid) {
        let doneByText = '';
        sortTime = event.doneDate;
        if (!singleUserVersion) {
          const employeesListDone = [event.doneByEmployeeUuid];
          const employeeNamesListDone = listNamesAsText(employeeNamesMe, employeesListDone);
          doneByText = ' av ' + employeeNamesListDone;
        }
        doneText = 'merket som utført ' + LocalDate.fromString(event.doneDate).toStringForDisplay() + doneByText;
      }
      let overdue = false;
      if (
        event.persistent &&
        LocalDate.fromString(event.date).isBefore(today) &&
        !event.doneDate &&
        !event.doneByEmployeeUuid
      ) {
        overdue = true;
      }
      result.push({
        event,
        uuid: '', // TODO
        hasDraft: false, // TODO
        eventUuid: event.uuid,
        eventName: event.name !== undefined ? event.name : '',
        eventNotes: '', // TODO event.notes,
        href: '/account/' + organizationId + '/' + context + '/eventOccurrences/' + event.uuid,
        time: (event.date ?? '') + event.time,
        sortTime: sortTime,
        dateAndTimeDisplay: dateAndTimeDisplay,
        dayTask: dayTask,
        overdue: overdue,
        employees: event.employees,
        employeeNames: employeeNamesDisplay,
        functionUuid: event.functionUuid,
        functionName: functionName,
        contacts: event.contacts,
        assignee: eventAssigneesText(event, functions, employeeNamesI, contacts),
        myTask: myTask,
        timeOfDayDisplay: dayTask ? '' : event.time ?? '', // TODO r.timeDisplay(),
        dateTimeForOrdering: (event.date ?? '') + event.time,
        doneDate: event.doneDate,
        doneByEmployeeUuid: event.doneByEmployeeUuid,
        doneByContactPersonUuid: event.doneByEmployeeUuid,
        doneText: doneText,
        locked: locked,
        accessible: accessible,
      });
    }
  });
  return sortBy(result, [(item) => item.time]);
}
export function taskEventsInstances(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState | undefined,
  employeeNamesI: ShortNameEntry[],
  employeeNamesMe: ShortNameEntry[],
  functions: FunctionViewModel[],
  contacts: ContactsAsUsers[],
  organizationid: number,
  currentUserUuid: string | undefined,
  singleUserVersion: boolean,
  today: LocalDate,
): EventInstance[] {
  if (organization === undefined) {
    return [];
  }
  const todoItems = taskEventTodoItemsFunction(
    events,
    organization,
    employeeNamesI,
    employeeNamesMe,
    functions,
    contacts,
    organizationid,
    currentUserUuid ? currentUserUuid : '',
    singleUserVersion,
  );
  const datedItems = taskEventInstancesFunction(
    events,
    organization,
    employeeNamesI,
    employeeNamesMe,
    contacts,
    functions,
    organizationid,
    currentUserUuid ? currentUserUuid : '',
    today,
    singleUserVersion,
    '2300',
  );
  return todoItems.concat(datedItems);
}
