import { html, nothing, TemplateResult } from 'lit';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/editors/components/d-edit-classification.js';
import { customElement, property } from 'lit/decorators.js';
import type { FormViewItem } from '../../library/components/d-form-view.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface GuidelineViewEditItem {
  pages: string[];
  content: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  name: string;
}

export interface GuidelineView extends AbstractEntityViewWithRevisions<GuidelineViewEditItem>, UpdateSectionItem {
  isEmployee: boolean;
  pages: string[];
  availablePages: SelectTagOption[];
  type: 'guidelines';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
}

/**
 *
 */
@customElement('d-guideline-view')
export class DGuidelineView extends EntityContent<GuidelineView, GuidelineViewEditItem> {
  static readonly styles = EntityContent.styles;
  @property({ type: String })
  entityType = 'guidelines';
  @property({ type: Boolean })
  helpOpened = false;

  get helpOnly() {
    return (
      this.helpContent !== '' &&
      (!this.entityView.content || this.entityView.content === '<p><br></p>') &&
      this.mode === 'normal'
    );
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addHtmlContentIfNotEmpty('', this.entityView.content);
    return builder.build();
  }

  async initializeEditItem() {
    this.editItem = {
      pages: this.entityView.pages,
      content: this.entityView.content,
      classification: this.entityView.classification,
      accessControl: this.entityView.accessControl,
      name: this.entityView.name,
    };
  }

  _setHelpOpened(newValue) {
    setTimeout(() => {
      if (newValue) {
        this.helpOpened = true;
      } else {
        this.helpOpened = false;
      }
    }, 0);
  }

  createNewItem() {
    return {
      content: '',
      template: '',
      category: '',
      status: '',
      name: '',
      accessControl: [],
      classification: 'NONE',
      pages: [this.pageId],
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: GuidelineViewEditItem): TemplateResult<1> {
    return html` ${this.entityView.isEmployee
        ? html` <d-section>
            <d-edit-classification
              .accessControl=${item.accessControl}
              .accessControlOptions=${this.entityView.accessControlOptions}
              .classification=${item.classification}
              @value-changed=${(
                e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>,
              ) => {
                this.editItem = {
                  ...item,
                  accessControl: e.detail.accessControl,
                  classification: e.detail.classification,
                };
              }}
            ></d-edit-classification>
          </d-section>`
        : nothing}

      <d-section>
        <d-edit-text
          field="guidelines_name"
          .autofocus=${this.entityView.isNew}
          .selectOnFocus=${this.entityView.isNew}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value="${item.name}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-edit-html
        field="guidelines_content"
        .value="${item.content}"
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      >
      </d-edit-html>

      <d-section>
        <d-select-tag
          label="Vis på temaside"
          not-deselectable
          .options=${this.entityView.availablePages}
          .value=${item.pages}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, pages: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>`;
  }

  /*
    render() {
      return html`
        <d-view-classification
          access-control="${this.item.accessControl}"
          classification="${this.item.classification}"
        ></d-view-classification>
        <!--
                  set writeAccess false, revisionsurl = "" and delete rest of view for other users
                  -->

        ${this.helpOnly
          ? nothing
          : html`<d-section>
              <d-view-html
                collapse-height="400"
                organization-id="${this.organizationId}"
                pages="${this.pages}"
                value="${this.item.content}"
              ></d-view-html>
            </d-section>`}
      `;
    }

   */

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-guideline-view': DGuidelineView;
  }
}
