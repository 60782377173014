import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

export type GestureType = 'roll' | 'blink' | 'peekRight' | 'readHorizontal' | 'upload' | 'happy' | 'surprised';

/**
 *
 * STATUS OK
 */
@customElement('d-spinner-robot')
export class DSpinnerRobot extends LitElement {
  @property({ type: Number })
  size = 60;
  /**
   * The gestures to display. For attribute use "blink|roll"
   */
  @property({
    type: Array,
    converter: (value) => {
      const a = (value ?? '')
        .split('|')
        .filter((e) => e !== '')
        .map((e) => e as GestureType);
      console.log(a);
      return a;
    },
  })
  gesture: GestureType[] = ['roll'];

  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;

  static readonly styles = css`
    :host {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :host([no-animation]),
    :host([no-animation]) .roll .pupil,
    :host([no-animation]) .blink .eye,
    :host([no-animation]) .readHorizontal .pupil,
    :host([no-animation]) .peekRight .pupil,
    :host([no-animation]) .upload .pupil {
      animation: none !important;
    }

    #wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .head {
      fill: #15ade5;
    }

    .eye {
      fill: white;
    }

    .pupil {
      fill: #15ade5;
    }

    .mouth {
      fill: black;
      opacity: 0;
    }

    .happy .mouth.happy {
      opacity: 0.4;
    }

    .surprised .mouth.surprised {
      opacity: 0.4;
    }

    .blink .eye {
      animation: blink 5s infinite both;
    }

    @keyframes blink {
      56.999% {
        opacity: 1;
      }
      57% {
        opacity: 0;
      }
      59.999% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      96.999% {
        opacity: 1;
      }
      97% {
        opacity: 0;
      }
      99.999% {
        opacity: 0;
      }
    }

    @-webkit-keyframes blink {
      56.999% {
        opacity: 1;
      }
      57% {
        opacity: 0;
      }
      59.999% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      96.999% {
        opacity: 1;
      }
      97% {
        opacity: 0;
      }
      99.999% {
        opacity: 0;
      }
    }

    .peekRight .pupil {
      animation: peek-right 5s infinite both;
    }

    @keyframes peek-right {
      59.999% {
        transform: translateX(0);
      }
      60% {
        transform: translateX(6px);
      }
      96.999% {
        transform: translateX(6px);
      }
      97% {
        transform: translateX(0);
      }
    }

    @-webkit-keyframes peek-right {
      59.999% {
        transform: translateX(0);
      }
      60% {
        transform: translateX(6px);
      }
      96.999% {
        transform: translateX(6px);
      }
      97% {
        transform: translateX(0);
      }
    }

    .roll .pupil {
      animation: roll 1s linear infinite;
    }

    @keyframes roll {
      from {
        transform: rotate(0deg) translateX(7px) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translateX(7px) rotate(-360deg);
      }
    }

    @-webkit-keyframes roll {
      from {
        transform: rotate(0deg) translateX(7px) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translateX(7px) rotate(-360deg);
      }
    }

    .readHorizontal .pupil {
      animation: readHorizontal 0.6s linear infinite;
    }

    @keyframes readHorizontal {
      0% {
        transform: translateX(-7px);
      }
      90% {
        transform: translateX(7px);
      }
    }

    @-webkit-keyframes readHorizontal {
      0% {
        transform: translateX(-7px);
      }
      90% {
        transform: translateX(7px);
      }
    }

    .upload .pupil {
      left: 0.05em;
      top: 0.1em;
      animation: upload 0.6s linear infinite;
    }

    @keyframes upload {
      0% {
        transform: translateY(7px);
      }
      90% {
        transform: translateY(-7px);
      }
    }

    @-webkit-keyframes upload {
      0% {
        transform: translateY(7px);
      }
      90% {
        transform: translateY(-7px);
      }
    }
  `;

  render() {
    return html`
      <div
        id="wrapper"
        class="${classMap({
          roll: this.gesture.includes('roll'),
          blink: this.gesture.includes('blink'),
          peekRight: this.gesture.includes('peekRight'),
          readHorizontal: this.gesture.includes('readHorizontal'),
          upload: this.gesture.includes('upload'),
          happy: this.gesture.includes('happy'),
          surprised: this.gesture.includes('surprised'),
        })}"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="${this.size}" height="${this.size}" viewBox="0 0 100 100">
          <rect class="head" width="100" height="100" rx="20" />
          <circle class="eye" cx="28.75" cy="32.5" r="12.5" />
          <circle class="eye" cx="71.25" cy="32.5" r="12.5" />
          <circle class="pupil" cx="28.75" cy="32.5" r="7.5" />
          <circle class="pupil" cx="71.25" cy="32.5" r="7.5" />
          <path class="mouth happy" d="M27.65 62.5a22.49 22.49 0 0 0 44.7 0Z" />
          <circle class="mouth surprised" cx="49.5" cy="66.28" r="7.5" />
        </svg>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-spinner-robot': DSpinnerRobot;
  }
}
