import { LocalDateTime } from 'src/utilities/local-date-time.js';
import type { PageViewModel, RiskAssessmentViewModel, SubstanceViewModel } from 'src/store/api';
import type { OrganizationState, State } from 'src/store/types.js';
import type { SubstancesPageViewModel } from 'src/pages/substances-page/substances-page-view-model.js';
import * as dabihStore from 'src/store';
import { currentEmployeeUuid, getOrganization, key, riskAssessmentsNotDeleted } from 'src/store';
import type { TableItemsSubstance } from 'src/pages/substances-page/d-substances-table.js';

export function substancesPageView(
  hrefPrefix: string,
  viewModel: PageViewModel,
  state: State,
): SubstancesPageViewModel {
  const organization = getOrganization(state);
  if (organization === undefined) {
    throw new Error('Illegal state (E154), organization not found');
  }

  const riskAssessments = riskAssessmentsNotDeleted(state);

  const organizationId = dabihStore.getOrganizationId(state);
  return {
    type: 'substances-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: dabihStore.writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: organizationId,
    icon: 'substances',
    substances: dabihStore
      .substancesNotDeleted(state)
      .filter((s) => s.archived === false)
      .map((s) => {
        return toTableItemSubstance(hrefPrefix, s, organization, riskAssessments);
      }),
    substancesArchived: dabihStore
      .substancesNotDeleted(state)
      .filter((s) => s.archived === true)
      .map((s) => {
        return toTableItemSubstance(hrefPrefix, s, organization, riskAssessments);
      }),
    key: dabihStore.key(state),
    pdfHref: '/api' + '/organizations/' + organizationId + '/substances/substances-list.pdf?key=' + key(state),
    displayResetControls: [2668, 2634, 2837, 3346, 3625, 3636, 3798, 3886].includes(organizationId),
  };
}

function toTableItemSubstance(
  hrefPrefix: string,
  substance: SubstanceViewModel,
  organization: OrganizationState,
  riskAssessments: RiskAssessmentViewModel[],
): TableItemsSubstance {
  const toState = () => {
    if (substance.uploadStatus === 'PENDING' || substance.uploadStatus === 'PRELIMINARY') {
      return 'pending';
    } else if (substance.uploadStatus === 'UNREADABLE') {
      return 'unreadable';
    } else return '';
  };

  const toSupplier = () => {
    if (substance.supplierUuid) {
      return organization.partnersById[substance.supplierUuid].name;
    } else {
      return '';
    }
  };

  const toRisk = () => {
    const risks = riskAssessments
      .filter((item) => item.relatedSubstance === substance.uuid)
      .map((item) => (item.probability ?? 0) * (item.consequence ?? 0));
    if (risks.length === 0) return '';
    const highestRisk = Math.max(...risks);
    if (highestRisk) {
      if (highestRisk > 0 && highestRisk < 5) {
        return '1';
      }
      if (highestRisk > 4 && highestRisk < 9) {
        return '2';
      }
      if (highestRisk > 8 && highestRisk < 13) {
        return '3';
      }
      if (highestRisk > 12) {
        return '4';
      }
    }
    return '';
  };

  const result: TableItemsSubstance = {
    state: toState(),
    uploaded: toUploadedDisplay(substance.uploadDateTime),
    uploadedSortValue: substance.uploadDateTime ?? '',
    name: substance.name ?? '',
    supplier: toSupplier(),
    hazardLabels: substance.hazardLabels ?? [],
    applications: substance.applications ?? '',
    location: substance.location ?? '',
    risk: toRisk(),
    href: hrefPrefix + 'substances/' + substance.uuid,
    uuid: substance.uuid,
    hasDraft: substance.hasDraft,
  };
  return result;
}

function toUploadedDisplay(dateTime?: string): string {
  if (dateTime === undefined || dateTime === '') {
    return '';
  }
  return LocalDateTime.fromIsoString(dateTime).toStringForDisplay();
}
