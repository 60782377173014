import type { PageViewModel } from 'src/store/api';
import type { State } from 'src/store/types.js';
import type { ReferencesPageViewModel } from 'src/pages/references-page/references-page-view-model.js';
import * as dabihStore from 'src/store';
import { currentEmployeeUuid, references } from 'src/store';

export function referencesPageView(
  hrefPrefix: string,
  viewModel: PageViewModel,
  state: State,
): ReferencesPageViewModel {
  return {
    type: 'references-page',
    href: hrefPrefix,
    name: viewModel.name,
    helpContent: viewModel.helpContent,
    writeAccess: dabihStore.writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: dabihStore.getOrganizationId(state),
    references: references(state),
  };
}
