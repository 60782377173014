import { css, html } from 'lit';
import './d-organization-subscription.js';
import './d-organization-organizations.js';
import './d-organization-profile.js';
import './d-organization-users.js';
import '../../library/fields/d-closer.js';
import { customElement, property } from 'lit/decorators.js';
import type { EmployeeViewModelWithName } from 'src/store/types.js';
import '../../library/fields/d-expansion.js';
import type { OrganizationReference } from 'src/store/api';
import type { Subscription } from './d-organization-subscription.js';
import type { ProfileUser } from './d-organization-profile.js';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access.js';
import { ResponsiveContainer } from 'src/library/elements/responsive-container.js';
import { headerViewStyles } from './header-view-styles.js';

export interface SettingsMenuItem {
  name: string;
  id: string;
}

export interface ProfileSettingsItem extends SettingsMenuItem {
  type: 'profile';
  isEmployee: boolean;
  user: ProfileUser;
  alerts: 'ALL' | 'SCHEDULED' | 'NONE';
  reminders: 'NONE' | 'SMS';
}

export interface UsersSettingsItem extends SettingsMenuItem {
  type: 'users';
  users: UserForAccess[];

  ownerEmail: string;

  currentUserEmail: string;
}

export interface SubscriptionSettingsItem extends SettingsMenuItem {
  type: 'subscription';
  subscription: Subscription;
  administratorEmployeesList: {
    name: string;
    email: string;
  }[];
  singleUserVersionAvailable: boolean;
  elmaLookup: (q: string) => Promise<{ text: string; value: string }[]>;
}

export interface OrganizationsSettingsItem extends SettingsMenuItem {
  type: 'organizations';
  organizations: OrganizationReference[];
}

export type SettingsItem =
  | ProfileSettingsItem
  | SubscriptionSettingsItem
  | OrganizationsSettingsItem
  | UsersSettingsItem;

/**
 * Display settings for current user and organization. The available settings are dependent on the access level of
 * the current user.
 *
 * @fires open-user-settings
 * @fires close-settings
 *
 */
@customElement('d-organization-settings')
export class DOrganizationSettings extends ResponsiveContainer {
  static readonly styles = [
    headerViewStyles,
    css`
      :host {
        display: block;
      }

      .meta-content-wrapper {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - var(--applicationHeaderHeight));
        max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight));
      }

      :host(.width600) .meta-content-wrapper {
        flex-direction: row;
        margin: 0 20px 20px 20px;
        max-height: calc(100vh - var(--applicationHeaderHeight) - 20px);
        max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight) - 20px);
      }

      d-closer {
        position: absolute;
        top: 10px;
        right: 16px;
        z-index: 100;
      }

      .menu {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        flex: none;
        box-sizing: border-box;
        padding: 8px 6px 8px 0;
        margin: 0 20px;
        border-bottom: 1px solid var(--borderColorOnBlue);
        background-color: var(--metaContentBackgroundColor);
      }

      :host(.width600) .menu {
        top: 14px;
        display: block;
        margin: 14px 0 20px 0;
        padding: 0;
        border-right: 1px solid var(--borderColorOnBlue);
        border-bottom: none;
      }

      .menu > *:hover,
      .menu > *:active,
      .menu > *[selected] {
        opacity: 1;
      }

      .menu > div,
      .menu > a {
        display: block;
        color: black;
        font-weight: 500;
        padding: 6px 12px 6px 0;
        cursor: pointer;
        opacity: 0.5;
      }

      :host(.width600) .menu > div,
      :host(.width600) .menu > a {
        margin: 0;
        padding: 4px 20px 4px 20px;
      }

      .page {
        flex-grow: 1;
        margin-top: -1px;
        font-family: var(--mainSans);
        font-weight: normal;
        font-size: 15px;
        text-transform: none;
        letter-spacing: normal;
        line-height: 160%;
        color: black;
        overflow-y: auto;
      }

      :host(.width600) .page {
        display: flex;
        flex-direction: column;
        padding-top: 6px;
        overflow: hidden;
      }

      .header {
        border-bottom: 1px solid var(--borderColorOnBlue);
        margin: 16px 20px 0 20px;
        padding-bottom: 14px;
        font-weight: bold;
        font-size: 24px;
        z-index: 1;
        display: none;
      }

      :host(.width600) .header {
        display: block;
      }

      .content {
        padding: 0 20px 30px 20px;
      }

      :host(.width600) .content {
        margin-top: -1px;
        overflow-y: auto;
      }
    `,
  ];
  @property({ type: Boolean, reflect: true })
  open = false;
  @property({ type: Object })
  user: any = {};
  @property({ type: Object })
  organization: any = {};
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Array })
  employees: EmployeeViewModelWithName[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  selectedPage = '';
  @property({ type: Array })
  settingsMenuItems: SettingsMenuItem[] = [];
  @property({ type: Object })
  settingsItem!: SettingsItem;

  get selectMenuItem() {
    return this.settingsMenuItems.find((item) => item.id === this.settingsItem.type);
  }

  _disableSmoothResize(open) {
    return !open;
  }

  _getEmployee(employees, username) {
    const compareStrings = function (string1, string2, ignoreCase, useLocale) {
      if (string1 && string2) {
        if (ignoreCase) {
          if (useLocale) {
            string1 = string1.toLocaleLowerCase();
            string2 = string2.toLocaleLowerCase();
          } else {
            string1 = string1.toLowerCase();
            string2 = string2.toLowerCase();
          }
        }
        return string1 === string2;
      }
      return false;
    };

    const areEqual = function (e1, e2) {
      return compareStrings(e1, e2, true, true);
    };

    const list = employees ?? [];

    const employees1 = list.filter(function (e) {
      return areEqual(e.email, username);
    });

    if (employees1.length > 0) {
      return employees1[0];
    }
  }

  _showOrganizations() {
    localStorage.removeItem('lastOrganizationId');
  }

  renderSettingsItem() {
    switch (this.settingsItem.type) {
      case 'organizations':
        return html`<d-organization-organizations
          .organizationId=${this.organizationId}
          .organizations=${this.settingsItem.organizations}
          .user="${this.user}"
        >
        </d-organization-organizations>`;
      case 'profile':
        return html`<d-organization-profile
          employee="${this._getEmployee(this.employees, this.user.username)}"
          @close-settings=${() => this.closeSettings()}
          organization="${this.organization}"
          .organizationId=${this.organizationId}
          .user=${this.settingsItem.user}
          .newEmail=${this.settingsItem.user.username}
          .isEmployee=${this.settingsItem.isEmployee}
          .alerts=${this.settingsItem.alerts}
          .receiveReminders=${this.settingsItem.reminders}
        >
        </d-organization-profile>`;
      case 'subscription':
        return this.renderSettings(this.settingsItem);
      case 'users':
        return this.renderUsers(this.settingsItem);
    }
  }

  render() {
    return html`
      <d-expansion ?opened=${this.open}>
        <div class="meta-content-wrapper">
          <d-closer @click=${this.closeSettings}></d-closer>
          <div class="menu">
            ${this.settingsMenuItems.map(
              (item) =>
                html`<div
                  @click=${() => this.openUserSettings(item.id)}
                  ?selected=${item.id === this.settingsItem.type}
                >
                  ${item.name}
                </div>`,
            )}
          </div>
          <div class="page">
            <div class="header">${this.selectMenuItem?.name}</div>
            <div class="content">${this.renderSettingsItem()}</div>
          </div>
        </div>
      </d-expansion>
    `;
  }

  private renderSettings(settingsItem: SubscriptionSettingsItem) {
    return html`<d-organization-subscription
      @update-subscription=${() => this.closeSettings()}
      .subscription=${settingsItem.subscription}
      .availableOwnerEmployees=${settingsItem.administratorEmployeesList.map((e) => {
        return { value: e.email, text: e.name };
      })}
      .singleUserVersionAvailable=${settingsItem.singleUserVersionAvailable}
      .elmaLookup=${async (q) => await settingsItem.elmaLookup(q)}
    >
    </d-organization-subscription>`;
  }

  private renderUsers(settingsItem1: UsersSettingsItem) {
    const currentUser = settingsItem1.users.find((u) => u.email === settingsItem1.currentUserEmail);
    if (currentUser === undefined) {
      throw new Error('Illegal state (E409), no current user');
    }
    return html`<d-organization-users
      .users=${settingsItem1.users}
      .accountOwnerEmail=${settingsItem1.ownerEmail}
      .currentUser=${currentUser}
    >
    </d-organization-users>`;
  }

  private closeSettings() {
    this.open = false;
    this.dispatchEvent(new CustomEvent('close-settings', { bubbles: true, composed: true }));
  }

  private openUserSettings(id: string) {
    this.dispatchEvent(
      new CustomEvent('open-user-settings', { bubbles: true, composed: true, detail: { settingsType: id } }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-settings': DOrganizationSettings;
  }
}
