import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-tooltip.js';
import '../../elements/d-label.js';
import './d-radio-cell.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

export interface Option {
  value: string;
  text: string;
}

/**
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-select-radio')
export class DSelectRadio extends LabeledElement {
  @property({ type: String })
  value = '';
  @property({ type: Array })
  options: Option[] = [];
  @property({ type: Boolean, reflect: true })
  vertical = false;
  @property({ type: Boolean, reflect: true })
  outskirts = false;

  static readonly styles = [
    ...LabeledElement.styles,
    css`
      .options {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -4px;
      }

      :host([vertical]) .options {
        display: block;
      }

      .option {
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
        min-height: var(--inputElementHeight);
        margin-bottom: 4px;
        padding: 6px 14px 0 0;
        cursor: pointer;
      }

      .option:last-child {
        padding-right: 0;
      }

      d-radio-cell {
        margin-top: -2px;
        margin-right: 6px;
        margin-left: -1px;
      }

      :host([outskirts]) .radioIcon {
        box-shadow: none;
      }

      .option[selected] .radioIcon:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--themeColor);
      }
    `,
  ];

  renderContent() {
    return html`
      <div class="options">
        ${this.options.map(
          (item) =>
            html` <div
              class="option"
              @click=${() => this.toggleSelect(item.value)}
              ?selected=${item.value === this.value}
            >
              <d-radio-cell ?checked=${item.value === this.value}></d-radio-cell>
              <d-label option label="${item.text}" allow-wrap> </d-label>
            </div>`,
        )}
      </div>
    `;
  }

  private toggleSelect(item: string) {
    if (this.value !== item) {
      this.value = item;
      this.dispatchEvent(
        new CustomEvent('value-changed', {
          bubbles: true,
          composed: true,
          detail: { value: this.value },
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-radio': DSelectRadio;
  }
}
