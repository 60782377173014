import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-spinner-robot.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import './d-substances-table.js';
import './d-substances-upload-feedback.js';
import type { SubstancesPageViewModel } from './substances-page-view-model.js';
import { cloneDeep } from 'lodash';

export interface UploadedFile {
  filename: string;
  duplicate: boolean;
  uuid: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-substances-page-content')
export class DSubstancesPageContent extends PageContent<SubstancesPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-expansion > div {
        position: relative;
      }

      .cancelUpload {
        padding-top: 20px;
        text-align: right;
        display: none;
      }

      .ready .cancelUpload {
        display: block;
      }

      .registerItemsPrompt {
        position: relative;
        display: none;

        background: transparent url(/images/substances-gray.svg) 50% 40px no-repeat;
        background-size: 200px 200px;

        width: 280px;
        margin: 40px auto;
        padding: 270px 20px 20px 20px;
        border: 5px dashed transparent;
        border-radius: 30px;
        text-align: center;
        opacity: 0.5;
        cursor: pointer;
        transition:
          background 0.3s,
          padding 0.3s;
      }

      .ready .registerItemsPrompt {
        display: block;
      }

      .registerItemsPrompt:hover,
      .registerItemsPrompt.hover {
        opacity: 0.8;
        border-color: silver;
      }

      .ready d-substances-upload-feedback {
        display: none;
      }

      #fileInput {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }

      .pendingAlert,
      .unreadableAlert {
        display: none;
      }

      .substanceAlert {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 8px 0;
      }

      #flexWrapper > d-ghs-hazard-label {
        flex: none;
        border-bottom: 1px solid var(--borderColor);
        margin-bottom: -1px;
      }

      #flexWrapper > d-ghs-hazard-label > .sectionElementInner {
        border-left: none;
      }

      .ghsLabel {
        width: 100px;
        padding-top: 104px;
        background-size: 95px 95px;
        background-position: 50% 0;
        background-repeat: no-repeat;
        text-align: center;
        hyphens: auto;
      }

      @media only screen and (max-width: 600px) {
        .ghsLabel {
          width: 95px;
          font-size: 14px;
        }
      }

      .maxWidth600 .ghsLabel {
        width: 95px;
        font-size: 14px;
      }

      .GHS01 {
        background-image: url(/images/ghs/ghs-01-explosive.svg);
      }

      .GHS02 {
        background-image: url(/images/ghs/ghs-02-flammable.svg);
      }

      .GHS03 {
        background-image: url(/images/ghs/ghs-03-oxidizing.svg);
      }

      .GHS04 {
        background-image: url(/images/ghs/ghs-04-compressed-gas.svg);
      }

      .GHS05 {
        background-image: url(/images/ghs/ghs-05-corrosive.svg);
      }

      .GHS06 {
        background-image: url(/images/ghs/ghs-06-toxic.svg);
      }

      .GHS07 {
        background-image: url(/images/ghs/ghs-07-harmful.svg);
      }

      .GHS08 {
        background-image: url(/images/ghs/ghs-08-health-hazard.svg);
      }

      .GHS09 {
        background-image: url(/images/ghs/ghs-09-enviromental-hazard.svg);
      }

      d-substance-view .listContent d-list-section-item {
        line-height: 150%;
      }

      .sticker .itemName:before,
      .groupRow.riskLevel .field-riskGroupName > span:before {
        content: '';
        margin-right: 8px;
        padding: 4px 5px 4px 7px;
        border-radius: 3px;
        color: white;
        font-family: var(--small);
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 2px;
        opacity: 1;
      }

      .sticker .itemName:before {
        display: inline;
        position: relative;
        top: -2px;
      }

      .sticker.suggestion .itemName:before {
        content: 'Forslag';
        background: hsl(0, 0%, 75%);
      }

      .wide .groupRow.riskLevel .field-riskGroupName {
        display: flex;
        flex-direction: row-reverse;
      }

      .groupRow.riskLevel .field-riskGroupName > span {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .narrow .groupRow.riskLevel .field-riskGroupName > span {
        position: relative;
        top: 6px;
        margin-top: -4px;
      }

      .columnLabels .field-riskGroupName {
        text-align: right;
      }

      .groupRow.riskLevel .field-riskGroupName > span {
        font-size: 0;
      }

      .groupRow.riskLevel .field-riskGroupName > span:before {
        display: inline;
        position: relative;
        top: 4px;
      }

      .narrow .groupRow.riskLevel .field-riskGroupName > span:before {
        top: -4px;
      }

      .sticker.risk1 .itemName:before {
        content: 'Lav';
      }

      .sticker.risk2 .itemName:before {
        content: 'Moderat';
      }

      .sticker.risk3 .itemName:before {
        content: 'Høy';
      }

      .sticker.risk4 .itemName:before {
        content: 'Svært høy';
      }

      .groupRow.riskLevel.risk1 .field-riskGroupName > span:before {
        content: 'L';
      }

      .groupRow.riskLevel.risk2 .field-riskGroupName > span:before {
        content: 'M';
      }

      .groupRow.riskLevel.risk3 .field-riskGroupName > span:before {
        content: 'H';
      }

      .groupRow.riskLevel.risk4 .field-riskGroupName > span:before {
        content: 'S';
      }

      .groupLabel.riskLevel .big #label {
        margin-right: 8px;
        padding: 6px 7px 4px 10px;
        border-radius: 4px;
        color: white;
        font-family: var(--small);
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 2px;
      }

      .narrow .groupLabel.riskLevel .big #label {
        margin-top: -4px;
      }

      .sticker.risk1 .itemName:before,
      .groupRow.riskLevel.risk1 .field-riskGroupName > span:before,
      .groupLabel.riskLevel.risk1 #label {
        background: hsl(106, 87%, 43%);
      }

      .sticker.risk2 .itemName:before,
      .groupRow.riskLevel.risk2 .field-riskGroupName > span:before,
      .groupLabel.riskLevel.risk2 #label {
        background: hsl(62, 57%, 39%);
      }

      .sticker.risk3 .itemName:before,
      .groupRow.riskLevel.risk3 .field-riskGroupName > span:before,
      .groupLabel.riskLevel.risk3 #label {
        background: hsl(31, 93%, 51%);
      }

      .sticker.risk4 .itemName:before,
      .groupRow.riskLevel.risk4 .field-riskGroupName > span:before,
      .groupLabel.riskLevel.risk4 #label {
        background: hsl(0, 80%, 56%);
      }
    `,
  ];

  @property({ type: String })
  progressState = 'ready';
  /**
   * Open the upload user interface. Property instead of state so it can be used in stories.
   */
  @property({ type: Boolean })
  showUploader = false;
  @property({ type: Array })
  uploadedFiles: UploadedFile[] = [];

  @property({ type: Boolean })
  dropTargetHover = false;
  @property({ type: Boolean })
  writeAccess = false;
  substancesTableConfig = {
    groupBy: '',
    groupByOptions: ['riskGroupSort', 'location', 'supplier', 'uploadDateTime'],
    sortBy: 'name',
    sortByOptions: [],
    columns: [
      {
        field: 'classes',
      },
      {
        field: 'uploadDateTime',
        label: 'opplastingstidspunkt',
        type: 'relativeDateAndTime',
        width: 0,
        groupOrder: 'DESC',
        hideColumn: true,
      },
      {
        field: 'location',
        label: 'lagringssted',
        width: 0,
        hideColumn: true,
      },
      {
        field: 'supplier',
        label: 'leverandør',
        width: 0,
        hideColumn: true,
      },
      {
        field: 'name',
        label: 'navn',
        width: 400,
      },
      {
        field: 'hazardLabels',
        label: '',
        width: 100,
        type: 'icons',
      },
      {
        field: 'applications',
        label: 'Bruksområder',
        width: 600,
      },
      {
        field: 'riskGroupName',
        label: 'risiko',
        width: 100,
      },
      {
        field: 'riskGroupSort',
        label: 'risikovurdering',
        width: 0,
        viewColumn: 'riskGroupName',
        groupClasses: 'riskGroupClasses',
        hideColumn: true,
      },
    ],
  };
  archivedSubstancesTableConfig = this._archivedSubstancesTableConfig(this.substancesTableConfig);

  get noSubstances() {
    return this.pageView.substances.length === 0 && this.pageView.substancesArchived.length === 0;
  }

  _setShowUploader() {
    this.showUploader = true;
    this.progressState = 'ready';
  }

  _uploadFinished() {
    const config = cloneDeep(this.substancesTableConfig);
    config.groupBy = 'uploadDateTime';
    this.progressState = 'uploadFinished';
    this.substancesTableConfig = config;
  }

  _cancelUpload() {
    this.showUploader = false;
    setTimeout(() => {
      this.progressState = 'ready';
    }, 1000);
  }

  _empty(list) {
    return list.length === 0;
  }

  _archivedSubstancesTableConfig(substancesTableConfig) {
    const config = cloneDeep(substancesTableConfig);
    config.groupBy = '';
    config.groupByOptions = [];
    return config;
  }

  _dragenter(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropTargetHover = true;
  }

  _dragleave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropTargetHover = false;
  }

  _dragover(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropTargetHover = true;
  }

  _drop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropTargetHover = false;
    this._handleDrop(e);
  }

  _registerItemsPromptClasses(dropTargetHover) {
    let classes = 'registerItemsPrompt';
    if (dropTargetHover) {
      classes += ' hover';
    }
    return classes;
  }

  _traverseFileTreePromise(item, path, folder) {
    return new Promise((resolve) => {
      if (item.isFile) {
        item.file((file) => {
          file.filepath = path ?? '' + file.name; //save full path
          folder.push(file);
          resolve(file);
        });
      } else if (item.isDirectory) {
        const dirReader = item.createReader();
        dirReader.readEntries((entries) => {
          const entriesPromises: Promise<unknown>[] = [];
          const subfolder = [];
          folder.push({ name: item.name, subfolder: subfolder });
          for (const entry of entries) {
            entriesPromises.push(this._traverseFileTreePromise(entry, path ?? '' + item.name + '/', subfolder));
          }
          resolve(Promise.all(entriesPromises));
        });
      }
    });
  }

  _getFilesDataTransferItems(dataTransferItems) {
    const files = [];
    return new Promise((resolve) => {
      const entriesPromises: Promise<unknown>[] = [];
      for (const it of dataTransferItems) {
        const item = it.webkitGetAsEntry();
        if (item) {
          entriesPromises.push(this._traverseFileTreePromise(item, '', files));
        }
      }
      Promise.all(entriesPromises).then(() => {
        resolve(files);
      });
    });
  }

  _flattenList(files) {
    let result: any[] = [];
    files.forEach((file) => {
      if (file.subfolder) {
        result = result.concat(this._flattenList(file.subfolder));
      } else if (file.type === 'application/pdf') {
        result.push(file);
      }
    });
    return result;
  }

  _handleDrop(e) {
    this._getFilesDataTransferItems(e.dataTransfer.items).then((files) => {
      const flatList = this._flattenList(files);
      this._handleFiles(flatList);
    });
  }

  _handleFileInputChange(e: InputEvent) {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files = target.files;

    this._handleFiles(files);
  }

  _handleFiles(files) {
    console.log('files', files);
    if (this.progressState === 'uploading') {
      return;
    }
    this.progressState = 'uploading';
    this.dispatchEvent(
      new CustomEvent<{ files: File[]; callback: (data: UploadedFile[]) => void }>('upload-substance-files', {
        composed: true,
        bubbles: true,
        detail: {
          files: files,
          callback: (data) => {
            this.uploadedFiles = data;
            this._uploadFinished();
          },
        },
      }),
    );
  }

  reset() {
    this.dispatchEvent(
      new CustomEvent('reset-substances', {
        composed: true,
        bubbles: true,
        detail: {},
      }),
    );
  }

  renderContent() {
    return html`
      <d-expansion ?opened=${this.showUploader || this.noSubstances}>
        <div class="${this.progressState}">
          ${this.noSubstances
            ? nothing
            : html`<div style="text-align: right">
                <d-action @click=${() => this._cancelUpload()}>Avbryt</d-action>
              </div>`}

          <div
            class="${this._registerItemsPromptClasses(this.dropTargetHover)}"
            @dragenter=${(e) => this._dragenter(e)}
            @dragleave=${(e) => this._dragleave(e)}
            @dragover=${(e) => this._dragover(e)}
            @drop=${(e) => this._drop(e)}
          >
            ${!this.noSubstances ? nothing : html`<h2>Ingen stoffer er registrert.</h2>`}
            <p>Legg til stoffer ved å dra og slippe sikkerhetsdatablader her, eller trykk for å laste opp.</p>

            <input
              accept=".pdf"
              id="fileInput"
              multiple=""
              @change=${(e) => this._handleFileInputChange(e)}
              type="file"
            />
          </div>

          <d-substances-upload-feedback
            .state=${this.progressState}
            .uploadedFiles=${this.uploadedFiles}
            .substances=${this.pageView.substances}
            .showUploader=${this.showUploader}
            @closed=${() => {
              this.progressState = 'ready';
              this.uploadedFiles = [];
              this.showUploader = false;
            }}
          ></d-substances-upload-feedback>
        </div>
      </d-expansion>

      <d-expansion ?opened=${!(this.showUploader || this.noSubstances)}>
        ${this._empty(this.pageView.substances)
          ? nothing
          : html`<d-substances-table
              .items=${this.pageView.substances}
              .sortBy=${'name'}
              .groupBy=${''}
              .filter=${true}
              theme-page
              .contentStickyTop=${this.contentStickyTop}
            ></d-substances-table>`}
        ${this._empty(this.pageView.substancesArchived)
          ? nothing
          : html` <h2>Ikke i bruk</h2>
              <d-substances-table
                .items=${this.pageView.substancesArchived}
                .sortBy=${'name'}
                .groupBy=${''}
                .filter=${true}
                theme-page
                .contentStickyTop=${this.contentStickyTop}
              ></d-substances-table>`}
      </d-expansion>

      ${this.pageView.displayResetControls
        ? html`<div style="position: fixed;bottom: 20px;left: 20px">
            <div>
              <button @click=${() => this.reset()}>Nullstill stoffkartotek</button>
            </div>
          </div>`
        : nothing}
    `;
  }

  protected willUpdate() {
    this.checkForShowUploader();
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`
      <d-action class="action" @click=${() => this._setShowUploader()}>Last opp</d-action>
      <d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>
      ${this.singleUserVersion
        ? nothing
        : html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`}
    `;
  }

  /**
   * Checks for show upload parameter in url and set the property and removes the parameter. Used to initialize upload from other pages
   *
   * @private
   */
  private checkForShowUploader() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);

    if (params.has('showSubstancesUploader')) {
      this.showUploader = true;
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-substances-page-content': DSubstancesPageContent;
  }
}
