import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/editors/index.js';
import '../../library/elements/d-tooltip.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import './d-edit-consequence.js';
import './d-edit-probability.js';
import './d-view-risk.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface RiskAssessmentView
  extends AbstractEntityViewWithRevisions<RiskAssessmentViewEditItem>,
    UpdateSectionItem {
  violatesNonRepudiationRequirement: boolean;
  violatesIntegrityRequirement: boolean;
  violatesInformationSecurityRequirements: boolean;
  violatesConfidentialityRequirement: boolean;
  violatesAvailabilityRequirement: boolean;
  responsibleBy: string;
  pages: string[];
  determinedBy: string;
  responsibleByDisplayName: string;
  determinedByDisplayName: string;
  measures: string;
  probability: number;
  consequence: number;
  riskDescription: string;
  description: string;
  assessmentDate: string;
  substanceRelated: boolean;
  relatedSubstance?: string;
  relatedSubstanceName: string;
  type: 'riskAssessments';
  name: string;
  availablePages: SelectTagOption[];
  availableEmployees: SelectDropdownOption[];
  informationSecurityDescription: string;
  createdFromTemplate?: number;
}

export interface RiskAssessmentViewEditItem {
  name: string;
  measures: string;
  probability: number;
  consequence: number;
  riskDescription: string;
  description: string;
  assessmentDate: string;
  violatesInformationSecurityRequirements: boolean;
  violatesConfidentialityRequirement: boolean;
  violatesIntegrityRequirement: boolean;
  violatesAvailabilityRequirement: boolean;
  violatesNonRepudiationRequirement: boolean;
  determinedBy: string;
  responsibleBy: string;
  pages: string[];
  createdFromTemplate?: number;
  relatedSubstance?: string;
}

/**
 *
 */
@customElement('d-risk-assessment-view')
export class DRiskAssessmentView extends EntityContent<RiskAssessmentView, RiskAssessmentViewEditItem> {
  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: String })
  defaultHelpForSubstancesRiskAssessment = '';

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addText('', this.entityView.relatedSubstanceName, 'l', this.entityView.substanceRelated);
    builder.addDate('riskAssessments_assessmentDate', this.entityView.assessmentDate, 'l');
    builder.addTextVertical('riskAssessments_description', this.entityView.description, 'l', true);
    builder.addTextVertical(
      'riskAssessments_informationSecurityDescription',
      this.entityView.informationSecurityDescription,
      'l',
      !this.entityView.substanceRelated,
    );
    builder.addTextVertical('riskAssessments_consequenceDescription', this.entityView.riskDescription, 'm', true);

    builder.addCustom(() => {
      return html`<d-view-risk
        field="riskAssessments_calculatedRisk"
        .value=${this.entityView.consequence * this.entityView.probability}
      ></d-view-risk>`;
    });

    builder.addTextVertical(
      'riskAssessments_measures',
      this.entityView.measures,
      'l',
      !this._computeHidden(this.entityView.consequence, this.entityView.probability),
    );

    builder.addText(
      'riskAssessments_determinedBy',
      this.entityView.determinedByDisplayName,
      'l',
      !this._computeHidden(this.entityView.consequence, this.entityView.probability),
    );
    builder.addText(
      'riskAssessments_responsibleBy',
      this.entityView.responsibleByDisplayName,
      'l',
      !this._computeHidden(this.entityView.consequence, this.entityView.probability),
    );
    console.log('builder done');
    return builder.build();
  }

  _helpContentTODO(
    item,
    templateIdForNew,
    suggestedRiskAssessments,
    substanceRelated,
    defaultHelpForSubstancesRiskAssessment,
  ) {
    if (item.createdFromTemplate || templateIdForNew) {
      let templateHelp = '';
      const templates = suggestedRiskAssessments.filter(function (r) {
        return r.pageId === item.createdFromTemplate || r.pageId === templateIdForNew;
      });
      if (templates.length) {
        templateHelp = templates[0].helpContent;
      }
      if (templateHelp) {
        return templateHelp;
      }
    }
    if (substanceRelated) {
      return defaultHelpForSubstancesRiskAssessment;
    }
    return item.helpContent;
  }

  _helpOpened(templateIdForNew) {
    return !!templateIdForNew;
  }

  _computeValue(consequence, probability) {
    return consequence * probability;
  }

  _computeHidden(consequence, probability) {
    return consequence * probability < 5;
  }

  async initializeEditItem() {
    this.editItem = {
      assessmentDate: this.entityView.assessmentDate,
      consequence: this.entityView.consequence,
      description: this.entityView.description,
      determinedBy: this.entityView.determinedBy,
      measures: this.entityView.measures,
      pages: this.entityView.pages,
      probability: this.entityView.probability,
      responsibleBy: this.entityView.responsibleBy,
      riskDescription: this.entityView.riskDescription,
      violatesAvailabilityRequirement: this.entityView.violatesAvailabilityRequirement,
      violatesConfidentialityRequirement: this.entityView.violatesConfidentialityRequirement,
      violatesInformationSecurityRequirements: this.entityView.violatesInformationSecurityRequirements,
      violatesIntegrityRequirement: this.entityView.violatesIntegrityRequirement,
      violatesNonRepudiationRequirement: this.entityView.violatesNonRepudiationRequirement,
      name: this.entityView.name,
      createdFromTemplate: this.entityView.createdFromTemplate,
      relatedSubstance: this.entityView.relatedSubstance,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: RiskAssessmentViewEditItem): TemplateResult<1> {
    return html` ${this.entityView.substanceRelated
        ? html`<d-section>
            <d-label class="substanceLabel" label="${this.entityView.relatedSubstanceName}"></d-label>
          </d-section>`
        : nothing}
      ${item.createdFromTemplate === undefined
        ? html` <d-section
            ><d-edit-text
              field="riskAssessments_name"
              .autofocus=${this.entityView.isNew}
              .selectOnFocus=${this.entityView.isNew}
              placeholder="Dette feltet må fylles ut"
              mark-if-empty
              .value=${item.name}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, name: e.detail.value };
              }}
            >
            </d-edit-text
          ></d-section>`
        : nothing}

      <d-section>
        <d-select-date
          field="riskAssessments_assessmentDate"
          .value=${item.assessmentDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, assessmentDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="riskAssessments_description"
          .value=${item.description}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, description: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      ${this.entityView.substanceRelated
        ? nothing
        : html`<div>
            <d-section>
              <d-checkbox
                ?checked=${item.violatesInformationSecurityRequirements}
                option-field="riskAssessments_violatesInformationSecurityRequirements"
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  this.editItem = { ...item, violatesInformationSecurityRequirements: e.detail.checked };
                }}
              >
              </d-checkbox>
            </d-section>

            ${item.violatesInformationSecurityRequirements
              ? html`<d-section field="riskAssessments_informationSecurityDescription">
                  <d-checkbox
                    ?checked=${item.violatesConfidentialityRequirement}
                    option-field="riskAssessments_violatesConfidentialityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesConfidentialityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesIntegrityRequirement}
                    option-field="riskAssessments_violatesIntegrityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesIntegrityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesAvailabilityRequirement}
                    option-field="riskAssessments_violatesAvailabilityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesAvailabilityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesNonRepudiationRequirement}
                    option-field="riskAssessments_violatesNonRepudiationRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesNonRepudiationRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                </d-section>`
              : nothing}
          </div>`}

      <d-section>
        <d-edit-probability
          .value=${item.probability}
          @value-changed=${(e: CustomEvent<{ value: number }>) => {
            this.editItem = { ...item, probability: e.detail.value };
          }}
        ></d-edit-probability>
      </d-section>

      <d-section>
        <d-edit-consequence
          .value=${item.consequence}
          @value-changed=${(e: CustomEvent<{ value: number }>) => {
            this.editItem = { ...item, consequence: e.detail.value };
          }}
        ></d-edit-consequence>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="riskAssessments_consequenceDescription"
          .value=${item.riskDescription}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, riskDescription: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-view-risk .value=${this._computeValue(item.consequence, item.probability)}> </d-view-risk>
      </d-section>

      ${this._computeHidden(item.consequence, item.probability)
        ? nothing
        : html`<d-section>
              <d-edit-textarea
                field="riskAssessments_measures"
                .value=${item.measures}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, measures: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
            <d-section>
              <d-select-dropdown
                class="minWidth300"
                field="riskAssessments_determinedBy"
                placeholder="Ingen"
                .options=${this.entityView.availableEmployees}
                .value=${item.determinedBy}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, determinedBy: e.detail.value };
                }}
              >
              </d-select-dropdown>

              <d-select-dropdown
                class="minWidth300"
                field="riskAssessments_responsibleBy"
                placeholder="Ingen"
                .options=${this.entityView.availableEmployees}
                .value=${item.responsibleBy}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, responsibleBy: e.detail.value };
                }}
              >
              </d-select-dropdown>
            </d-section>`}

      <d-section>
        <d-select-tag
          label="Vis på temaside"
          not-deselectable
          .options=${this.entityView.availablePages}
          lock="279"
          .value=${item.pages}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            this.editItem = { ...item, pages: e.detail.value };
          }}
        >
        </d-select-tag>
      </d-section>`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-risk-assessment-view': DRiskAssessmentView;
  }
}
