import {
  createPartner,
  deleteItem,
  getOrganization,
  getStore,
  saveItem,
  updateEntityAdditionalProperties,
} from 'src/store';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import type {
  ComputerUpdateMessage,
  ComputerViewModel,
  NetworkUpdateMessage,
  NetworkUpdateMessageNetworkTypeEnum,
  PersonalDataItemViewModel,
} from 'src/store/api';
import {
  ComputerUpdateMessageAntiVirusOperatorTypeEnum,
  ComputerUpdateMessageSystemUpdateOperatorTypeEnum,
  ExternalConnectionUpdateMessage,
  ExternalConnectionUpdateMessageConnectionTypeEnum,
  NetworkUpdateMessageAntiVirusOperatorTypeEnum,
  NetworkUpdateMessageFirewallEnum,
  NetworkUpdateMessageSystemUpdateOperatorTypeEnum,
  PersonalDataItemUpdateMessage,
  PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
} from 'src/store/api';
import type { MapElementChangedDetail } from 'src/d-app-core.js';
import type { CloudServiceEditItem } from 'src/pages/computers-page/d-computer-unit-cloud-service.js';
import type { RemoteServerEditItem } from 'src/pages/computers-page/d-computer-unit-remote-server.js';
import type { PrinterEditItem } from 'src/pages/computers-page/d-computer-unit-printer.js';
import type { LocalEditItem } from 'src/pages/computers-page/d-computer-unit-local.js';
import type { ExternalConnectionEditItem } from 'src/pages/computers-page/d-external-connection-view.js';
import type { NetworkConnectionEditItem } from 'src/pages/computers-page/d-network-connection-view.js';
import type { NetworkFirewallEditItem } from 'src/pages/computers-page/d-network-firewall-view.js';
import type { NetworkEditItem } from 'src/pages/computers-page/d-network-item-view.js';
import type { DataEditItem } from 'src/pages/computers-page/d-data-item-view.js';
import type {
  AddDataItemApplication,
  AddDataItemCloudServiceApplication,
  AddDataItemComputerApplication,
  AddDataItemRemoteServerApplication,
  DataItemApplicationChange,
  DataItemApplicationComputerChange,
  DataItemApplicationSupplierChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import { dataItemChanged } from 'src/d-app--data-item-changed.js';
import type { BackupChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-backup.js';
import type { NetworkExternalConnectionChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-network-external-connections.js';
import { uuid } from 'src/utilities/text.js';
import type { AccessChange } from 'src/pages/computers-page/infosec-procedure/d-infosec-access.js';
import type { OrganizationState } from 'src/store/types.js';

function countAttachedPersonalItems(
  computerUuid: string,
  personalDataItemsById: { [p: string]: PersonalDataItemViewModel },
): number {
  return Object.values(personalDataItemsById).filter((p) => p.storageUnit === computerUuid).length;
}
function countAttachedComputers(partnerUuid: string, computersById: { [p: string]: ComputerViewModel }): number {
  return Object.values(computersById).filter((p) => p.serviceProvider === partnerUuid).length;
}

function removeDataItemStorageUnit(
  o: OrganizationState,
  element: PersonalDataItemViewModel,
  now: Date,
  storageUnit: string,
) {
  const computer = o.computersById[storageUnit];
  if (computer) {
    if (computer.createdDateTime) {
      const d = new Date(computer.createdDateTime);
      const seconds = (now.getTime() - d.getTime()) / 1000;
      if (seconds < 30 && countAttachedPersonalItems(computer.uuid, o.personalDataItemsById) < 2) {
        getStore().dispatch(deleteItem('computers', computer.uuid));
      }
    }
    if (computer.serviceProvider) {
      const partner = o.partnersById[computer.serviceProvider];
      if (partner && partner.contentLastModified && partner.contentLastModifiedWasCreate) {
        const d = new Date(partner.contentLastModified);
        const seconds = (now.getTime() - d.getTime()) / 1000;
        const attachedComputers = countAttachedComputers(partner.uuid, o.computersById);
        if (seconds < 30 && attachedComputers < 2) {
          getStore().dispatch(deleteItem('partners', partner.uuid));
        }
      }
    }
  }
}

function removeDataItemAssets(element: PersonalDataItemViewModel, o: OrganizationState, now: Date) {
  for (const relatedAsset of element.relatedAssets) {
    const a = o.assetsById[relatedAsset];
    if (a && a.createdDateTime) {
      const d = new Date(a.createdDateTime);

      const seconds = (now.getTime() - d.getTime()) / 1000;
      if (seconds < 30) {
        getStore().dispatch(deleteItem('assets', a.uuid));
      }
    }
  }
}

function removeDataItemAndSystemCreatedEntities(
  o: OrganizationState,
  e: CustomEvent<{ uuid: string; dataType: string; category: string }>,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    const now = new Date();
    if (element.type === 'patientData.assetData') {
      removeDataItemAssets(element, o, now);
    }
    if (element.storageUnit) {
      removeDataItemStorageUnit(o, element, now, element.storageUnit);
    }

    getStore().dispatch(deleteItem('personalDataItems', element.uuid));
  }
}

/**
 * Når brukeren sletter et dataItem skal også alle relaterte elementer som er opprettet for mindre enn 30 sekunder siden slettes.
 * Relaterte elementer kan være
 *
 * utstyrsenheter som identifiseres i feltet assets
 * lagringsenhet som identifiseres i feltet storageUnit (slettes bare hvis de ikke er referert i andre dataItems)
 * samarbeidspartnere som identifiseres i feltet partners i den relaterte lagringsenheten
 *
 * @param e
 */
export async function dataItemsRemovedHandler(e: CustomEvent<{ uuid: string; dataType: string; category: string }>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    removeDataItemAndSystemCreatedEntities(o, e);
  }
}

export async function computerNetworkChangeHandler(e: CustomEvent<ComputerNetworkChange>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    if (e.detail.newNetwork) {
      const networkMessage: NetworkUpdateMessage = {
        name: e.detail.newNetworkName,
        connectionType: e.detail.newNetworkConnectionType,
        networkType: e.detail.newNetworkType as NetworkUpdateMessageNetworkTypeEnum,
      };
      getStore().dispatch(saveItem('networks', e.detail.networkUuid, networkMessage));
    }

    if (e.detail.newComputer) {
      const computerUpdateMessage: ComputerUpdateMessage = {
        location: '',
        name: e.detail.newComputerName,
        type: e.detail.newComputerType,
        networkUuid: e.detail.networkUuid,
        unitType: 'computer',
        serviceProvider: '',
        connectionType: '',
        serviceProviderContract: '',
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: undefined,
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: undefined,
        antiVirusRequirements: [],
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        elevated: false,
        mobileMedicalDataRequirements: [],
        notes: undefined,
      };
      getStore().dispatch(saveItem('computers', e.detail.computerUuid, computerUpdateMessage));
    }

    const computer = o.computersById[e.detail.computerUuid];
    if (computer !== undefined) {
      const message: ComputerUpdateMessage = {
        uuid: computer.uuid,
        location: computer.location,
        name: computer.name,
        type: computer.type,
        networkUuid: e.detail.networkUuid,
        unitType: computer.unitType,
        serviceProvider: computer.serviceProvider,
        connectionType: computer.connectionType,
        systemUpdateOperatorType: computer.systemUpdateOperatorType,
        systemUpdateOperator: computer.systemUpdateOperator,
        antiVirusOperatorType: computer.antiVirusOperatorType,
        antiVirusOperator: computer.antiVirusOperator,
        locked: computer.locked,
        restrictedPhysicalAccess: computer.restrictedPhysicalAccess,
        displayPositionSecure: computer.displayPositionSecure,
        elevated: computer.elevated,
        notes: computer.notes,
      };
      getStore().dispatch(saveItem('computers', e.detail.computerUuid, message));
    }
  }
}

export async function mapElementChangedHandler(
  e: CustomEvent<MapElementChangedDetail>,
  showNotification: (s: string) => void,
) {
  switch (e.detail.unitType) {
    case 'cloudService':
      await cloudServiceChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'remoteServer':
      await remoteServerChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'printer':
      await printerChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'computer':
      await computerChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'externalConnection':
      await externalConnectionChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'connection':
      await connectionChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'firewall':
      await firewallChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'network':
      await networkChangedHandler(e.detail.uuid, e.detail.element);
      break;
    case 'personalDataItem':
      await personalDataItemChangedHandler(e.detail.uuid, e.detail.element, e.detail.extra, showNotification);
      break;
  }
}

async function cloudServiceChangedHandler(uuid: string, editItem: CloudServiceEditItem) {
  const message: ComputerUpdateMessage = {
    uuid: uuid,
    location: '',
    name: editItem.serviceName,
    type: 'UNDEFINED',
    networkUuid: undefined,
    unitType: 'cloudService',
    serviceProvider: editItem.supplierUuid,
    connectionType: editItem.connectionType,
    serviceProviderContract: editItem.contractUuid,
    systemUpdateOperatorType: 'UNDEFINED',
    systemUpdateOperator: undefined,
    antiVirusOperatorType: 'UNDEFINED',
    antiVirusOperator: undefined,
    antiVirusRequirements: [],
    locked: false,
    restrictedPhysicalAccess: false,
    displayPositionSecure: false,
    elevated: false,
    mobileMedicalDataRequirements: [],
    notes: undefined,
  };
  getStore().dispatch(saveItem('computers', uuid, message));
}

async function remoteServerChangedHandler(uuid: string, editItem: RemoteServerEditItem) {
  const message: ComputerUpdateMessage = {
    uuid: uuid,
    location: '',
    name: '',
    type: 'UNDEFINED',
    networkUuid: undefined,
    unitType: 'remoteServer',
    serviceProvider: editItem.supplierUuid,
    connectionType: editItem.connectionType,
    serviceProviderContract: editItem.contractUuid,
    systemUpdateOperatorType: 'UNDEFINED',
    systemUpdateOperator: undefined,
    antiVirusOperatorType: 'UNDEFINED',
    antiVirusOperator: undefined,
    antiVirusRequirements: [],
    locked: false,
    restrictedPhysicalAccess: false,
    displayPositionSecure: false,
    elevated: false,
    mobileMedicalDataRequirements: [],
    notes: undefined,
  };
  getStore().dispatch(saveItem('computers', uuid, message));
}

async function printerChangedHandler(uuid: string, element: PrinterEditItem) {
  const message: ComputerUpdateMessage = {
    uuid: uuid,
    location: element.location,
    name: element.name,
    type: 'UNDEFINED',
    networkUuid: element.networkUuid,
    unitType: 'printer',
    serviceProvider: '',
    connectionType: '',
    serviceProviderContract: '',
    systemUpdateOperatorType: 'UNDEFINED',
    systemUpdateOperator: undefined,
    antiVirusOperatorType: 'UNDEFINED',
    antiVirusOperator: undefined,
    antiVirusRequirements: [],
    locked: false,
    restrictedPhysicalAccess: false,
    displayPositionSecure: false,
    elevated: false,
    mobileMedicalDataRequirements: [],
    notes: element.notes,
    printerPositionSecure: element.printerPositionSecure,
  };
  getStore().dispatch(saveItem('computers', uuid, message));
}

async function computerChangedHandler(uuid: string, element: LocalEditItem) {
  const message: ComputerUpdateMessage = {
    uuid: uuid,
    location: element.location,
    name: element.name,
    type: element.type,
    networkUuid: element.networkUuid,
    unitType: 'computer',
    serviceProvider: undefined,
    connectionType: 'NONE',
    systemUpdateOperatorType: element.systemUpdateOperatorType as ComputerUpdateMessageSystemUpdateOperatorTypeEnum,
    systemUpdateOperator: element.systemUpdateOperator,
    antiVirusOperatorType: element.antiVirusOperatorType as ComputerUpdateMessageAntiVirusOperatorTypeEnum,
    antiVirusOperator: element.antiVirusOperator,
    antiVirusRequirements: element.antiVirusRequirements,
    locked: element.locked,
    restrictedPhysicalAccess: element.restrictedPhysicalAccess,
    displayPositionSecure: element.displayPositionSecure,
    elevated: element.elevated,
    mobileMedicalDataRequirements: element.mobileMedicalDataRequirements,
    notes: element.notes,
  };
  getStore().dispatch(saveItem('computers', uuid, message));
}

async function externalConnectionChangedHandler(uuid: string, element: ExternalConnectionEditItem) {
  const message: ExternalConnectionUpdateMessage = {
    networkUuid: element.networkUuid,
    name: '',
    description: element.description,
    connectionType: element.connectionType as ExternalConnectionUpdateMessageConnectionTypeEnum,
    supplierUuid: undefined,
    employeeUuid: element.employeeUuid,
    partnerUuid: element.partnerUuid,
    type: element.type,
    otherRiskAssessments: element.otherRiskAssessments,
    riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
    riskAssessments: element.riskAssessments,
  };
  getStore().dispatch(saveItem('externalConnections', uuid, message));
}

async function connectionChangedHandler(uuid: string, element: NetworkConnectionEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const n = o.networksById[uuid];

    const message: NetworkUpdateMessage = {
      name: n.name,
      connectionType: element.connectionType,
      supplierUuid: element.supplierUuid,
      firewall: n.firewall,
      firewallName: n.firewallName,
      systemUpdateOperatorType: n.systemUpdateOperatorType,
      systemUpdateOperator: n.systemUpdateOperator,
      antiVirusOperatorType: n.antiVirusOperatorType,
      antiVirusOperator: n.antiVirusOperator,
      networkType: n.networkType,
    };
    getStore().dispatch(saveItem('networks', uuid, message));
  }
}

async function firewallChangedHandler(uuid: string, element: NetworkFirewallEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const n = o.networksById[uuid];

    const message: NetworkUpdateMessage = {
      name: n.name,
      connectionType: n.connectionType,
      supplierUuid: n.supplierUuid,
      firewall: element.firewallType as NetworkUpdateMessageFirewallEnum,
      firewallName: element.firewallName,
      systemUpdateOperatorType: n.systemUpdateOperatorType,
      systemUpdateOperator: n.systemUpdateOperator,
      antiVirusOperatorType: n.antiVirusOperatorType,
      antiVirusOperator: n.antiVirusOperator,
      networkType: n.networkType,
    };
    getStore().dispatch(saveItem('networks', uuid, message));
  }
}

async function networkChangedHandler(uuid: string, element: NetworkEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const message: NetworkUpdateMessage = {
      name: element.name,
      connectionType: element.connectionType,
      supplierUuid: element.supplierUuid,
      firewall: element.firewallType as NetworkUpdateMessageFirewallEnum,
      firewallName: element.firewallName,
      systemUpdateOperatorType: element.systemUpdateOperatorType as NetworkUpdateMessageSystemUpdateOperatorTypeEnum,
      systemUpdateOperator: element.systemUpdateOperator,
      antiVirusOperatorType: element.antiVirusOperatorType as NetworkUpdateMessageAntiVirusOperatorTypeEnum,
      antiVirusOperator: element.antiVirusOperator,
      networkType: element.type as NetworkUpdateMessageNetworkTypeEnum,
    };
    getStore().dispatch(saveItem('networks', uuid, message));
  }
}

async function personalDataItemChangedHandler(
  uuid: string,
  element: DataEditItem,
  extra: AddDataItemApplication | undefined,
  showNotification: (s: string) => void,
) {
  let fields = {};
  if (extra !== undefined) {
    await createNewApplication(uuid, extra, element.dataType, element.category, showNotification);
    const state = getStore().getState();
    const o = getOrganization(state);
    if (o !== undefined) {
      const newItem = o.personalDataItemsById[uuid];

      fields = {
        storageUnit: newItem.storageUnit,
      };
    }
  }

  const message: PersonalDataItemUpdateMessage = {
    establishedDate: '',
    name: element.name,
    type: element.dataType + '.' + element.category,
    storageMedium: '',
    storageUnit: element.storageUnit,
    storageLocation: '',
    backupOperatorType: PersonalDataItemUpdateMessageBackupOperatorTypeEnum.Undefined,
    backupOperator: undefined,
    application: element.application,
    journalSupplier: undefined,
    journalSize: undefined,
    sharedMedicalRecords: element.sharedMedicalRecords,
    sharedMedicalRecordsPartners: element.sharedMedicalRecordsPartners,
    sharedMedicalRecordsContracts:
      element.sharedMedicalRecordsContracts !== '' ? [element.sharedMedicalRecordsContracts] : [],
    treatments: undefined,
    purpose: element.purpose,
    legalBasis: element.legalBasis,
    consentContracts: element.consentContracts,
    journalHasElectronicMessaging: element.journalHasElectronicMessaging,
    messagingType: element.messagingType,
    messagingTypeOther: element.messagingTypeOther,
    messagingSupplier: element.messagingSupplier,
    messageMonitoring: element.messageMonitoring,
    autoLookupPatients: element.autoLookupPatients,
    autoLookupAddress: element.autoLookupAddress,
    autoLookupAddressNhn: element.autoLookupAddressNhn,
    autoUpdateAddressNhn: element.autoUpdateAddressNhn,
    riskAssessments: undefined,
    otherRiskAssessments: '',
    riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
    patientDialogueRequirements: element.patientDialogueRequirements,
    notes: element.notes,
    accessingEmployees: element.accessingEmployees,
    accessingPartners: element.accessingPartners,
    backupFrequency: element.backupFrequency,
    backupResponsible: element.backupResponsible,
    noBackupReason: element.noBackupReason,
    personalData: element.personalData,
    relatedAssets: element.relatedAssets,
    ...fields,
  };
  getStore().dispatch(saveItem('personalDataItems', uuid, message));
}

async function createNewApplication(
  uuid: string,
  extra: AddDataItemComputerApplication | AddDataItemCloudServiceApplication | AddDataItemRemoteServerApplication,
  dataType: string,
  category: string,
  showNotification: (s: string) => void,
) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    switch (extra.storageUnitType) {
      case 'computers': {
        const c: DataItemApplicationComputerChange = extra.newComputer
          ? {
              newComputer: extra.newComputer,
              newComputerName: extra.newComputerName ?? '',
              newComputerType: extra.newComputerType ?? '',
            }
          : { newComputer: extra.newComputer, computerUuid: extra.computerUuid };
        await dataItemChanged(
          o,
          new CustomEvent<DataItemApplicationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: uuid,
              application: extra.application,

              storageUnitType: 'computers',

              ...c,
              dataType: dataType,
              category: category,
            },
          }),
          state,
          showNotification,
        );
        break;
      }
      case 'cloudServices':
      case 'remoteServers': {
        const s: DataItemApplicationSupplierChange = extra.newSupplier
          ? { newSupplier: true, newSupplierName: extra.newSupplierName, supplierUuid: extra.supplierUuid }
          : { newSupplier: false, supplierUuid: extra.supplierUuid };
        await dataItemChanged(
          o,
          new CustomEvent<DataItemApplicationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: uuid,
              application: extra.application,

              storageUnitType: extra.storageUnitType,

              dataType: dataType,
              category: category,
              ...s,
            },
          }),
          state,
          showNotification,
        );
      }
    }
  }
}

export async function backupChangeHandler(e: CustomEvent<BackupChange>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const message = {
      backupFrequency: e.detail.backupFrequency,
      backupResponsible: e.detail.backupResponsible,
      noBackupReason: e.detail.noBackupReason,
    };
    getStore().dispatch(updateEntityAdditionalProperties('personalDataItems', e.detail.uuid, message));
  }
}

export async function externalConnectChangeHandler(e: CustomEvent<NetworkExternalConnectionChange>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const message: ExternalConnectionUpdateMessage = {
      networkUuid: e.detail.networkUuid,
      name: '',
      description: '',
      connectionType: e.detail.connectionType as ExternalConnectionUpdateMessageConnectionTypeEnum,
      supplierUuid: e.detail.type === 'Tilkoblet samarbeidspartner' ? e.detail.partnerUuid : undefined,
      employeeUuid: e.detail.type === 'Hjemmekontor' ? e.detail.employeeUuid : undefined,
      partnerUuid: e.detail.type === 'Tilkoblet samarbeidspartner' ? e.detail.partnerUuid : undefined,
      type: e.detail.type,
      riskAssessments: [],
      otherRiskAssessments: '',
      riskAssessmentConcludesOk: false,
    };
    getStore().dispatch(saveItem('externalConnections', e.detail.uuid ?? uuid(), message));
  }
}

export async function accessChangeHandler(e: CustomEvent<AccessChange>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    if (e.detail.newPartner !== undefined) {
      getStore().dispatch(createPartner(e.detail.newPartner.value, e.detail.newPartner.text));
    }

    const message = {
      accessingPartners: e.detail.accessingPartners,
      accessingEmployees: e.detail.accessingEmployees,
    };
    getStore().dispatch(updateEntityAdditionalProperties('personalDataItems', e.detail.uuid, message));
  }
}

export async function mapElementDeletedHandler(e: CustomEvent<{ uuid: string; unitType: string }>) {
  switch (e.detail.unitType) {
    case 'cloudService':
      getStore().dispatch(deleteItem('computers', e.detail.uuid));
      break;
    case 'remoteServer':
      getStore().dispatch(deleteItem('computers', e.detail.uuid));
      break;
    case 'printer':
      getStore().dispatch(deleteItem('computers', e.detail.uuid));
      break;
    case 'computer':
      getStore().dispatch(deleteItem('computers', e.detail.uuid));
      break;
    case 'externalConnection':
      getStore().dispatch(deleteItem('externalConnections', e.detail.uuid));
      break;
    case 'connection':
      getStore().dispatch(deleteItem('networks', e.detail.uuid));
      break;
    case 'firewall':
      getStore().dispatch(deleteItem('networks', e.detail.uuid));
      break;
    case 'network':
      getStore().dispatch(deleteItem('networks', e.detail.uuid));
      break;
    case 'personalDataItem':
      getStore().dispatch(deleteItem('personalDataItems', e.detail.uuid));
      break;
  }
}
