import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-closer.js';
import '../../library/fields/d-view-info.js';
import './d-skil-course-table.js';
import { classMap } from 'lit/directives/class-map.js';

/**
 *
 * STATUS OK
 */
@customElement('d-skil-course-section')
export class DSkilCourseSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    :host > div {
      display: block;
      position: relative;
      border: 1px solid hsl(169, 25%, 78%);
      border-radius: 10px;
      background-color: hsl(169, 25%, 85%);
      padding: 8px;
      cursor: pointer;
      transition: all 0.3s;
    }

    :host > div.open {
      border-radius: 14px;
      padding: 20px;
      cursor: default;
    }

    .title {
      background-image: url(/images/partners/skil.svg);
      background-position: 0 -18px;
      background-repeat: no-repeat;
      box-sizing: border-box;
      min-height: 22px;
      padding: 3px 0 0 68px;
      background-size: 60px 60px;
      font-size: 15px;
      font-weight: 400;
      color: var(--linkColorGray);
      transition:
        background 0.3s,
        min-height 0.3s,
        padding 0.3s,
        font-size 0.3s,
        font-weight 0.3s;
    }

    :host > div:hover .title {
      color: black;
    }

    .title span {
      transition: all 0.3s;
    }

    .open .title {
      min-height: 36px;
      padding-top: 6px;
      padding-bottom: 20px;
      padding-left: 110px;
      background-position: -3px -33px;
      background-size: 100px 100px;
      font-size: 20px;
      font-weight: 600;
      color: black;
    }

    .open .title span {
      font-size: 0;
      opacity: 0;
    }

    .skil-logo {
      flex: none;
      width: 95px;
      height: 35px;
      background: url(/images/partners/skil.svg) -3px -33px no-repeat;
      background-size: 100px 100px;
      margin-right: 10px;
    }
  `;

  @property({ type: Boolean })
  opened = false;
  @property({ type: Number })
  organizationId = 0;
  courses = [
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '00',
      name: 'Legemiddelgjennomgang',
      price: '4.000,-',
      href: 'https://www.skilnet.no/legemiddelgjennomgang-lmg/',
      target: 'skil',
    },
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '01',
      name: 'E-konsultasjon',
      price: '4.000,-',
      href: 'https://www.skilnet.no/smakurs/e-konsultasjon-kvalitetspakke/',
      target: 'skil',
    },
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '02',
      name: 'Bedre liv med diabetes',
      price: '4.000,-',
      href: 'https://www.skilnet.no/smakurs/dia/',
      target: 'skil',
    },
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '03',
      name: 'Vanedannende legemidler',
      price: '4.000,-',
      href: 'https://www.skilnet.no/smakurs/vlm/',
      target: 'skil',
    },

    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '04',
      name: 'Sunn seksuell helse hele livet',
      price: '4.000,-',
      href: 'https://www.skilnet.no/smakurs/shl/',
      target: 'skil',
    },
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '05',
      name: 'Kloke valg',
      price: '4.000,-',
      href: 'https://www.skilnet.no/gjor-kloke-valg-klok/',
      target: 'skil',
    },
    {
      category: 'Kvalitetspakker',
      categorySortValue: '1',
      sort: '05',
      name: 'Sykmeldingsarbeid for leger',
      price: '4.000,-',
      href: 'https://www.skilnet.no/smakurs/sykmeldingsarbeid-for-leger-sfl/',
      target: 'skil',
    },
    {
      category: 'E-læringskurs',
      categorySortValue: '2',
      sort: '06',
      name: 'Gruppelederkurs',
      price: 'gratis',
      href: 'https://www.skilnet.no/smakurs/gruppelederkurs/',
      target: 'skil',
    },
    {
      category: 'E-læringskurs',
      categorySortValue: '2',
      sort: '07',
      name: 'Grunnkurs i kvalitetsarbeid på legekontor',
      price: 'gratis',
      href: 'https://www.skilnet.no/smakurs/grunnkurs-kvalitetsarbeid-for-legekontor-gkl/',
      target: 'skil',
    },
    {
      category: 'E-læringskurs',
      categorySortValue: '2',
      sort: '08',
      name: 'Trygg med Trinnvis',
      price: 'gratis',
      href: 'https://www.skilnet.no/smakurs/trygg-med-trinnvis-tmt/',
      target: 'skil',
    },
    {
      category: 'E-læringskurs',
      categorySortValue: '2',
      sort: '09',
      name: 'Kvalitet i e-resept',
      price: 'gratis',
      href: 'https://www.skilnet.no/smakurs/kvalitet-i-e-resept-ker/',
      target: 'skil',
    },
    {
      category: 'E-læringskurs',
      categorySortValue: '2',
      sort: '10',
      name: 'Diagnosekodekurs',
      price: 'gratis',
      href: 'https://www.skilnet.no/smakurs/koding-av-diagnoser-i-allmennpraksis-dkk/',
      target: 'skil',
    },
    {
      category: 'Kvalitetsseminar',
      categorySortValue: '3',
      sort: '11',
      name: 'Basisseminar',
      price: '1.500,-',
      href: 'https://www.skilnet.no/kommunesamarbeid/kvalitetsseminar-basisseminar/',
      target: 'skil',
    },
    {
      category: 'Kvalitetsseminar',
      categorySortValue: '3',
      sort: '11',
      name: 'Skrøpelige eldre og sårbare grupper',
      price: '1.500,-',
      href: 'https://www.skilnet.no/kommunesamarbeid/kvalitetsseminar-skropelige-eldre-og-sarbare-grupper/',
      target: 'skil',
    },
    {
      category: 'Kvalitetsseminar',
      categorySortValue: '3',
      sort: '11',
      name: 'Trivsel og arbeidsglede',
      price: '1.500,-',
      href: 'https://www.skilnet.no/kommunesamarbeid/trivsel/',
      target: 'skil',
    },
  ];
  private info =
    '<p>SKIL er en nonprofit-organisasjon eid av Legeforeningen. SKIL tilbyr en rekke kurs med fokus på ' +
    'kvalitetsarbeid på legekontor. Kursene kan gjennomføres individuelt eller i grupper. TrinnVis har et tett ' +
    'samarbeid med SKIL.</p>' +
    '<p>SKILs kvalitetspakker er organisert som kliniske emnekurs der deltakerne reflekterer over data fra egen ' +
    'praksis, planlegger forbedringer og setter mål. I tillegg har SKIL en rekke gratis e-læringskurs.</p>';

  _toggle(e) {
    if (e.target.id !== 'closer') {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  _collapsibleWrapperClasses() {
    let classes = '';
    if (this.opened) {
      classes += ' open';
    }
    return classes;
  }

  render() {
    return html`
      <div class="${classMap({ open: this.opened, collapsibleWrapper: true })}" @click=${(e) => this._toggle(e)}>
        <div class="title"><span>Kvalitetspakker og kurs fra </span>Senter for kvalitet i legekontor</div>
        <d-expansion ?opened=${this.opened}>
          <d-closer id="closer"></d-closer>
          <d-view-info .content=${this.info}> </d-view-info>
          <d-skil-course-table
            .items=${this.courses}
            sort-by="sort"
            group-by="category"
            @row-clicked=${this.onSkilCourseRowClick}
          ></d-skil-course-table>
        </d-expansion>
      </div>
    `;
  }

  private onSkilCourseRowClick(e: CustomEvent<{ href: string }>) {
    e.preventDefault();
    window.open(e.detail.href);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-skil-course-section': DSkilCourseSection;
  }
}
