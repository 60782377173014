import { css, html, nothing } from 'lit';
import { DPrepareBehavior } from './d-prepare-behavior.js';
import './d-robot-header.js';
import '../../library/elements/d-label.js';
import '../../library/editors/elements/d-select-dropdown.js';
import { customElement, property, query } from 'lit/decorators.js';
import type { AccountStateViewModel } from '../../store/api';
import { produce } from 'immer';

/**
 *
 */
@customElement('d-prepare-step-1')
export class DPrepareStep1 extends DPrepareBehavior {
  static readonly styles = [
    ...super.styles,
    css`
      :host {
        display: block;
      }

      .functionsAssign {
        margin-top: 16px;
      }

      .label-wrap {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
        align-items: baseline;
        padding: 6px 0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        overflow: visible;
      }

      .label-wrap > *:first-child {
        width: 170px;
        flex: none;
      }

      .label-wrap > *:last-child {
        margin-right: 0;
        width: 300px;
        flex: 1;
      }

      @media screen and (max-width: 500px) {
        .label-wrap {
          display: block;
        }

        .label-wrap > *:first-child,
        .label-wrap > *:last-child {
          display: block;
          width: 100%;
          text-align: left;
        }
      }

      .maxWidth500 .label-wrap {
        display: block;
      }

      .maxWidth500 .label-wrap > *:first-child,
      .maxWidth500 .label-wrap > *:last-child {
        display: block;
        width: 100%;
        text-align: left;
      }

      d-select {
        display: inline-block;
      }

      d-label {
        display: inline-block;
      }
    `,
  ];
  @query('#accountConfig2Form')
  accountConfig2Form!: HTMLElement;
  @property({ type: Object })
  state: AccountStateViewModel = {
    assetFunction: '',
    computerFunction: '',
    economicsFunction: '',
    employees: [],
    healthSafetyAndEnvironmentalFunction: '',
    humanResourcesFunction: '',
    leaderFunction: '',
    professionalDevelopmentFunction: '',
    qualityManagerFunction: '',
  };
  @property({ type: Boolean })
  isPublic = true;

  protected back = 'step0';
  protected next = 'finish';

  private robotMessage =
    'Her fordeler du <a href="https://trinnvis.no/hjelp/ansvarsomrader/" target="_blank">ansvarsområder</a> – hvis du ikke har tenkt å gjøre alt selv? Hvert ansvarsområde har egne rutiner som du selv kan redigere etterhvert. Du kan også lage dine egne ansvarsområder.';
  private standardResponsibilities = [
    {
      field: 'leaderFunction',
      isPublic: false,
      name: 'Daglig leder',
    },
    {
      field: 'humanResourcesFunction',
      isPublic: false,
      name: 'Personalansvarlig',
    },
    {
      field: 'economicsFunction',
      isPublic: false,
      name: 'Økonomiansvarlig',
    },
    {
      field: 'computerFunction',
      isPublic: false,
      name: 'Dataansvarlig',
    },
    {
      field: 'assetFunction',
      isPublic: false,
      name: 'Utstyrsansvarlig',
    },
    {
      field: 'professionalDevelopmentFunction',
      isPublic: false,
      name: 'Faglig leder',
    },
    {
      field: 'healthSafetyAndEnvironmentalFunction',
      isPublic: false,
      name: 'HMS-ansvarlig',
    },
    {
      field: 'qualityManagerFunction',
      isPublic: false,
      name: 'Kvalitetsansvarlig',
    },
  ];

  private get employeeOptions() {
    return this.state.employees
      .filter((employee) => {
        return !this._stringIsEmpty(employee.firstName) || !this._stringIsEmpty(employee.lastName);
      })
      .map((employee) => {
        return {
          value: employee.uuid,
          text: employee.firstName + ' ' + employee.lastName,
        };
      });
  }

  _stringIsEmpty(value) {
    return value ? value.trim().length === 0 : true;
  }

  _shouldHide(isPublic, needPublic) {
    return needPublic && !isPublic;
  }

  _setFieldValue(e: CustomEvent<{ value: string }>) {
    const t = e.target as HTMLSelectElement;
    if (t) {
      this.state = produce(this.state, (draft) => {
        const field = t.dataset['field'];
        if (field) {
          draft[field] = e.detail.value;
        }
      });
      this.dispatchEvent(new CustomEvent('state-changed', { composed: true, bubbles: true, detail: this.state }));
    }
  }

  render() {
    return html`
      <d-robot-header .message=${this.robotMessage}></d-robot-header>
      <div class="outskirtsContent">
        <h2>Ansvarsfordeling</h2>
        <form id="accountConfig2Form" @submit=${(e) => this._finish(e)}>
          <div class="functionsAssign">
            ${this.standardResponsibilities.map(
              (item) =>
                html` ${this._shouldHide(this.isPublic, item.isPublic)
                  ? nothing
                  : html`
                      <div class="label-wrap">
                        <d-label label="${item.name}"></d-label>
                        <d-select-dropdown
                          outskirts
                          .options=${this.employeeOptions}
                          .value=${this.state[item.field]}
                          data-field="${item.field}"
                          @value-changed=${(e) => this._setFieldValue(e)}
                        ></d-select-dropdown>
                      </div>
                    `}`,
            )}
          </div>

          <div class="buttons">
            <button @click=${(e) => this._backward(e)} type="button">Forrige</button>
            <button class="right" @click=${(e) => this._cancel(e)} type="button">Avbryt</button>
            <button class="right nextPage" type="submit">Neste</button>
          </div>
        </form>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare-step-1': DPrepareStep1;
  }
}
