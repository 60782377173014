import { fromInterface } from 'src/models/resolvers/from-interface.js';
import type { IssueViewModel } from 'src/store/api';

export class IssueResolver extends fromInterface<IssueViewModel>() {
  get processed(): boolean {
    if (this.evaluatedDate && this.evaluatedBy) {
      return true;
    }
    if (this.evaluated) {
      //skal evalueres
      return false;
    }
    if (this.implementedMeasure) {
      return true;
    }
    if (this.requiresMeasures) {
      return false;
    }
    return !!(this.processedDate && this.processedBy);
  }
}
