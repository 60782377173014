import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import {
  FileViewerDialog,
  FileViewerDocument,
  FileViewerInput,
  FileViewerResult,
} from 'src/layout/parts/file-viewer-dialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { BaseListSectionElement } from 'src/library/lists/base-list-section-element.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { sortedByName } from '../../store/utilities.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../elements/d-progress-bar.js';
import './d-list-header.js';
import { DListSection } from './d-list-section.js';

export interface AttachmentItem {
  name: string;
  uuid: string;
  fullPath: string;
  previewHref: string;

  downloadHref: string;
  asDocumentItem: () => Promise<FileViewerDocument>;
}

export interface UploadItem {
  name: string;
  progress: number;
  file: File;
}

/**
 *
 */
@customElement('d-list-section-attachment')
export class DListSectionAttachment extends BaseListSectionElement<AttachmentItem> {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
        padding-bottom: 11px;
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
      }

      .uploading {
        margin-left: var(--listPaddingLeft);
      }

      .uploading > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      d-progress-bar {
        width: 120px;
        height: 6px;
        border-radius: 3px;
        margin-right: 12px;
      }

      @media only screen and (max-width: 600px) {
        .files-wrapper,
        .uploading {
          margin-left: 0;
        }
      }
    `,
  ];
  attachmentsBefore: any;
  u: any;
  @property({ type: String })
  field = 'generalFields_attachments';
  @property({ type: String })
  icon = 'attachments';
  @property({ type: String })
  url = '';
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  pageId = '';
  @property({ type: String })
  allowedTypes = '*';
  @property({ type: Array })
  uploadList: UploadItem[] = [];
  @property({ type: Array })
  actions: ActionInput[] = [
    {
      name: 'Last opp',
      action: 'upload',
      slot: 'top-right',
    },
  ];

  _removeFile(e, d) {
    const i = this.uploadList.indexOf(d.file);

    if (i >= 0) {
      this.uploadList.splice(i, 1);
    }

    if (this.uploadList.length === 0) {
      setTimeout(() => {
        console.log('After file upload ' + this.u + ' ' + this.attachmentsBefore + ' -> ' + this.items.length);
        const shouldReload = this.attachmentsBefore + this.u > this.items.length;
        if (shouldReload) {
          console.log('Expected messages not received. Forcing reload');
          location.reload();
        }
      }, 500);
    }
  }

  _handleFilePick(e) {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files !== null) {
      this.u = files.length;
      this.attachmentsBefore = this.items.length;

      for (const file1 of files) {
        this.uploadList = [...this.uploadList, { name: file1.name, progress: 0, file: file1 }];
        this.dispatchEvent(
          new CustomEvent<{ file: File; callback: () => void }>('upload-file', {
            bubbles: true,
            composed: true,
            detail: {
              file: file1,
              callback: () => {
                this.uploadList = this.uploadList.filter((f) => f.file !== file1);
              },
            },
          }),
        );
      }
    }
  }

  _isDisabled(pageId, writeAccess) {
    return pageId === 3482 || !writeAccess;
  }

  async onAttachmentItemClick(item: AttachmentItem) {
    const input: FileViewerInput = { document: await item.asDocumentItem(), writeAccess: this.writeAccess };
    const result: FileViewerResult = await FileViewerDialog.open(input);
    console.error(result);
  }

  _handleActions(e) {
    if (e.detail === 'upload' && this.shadowRoot) {
      let input;
      if (this.shadowRoot.getElementById('input')) {
        input = this.shadowRoot.getElementById('input') as HTMLInputElement;
        if (input) {
          input.click();
        }
      }
    }
  }

  renderHeader(): TemplateResult | typeof nothing {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header
        icon="attachments"
        field="generalFields_attachments"
        .actions=${this.actions}
        @action=${(e) => this._handleActions(e)}
        style=${styleMap(listHeaderStyles)}
        ?theme-page=${this.themePage}
        ?map-element=${this.mapElement}
      >
      </d-list-header>
    `;
  }

  renderItem(item: AttachmentItem): TemplateResult | typeof nothing {
    const asListItem = this.toListSectionItemInput(item);
    return html`<d-list-section-item
      .input=${asListItem}
      @click=${() => this.onAttachmentItemClick(item)}
    ></d-list-section-item>`;
  }

  renderPostContent(): TemplateResult | typeof nothing {
    return this._isDisabled(this.pageId, this.writeAccess)
      ? nothing
      : html`
          <div class="uploading">
            ${sortedByName(this.uploadList).map((item) => {
              const asListItem: ListSectionItemInput = {
                locked: false,
                secondaryLabel: '',
                updateStatus: 'none',
                accessible: true,
                label: item.name,
              };
              return html`<div>
                <d-progress-bar total="100" .partNumber=${item.progress}></d-progress-bar
                ><d-list-section-item .input=${asListItem}></d-list-section-item>
              </div>`;
            })}
          </div>
          <input
            id="input"
            accept="${this.allowedTypes}"
            multiple=""
            @change=${(e) => this._handleFilePick(e)}
            type="file"
          />
        `;
  }

  private fileName(name) {
    const n = name.lastIndexOf('.');
    return n > 0 ? name.slice(0, n) : name;
  }

  private fileExtension(attachment) {
    const p = attachment.fullPath;
    const ext = p.substring(p.lastIndexOf('.') + 1);
    return '.' + ext.toLowerCase();
  }

  private toListSectionItemInput(attachment: AttachmentItem): ListSectionItemInput {
    return {
      locked: false,
      secondaryLabel: '',
      label: this.fileName(attachment.name) + this.fileExtension(attachment),
      accessible: true,
      updateStatus: 'none',
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-attachment': DListSectionAttachment;
  }
}
