import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { AssetCheckStatuses, ListSectionItemInput } from 'src/library/lists/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../editors/elements/d-edit-textarea.js';
import '../fields/d-expansion.js';

import '../fields/d-view-text.js';
import './d-list-section-item.js';
import { shortenPath } from 'src/utilities/path';

/**
 *
 *
 */
@customElement('d-list-section-event-asset-item')
export class DListSectionEventAssetItem extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .item {
      margin-left: calc(var(--listPaddingLeft) * -1);
      padding-left: var(--listPaddingLeft);
      background-position: 1px 8px;
      background-size: 24px auto;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .item.ok {
      background-image: url(/images/check-mini-blue.svg);
    }

    .item.not-ok {
      background-image: url(/images/x-fat-orange.svg);
    }

    .item.open {
      background: none;
      cursor: default;
    }

    .header {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .actions {
      flex: none;
      margin-right: -8px;
      padding: 10px 0 9px 0;
    }

    .actions d-action {
      margin: -6px 0;
      display: none;
    }

    .actions d-action:first-child {
      display: inline-block;
      opacity: 0;
    }

    .item:hover .actions d-action:first-child {
      opacity: 1;
    }

    .open .actions d-action {
      display: inline-block;
    }

    .open .actions d-action:first-child {
      display: none;
    }

    .header-content {
      flex: 1;
      padding: 10px 0 9px 0;
      color: var(--linkColorGray);
      line-height: var(--lineHeightDefault);
    }

    .item:not(.open):hover .header-content {
      color: black;
    }

    .header-content > div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    .name {
      margin-right: 6px;
      transition: font-size 0.2s;
    }

    .name:hover {
      color: black;
    }

    .open .name {
      font-size: 18px;
      font-weight: 600;
      color: hsl(0, 0%, 20%);
    }

    .done-text {
      font-size: 14px;
      font-weight: 200;
    }

    .open .done-text {
      display: none;
    }

    .edit {
      padding-bottom: 12px;
    }

    .icon-wrapper {
      display: flex;
      margin-bottom: 10px;
      color: black;
      line-height: 120%;
    }

    .icon-wrapper > div {
      padding: 1px 2px 0 28px;
      opacity: 0.5;
      cursor: pointer;
    }

    .icon-wrapper > div:hover {
      opacity: 1;
    }

    .icon-wrapper > div {
      background-position: 3px -2px;
      background-size: 24px auto;
      background-repeat: no-repeat;
    }

    .icon-ok {
      background-image: url(/images/check-mini-gray.svg);
    }

    .icon-not-ok {
      background-image: url(/images/x-fat-gray.svg);
    }

    .item.ok .icon-ok {
      background-image: url(/images/check-mini-blue.svg);
      opacity: 1;
      cursor: default;
    }

    .item.not-ok .icon-not-ok {
      background-image: url(/images/x-fat-orange.svg);
      opacity: 1;
      cursor: default;
    }

    .notes {
      font-family: var(--mainSerif), serif;
      font-size: 14px;
      font-style: italic;
    }

    @media only screen and (max-width: 600px) {
      .item {
        margin-left: 0;
      }
    }
  `;
  @property({ type: Object })
  input?: ListSectionItemInput;
  @property({ type: Boolean })
  open = false;
  @property({ type: String })
  status: AssetCheckStatuses = 'NONE';
  previousStatus: AssetCheckStatuses = 'NONE';
  previousComments = '';
  @property({ type: String })
  comments = '';
  @property({ type: String })
  checkedBy = '';
  @property({ type: String })
  checkedTime = '';
  @property({ type: String })
  checkedByTime = '';
  @property({ type: Object })
  event: { uuid: string } = { uuid: '' };
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  href = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  assetUuid = '';
  @property({ type: String })
  secondaryLabel = '';
  @property({ type: String })
  contentLastModified = '';
  @property({ type: String })
  currentEmployeeUuid?: string = '';
  @property({ type: Array })
  employeesShortNamesAndI: { uuid: string; status: string; name: string }[] = [];
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Number })
  parentLevel = 0;
  @property({ type: String })
  parentLevelPath = '';

  _listItemClasses(open, status) {
    let classes = 'item';
    if (open) {
      classes += ' open';
      if (this.status === 'OK') {
        classes += ' ok';
      }
      if (this.status === 'NOT_OK') {
        classes += ' not-ok';
      }
    } else {
      if (status === 'OK') {
        classes += ' ok';
      }
      if (status === 'NOT_OK') {
        classes += ' not-ok';
      }
    }
    return classes;
  }

  _showComments(open, comments) {
    return !open && comments;
  }

  _formatDate(value) {
    return LocalDate.fromString(value).toStringForDisplay();
  }

  _checkedByTime(singleUserVersion, status, comments, checkedBy, checkedTime, employeesShortNamesAndI) {
    let result = '';
    let d = '';
    if (checkedTime) {
      d = LocalDate.fromString(checkedTime).toStringForDisplay();
    }
    if ((status !== '' || comments !== '') && checkedBy !== '' && employeesShortNamesAndI) {
      let name = '';
      if (!singleUserVersion) {
        const names = employeesShortNamesAndI.filter(function (item) {
          return item.uuid === checkedBy;
        });
        if (names) {
          name = names[0].name + ', ';
        }
      }
      result = name + d;
    }
    return result;
  }

  _cancel() {
    this.comments = this.previousComments;
    this.open = false;
  }

  _save() {
    if (this.currentEmployeeUuid === undefined) {
      throw new Error('Illegal state (E101), trying to save with current user not employee');
    }
    this.checkedBy = this.currentEmployeeUuid;
    this.checkedTime = LocalDate.now().toString();

    this.open = false;

    const data = JSON.parse(this.input?.clickData ?? '');
    this.dispatchEvent(
      new CustomEvent<{
        checkedBy: string;
        checkedTime: string;
        status: string;
        comments: string;
        assetUuid: string;
        eventOccurrenceUuid: string;
      }>('update-event-asset', {
        composed: true,
        bubbles: true,
        detail: {
          checkedBy: this.currentEmployeeUuid,
          checkedTime: this.checkedTime,
          status: this.status,
          comments: this.comments,
          assetUuid: data.assetUuid,
          eventOccurrenceUuid: data.eventOccurrenceUuid,
        },
      }),
    );
  }

  render() {
    if (this.input) {
      return html`
        <div class="${this._listItemClasses(this.open, this.input.assetCheckStatus)}">
          <div class="header">
            <div class="actions">
              <d-action mini .href=${shortenPath(this.input.href ?? '')}>Gå til enheten</d-action>
              <d-action mini @click=${() => this._cancel()}>Avbryt</d-action>
              <d-action mini @click=${() => this._save()}>Ferdig</d-action>
            </div>
            <div class="header-content" @click=${() => this.onOpen()}>
              <div>
                <div class="name">${this.input.label}</div>
                <div class="done-text">${this.input.assetCheckDoneText}</div>
              </div>
              <d-expansion speed="200" ?opened=${!this.open}>
                <div class="notes">${this.input.assetCheckComments}</div>
              </d-expansion>
            </div>
          </div>
          <d-expansion speed="200" ?opened=${this.open}>
            <div class="edit">
              <div class="icon-wrapper" @click=${this.onOpen}>
                <d-label label="Status"></d-label>
                <div class="icon-ok" @click=${this.onOk}>OK</div>
                <div class="icon-not-ok" @click=${this.onNotOk}>Ikke OK</div>
              </div>
              <d-edit-textarea
                placeholder="Kommentarer"
                .value="${this.comments ?? ''}"
                @value-changed=${this.onCommentsChanged}
              ></d-edit-textarea>
            </div>
          </d-expansion>
        </div>
      `;
    }
  }

  private onCommentsChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.comments = e.detail.value;
  }

  private onOpen() {
    if (this.input?.writeAccess && !this.open) {
      this.status = this.input.assetCheckStatus ?? 'NONE';
      this.comments = this.input.assetCheckComments ?? '';
      this.previousStatus = this.status;
      this.previousComments = this.comments;
      this.open = true;
    }
  }

  private onOk(e: Event) {
    e.stopPropagation();
    if (this.open) {
      if (this.status === 'OK') {
        this.status = 'NONE';
      } else {
        this.status = 'OK';
      }
    } else {
      this.onOpen();
    }
  }

  private onNotOk(e: Event) {
    e.stopPropagation();
    if (this.open) {
      if (this.status === 'NOT_OK') {
        this.status = 'NONE';
      } else {
        this.status = 'NOT_OK';
      }
    } else {
      this.onOpen();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-event-asset-item': DListSectionEventAssetItem;
  }
}
