/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssetsUpdateMessage
 */
export interface AssetsUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  owner?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  userManual?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  supplier?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  acquiredYear?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  radiationType: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  radiation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  dsaApproved: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  dsaRegistered: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  discardedYear?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  accessory?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  competency?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  maintenance?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  maintenanceInterval?: string;
  /**
   *
   * @type {string}
   * @memberof AssetsUpdateMessage
   */
  complianceDeclaration?: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  requiresElectricity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  storesSensitiveInformation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  maintenanceRequired?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  assetVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  documentationVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetsUpdateMessage
   */
  documented?: boolean;
}

/**
 * Check if a given object implements the AssetsUpdateMessage interface.
 */
export function instanceOfAssetsUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'radiationType' in value;
  isInstance = isInstance && 'radiation' in value;
  isInstance = isInstance && 'dsaApproved' in value;
  isInstance = isInstance && 'dsaRegistered' in value;

  return isInstance;
}

export function AssetsUpdateMessageFromJSON(json: any): AssetsUpdateMessage {
  return AssetsUpdateMessageFromJSONTyped(json, false);
}

export function AssetsUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetsUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    number: !exists(json, 'number') ? undefined : json['number'],
    category: !exists(json, 'category') ? undefined : json['category'],
    owner: !exists(json, 'owner') ? undefined : json['owner'],
    manufacturer: !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
    model: !exists(json, 'model') ? undefined : json['model'],
    location: !exists(json, 'location') ? undefined : json['location'],
    userManual: !exists(json, 'userManual') ? undefined : json['userManual'],
    supplier: !exists(json, 'supplier') ? undefined : json['supplier'],
    acquiredYear: !exists(json, 'acquiredYear') ? undefined : json['acquiredYear'],
    radiationType: json['radiationType'],
    radiation: json['radiation'],
    dsaApproved: json['dsaApproved'],
    dsaRegistered: json['dsaRegistered'],
    discardedYear: !exists(json, 'discardedYear') ? undefined : json['discardedYear'],
    accessory: !exists(json, 'accessory') ? undefined : json['accessory'],
    competency: !exists(json, 'competency') ? undefined : json['competency'],
    maintenance: !exists(json, 'maintenance') ? undefined : json['maintenance'],
    maintenanceInterval: !exists(json, 'maintenanceInterval') ? undefined : json['maintenanceInterval'],
    complianceDeclaration: !exists(json, 'complianceDeclaration') ? undefined : json['complianceDeclaration'],
    requiresElectricity: !exists(json, 'requiresElectricity') ? undefined : json['requiresElectricity'],
    storesSensitiveInformation: !exists(json, 'storesSensitiveInformation')
      ? undefined
      : json['storesSensitiveInformation'],
    maintenanceRequired: !exists(json, 'maintenanceRequired') ? undefined : json['maintenanceRequired'],
    assetVerified: !exists(json, 'assetVerified') ? undefined : json['assetVerified'],
    documentationVerified: !exists(json, 'documentationVerified') ? undefined : json['documentationVerified'],
    documented: !exists(json, 'documented') ? undefined : json['documented'],
  };
}

export function AssetsUpdateMessageToJSON(value?: AssetsUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    number: value.number,
    category: value.category,
    owner: value.owner,
    manufacturer: value.manufacturer,
    model: value.model,
    location: value.location,
    userManual: value.userManual,
    supplier: value.supplier,
    acquiredYear: value.acquiredYear,
    radiationType: value.radiationType,
    radiation: value.radiation,
    dsaApproved: value.dsaApproved,
    dsaRegistered: value.dsaRegistered,
    discardedYear: value.discardedYear,
    accessory: value.accessory,
    competency: value.competency,
    maintenance: value.maintenance,
    maintenanceInterval: value.maintenanceInterval,
    complianceDeclaration: value.complianceDeclaration,
    requiresElectricity: value.requiresElectricity,
    storesSensitiveInformation: value.storesSensitiveInformation,
    maintenanceRequired: value.maintenanceRequired,
    assetVerified: value.assetVerified,
    documentationVerified: value.documentationVerified,
    documented: value.documented,
  };
}
