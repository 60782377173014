/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { LeavePeriod } from './LeavePeriod';
import { LeavePeriodFromJSON, LeavePeriodFromJSONTyped, LeavePeriodToJSON } from './LeavePeriod';
import type { PlusTimePeriod } from './PlusTimePeriod';
import { PlusTimePeriodFromJSON, PlusTimePeriodFromJSONTyped, PlusTimePeriodToJSON } from './PlusTimePeriod';
import type { TimekeepingCorrection } from './TimekeepingCorrection';
import {
  TimekeepingCorrectionFromJSON,
  TimekeepingCorrectionFromJSONTyped,
  TimekeepingCorrectionToJSON,
} from './TimekeepingCorrection';
import type { TutorialState } from './TutorialState';
import { TutorialStateFromJSON, TutorialStateFromJSONTyped, TutorialStateToJSON } from './TutorialState';
import type { WorkSchedule } from './WorkSchedule';
import { WorkScheduleFromJSON, WorkScheduleFromJSONTyped, WorkScheduleToJSON } from './WorkSchedule';
import type { WorkScheduleException } from './WorkScheduleException';
import {
  WorkScheduleExceptionFromJSON,
  WorkScheduleExceptionFromJSONTyped,
  WorkScheduleExceptionToJSON,
} from './WorkScheduleException';

/**
 *
 * @export
 * @interface EmployeeViewModel
 */
export interface EmployeeViewModel {
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  postcode1?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  postcode2?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  secondaryPhone?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  associationType?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  profession?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeViewModel
   */
  isNew: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  firstName: string;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeViewModel
   */
  group?: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  herNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  hprNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  expertise?: string;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeViewModel
   */
  deletable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  draftName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeeViewModel
   */
  issues?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeeViewModel
   */
  riskAssessment?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeeViewModel
   */
  externalConnections?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeeViewModel
   */
  qualitySubGoalsResponsible?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  accessLevel?: EmployeeViewModelAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  gender: EmployeeViewModelGenderEnum;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  speciality?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  nextOfKin: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  staffGroup?: EmployeeViewModelStaffGroupEnum;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof EmployeeViewModel
   */
  attachments?: Array<AttachmentViewModel>;
  /**
   *
   * @type {Array<LeavePeriod>}
   * @memberof EmployeeViewModel
   */
  leavePeriods: Array<LeavePeriod>;
  /**
   *
   * @type {Array<WorkSchedule>}
   * @memberof EmployeeViewModel
   */
  workSchedules: Array<WorkSchedule>;
  /**
   *
   * @type {Array<WorkScheduleException>}
   * @memberof EmployeeViewModel
   */
  workScheduleExceptions: Array<WorkScheduleException>;
  /**
   *
   * @type {Array<PlusTimePeriod>}
   * @memberof EmployeeViewModel
   */
  plusTimePeriods: Array<PlusTimePeriod>;
  /**
   *
   * @type {Array<TimekeepingCorrection>}
   * @memberof EmployeeViewModel
   */
  timekeepingCorrections: Array<TimekeepingCorrection>;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  status?: EmployeeViewModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof EmployeeViewModel
   */
  summaries?: EmployeeViewModelSummariesEnum;
  /**
   *
   * @type {Array<TutorialState>}
   * @memberof EmployeeViewModel
   */
  tutorialStates: Array<TutorialState>;
  /**
   * Set to NONE to turn off all notifications using SMS.
   * @type {string}
   * @memberof EmployeeViewModel
   */
  receiveReminders?: EmployeeViewModelReceiveRemindersEnum;
}

/**
 * @export
 */
export const EmployeeViewModelAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type EmployeeViewModelAccessLevelEnum =
  (typeof EmployeeViewModelAccessLevelEnum)[keyof typeof EmployeeViewModelAccessLevelEnum];

/**
 * @export
 */
export const EmployeeViewModelGenderEnum = {
  Undefined: 'UNDEFINED',
  Male: 'MALE',
  Female: 'FEMALE',
} as const;
export type EmployeeViewModelGenderEnum =
  (typeof EmployeeViewModelGenderEnum)[keyof typeof EmployeeViewModelGenderEnum];

/**
 * @export
 */
export const EmployeeViewModelStaffGroupEnum = {
  G1: 'G1',
  G2: 'G2',
  G3: 'G3',
} as const;
export type EmployeeViewModelStaffGroupEnum =
  (typeof EmployeeViewModelStaffGroupEnum)[keyof typeof EmployeeViewModelStaffGroupEnum];

/**
 * @export
 */
export const EmployeeViewModelStatusEnum = {
  Active: 'ACTIVE',
  Leave: 'LEAVE',
  Terminated: 'TERMINATED',
} as const;
export type EmployeeViewModelStatusEnum =
  (typeof EmployeeViewModelStatusEnum)[keyof typeof EmployeeViewModelStatusEnum];

/**
 * @export
 */
export const EmployeeViewModelSummariesEnum = {
  None: 'NONE',
  All: 'ALL',
  Scheduled: 'SCHEDULED',
} as const;
export type EmployeeViewModelSummariesEnum =
  (typeof EmployeeViewModelSummariesEnum)[keyof typeof EmployeeViewModelSummariesEnum];

/**
 * @export
 */
export const EmployeeViewModelReceiveRemindersEnum = {
  None: 'NONE',
  Sms: 'SMS',
} as const;
export type EmployeeViewModelReceiveRemindersEnum =
  (typeof EmployeeViewModelReceiveRemindersEnum)[keyof typeof EmployeeViewModelReceiveRemindersEnum];

/**
 * Check if a given object implements the EmployeeViewModel interface.
 */
export function instanceOfEmployeeViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'isNew' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'gender' in value;
  isInstance = isInstance && 'nextOfKin' in value;
  isInstance = isInstance && 'leavePeriods' in value;
  isInstance = isInstance && 'workSchedules' in value;
  isInstance = isInstance && 'workScheduleExceptions' in value;
  isInstance = isInstance && 'plusTimePeriods' in value;
  isInstance = isInstance && 'timekeepingCorrections' in value;
  isInstance = isInstance && 'tutorialStates' in value;

  return isInstance;
}

export function EmployeeViewModelFromJSON(json: any): EmployeeViewModel {
  return EmployeeViewModelFromJSONTyped(json, false);
}

export function EmployeeViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    postcode1: !exists(json, 'postcode1') ? undefined : json['postcode1'],
    postcode2: !exists(json, 'postcode2') ? undefined : json['postcode2'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    secondaryPhone: !exists(json, 'secondaryPhone') ? undefined : json['secondaryPhone'],
    associationType: !exists(json, 'associationType') ? undefined : json['associationType'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    uuid: json['uuid'],
    address: !exists(json, 'address') ? undefined : json['address'],
    address2: !exists(json, 'address2') ? undefined : json['address2'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    isNew: json['isNew'],
    lastName: json['lastName'],
    firstName: json['firstName'],
    group: !exists(json, 'group') ? undefined : json['group'],
    herNumber: !exists(json, 'herNumber') ? undefined : json['herNumber'],
    hprNumber: !exists(json, 'hprNumber') ? undefined : json['hprNumber'],
    expertise: !exists(json, 'expertise') ? undefined : json['expertise'],
    deletable: !exists(json, 'deletable') ? undefined : json['deletable'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    issues: !exists(json, 'issues') ? undefined : json['issues'],
    riskAssessment: !exists(json, 'riskAssessment') ? undefined : json['riskAssessment'],
    externalConnections: !exists(json, 'externalConnections') ? undefined : json['externalConnections'],
    qualitySubGoalsResponsible: !exists(json, 'qualitySubGoalsResponsible')
      ? undefined
      : json['qualitySubGoalsResponsible'],
    accessLevel: !exists(json, 'accessLevel') ? undefined : json['accessLevel'],
    gender: json['gender'],
    speciality: !exists(json, 'speciality') ? undefined : json['speciality'],
    nextOfKin: json['nextOfKin'],
    staffGroup: !exists(json, 'staffGroup') ? undefined : json['staffGroup'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
    leavePeriods: (json['leavePeriods'] as Array<any>).map(LeavePeriodFromJSON),
    workSchedules: (json['workSchedules'] as Array<any>).map(WorkScheduleFromJSON),
    workScheduleExceptions: (json['workScheduleExceptions'] as Array<any>).map(WorkScheduleExceptionFromJSON),
    plusTimePeriods: (json['plusTimePeriods'] as Array<any>).map(PlusTimePeriodFromJSON),
    timekeepingCorrections: (json['timekeepingCorrections'] as Array<any>).map(TimekeepingCorrectionFromJSON),
    status: !exists(json, 'status') ? undefined : json['status'],
    summaries: !exists(json, 'summaries') ? undefined : json['summaries'],
    tutorialStates: (json['tutorialStates'] as Array<any>).map(TutorialStateFromJSON),
    receiveReminders: !exists(json, 'receiveReminders') ? undefined : json['receiveReminders'],
  };
}

export function EmployeeViewModelToJSON(value?: EmployeeViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    postcode1: value.postcode1,
    postcode2: value.postcode2,
    email: value.email,
    phone: value.phone,
    secondaryPhone: value.secondaryPhone,
    associationType: value.associationType,
    profession: value.profession,
    uuid: value.uuid,
    address: value.address,
    address2: value.address2,
    notes: value.notes,
    isNew: value.isNew,
    lastName: value.lastName,
    firstName: value.firstName,
    group: value.group,
    herNumber: value.herNumber,
    hprNumber: value.hprNumber,
    expertise: value.expertise,
    deletable: value.deletable,
    deleted: value.deleted,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    issues: value.issues,
    riskAssessment: value.riskAssessment,
    externalConnections: value.externalConnections,
    qualitySubGoalsResponsible: value.qualitySubGoalsResponsible,
    accessLevel: value.accessLevel,
    gender: value.gender,
    speciality: value.speciality,
    nextOfKin: value.nextOfKin,
    staffGroup: value.staffGroup,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
    leavePeriods: (value.leavePeriods as Array<any>).map(LeavePeriodToJSON),
    workSchedules: (value.workSchedules as Array<any>).map(WorkScheduleToJSON),
    workScheduleExceptions: (value.workScheduleExceptions as Array<any>).map(WorkScheduleExceptionToJSON),
    plusTimePeriods: (value.plusTimePeriods as Array<any>).map(PlusTimePeriodToJSON),
    timekeepingCorrections: (value.timekeepingCorrections as Array<any>).map(TimekeepingCorrectionToJSON),
    status: value.status,
    summaries: value.summaries,
    tutorialStates: (value.tutorialStates as Array<any>).map(TutorialStateToJSON),
    receiveReminders: value.receiveReminders,
  };
}
