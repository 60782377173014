/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { ContactPersonViewModel } from './ContactPersonViewModel';
import {
  ContactPersonViewModelFromJSON,
  ContactPersonViewModelFromJSONTyped,
  ContactPersonViewModelToJSON,
} from './ContactPersonViewModel';

/**
 *
 * @export
 * @interface PartnerViewModel
 */
export interface PartnerViewModel {
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  industry?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  uuid: string;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  isNew: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  remoteAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  physicalAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  pageAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  deletable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof PartnerViewModel
   */
  draftName?: string;
  /**
   *
   * @type {number}
   * @memberof PartnerViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof PartnerViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PartnerViewModel
   */
  externalConnections?: Array<string>;
  /**
   *
   * @type {Array<ContactPersonViewModel>}
   * @memberof PartnerViewModel
   */
  contacts?: Array<ContactPersonViewModel>;
  /**
   *
   * @type {Array<number>}
   * @memberof PartnerViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof PartnerViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * Check if a given object implements the PartnerViewModel interface.
 */
export function instanceOfPartnerViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'isNew' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function PartnerViewModelFromJSON(json: any): PartnerViewModel {
  return PartnerViewModelFromJSONTyped(json, false);
}

export function PartnerViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    url: !exists(json, 'url') ? undefined : json['url'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    industry: !exists(json, 'industry') ? undefined : json['industry'],
    name: json['name'],
    address: !exists(json, 'address') ? undefined : json['address'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    uuid: json['uuid'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    isNew: json['isNew'],
    remoteAccess: !exists(json, 'remoteAccess') ? undefined : json['remoteAccess'],
    physicalAccess: !exists(json, 'physicalAccess') ? undefined : json['physicalAccess'],
    pageAccess: !exists(json, 'pageAccess') ? undefined : json['pageAccess'],
    deletable: !exists(json, 'deletable') ? undefined : json['deletable'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    externalConnections: !exists(json, 'externalConnections') ? undefined : json['externalConnections'],
    contacts: !exists(json, 'contacts')
      ? undefined
      : (json['contacts'] as Array<any>).map(ContactPersonViewModelFromJSON),
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function PartnerViewModelToJSON(value?: PartnerViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    url: value.url,
    notes: value.notes,
    industry: value.industry,
    name: value.name,
    address: value.address,
    phone: value.phone,
    uuid: value.uuid,
    deleted: value.deleted,
    isNew: value.isNew,
    remoteAccess: value.remoteAccess,
    physicalAccess: value.physicalAccess,
    pageAccess: value.pageAccess,
    deletable: value.deletable,
    helpContent: value.helpContent,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    externalConnections: value.externalConnections,
    contacts:
      value.contacts === undefined ? undefined : (value.contacts as Array<any>).map(ContactPersonViewModelToJSON),
    pages: value.pages,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
