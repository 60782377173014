/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskAssessmentUpdateMessage
 */
export interface RiskAssessmentUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  responsibleBy?: string;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentUpdateMessage
   */
  probability?: number;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentUpdateMessage
   */
  consequence?: number;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  riskDescription?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  measures?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  assessmentDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentUpdateMessage
   */
  violatesInformationSecurityRequirements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentUpdateMessage
   */
  violatesIntegrityRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentUpdateMessage
   */
  violatesConfidentialityRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentUpdateMessage
   */
  violatesNonRepudiationRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentUpdateMessage
   */
  violatesAvailabilityRequirement?: boolean;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  relatedSubstance?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  determinedBy?: string;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentUpdateMessage
   */
  createdFromTemplate?: number;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof RiskAssessmentUpdateMessage
   */
  pages?: Array<number>;
}

/**
 * Check if a given object implements the RiskAssessmentUpdateMessage interface.
 */
export function instanceOfRiskAssessmentUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RiskAssessmentUpdateMessageFromJSON(json: any): RiskAssessmentUpdateMessage {
  return RiskAssessmentUpdateMessageFromJSONTyped(json, false);
}

export function RiskAssessmentUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RiskAssessmentUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    responsibleBy: !exists(json, 'responsibleBy') ? undefined : json['responsibleBy'],
    probability: !exists(json, 'probability') ? undefined : json['probability'],
    consequence: !exists(json, 'consequence') ? undefined : json['consequence'],
    riskDescription: !exists(json, 'riskDescription') ? undefined : json['riskDescription'],
    measures: !exists(json, 'measures') ? undefined : json['measures'],
    assessmentDate: !exists(json, 'assessmentDate') ? undefined : json['assessmentDate'],
    violatesInformationSecurityRequirements: !exists(json, 'violatesInformationSecurityRequirements')
      ? undefined
      : json['violatesInformationSecurityRequirements'],
    violatesIntegrityRequirement: !exists(json, 'violatesIntegrityRequirement')
      ? undefined
      : json['violatesIntegrityRequirement'],
    violatesConfidentialityRequirement: !exists(json, 'violatesConfidentialityRequirement')
      ? undefined
      : json['violatesConfidentialityRequirement'],
    violatesNonRepudiationRequirement: !exists(json, 'violatesNonRepudiationRequirement')
      ? undefined
      : json['violatesNonRepudiationRequirement'],
    violatesAvailabilityRequirement: !exists(json, 'violatesAvailabilityRequirement')
      ? undefined
      : json['violatesAvailabilityRequirement'],
    relatedSubstance: !exists(json, 'relatedSubstance') ? undefined : json['relatedSubstance'],
    determinedBy: !exists(json, 'determinedBy') ? undefined : json['determinedBy'],
    createdFromTemplate: !exists(json, 'createdFromTemplate') ? undefined : json['createdFromTemplate'],
    name: !exists(json, 'name') ? undefined : json['name'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
  };
}

export function RiskAssessmentUpdateMessageToJSON(value?: RiskAssessmentUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    responsibleBy: value.responsibleBy,
    probability: value.probability,
    consequence: value.consequence,
    riskDescription: value.riskDescription,
    measures: value.measures,
    assessmentDate: value.assessmentDate === undefined ? undefined : value.assessmentDate,
    violatesInformationSecurityRequirements: value.violatesInformationSecurityRequirements,
    violatesIntegrityRequirement: value.violatesIntegrityRequirement,
    violatesConfidentialityRequirement: value.violatesConfidentialityRequirement,
    violatesNonRepudiationRequirement: value.violatesNonRepudiationRequirement,
    violatesAvailabilityRequirement: value.violatesAvailabilityRequirement,
    relatedSubstance: value.relatedSubstance,
    determinedBy: value.determinedBy,
    createdFromTemplate: value.createdFromTemplate,
    name: value.name,
    pages: value.pages,
  };
}
