/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SubstanceUpdateMessage
 */
export interface SubstanceUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof SubstanceUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceUpdateMessage
   */
  supplierUuid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SubstanceUpdateMessage
   */
  hazardLabels?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SubstanceUpdateMessage
   */
  applications?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceUpdateMessage
   */
  location?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubstanceUpdateMessage
   */
  archived?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubstanceUpdateMessage
   */
  notes?: string;
}

/**
 * Check if a given object implements the SubstanceUpdateMessage interface.
 */
export function instanceOfSubstanceUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SubstanceUpdateMessageFromJSON(json: any): SubstanceUpdateMessage {
  return SubstanceUpdateMessageFromJSONTyped(json, false);
}

export function SubstanceUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstanceUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    hazardLabels: !exists(json, 'hazardLabels') ? undefined : json['hazardLabels'],
    applications: !exists(json, 'applications') ? undefined : json['applications'],
    location: !exists(json, 'location') ? undefined : json['location'],
    archived: !exists(json, 'archived') ? undefined : json['archived'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
  };
}

export function SubstanceUpdateMessageToJSON(value?: SubstanceUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    supplierUuid: value.supplierUuid,
    hazardLabels: value.hazardLabels,
    applications: value.applications,
    location: value.location,
    archived: value.archived,
    notes: value.notes,
  };
}
